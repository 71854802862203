import React from "react";
import { Grid, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import { useNavigate } from "react-router-dom";
import jobdescription from "../../../assets/Icons/JobDescription.png";
import about from "../../../assets/Icons/Company.png";
import JobCardJd from "../../../components/jobCards/topCardCandidateJd";
import JobCardJdMobile from "../../../components/jobCards/topCardCandidateJdMobile";
import JobCardMain from "../../../components/jobCards/jobCardMain";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  li: {
    marginBottom: "10px",
    marginLeft: "40px",
  },
  mainHome: {
    paddingTop: "80px",
    backgroundSize: "cover",
    backgroundColor: "#F6F8FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },

  container: {
    padding: "0px 75px",
  },
  maincard: {
    height: "auto",
    marginBottom: "20px",
  },

  topbar: {
    padding: "20px",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    // color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  jobbox: {
    padding: "20px",
    borderRadius: "10px",
    height: "auto",
    // color: "#5677B9",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  descriptionbox: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    // color: "#223870",
    backgroundColor: "#DFE5F6",
  },
  aboutbox: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    // color: "#223870",
    backgroundColor: "#DFE5F6",
  },

  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
    topbar: {
      marginTop: "-150px",
    },
  },
  "@media (max-width: 468px)": {
    mainHome: {
      padding: "50px 10px",
    },
    search: {
      marginRight: "0px",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0px 0px",
    },
    topbar: {
      marginTop: "50px",
      padding: "20px 5px",
    },
    descriptionbox: {
      padding: "20px 5px",
    },
  },
}));

const JobDescriptionBeforeSignup = () => {
  const classes = useStyles();
  const handleYears = (event) => {
    setYears(event.target.value);
  };
  const [years, setYears] = React.useState("Experience");
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/JobDescription");
  };
  const redirectApply = () => {
    navigate("/candidateRegister");
  };
  return (
    <>
      <Navbar />
      <Box className={classes.mainHome}>
        <Box className={classes.container}>
          <Box className={classes.maincard}>
            <Box
              className={classes.topbar}
              sx={{
                "@media (max-width: 468px)": {
                  display: "none",
                },
              }}
            >
              <JobCardJd />
            </Box>
            <Box
              className={classes.topbar}
              sx={{
                // display: "none",
                "@media (min-width: 468px)": {
                  display: "none",
                },
              }}
            >
              <JobCardJdMobile />
            </Box>
            <Box className={classes.descriptionbox}>
              {" "}
              <Grid container>
                <Grid Item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Location"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          width: "18px",
                          height: "18px",
                          "@media (max-width: 468px)": {
                            marginLeft: "-5px",
                            marginTop: "-5px",
                            width: "16px",
                            height: "16px",
                          },
                        }}
                        src={jobdescription}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media (min-width: 1640px)": { marginLeft: "-110px" },
                      marginLeft: "-55px",
                      fontSize: "18px",
                      textAlign: "left",
                      color: "#304672",
                      "@media (max-width: 468px)": {
                        fontSize: "16px",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    Job Description
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Typography
                variant="p"
                sx={{
                  textAlign: "left",
                  color: "#5677B9",
                  fontWeight: "500",
                  marginBottom: "20px",
                  "@media (max-width: 468px)": {
                    fontSize: "14px",
                  },
                }}
              >
                Nunc vel risus commodo viverra maecenas accumsan. At lectus urna
                duis convallis. Pellentesque sit amet porttitor eget dolor morbi
                non arcu. Amet tellus cras adipiscing enim eu turpis egestas
                pretium. Volutpat consequat mauris nunc congue nisi vitae.
                Elementum eu facilisis sed odio morbi. Pretium nibh ipsum
                consequat nisl vel pretium lectus. Lectus urna duis convallis
                convallis tellus id interdum. Eros donec ac odio tempor orci
                dapibus ultrices in iaculis. Diam vel quam elementum pulvinar
                etiam. Amet volutpat consequat mauris nunc congue nisi vitae
                suscipit tellus. Vitae sapien pellentesque habitant morbi
                tristique.
                <br />
                <br />
                <ul>
                  <li className={classes.li}>
                    Nibh sit amet commodo nulla facilisi nullam vehicula. Dolor
                    sit amet consectetur adipiscing elit duis. Nunc sed blandit
                    libero volutpat sed cras ornare arcu. A pellentesque sit
                    amet porttitor eget dolor morbi non. Augue eget arcu dictum
                    varius duis at consectetur lorem donec.
                  </li>
                  <li className={classes.li}>
                    Neque convallis a cras semper auctor neque vitae. Ridiculus
                    mus mauris vitae ultricies leo integer malesuada nunc vel.
                    Eleifend mi in nulla posuere sollicitudin aliquam. In eu mi
                    bibendum neque egestas congue quisque.
                  </li>
                  <li className={classes.li}>
                    Faucibus et mestie ac feugiat sed. Condimentum lacinia quis
                    vel eros donec ac odio tempor.
                  </li>
                  <li className={classes.li}>
                    Commodo elit at imperdiet dui accumsan sit amet nulla
                    facilisi. Integer feugiat scelerisque varius morbi enim nunc
                    faucibus a pellentesque. Cur sus in hac habitasse platea.
                    Praesent tristique magna sit amet purus. Cras tincidunt
                    lobortis feugiat vivamus at augue eget.
                  </li>
                  <li className={classes.li}>
                    {" "}
                    Aenean pharetra magna ac placerat. Molestie nunc non blandit
                    massa enim nec dui nunc mattis. In eu mi bibendum neque
                    egestas congue qui sque egestas diam. Nec feugiat nisl
                    pretium fusce id velit ut tortor. In hac habitasse platea
                    dictumst vestibulum rhoncus est pellentesque elit.
                  </li>
                  <li className={classes.li}>
                    Convallis posuere morbi leo urna. Nulla facilisi nullam
                    vehicula ipsum a arcu cursus vitae. Tellus in metus
                    vulputate eu scelerisque felis imperdiet proin fermentum. Mi
                    in nulla posuere sollicitudin. Tortor aliquam nulla facilisi
                    cras. Aliquet sagittis id consectetur purus ut.
                  </li>
                  <li className={classes.li}>
                    Nec feugiat nisl pretium fusce. In pellentesque massa
                    placerat duis ultricies lacus sed. Est ultricies integer
                    quis auctor elit sed vulputate mi sit. Gravida arcu ac
                    tortor dignissim convallis aenean.
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box className={classes.aboutbox}>
              {" "}
              <Grid container>
                <Grid Item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Location"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          width: "16px",
                          height: "16px",
                          "@media (max-width: 468px)": {
                            marginLeft: "-10px",
                            marginTop: "-5px",
                            width: "14px",
                            height: "14px",
                          },
                        }}
                        src={about}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media (min-width: 1640px)": { marginLeft: "-110px" },
                      marginLeft: "-55px",
                      fontSize: "18px",
                      textAlign: "left",
                      color: "#304672",
                      "@media (max-width: 468px)": {
                        fontSize: "16px",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    About The Company
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Typography
                variant="p"
                sx={{
                  textAlign: "left",
                  color: "#5677B9",
                  fontWeight: "500",
                  marginBottom: "20px",
                }}
              >
                Euismod elementum nisi quis eleifend. Elementum sagittis vitae
                et leo duis ut diam. Est ante in nibh mauris cursus mattis me a
                iaculis. Ultricies mi eget mauris pharetra. Bibendum neque
                egestas congue quisque egestas diam in. Condimentum vitae sapien
                pellentesque habitant morbi. Lectus sit amet est placerat in.
                Dignissim convallis aenean et tortor at risus viverra adi
                piscing. Ultrices mi tempus imperdiet nulla malesuada
                pellentesque elit eget. Erat pellentesque adipiscing commodo
                elit at imperdiet dui accumsan sit. Eget sit amet tellus cras
                adipiscing enim eu turpis. Viverra mauris in aliquam sem
                fringilla utmor Abi. Quis varius quam quisque id diam. Duis
                ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
                Mattis pellentesque id nibh tortor id. Non diam phasellus
                vestibulum lorem sed risus ultricies tristique.s. Vitae sapien
                pellentesque habitant morbi tristique.
              </Typography>
            </Box>

            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                color: "#5677B9",
                fontWeight: "600",
                marginBottom: "20px",
              }}
            >
              Similar Jobs
            </Typography>
            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {" "}
              <Grid Item xs={12} sm={6}>
                <Box
                  className={classes.jobbox}
                  sx={{
                    margin: "10px 10px 10px 0px",
                    "@media (max-width: 468px)": {
                      margin: "0px",
                    },
                  }}
                >
                  <JobCardMain />
                </Box>
              </Grid>
              <Grid Item xs={12} sm={6}>
                <Box
                  className={classes.jobbox}
                  sx={{
                    margin: "10px 0px 10px 10px",
                    "@media (max-width: 468px)": {
                      margin: "10px 0px 0px 0px",
                    },
                  }}
                >
                  <JobCardMain />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default JobDescriptionBeforeSignup;
