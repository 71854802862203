import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import companylogo from "../../assets/images/dummy.png";
import experience from "../../assets/Icons/Experience.png";
import jobtype from "../../assets/Icons/JobType.png";
import industry from "../../assets/Icons/Industry.png";
import location from "../../assets/Icons/Location.png";
import date from "../../assets/Icons/Calendar.png";
import designation from "../../assets/Icons/Function.png";
import salary from "../../assets/Icons/Salary.png";
import applicant from "../../assets/Icons/Applicants.png";
import jobdescription from "../../assets/Icons/JobDescription.png";
import Edu from "../../assets/Icons/Education.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
}));

const JobCardJd = (props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const redirect = () => {
    navigate("/JobDescription");
  };
  const redirectApply = () => {
    navigate("/candidateRegister");
  };
  return (
    <>
      <Grid container>
        <Grid Item>
          <Typography
            variant="h5"
            sx={{
              marginTop: "15px",
              marginLeft: "20px",
              textAlign: "left",
              color: "#304672",
              fontWeight: "600",
              "@media (max-width: 468px)": {
                marginTop: "0px",
                marginLeft: "0px",
                fontSize: "22px",
              },
            }}
          >
            Designation
          </Typography>
          <Typography
            variant="h7"
            sx={{
              marginLeft: "20px",
              textAlign: "left",
              color: "#304672",
              fontWeight: "600",
              "@media (max-width: 468px)": {
                marginTop: "15px",
                marginLeft: "0px",
                fontSize: "18px",
              },
            }}
          >
            Company name
          </Typography>
        </Grid>
        <Grid Item>
          <Box
            component="img"
            src={companylogo}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "80px",
              height: "80px",
              "@media (max-width: 468px)": {
                right: "15px",
                width: "60px",
                height: "60px",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid Item xs={12} sm={8}>
          <Grid container sx={{ marginTop: "20px" }}>
            <Grid Item sm={4}>
              {" "}
              <Grid container>
                <Grid Item xs={1} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Total Experience"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (max-width: 468px)": {
                            width: "15px",
                            height: "15px",
                          },
                        }}
                        src={experience}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (max-width: 468px)": { fontSize: "14px" },
                    }}
                  >
                    Experience in Years
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Job Type"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (max-width: 468px)": {},
                        }}
                        src={jobtype}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (max-width: 468px)": {},
                    }}
                  >
                    Job Type
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Industry"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (max-width: 468px)": {},
                        }}
                        src={industry}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (max-width: 468px)": {},
                    }}
                  >
                    Industry
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid Item sm={4}>
              {" "}
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Location"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (max-width: 468px)": {},
                        }}
                        src={location}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (max-width: 468px)": {},
                    }}
                  >
                    Location
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    disableFocusListener
                    disableTouchListener
                    title="Date"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={date}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                    }}
                  >
                    Date(2 Jul 2022)
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Designation"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={designation}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                    }}
                  >
                    Function
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid Item sm={4}>
              {" "}
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title=" Salary"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={salary}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                    }}
                  >
                    Salary
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={12} sm={2}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title=" Total applicants"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={applicant}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={12} sm={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "-10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                    }}
                  >
                    Total applicants(13 Applicants)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid Item xs={1} sm={1}>
              <Tooltip
                enterNextDelay={1500}
                enterDelay={1500}
                leaveDelay={200}
                title="Job Description"
                placement="left"
                arrow
              >
                <IconButton>
                  {" "}
                  <Box
                    component="img"
                    sx={{
                      width: "18px",
                      height: "18px",
                    }}
                    src={Edu}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid Item xs={10} sm={10}>
              <Box
                sx={{
                  marginTop: "3px",
                  marginLeft: "-23px",
                  textAlign: "left",
                  color: "#5677B9",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    color: "#5677B9",
                    fontWeight: "500",
                    marginBottom: "20px",
                    "@media (max-width: 468px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  Education
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
            sx={{ "@media (max-width: 468px)": { display: "none" } }}
          >
            <Grid Item xs={1} sm={1}>
              <Tooltip
                enterNextDelay={1500}
                enterDelay={1500}
                leaveDelay={200}
                title="Job Description"
                placement="left"
                arrow
              >
                <IconButton>
                  {" "}
                  <Box
                    component="img"
                    sx={{
                      marginLeft: "2px",
                      width: "18px",
                      height: "18px",
                    }}
                    src={jobdescription}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid Item xs={10} sm={10}>
              <Box
                sx={{
                  marginTop: "3px",
                  marginLeft: "-23px",
                  textAlign: "left",
                  "@media (min-width: 1450px)": { marginLeft: "-38px" },
                  "@media (min-width: 1350px)": { marginLeft: "-28px" },
                  "@media (min-width: 1580px)": { marginLeft: "-40px" },
                  "@media (min-width: 1540px)": { marginLeft: "-30px" },
                  "@media (min-width: 1570px)": { marginLeft: "-35px" },
                  "@media (min-width: 1600px)": { marginLeft: "-40px" },
                  "@media (min-width: 1680px)": { marginLeft: "-40px" },
                  "@media (min-width: 1760px)": { marginLeft: "-50px" },
                  "@media (min-width: 1800px)": { marginLeft: "-50px" },
                  "@media (min-width: 1868px)": { marginLeft: "-50px" },
                  "@media (min-width: 1900px)": { marginLeft: "-50px" },
                  "@media (min-width: 2000px)": { marginLeft: "-70px" },
                  "@media (min-width: 2100px)": { marginLeft: "-75px" },
                  "@media (min-width: 2200px)": { marginLeft: "-80px" },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    color: "#5677B9",
                    fontWeight: "500",
                    marginBottom: "20px",
                  }}
                >
                  Nunc vel risus commodo viverra maecenas accumsan. At lectus
                  urna duis convallis. Pellentesque sit amet Volutpat consequat
                  mauris nunc congue nisi vitae..
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid Item xs={12} sm={4}>
          <Grid
            container
            justify="center"
            alignItems="center"
            sx={{
              marginTop: "60px",
            }}
          >
            <Grid Item>
              {" "}
              <Button
                onClick={redirectApply}
                type="submit"
                sx={{
                  marginLeft: "80px",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "3px 30px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "16px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                  "@media (min-width: 1536px)": {
                    fontSize: "20px",
                  },
                }}
              >
                Apply
              </Button>
            </Grid>
            <Grid Item>
              {" "}
              <Button
                type="submit"
                sx={{
                  marginLeft: "20px",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "4px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "16px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                  "@media (min-width: 1640px)": {
                    fontSize: "20px",
                    marginLeft: "40px",
                  },
                }}
              >
                Refer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default JobCardJd;
