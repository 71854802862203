/** @format */

import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Card1Bg from "../../../assets/images/PermanentStaffing.png";
import Card2Bg from "../../../assets/images/ContigentStaffing.png";
import Card3Bg from "../../../assets/images/ExecutiveSearch.png";
import Card4Bg from "../../../assets/images/ConsultingServices.png";
import Card5Bg from "../../../assets/images/Niche&DiversityHiring.png";
import Card6Bg from "../../../assets/images/RecruitmentProcessingOutsourcing.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
  },
  serviceContainer: {
    padding: "50px auto",
    backgroundColor: "#F3F2FB",
    height: "auto",
    width: "100%",
    textAlign: "center",
  },
  title: {
    paddingTop: "90px",
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
  },
  title1: {
    paddingTop: "5px",
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
  },
  cards: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
    textAlign: "center",
    width: "100%",
  },
  card1: {
    margin: "auto 10px",
    backgroundImage: `url(${Card1Bg})`,
    width: "auto",
    height: "200px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
  },
  card2: {
    margin: "auto 10px",
    backgroundImage: `url(${Card2Bg})`,
    width: "auto",
    height: "200px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
  },
  card3: {
    margin: "auto 10px",
    backgroundImage: `url(${Card3Bg})`,
    width: "auto",
    height: "200px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
  },
  card4: {
    margin: "auto 10px",
    backgroundImage: `url(${Card4Bg})`,
    width: "auto",
    height: "200px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
  },
  card5: {
    margin: "auto 10px",
    backgroundImage: `url(${Card5Bg})`,
    width: "auto",
    height: "200px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
  },
  card6: {
    margin: "auto 10px",
    backgroundImage: `url(${Card6Bg})`,
    width: "auto",
    height: "200px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
  },
  heading: {
    fontSize: "22px",
    lineHeight: "24px",
    fontWeight: " 500",
    color: "#fdcf57",
    marginBottom: "10px",
  },
  text: {
    margin: "10px 20px 0px 50px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    textAlign: "left",
  },
  text1: {
    marginTop: "15px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    textAlign: "center",
  },
  "@media (max-width: 1200px)": {
    cards: {
      marginLeft: "0px",
      padding: "0px",
    },
    card1: {
      width: "auto",
      height: "180px",
      marginBottom: "10px",
    },
    card2: {
      width: "auto",
      height: "180px",
      marginBottom: "10px",
    },
    card3: {
      width: "auto",
      height: "180px",
      marginBottom: "10px",
    },
    card4: {
      width: "auto",
      height: "180px",
      marginBottom: "10px",
    },
    card5: {
      width: "auto",
      height: "180px",
      marginBottom: "10px",
    },
    card6: {
      width: "auto",
      height: "180px",
      marginBottom: "10px",
    },
    heading: {
      fontSize: "20px",
      lineHeight: "20px",
    },
    text: {
      margin: "0px 20px",
      fontSize: "14px",
      lineHeight: "16px",
    },
    text1: {
      margin: "0px 20px",
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  "@media (min-width: 1536px)": {
    cards: {
      marginLeft: "10px",
    },
    card1: {
      width: "auto",
      height: "200px",
      margin: "0px 15px",
      paddingTop: "50px",
    },
    card2: {
      width: "auto",
      height: "200px",
      margin: "0px 15px",
      paddingTop: "50px",
    },
    card3: {
      width: "auto",
      height: "200px",
      margin: "0px 15px",
      paddingTop: "50px",
    },
    card4: {
      width: "auto",
      height: "200px",
      margin: "0px 15px",
      paddingTop: "50px",
    },
    card5: {
      width: "auto",
      height: "200px",
      margin: "0px 15px",
      paddingTop: "50px",
    },
    card6: {
      width: "auto",
      height: "200px",
      margin: "0px 15px",
      paddingTop: "50px",
    },
  },
  "@media (max-width: 468px)": {
    text: {
      fontSize: "16px",
      lineHeight: "18px",
      margin: "10px 20px 0px 50px",
    },
    text1: {
      fontSize: "16px",
      lineHeight: "18px",
    },
    serviceContainer: { padding: "10px 15px", margin: "0" },
    title: {
      paddingBottom: "10px",
      fontSize: "26px",
      lineHeight: "30px",
    },
    title1: {
      marginTop: "15px",
      paddingBottom: "10px",
      fontSize: "26px",
      lineHeight: "30px",
    },
    subtitle: {
      fontSize: "20px",
      lineHeight: "22px",
    },
    heading: {
      fontSize: "18px",
      lineHeight: "22px",
    },
    cards: {
      marginLeft: "0px",
    },
    card1: {
      width: "auto",
      height: "170px",
      padding: "10px",
    },
    card2: {
      width: "auto",
      height: "170px",
      padding: "10px",
    },
    card3: {
      width: "auto",
      height: "170px",
      padding: "10px",
    },
    card4: {
      width: "auto",
      height: "170px",
      padding: "10px",
    },
    card5: {
      width: "auto",
      height: "170px",
      padding: "10px",
    },
    card6: {
      width: "auto",
      height: "170px",
      padding: "10px",
    },
  },
}));

const Services = () => {
  const classes = useStyles();
  return (
    <Box className={classes.serviceContainer} id='services'>
      <Box className={classes.title}>Services Offered </Box>

      <Grid
        container
        spacing={0}
        justify='space-between'
        alignItems='stretch'
        columns={{ xs: 2, sm: 9, md: 14, lg: 14 }}
        className={classes.cards}>
        <Grid Item xs={2} sm={4} md={4} style={{ textalign: "center" }}>
          <Box className={classes.card1}>
            <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
              Recruitment
            </Box>
            <Box className={classes.text} style={{ color: "#304672" }}>
              <ul>
                <li>End to End Staffing</li>
                <li>Permanent</li>
                <li>Contractual</li>
                <li>Campus hiring</li>
              </ul>
            </Box>
          </Box>
        </Grid>
        <Grid Item xs={2} sm={4} md={4} style={{ textalign: "center" }}>
          <Box className={classes.card2}>
            <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
              RPO
            </Box>
            <Box className={classes.text} style={{ color: "#304672" }}>
              <ul>
                <li>Deep dive services in high volume/strategic recruitment</li>
                <li>Cost Effective</li>
                <li>Time Effective</li>
                <li>Process Effective Consulting</li>
              </ul>
            </Box>
          </Box>
        </Grid>
        <Grid Item xs={2} sm={4} md={4} style={{ textalign: "center" }}>
          <Box className={classes.card3}>
            <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
              HRM Consulting
            </Box>
            <Box className={classes.text} style={{ color: "#304672" }}>
              <ul>
                <li>Succession planning</li>
                <li>Org restructuring</li>
                <li>Policy restructuring </li>
                <li>Employee engagement</li>
                <li>Compensation benchmarking</li>
                <li>Competitor analysis</li>
              </ul>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.title1}>Key differentiators</Box>
      <Grid
        container
        spacing={0}
        justify='space-between'
        alignItems='stretch'
        columns={{ xs: 2, sm: 9, md: 14, lg: 14 }}
        className={classes.cards}>
        <Grid Item xs={2} sm={4} md={4} style={{ textalign: "center" }}>
          <Box className={classes.card4}>
            <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
              AI enabled screening platform
            </Box>
            <Box className={classes.text1} style={{ color: "#304672" }}>
              Our AI enabled platform helps to shortlist the ideal candidates,
              as well as automate manual tasks in the recruitment process.
            </Box>
          </Box>
        </Grid>
        <Grid Item xs={2} sm={4} md={4} style={{ textalign: "center" }}>
          <Box className={classes.card5}>
            <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
              Team of industry experts
            </Box>
            <Box className={classes.text1} style={{ color: "#304672" }}>
              Our team is comprised of industry experts who are passionate about
              their work, have excelled in their field and have an extensive
              background of over 20+ years of industry exposure.
            </Box>
          </Box>
        </Grid>
        <Grid Item xs={2} sm={4} md={4} style={{ textalign: "center" }}>
          <Box className={classes.card6}>
            <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
              Candidate management
            </Box>
            <Box className={classes.text1} style={{ color: "#304672" }}>
              Our candidate management teams help in building and maintaining a
              healthy relationship with candidates & this helps to reduce
              decline ratio.
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Services;
