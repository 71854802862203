import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography, Stack } from '@mui/material';
import Logo from '../../../assets/images/LogowithTagline.png';
import dropDown from '../../../assets/Icons/Dropdown.png';
import UpArrow from '../../../assets/Icons/UpArrow.png';
import CustomizedSnackbars from '../../../components/Snackbar/CustomizedSnackbars';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Navbar from '../../../components/Header/Navbar';
import JobCardMain from '../../../components/jobCards/jobCardMain';
import JobCandidateCardnew from '../../../components/jobCards/JobCandidateCardnew';
import { useSelector, useDispatch } from 'react-redux';
import * as jobsActions from '../../../Store/Jobs';
import * as authActions from '../../../Store/auth';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  container: { width: 'auto', padding: '0', margin: '0' },

  topbar: {
    position: 'relative',
    marginTop: '80px',
    padding: '20px 75px',
    backgroundSize: 'cover',
    backgroundColor: '#DFE5F6',
    height: 'auto',
    width: '100%',
    backgroundPosition: 'center',
  },
  imgFrame: {
    background: '#fff',
    width: '120px',
    height: '120px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  img: {
    objectFit: 'contain',
    margin: '0 auto',
    width: '110px',
    height: '110px',
    borderRadius: '5px',
    backgroundSize: 'cover',
  },
  text: { marginLeft: '30px' },
  button: {
    position: 'absolute',
    top: '55px',
    right: '100px',
  },
  body: {
    padding: '20px 0px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#F6F8FD',
    textAlign: 'center',
  },
  jobbox: {
    marginLeft: '15px',
    padding: '20px 0px',
    marginBottom: '20px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#EEF3FD',
    position: 'relative',
  },

  '@media (min-width: 1640px)': {
    mainHome: {
      marginTop: '80px',
      paddingTop: '150px',
    },
    title: {
      paddingRight: '60px ',
    },
  },
  '@media (max-width: 468px)': {
    title: {
      fontSize: '22px',
      textAlign: 'center',
      lineHeight: '26px',
    },
    jobbox: {
      width: '100%',
      margin: ' 20px 0px 0px 0px',
      padding: '0px 5px',
    },
    container: {
      width: '100%',
      padding: '0px 0px',
    },
    topbar: {
      position: 'relative',
      padding: '20px 10px',
      height: '170px',
      width: '100%',
    },
    imgFrame: {
      width: '90px',
      height: '90px',
    },
    img: {
      width: '80px',
      height: '80px',
    },
    text: { marginLeft: '10px' },
    button: {
      position: 'absolute',
      top: '120px',
      right: '20px',
    },
    body: {
      padding: '0px',
      borderRadius: '10px',
    },
  },
}));

const JobCandidateList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  const redirect = () => {
    navigate('/createJob');
  };

  const redirectedit = () => {
    navigate('/editJob');
  };
  const redirectCompanyJd = () => {
    navigate('/companyJd');
  };
  const [pageNoOpen, setPageNoOpen] = React.useState(0);
  const [pageNoClosed, setPageNoClosed] = React.useState(0);
  const [pageNoInactive, setPageNoInactive] = React.useState(0);
  const profile = useSelector((state) => state?.auth?.profile);

  useEffect(() => {
    console.log('keywords', params);
    async function jobDetails() {
      const id = params?.id;
      // const inactiveJobList = await dispatch(jobsActions.getJobDescription(id));
    }
    jobDetails();
  }, []);

  useEffect(() => {
    console.log('keywords', params);
    async function jobDetails() {
      const id = '62eb98b0eb8841c395dd00f0';
      const applicantProfile = await dispatch(
        jobsActions.getJobCandidateProfile(id)
      );
    }
    jobDetails();
  }, []);

  useEffect(() => {
    async function companyProfile() {
      const profile = await dispatch(authActions.getProfile());
      console.log('closedJobList ==========', profile);
    }
    companyProfile();
  }, []);

  const [numberOfOpenJobs, setNumberOfOpenJobs] = React.useState(2);
  const [numberOfInactiveJobs, setNumberOfInactiveJobs] = React.useState(2);
  const [numberOfClosedJobs, setNumberOfClosedJobs] = React.useState(2);
  const [noOfrejectedcandi, setNoOfrejectedcandi] = React.useState(2);
  const jobCandidateApply = useSelector(
    (state) => state?.jobs?.jobCandidateApply
  );
  console.log('openJobs==================', jobCandidateApply);
  const inactiveJobs = useSelector(
    (state) => state?.jobs?.createdInactiveJobList
  );
  console.log('inactiveJobs==================', inactiveJobs);
  const jobCandidateShortlist = useSelector(
    (state) => state?.jobs?.jobCandidateShortlist
  );

  const jobCandidateHold = useSelector(
    (state) => state?.jobs?.jobCandidateHold
  );

  const jobCandidateReject = useSelector(
    (state) => state?.jobs?.jobCandidateReject
  );
  console.log('closedJobs==================', jobCandidateShortlist);
  const seeMoreOpen = () => {
    if (jobCandidateApply?.length > numberOfOpenJobs) {
      setNumberOfOpenJobs(jobCandidateApply?.length);
    }
  };
  const seeMoreInactive = () => {
    if (jobCandidateHold?.length > numberOfInactiveJobs) {
      setNumberOfInactiveJobs(jobCandidateHold?.length);
    }
  };
  const seeMoreClosed = () => {
    if (jobCandidateHold?.length > numberOfClosedJobs) {
      setNumberOfClosedJobs(jobCandidateHold?.length);
    }
  };

  const seeMoreRejected = () => {
    if (jobCandidateReject?.length > noOfrejectedcandi) {
      setNoOfrejectedcandi(jobCandidateReject?.length);
    }
  };
  const seeLessOpen = () => {
    setNumberOfOpenJobs(2);
  };
  const seeLessInactive = () => {
    setNumberOfOpenJobs(2);
  };

  const seeLessClosed = () => {
    setNumberOfOpenJobs(2);
  };

  const seeRejectedLess = () => {
    setNoOfrejectedcandi(2);
  };
  return (
    <>
      <Navbar />
      <Box className={classes.container}>
        {' '}
        <Box className={classes.body}>
          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Applied{' '}
                {jobCandidateApply?.length > 0 ? jobCandidateApply?.length : ''}
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
                onClick={seeMoreOpen}
              >
                {jobCandidateApply?.length > 0 &&
                jobCandidateApply?.length !== numberOfOpenJobs ? (
                  <Box
                    component='img'
                    sx={{
                      width: '20px',
                      height: '20px',
                      '@media (max-width: 468px)': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component='img'
                    sx={{
                      width: '20px',
                      height: '20px',
                      '@media (max-width: 468px)': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {jobCandidateApply?.length > 0 &&
                jobCandidateApply?.length === numberOfOpenJobs && (
                  <Button
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      width: '20px',
                      height: '20px',
                      '@media (max-width: 468px)': {
                        width: '16px',
                        height: '16px',
                        top: '10px',
                      },
                    }}
                    onClick={seeLessOpen}
                  >
                    <Box
                      component='img'
                      sx={{
                        width: '20px',
                        height: '20px',
                        '@media (max-width: 468px)': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {jobCandidateApply?.length > 0 &&
                jobCandidateApply
                  ?.slice(0, numberOfOpenJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Shortlisted{' '}
                {jobCandidateShortlist?.length > 0
                  ? jobCandidateShortlist?.length
                  : ''}
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
              >
                {' '}
                <Box
                  component='img'
                  sx={{
                    width: '20px',
                    height: '20px',
                    '@media (max-width: 468px)': {
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreInactive}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {jobCandidateShortlist?.length > 0 &&
                jobCandidateShortlist
                  ?.slice(0, numberOfOpenJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Hold{' '}
                {jobCandidateHold?.length > 0 ? jobCandidateHold?.length : ''}
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
              >
                {' '}
                <Box
                  component='img'
                  sx={{
                    width: '20px',
                    height: '20px',
                    '@media (max-width: 468px)': {
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreClosed}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {jobCandidateHold?.length > 0 &&
                jobCandidateHold
                  ?.slice(0, numberOfClosedJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>

          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Rejected{' '}
                {jobCandidateReject?.length > 0
                  ? jobCandidateReject?.length
                  : ''}
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
              >
                {' '}
                <Box
                  component='img'
                  sx={{
                    width: '20px',
                    height: '20px',
                    '@media (max-width: 468px)': {
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreRejected}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {jobCandidateReject?.length > 0 &&
                jobCandidateReject
                  ?.slice(0, noOfrejectedcandi)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default JobCandidateList;
