import React, { useEffect } from "react";

import { Grid, Box, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import CustomizedSnackbars from "../../../components/Snackbar/CustomizedSnackbars";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import companylogo from "../../../assets/images/dummy.png";
import experience from "../../../assets/Icons/Experience.png";
import jobtype from "../../../assets/Icons/JobType.png";
import industry from "../../../assets/Icons/Industry.png";
import location from "../../../assets/Icons/Location.png";
import date from "../../../assets/Icons/Calendar.png";
import designation from "../../../assets/Icons/Function.png";
import salary from "../../../assets/Icons/Salary.png";
import applicant from "../../../assets/Icons/Applicants.png";
import jobdescription from "../../../assets/Icons/JobDescription.png";
import aboutCompany from "../../../assets/Icons/AboutTheCompany.png";
import dropDown from "../../../assets/Icons/Dropdown.png";
import education from "../../../assets/Icons/Education.png";
import Skills from "../../../assets/Icons/Skills.png";
import JobCardCompanyJd from "../../../components/jobCards/topCardCompanyJd";
import JobCardCompanyJdMobile from "../../../components/jobCards/topCardCompanyJdMobile";
import JobCandidateList from "./JobCandidateList";
import { saveAs } from "file-saver";

import * as jobsActions from "../../../Store/Jobs";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  li: {
    marginBottom: "10px",
    marginLeft: "40px",
  },
  mainHome: {
    paddingTop: "80px",
    backgroundSize: "cover",
    backgroundColor: "#F6F8FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },

  container: {
    padding: "0px 75px",
  },
  maincard: {
    height: "auto",
    marginBottom: "20px",
  },

  topbar: {
    padding: "20px",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    // color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  jobbox: {
    padding: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  descriptionbox: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
  },
  aboutbox: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
  },

  "@media (min-width: 1600px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
    topbar: {
      marginTop: "-150px",
    },
  },
  "@media (max-width: 468px)": {
    mainHome: {
      padding: "50px 10px",
    },
    search: {
      marginRight: "0px",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0px 0px",
    },
    topbar: {
      marginTop: "50px",
      padding: "20px 5px",
    },
    descriptionbox: {
      padding: "20px 5px",
    },
  },
}));

const CompanyJD = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let params = useParams();
  const dispatch = useDispatch();

  const jobDetails = useSelector((state) => state?.jobs?.jobDetails);
  const candidateJobStatus = useSelector(
    (state) => state?.jobs?.candidateJobStatus
  );
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const candidateProfile = useSelector(
    (state) => state?.auth?.candidateProfile
  );
  const profile = useSelector((state) => state?.auth?.profile);
  const usertype = useSelector((state) => state?.auth?.usertype);
  const job_creator = useSelector((state) => state?.jobs?.job_creator);
  useEffect(() => {
    console.log("keywords", params);
    // async function jobDetails() {
    //   const id = params?.id;
    //   const inactiveJobList = await dispatch(jobsActions.getJobDescription(id));
    // }
    // jobDetails();
  }, []);

  useEffect(() => {
    async function myStatusDetails() {
      const id = params?.id;
      const payload = {
        job_id: params?.id,
      };
      const candidatestatus = await dispatch(
        jobsActions.getCandidateJobAppliedStatus(payload)
      );
    }
    myStatusDetails();
  }, []);

  const showPdf = () => {
    saveAs(jobDetails?.job_description, "job_description.pdf");
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.mainHome}>
        <Box className={classes.container}>
          <Box className={classes.maincard}>
            <Box
              className={classes.topbar}
              sx={{
                "@media (max-width: 468px)": {
                  display: "none",
                },
              }}
            >
              <JobCardCompanyJd />
            </Box>
            <Box
              className={classes.topbar}
              sx={{
                // display: "none",
                "@media (min-width: 468px)": {
                  display: "none",
                },
              }}
            >
              <JobCardCompanyJdMobile jobdetails={jobDetails} />
            </Box>
            <Box className={classes.descriptionbox}>
              {" "}
              <Grid container sx={{ flexGrow: 1 }}>
                <Grid Item xs={1} sm={1} pr={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Job Description"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginTop: "-2px",
                          "@media (max-width: 468px)": {
                            marginLeft: "-5px",
                            marginTop: "-5px",
                            width: "16px",
                            height: "16px",
                          },
                          "@media (min-width: 1600px)": { marginTop: "-3px" },
                        }}
                        src={jobdescription}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={3} sm={3} pl={0}>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media (min-width: 1500px)": { marginLeft: "-83px" },
                      "@media (min-width: 1550px)": { marginLeft: "-85px" },
                      "@media (min-width: 1600px)": { marginLeft: "-70px" },
                      "@media (min-width: 1680px)": { marginLeft: "-93px" },
                      "@media (min-width: 1780px)": { marginLeft: "-105px" },
                      "@media (min-width: 1900px)": { marginLeft: "-108px" },
                      "@media (min-width: 2000px)": { marginLeft: "-118px" },
                      "@media (min-width: 2200px)": { marginLeft: "-138px" },
                      "@media (min-width: 2400px)": { marginLeft: "-145px" },
                      "@media (min-width: 2500px)": { marginLeft: "-148px" },
                      marginLeft: "-60px",
                      fontSize: "18px",
                      textAlign: "left",
                      color: "#304672",
                      "@media (max-width: 468px)": {
                        fontSize: "16px",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    Job Description
                  </Typography>{" "}
                </Grid>
                <Grid Item xs={8} sm={8}></Grid>
              </Grid>
              {jobDetails?.job_description?.includes("https://exotalent-cv") ? (
                <Box pl={1} onClick={showPdf}>
                  <Typography
                    variant="p"
                    sx={{
                      textAlign: "left",
                      color: "#5677B9",
                      fontWeight: "500",
                      marginBottom: "20px",
                      "@media (max-width: 468px)": {
                        fontSize: "14px",
                      },
                      cursor: "pointer",
                    }}
                  >
                    Download JD
                  </Typography>
                </Box>
              ) : (
                <Box px={1.5} onClick={showPdf}>
                  <Typography
                    variant="p"
                    sx={{
                      textAlign: "left",
                      color: "#5677B9",
                      fontWeight: "500",
                      marginBottom: "20px",
                      "@media (max-width: 468px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {jobDetails?.job_description}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className={classes.descriptionbox}>
              {" "}
              <Grid container>
                <Grid Item xs={1} sm={1} pr={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title="Location"
                    placement="left"
                    arrow
                  >
                    <IconButton>
                      {" "}
                      <Box
                        component="img"
                        sx={{
                          marginTop: "-4px",
                          width: "18px",
                          height: "18px",
                          "@media (max-width: 468px)": {
                            marginLeft: "-5px",
                            marginTop: "-5px",
                            width: "16px",
                            height: "16px",
                          },
                          "@media (min-width: 1600px)": { marginTop: "-3px" },
                        }}
                        src={aboutCompany}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={11} sm={11}>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media (min-width: 1500px)": { marginLeft: "-83px" },
                      "@media (min-width: 1550px)": { marginLeft: "-85px" },
                      "@media (min-width: 1600px)": { marginLeft: "-83px" },
                      "@media (min-width: 1680px)": { marginLeft: "-93px" },
                      "@media (min-width: 1780px)": { marginLeft: "-105px" },
                      "@media (min-width: 1900px)": { marginLeft: "-108px" },
                      "@media (min-width: 2000px)": { marginLeft: "-118px" },
                      "@media (min-width: 2200px)": { marginLeft: "-138px" },
                      "@media (min-width: 2400px)": { marginLeft: "-145px" },
                      "@media (min-width: 2500px)": { marginLeft: "-148px" },
                      marginLeft: "-60px",
                      fontSize: "18px",
                      textAlign: "left",
                      color: "#304672",
                      "@media (max-width: 468px)": {
                        fontSize: "16px",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    About The Company
                  </Typography>{" "}
                </Grid>
                <Grid Item xs={12} sm={12}>
                  <Box px={2}>
                    <Typography variant="p" sx={{ color: "#5677B9" }}>
                      {jobDetails?.about_company}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {usertype === "company"  && <JobCandidateList />}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CompanyJD;

// Nunc vel risus commodo viverra maecenas accumsan. At lectus urna
// duis convallis. Pellentesque sit amet porttitor eget dolor morbi
// non arcu. Amet tellus cras adipiscing enim eu turpis egestas
// pretium. Volutpat consequat mauris nunc congue nisi vitae.
// Elementum eu facilisis sed odio morbi. Pretium nibh ipsum
// consequat nisl vel pretium lectus. Lectus urna duis convallis
// convallis tellus id interdum. Eros donec ac odio tempor orci
// dapibus ultrices in iaculis. Diam vel quam elementum pulvinar
// etiam. Amet volutpat consequat mauris nunc congue nisi vitae
// suscipit tellus. Vitae sapien pellentesque habitant morbi
// tristique.
// <br />
// <br />
// <ul>
//   <li className={classes.li}>
//     Nibh sit amet commodo nulla facilisi nullam vehicula. Dolor
//     sit amet consectetur adipiscing elit duis. Nunc sed blandit
//     libero volutpat sed cras ornare arcu. A pellentesque sit
//     amet porttitor eget dolor morbi non. Augue eget arcu dictum
//     varius duis at consectetur lorem donec.
//   </li>
//   <li className={classes.li}>
//     Neque convallis a cras semper auctor neque vitae. Ridiculus
//     mus mauris vitae ultricies leo integer malesuada nunc vel.
//     Eleifend mi in nulla posuere sollicitudin aliquam. In eu mi
//     bibendum neque egestas congue quisque.
//   </li>
//   <li className={classes.li}>
//     Faucibus et mestie ac feugiat sed. Condimentum lacinia quis
//     vel eros donec ac odio tempor.
//   </li>
//   <li className={classes.li}>
//     Commodo elit at imperdiet dui accumsan sit amet nulla
//     facilisi. Integer feugiat scelerisque varius morbi enim nunc
//     faucibus a pellentesque. Cur sus in hac habitasse platea.
//     Praesent tristique magna sit amet purus. Cras tincidunt
//     lobortis feugiat vivamus at augue eget.
//   </li>
//   <li className={classes.li}>
//     {" "}
//     Aenean pharetra magna ac placerat. Molestie nunc non blandit
//     massa enim nec dui nunc mattis. In eu mi bibendum neque
//     egestas congue qui sque egestas diam. Nec feugiat nisl
//     pretium fusce id velit ut tortor. In hac habitasse platea
//     dictumst vestibulum rhoncus est pellentesque elit.
//   </li>
//   <li className={classes.li}>
//     Convallis posuere morbi leo urna. Nulla facilisi nullam
//     vehicula ipsum a arcu cursus vitae. Tellus in metus
//     vulputate eu scelerisque felis imperdiet proin fermentum. Mi
//     in nulla posuere sollicitudin. Tortor aliquam nulla facilisi
//     cras. Aliquet sagittis id consectetur purus ut.
//   </li>
//   <li className={classes.li}>
//     Nec feugiat nisl pretium fusce. In pellentesque massa
//     placerat duis ultricies lacus sed. Est ultricies integer
//     quis auctor elit sed vulputate mi sit. Gravida arcu ac
//     tortor dignissim convallis aenean.
//   </li>
// </ul>

// <Box sx={{ textAlign: "center" }}>
//   <Typography
//     variant="h6"
//     sx={{
//       color: "#223870",
//       fontWeight: "600",
//       marginBottom: "20px",
//     }}
//   >
//     Candidates
//   </Typography>
//   <Box sx={{ marginTop: "20px" }}>
//     <Box sx={{ position: "relative" }}>
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#223870",
//           fontWeight: "600",
//           marginBottom: "20px",
//         }}
//       >
//         Applied (12)
//       </Typography>
//       <Box
//         component="img"
//         sx={{
//           position: "absolute",
//           top: "15px",
//           right: "15px",
//           width: "20px",
//           height: "20px",
//         }}
//         src={dropDown}
//       />
//     </Box>
//
//     <Grid
//       container
//       direction="row"
//       spacing={0}
//       justify="center"
//       alignItems="center"
//       columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
//     >
//       {" "}
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 10px 10px 0px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 0px 10px 10px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//     </Grid>
//   </Box>
//   <Box sx={{ marginTop: "20px" }}>
//     <Box sx={{ position: "relative" }}>
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#223870",
//           fontWeight: "600",
//           marginBottom: "20px",
//         }}
//       >
//         Shortlisted (6)
//       </Typography>
//       <Box
//         component="img"
//         sx={{
//           position: "absolute",
//           top: "15px",
//           right: "15px",
//           width: "20px",
//           height: "20px",
//         }}
//         src={dropDown}
//       />
//     </Box>
//
//     <Grid
//       container
//       direction="row"
//       spacing={0}
//       justify="center"
//       alignItems="center"
//       columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
//     >
//       {" "}
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 10px 10px 0px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 0px 10px 10px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//     </Grid>
//   </Box>
//   <Box sx={{ marginTop: "20px" }}>
//     <Box sx={{ position: "relative" }}>
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#223870",
//           fontWeight: "600",
//           marginBottom: "20px",
//         }}
//       >
//         On Hold (6)
//       </Typography>
//       <Box
//         component="img"
//         sx={{
//           position: "absolute",
//           top: "15px",
//           right: "15px",
//           width: "20px",
//           height: "20px",
//         }}
//         src={dropDown}
//       />
//     </Box>
//
//     <Grid
//       container
//       direction="row"
//       spacing={0}
//       justify="center"
//       alignItems="center"
//       columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
//     >
//       {" "}
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 10px 10px 0px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 0px 10px 10px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//     </Grid>
//   </Box>
//   <Box sx={{ marginTop: "20px" }}>
//     <Box sx={{ position: "relative" }}>
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#223870",
//           fontWeight: "600",
//           marginBottom: "20px",
//         }}
//       >
//         Rejected (8)
//       </Typography>
//       <Box
//         component="img"
//         sx={{
//           position: "absolute",
//           top: "15px",
//           right: "15px",
//           width: "20px",
//           height: "20px",
//         }}
//         src={dropDown}
//       />
//     </Box>
//
//     <Grid
//       container
//       direction="row"
//       spacing={0}
//       justify="center"
//       alignItems="center"
//       columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
//     >
//       {" "}
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 10px 10px 0px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//       <Grid Item xs={12} sm={6}>
//         <Box
//           className={classes.jobbox}
//           sx={{ margin: "10px 0px 10px 10px" }}
//         >
//           <Grid
//             container
//             spacing={{ xs: 2, md: 3 }}
//             columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
//           >
//             <Grid Item>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   marginTop: "15px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                   fontWeight: "600",
//                 }}
//               >
//                 Candidate Name
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "18px",
//                   marginLeft: "20px",
//                   textAlign: "left",
//                   color: "#304672",
//                 }}
//               >
//                 Designation
//               </Typography>
//             </Grid>
//             <Grid Item>
//               <Box
//                 component="img"
//                 src={companylogo}
//                 sx={{
//                   borderRadius: "8px",
//                   position: "absolute",
//                   top: "20px",
//                   right: "20px",
//                   width: "80px",
//                   height: "80px",
//                 }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container>
//             <Grid Item xs={12} sm={12}>
//               <Grid
//                 container
//                 sx={{ marginTop: "20px", marginLeft: "5px" }}
//               >
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={location}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Location
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={jobtype}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Job Type
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={industry}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Industry
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={designation}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Function
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={5}>
//                   {" "}
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={experience}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Experience in Years
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={salary}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Min Salary
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={applicant}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Max Salary
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid container>
//                     <Grid Item xs={12} sm={2}>
//                       <Box
//                         component="img"
//                         sx={{
//                           marginTop: "5px",
//                           width: "18px",
//                           height: "18px",
//                         }}
//                         src={date}
//                       />
//                     </Grid>
//                     <Grid Item xs={12} sm={10}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           fontSize: "16px",
//                           textAlign: "left",
//                           color: "#5677B9",
//                         }}
//                       >
//                         Applied on (2 Jul 2022)
//                       </Typography>{" "}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid Item sm={2}>
//                   {" "}
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid Item xs={12} sm={1}>
//                   <Box
//                     component="img"
//                     sx={{
//                       marginTop: "5px",
//                       width: "18px",
//                       height: "18px",
//                     }}
//                     src={education}
//                   />
//                 </Grid>
//                 <Grid Item xs={12} sm={11}>
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontSize: "16px",
//                       textAlign: "left",
//                       color: "#5677B9",
//                     }}
//                   >
//                     Education
//                   </Typography>{" "}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//     </Grid>
//   </Box>
// </Box>
