import { API, apiUrl } from '../../services';
import { http, http_noauth, http_local } from '../../services/client';
import { showMessage } from '../message';
import {
  setUserData,
  setTokenResponse,
  getUserData as getLocalUserData,
  getLoggedIn,
  clearAll,
  getAccessToken,
} from '../../services/authData';

export const SEND_EMAIL_OTP_FULFILLED = 'SEND_EMAIL_OTP_FULFILLED';
export const SEND_EMAIL_OTP_PENDING = 'SEND_EMAIL_OTP_PENDING';
export const SEND_EMAIL_OTP_REJECTED = 'SEND_EMAIL_OTP_REJECTED';

export const SEND_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED =
  'SEND_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED';
export const SEND_OTP_COMPANY_REGISTRATION_EMAIL_PENDING =
  'SEND_OTP_COMPANY_REGISTRATION_EMAIL_PENDING';
export const SEND_OTP_COMPANY_REGISTRATION_EMAIL_REJECTED =
  'SEND_OTP_COMPANY_REGISTRATION_EMAIL_REJECTED';

const sendEmailOtpRequest = () => ({
  type: SEND_OTP_COMPANY_REGISTRATION_EMAIL_PENDING,
});

const sendEmailOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_REGISTRATION_EMAIL_REJECTED,
});

const sendEmailOtpSuccess = (res, data, type) => ({
  type: SEND_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED,
  payload: { companyEmailOtp: true, ...res, email: data?.email_id },
});
export const sendEmailOtp = (payload, type) => {
  return (dispatch) => {
    dispatch(sendEmailOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(sendEmailOtpSuccess(response, payload));
          showMessage({
            dispatch,
            message: 'Successfully Otp send',
            variant: 'success',
          });
        } else {
          console.log('response', response);
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendEmailOtpFailure(error));
        return error;
      });
  };
};
export const SEND_MOBILE_OTP_FULFILLED = 'SEND_MOBILE_OTP_FULFILLED';
export const SEND_MOBILE_OTP_PENDING = 'SEND_MOBILE_OTP_PENDING';
export const SEND_MOBILE_OTP_REJECTED = 'SEND_MOBILE_OTP_REJECTED';

export const SEND_MOBILE_COMPANY_OTP_FULFILLED =
  'SEND_MOBILE_COMPANY_OTP_FULFILLED';
export const SEND_MOBILE_COMPANY_OTP_PENDING =
  'SEND_MOBILE_COMPANY_OTP_PENDING';
export const SEND_MOBILE_COMPANY_OTP_REJECTED =
  'SEND_MOBILE_COMPANY_OTP_REJECTED';

const sendMobileOtpRequest = () => ({
  type: SEND_MOBILE_OTP_PENDING,
});

const sendMobileOtpFailure = (error) => ({
  type: SEND_MOBILE_OTP_REJECTED,
});

const sendMobileOtpSuccess = (res, data, type) => ({
  type: SEND_MOBILE_COMPANY_OTP_FULFILLED,
  payload: { companyMobileOtp: true, ...res, mobile: data?.mobile_number },
});
export const sendMobileOtp = (payload, type) => {
  return (dispatch) => {
    dispatch(sendMobileOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, payload)
      .then((response) => {
        dispatch(sendMobileOtpSuccess(response, payload));
        return response;
      })
      .catch((error) => {
        dispatch(sendMobileOtpFailure(error));
        return error;
      });
  };
};

export const VERIFY_EMAIL_OTP_FULFILLED = 'VERIFY_EMAIL_OTP_FULFILLED';
export const VERIFY_EMAIL_OTP_PENDING = 'VERIFY_EMAIL_OTP_PENDING';
export const VERIFY_EMAIL_OTP_REJECTED = 'VERIFY_EMAIL_OTP_REJECTED';

export const VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED =
  'VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED';
export const VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_PENDING =
  'VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_PENDING';
export const VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_REJECTED =
  'VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_REJECTED';

const verifyEmailOtpRequest = () => ({
  type: VERIFY_EMAIL_OTP_PENDING,
});

const verifyEmailOtpFailure = (error) => ({
  type: VERIFY_EMAIL_OTP_REJECTED,
});

const verifyEmailOtpSuccess = (userData) => ({
  type: VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED,
  payload: {
    userData,
    loggedIn: true,
    emailverification: true,
    otpsend: false,
  },
});

export const verifyEmailOtp = (payload) => {
  return (dispatch) => {
    dispatch(verifyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, payload)
      .then((response) => {
        dispatch(verifyEmailOtpSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(verifyEmailOtpFailure(error));
        return error;
      });
  };
};
export const VERIFY_MOBILE_COMPANY_OTP_FULFILLED =
  'VERIFY_MOBILE_COMPANY_OTP_FULFILLED';
export const VERIFY_MOBILE_COMPANY_OTP_PENDING =
  'VERIFY_MOBILE_COMPANY_OTP_PENDING';
export const VERIFY_MOBILE_COMPANY_OTP_REJECTED =
  'VERIFY_MOBILE_COMPANY_OTP_REJECTED';
const verifyMobileOtpRequest = () => ({
  type: VERIFY_MOBILE_COMPANY_OTP_PENDING,
});

const verifyMobileOtpFailure = (error) => ({
  type: VERIFY_MOBILE_COMPANY_OTP_REJECTED,
});

const verifyMobileOtpSuccess = (userData) => ({
  type: VERIFY_MOBILE_COMPANY_OTP_FULFILLED,
  payload: {
    userData,
  },
});

export const verifyMobileOtp = (payload) => {
  return (dispatch) => {
    dispatch(verifyMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, payload)
      .then((response) => {
        dispatch(verifyMobileOtpSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(verifyMobileOtpFailure(error));
        return error;
      });
  };
};
export const COMPANY_REGISTER_FULFILLED = 'COMPANY_REGISTER_FULFILLED';
export const COMPANY_REGISTER_PENDING = 'COMPANY_REGISTER_PENDING';
export const COMPANY_REGISTER_REJECTED = 'COMPANY_REGISTER_REJECTED';

const companyRegisterRequest = () => ({
  type: COMPANY_REGISTER_FULFILLED,
});
const companyRegisterFailure = (error) => ({
  type: COMPANY_REGISTER_PENDING,
});

const companyRegisterSuccess = (userData, username, type) => ({
  type: COMPANY_REGISTER_REJECTED,
  payload: {
    user: userData.data,
    token: userData.token,
    loggedIn: true,
    username: username,
    type: type,
  },
});

export const registerCompany = (payload) => {
  return (dispatch) => {
    dispatch(companyRegisterRequest());
    return http_noauth
      .post(apiUrl.COMPANY_REGISTER, payload)
      .then((response) => {
        dispatch(companyRegisterSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(companyRegisterFailure(error));
        return error;
      });
  };
};

export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

const loginRequest = () => ({
  type: LOGIN_PENDING,
});
const loginFailure = (error) => ({
  type: LOGIN_REJECTED,
});

const loginSuccess = (userData, username, type) => ({
  type: LOGIN_FULFILLED,
  payload: {
    user: userData.data,
    token: userData.token,
    loggedIn: true,
    username: username,
    type: type,
  },
});
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';

const logoutRequest = () => ({
  type: LOGOUT_PENDING,
});
const logoutFailure = (error) => ({
  type: LOGOUT_REJECTED,
});
const logoutSuccess = (userData, username, type) => ({
  type: LOGOUT_FULFILLED,
  payload: {
    user: userData.data,
    token: userData.token,
    loggedIn: false,
    username: username,

    type: type,
  },
});
export const VERIFY_COMPANY_GST_FULFILLED = 'VERIFY_COMPANY_GST_FULFILLED';
export const VERIFY_COMPANY_GST_PENDING = 'VERIFY_COMPANY_GST_PENDING';
export const VERIFY_COMPANY_GST_REJECTED = 'VERIFY_COMPANY_GST_REJECTED';
const verifyGstRequest = () => ({
  type: VERIFY_COMPANY_GST_PENDING,
});

const verifyGstFailure = (error) => ({
  type: VERIFY_COMPANY_GST_REJECTED,
  payload: {
    gstverification: true,
    otpsend: false,
  },
});

const verifyGstSuccess = (userData) => ({
  type: VERIFY_COMPANY_GST_FULFILLED,
  payload: {
    gstverification: true,
    otpsend: false,
  },
});

export const verifyGst = (payload) => {
  return (dispatch) => {
    dispatch(verifyGstRequest());
    return http_noauth
      .post(apiUrl.VERIFY_GST, payload)
      .then((response) => {
        if (response?.data?.flag === true) {
          dispatch(verifyGstSuccess(response));
        } else if (response?.data?.flag === false) {
          dispatch(verifyGstFailure(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyGstFailure(error));
        return error;
      });
  };
};

// candidate email  otp send

export const SEND_OTP_CANDIDATE_EMAIL_FULFILLED =
  'SEND_OTP_CANDIDATE_EMAIL_FULFILLED';
export const SEND_OTP_CANDIDATE_EMAIL_PENDING =
  'SEND_OTP_CANDIDATE_EMAIL_PENDING';
export const SEND_OTP_CANDIDATE_EMAIL_REJECTED =
  'SEND_OTP_CANDIDATE_EMAIL_REJECTED';

const sendCandidateEmailOtpRequest = () => ({
  type: SEND_OTP_CANDIDATE_EMAIL_PENDING,
});

const sendCandidateEmailOtpFailure = (error) => ({
  type: SEND_OTP_CANDIDATE_EMAIL_REJECTED,
  payload: { error },
});

const sendCandidateEmailOtpSuccess = (response, data) => ({
  type: SEND_OTP_CANDIDATE_EMAIL_FULFILLED,
  payload: { candidateEmailOtp: true, ...response, email: data?.email_id },
});

export const checkCandidateEmail = (data) => {
  return (dispatch) => {
    dispatch(sendCandidateEmailOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(sendCandidateEmailOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCandidateEmailOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_CANDIDATE_EMAIL_DIALOG = 'CLOSE_CANDIDATE_EMAIL_DIALOG';
export const closeCandidateEmailDialog = () => {
  return {
    type: CLOSE_CANDIDATE_EMAIL_DIALOG,
  };
};

// candidate email otp verification

export const VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED =
  'VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED';
export const VERIFY_OTP_CANDIDATE_EMAIL_PENDING =
  'VERIFY_OTP_CANDIDATE_EMAIL_PENDING';
export const VERIFY_OTP_CANDIDATE_EMAIL_REJECTED =
  'VERIFY_OTP_CANDIDATE_EMAIL_REJECTED';
export const VERIFY_OTP_CANDIDATE_EMAIL_EXIST_REJECTED =
  'VERIFY_OTP_CANDIDATE_EMAIL_EXIST_REJECTED';

const verifyCandidateEmailOtpRequest = () => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_PENDING,
});

const verifyCandidateEmailOtpFailure = (error) => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_REJECTED,
  payload: { error },
});

const verifyCandidateEmailOtpSuccess = (response) => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED,
  payload: response,
});

const verifyCandidateEmailOtpExistFailure = () => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_EXIST_REJECTED,
});

export const verifyCandidateEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(verifyCandidateEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log('header', response);
        if (response?.data?.status === true) {
          debugger;
          if (response?.data?.data?.signup_status === false) {
            dispatch(verifyCandidateEmailOtpSuccess(response));
          } else {
            showMessage({
              dispatch,
              message: 'Email Id already Registered',
              variant: 'error',
            });
            dispatch(verifyCandidateEmailOtpExistFailure());
          }
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyCandidateEmailOtpFailure(error));
        return error;
      });
  };
};

// candidate mobile  otp send

export const SEND_OTP_CANDIDATE_MOBILE_FULFILLED =
  'SEND_OTP_CANDIDATE_MOBILE_FULFILLED';
export const SEND_OTP_CANDIDATE_MOBILE_PENDING =
  'SEND_OTP_CANDIDATE_MOBILE_PENDING';
export const SEND_OTP_CANDIDATE_MOBILE_REJECTED =
  'SEND_OTP_CANDIDATE_MOBILE_REJECTED';

const sendCandidateMobileOtpRequest = () => ({
  type: SEND_OTP_CANDIDATE_MOBILE_PENDING,
});

const sendCandidateMobileOtpFailure = (error) => ({
  type: SEND_OTP_CANDIDATE_MOBILE_REJECTED,
  payload: { error },
});

const sendCandidateMobileOtpSuccess = (response, data) => ({
  type: SEND_OTP_CANDIDATE_MOBILE_FULFILLED,
  payload: {
    candidateMobileOtp: true,
    ...response,
    mobile: data?.mobile_number,
  },
});

export const checkCandidateMobile = (data) => {
  return (dispatch) => {
    dispatch(sendCandidateMobileOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        console.log('checkCandidateMobile', response?.config?.headers);
        if (response?.data?.status === true) {
          dispatch(sendCandidateMobileOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCandidateMobileOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_CANDIDATE_MOBILE_DIALOG = 'CLOSE_CANDIDATE_MOBILE_DIALOG';
export const closeCandidateMobileDialog = () => {
  return {
    type: CLOSE_CANDIDATE_MOBILE_DIALOG,
  };
};

// candidate email otp verification

export const VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED =
  'VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED';
export const VERIFY_OTP_CANDIDATE_MOBILE_PENDING =
  'VERIFY_OTP_CANDIDATE_MOBILE_PENDING';
export const VERIFY_OTP_CANDIDATE_MOBILE_REJECTED =
  'VERIFY_OTP_CANDIDATE_MOBILE_REJECTED';
export const VERIFY_OTP_CANDIDATE_MOBILE_EXIST_REJECTED =
  'VERIFY_OTP_CANDIDATE_MOBILE_EXIST_REJECTED';

const verifyCandidateMobileOtpRequest = () => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_PENDING,
});

const verifyCandidateMobileOtpFailure = (error) => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_REJECTED,
  payload: { error },
});

const verifyCandidateMobileOtpSuccess = (response) => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED,
  payload: response,
});

const verifyCandidateMobileOtpExistFailure = () => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_EXIST_REJECTED,
});

export const verifyCandidateMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(verifyCandidateMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log(
          'verifyCandidateMobileOtp',
          response,
          response?.config?.headers
        );
        if (response?.data?.status === true) {
          if (response?.data?.data?.signup_status === false) {
            dispatch(verifyCandidateMobileOtpSuccess(response));
          } else {
            showMessage({
              dispatch,
              message: 'Mobile no already Registered',
              variant: 'error',
            });
            dispatch(verifyCandidateMobileOtpExistFailure());
          }
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyCandidateMobileOtpFailure(error));
        return error;
      });
  };
};

// signupCandidate

export const SIGNUP_CANDIDATE_FULFILLED = 'SIGNUP_CANDIDATE_FULFILLED';
export const SIGNUP_CANDIDATE_PENDING = 'SIGNUP_CANDIDATE_PENDING';
export const SIGNUP_CANDIDATE_REJECTED = 'SIGNUP_CANDIDATE_REJECTED';

const candidateSignupRequest = () => ({
  type: SIGNUP_CANDIDATE_PENDING,
});

const candidateSignupFailure = (error) => ({
  type: SIGNUP_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateSignupFulfilled = (response) => ({
  type: SIGNUP_CANDIDATE_FULFILLED,
  payload: response,
});

export const signupCandidate = (data, config) => {
  return (dispatch) => {
    dispatch(candidateSignupRequest());
    return http_noauth
      .post(apiUrl.CANDIDATE_SIGNUP, data, config)
      .then((response) => {
        console.log('signupCandidate', response, response?.config?.headers);
        if (response?.data?.status === true) {
          setTokenResponse(response?.config?.headers['x-auth']);
          dispatch(candidateSignupFulfilled(response));
          showMessage({
            dispatch,
            message: 'Signup Successfully',
            variant: 'success',
          });
        } else {
          dispatch(candidateSignupFailure(response?.data));
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateSignupFailure(error));
        return error;
      });
  };
};

export const RESET_CANDIDATE_DETAILS = 'RESET_CANDIDATE_DETAILS';
export const resetCandidateDetails = () => {
  return {
    type: RESET_CANDIDATE_DETAILS,
  };
};

//candidate logedIn

export const LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED =
  'LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED';
export const LOGIN_EMAIL_OTP_CANDIDATE_PENDING =
  'LOGIN_EMAIL_OTP_CANDIDATE_PENDING';
export const LOGIN_EMAIL_OTP_CANDIDATE_REJECTED =
  'LOGIN_EMAIL_OTP_CANDIDATE_REJECTED';

const candidateLoginOtpRequest = () => ({
  type: LOGIN_EMAIL_OTP_CANDIDATE_PENDING,
});

const candidateLoginOtpFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED,
  payload: { response: response, candidateEmail: data?.email_id },
});

export const sendCandidateEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(candidateLoginOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(candidateLoginOtpFulfilled(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED =
  'LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED';
export const LOGIN_MOBILE_OTP_CANDIDATE_PENDING =
  'LOGIN_MOBILE_OTP_CANDIDATE_PENDING';
export const LOGIN_MOBILE_OTP_CANDIDATE_REJECTED =
  'LOGIN_MOBILE_OTP_CANDIDATE_REJECTED';

const candidateMobileLoginOtpRequest = () => ({
  type: LOGIN_MOBILE_OTP_CANDIDATE_PENDING,
});

const candidateMobileLoginOtpFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateMobileLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED,
  payload: { response: response, candidateMoblie: data?.mobile_number },
});

export const sendCandidateMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(candidateMobileLoginOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(candidateMobileLoginOtpFulfilled(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateMobileLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED =
  'LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED';
export const LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING =
  'LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING';
export const LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED =
  'LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED';

const candidateLoginVerifyRequest = () => ({
  type: LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING,
});

const candidateLoginVerifyFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateLoginVerifyFulfilled = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED,
  payload: response,
});

export const sendCandidateEmailVerify = (data) => {
  return (dispatch) => {
    dispatch(candidateLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log(
          'sendCandidateEmailVerify',
          response?.config,
          response?.headers
        );
        if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers['x-auth']);
          dispatch(candidateLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(candidateLoginVerifyFailure(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateLoginVerifyFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED =
  'LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED';
export const LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING =
  'LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING';
export const LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED =
  'LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED';

const candidateMobileLoginVerifyRequest = () => ({
  type: LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING,
});

const candidateMobileLoginVerifyFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateMobileLoginVerifyFulfilled = (response) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED,
  payload: response,
});

export const sendCandidateMobileVerify = (data) => {
  return (dispatch) => {
    dispatch(candidateMobileLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log();
        if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers['x-auth']);
          dispatch(candidateMobileLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(candidateMobileLoginVerifyFailure(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: 'error',
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateMobileLoginVerifyFailure(error));
        return error;
      });
  };
};

export const RESET_CANDIDATE_LOGIN_DETAILS = 'RESET_CANDIDATE_LOGIN_DETAILS';
export const resetCandidateLoginDetails = () => {
  return {
    type: RESET_CANDIDATE_LOGIN_DETAILS,
  };
};
// company email  otp send

export const SEND_OTP_COMPANY_EMAIL_FULFILLED =
  'SEND_OTP_COMPANY_EMAIL_FULFILLED';
export const SEND_OTP_COMPANY_EMAIL_PENDING = 'SEND_OTP_COMPANY_EMAIL_PENDING';
export const SEND_OTP_COMPANY_EMAIL_REJECTED =
  'SEND_OTP_COMPANY_EMAIL_REJECTED';

const sendcompanyEmailOtpRequest = () => ({
  type: SEND_OTP_COMPANY_EMAIL_PENDING,
});

const sendcompanyEmailOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_EMAIL_REJECTED,
  payload: { error },
});

const sendcompanyEmailOtpSuccess = (response, data) => ({
  type: SEND_OTP_COMPANY_EMAIL_FULFILLED,
  payload: { companyEmailOtp: true, ...response, email: data?.email_id },
});

export const checkCompanyEmail = (data) => {
  return (dispatch) => {
    dispatch(sendcompanyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        dispatch(sendcompanyEmailOtpSuccess(response, data));
        return response;
      })
      .catch((error) => {
        dispatch(sendcompanyEmailOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_COMPANY_EMAIL_DIALOG = 'CLOSE_COMPANY_EMAIL_DIALOG';
export const closecompanyEmailDialog = () => {
  return {
    type: CLOSE_COMPANY_EMAIL_DIALOG,
  };
};

// company email otp verification

export const VERIFY_OTP_COMPANY_EMAIL_FULFILLED =
  'VERIFY_OTP_COMPANY_EMAIL_FULFILLED';
export const VERIFY_OTP_COMPANY_EMAIL_PENDING =
  'VERIFY_OTP_COMPANY_EMAIL_PENDING';
export const VERIFY_OTP_COMPANY_EMAIL_REJECTED =
  'VERIFY_OTP_COMPANY_EMAIL_REJECTED';

export const VERIFY_OTP_COMPANY_EMAIL_EXIST_REJECTED =
  'VERIFY_OTP_COMPANY_EMAIL_EXIST_REJECTED';

const verifycompanyEmailOtpRequest = () => ({
  type: VERIFY_OTP_COMPANY_EMAIL_PENDING,
});

const verifycompanyEmailOtpFailure = (error) => ({
  type: VERIFY_OTP_COMPANY_EMAIL_REJECTED,
  payload: { error },
});

const verifycompanyEmailOtpSuccess = (response) => ({
  type: VERIFY_OTP_COMPANY_EMAIL_FULFILLED,
  payload: response,
});

const verifycompanyEmailOtpExistFailure = () => ({
  type: VERIFY_OTP_COMPANY_EMAIL_EXIST_REJECTED,
});

export const verifyCompanyEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(verifycompanyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log('header', response);
        if (response?.data?.status === true) {
          if (response?.data?.data?.signup_status === false) {
            dispatch(verifycompanyEmailOtpSuccess(response));
          } else {
            showMessage({
              dispatch,
              message: 'Email Id already Registered',
              variant: 'error',
            });
            dispatch(verifycompanyEmailOtpExistFailure(response));
          }
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifycompanyEmailOtpFailure(error));
        return error;
      });
  };
};

// company mobile  otp send

export const SEND_OTP_COMPANY_MOBILE_FULFILLED =
  'SEND_OTP_COMPANY_MOBILE_FULFILLED';
export const SEND_OTP_COMPANY_MOBILE_PENDING =
  'SEND_OTP_COMPANY_MOBILE_PENDING';
export const SEND_OTP_COMPANY_MOBILE_REJECTED =
  'SEND_OTP_COMPANY_MOBILE_REJECTED';

const sendcompanyMobileOtpRequest = () => ({
  type: SEND_OTP_COMPANY_MOBILE_PENDING,
});

const sendcompanyMobileOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_MOBILE_REJECTED,
  payload: { error },
});

const sendcompanyMobileOtpSuccess = (response, data) => ({
  type: SEND_OTP_COMPANY_MOBILE_FULFILLED,
  payload: {
    companyMobileOtp: true,
    ...response,
    mobile: data?.mobile_number,
  },
});

export const checkcompanyMobile = (data) => {
  return (dispatch) => {
    dispatch(sendcompanyMobileOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        console.log('checkcompanyMobile', response?.config?.headers);
        if (response?.data?.status === true) {
          dispatch(sendcompanyMobileOtpSuccess(response, data));
        }
        showMessage({
          dispatch,
          message: response?.data?.msg,
          variant: 'error',
        });
        return response;
      })
      .catch((error) => {
        dispatch(sendcompanyMobileOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_COMPANY_MOBILE_DIALOG = 'CLOSE_COMPANY_MOBILE_DIALOG';
export const closecompanyMobileDialog = () => {
  return {
    type: CLOSE_COMPANY_MOBILE_DIALOG,
  };
};

// company email otp verification

export const VERIFY_OTP_COMPANY_MOBILE_FULFILLED =
  'VERIFY_OTP_COMPANY_MOBILE_FULFILLED';
export const VERIFY_OTP_COMPANY_MOBILE_PENDING =
  'VERIFY_OTP_COMPANY_MOBILE_PENDING';
export const VERIFY_OTP_COMPANY_MOBILE_REJECTED =
  'VERIFY_OTP_COMPANY_MOBILE_REJECTED';

export const VERIFY_OTP_COMPANY_MOBILE_EXIST_REJECTED =
  'VERIFY_OTP_COMPANY_MOBILE_EXIST_REJECTED';

const verifycompanyMobileOtpRequest = () => ({
  type: VERIFY_OTP_COMPANY_MOBILE_PENDING,
});

const verifycompanyMobileOtpFailure = (error) => ({
  type: VERIFY_OTP_COMPANY_MOBILE_REJECTED,
  payload: { error },
});

const verifycompanyMobileOtpSuccess = (response) => ({
  type: VERIFY_OTP_COMPANY_MOBILE_FULFILLED,
  payload: response,
});

const verifycompanyMobileExistRejected = () => ({
  type: VERIFY_OTP_COMPANY_MOBILE_EXIST_REJECTED,
});

export const verifycompanyMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(verifycompanyMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log(
          'verifycompanyMobileOtp',
          response,
          response?.config?.headers
        );
        if (response?.data?.status === true) {
          if (response?.data?.data?.signup_status === false) {
            dispatch(verifycompanyMobileOtpSuccess(response));
          } else {
            showMessage({
              dispatch,
              message: 'Mobile no already Registered',
              variant: 'error',
            });
            dispatch(verifycompanyMobileExistRejected(response));
          }
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifycompanyMobileOtpFailure(error));
        return error;
      });
  };
};

// signupcompany

export const SIGNUP_COMPANY_FULFILLED = 'SIGNUP_COMPANY_FULFILLED';
export const SIGNUP_COMPANY_PENDING = 'SIGNUP_COMPANY_PENDING';
export const SIGNUP_COMPANY_REJECTED = 'SIGNUP_COMPANY_REJECTED';

const companySignupRequest = () => ({
  type: SIGNUP_COMPANY_PENDING,
});

const companySignupFailure = (error) => ({
  type: SIGNUP_COMPANY_REJECTED,
  payload: { error },
});

const companySignupFulfilled = (response) => ({
  type: SIGNUP_COMPANY_FULFILLED,
  payload: response,
});

export const signupcompany = (data, config) => {
  return (dispatch) => {
    debugger;
    dispatch(companySignupRequest());
    return http_noauth
      .post(apiUrl.COMPANY_SIGNUP, data, config)
      .then((response) => {
        console.log(
          'signupcompany',
          response?.data?.msg,
          response?.config?.headers
        );
        if (response?.data?.status === true) {
          setTokenResponse(response?.config?.headers['x-auth']);
          dispatch(companySignupFulfilled(response));
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'success',
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        showMessage({
          dispatch,
          message: error?.data?.msg,
          variant: 'error',
        });
        dispatch(companySignupFailure(error));
        return error;
      });
  };
};

export const RESET_COMPANY_DETAILS = 'RESET_COMPANY_DETAILS';
export const resetcompanyDetails = () => {
  return {
    type: RESET_COMPANY_DETAILS,
  };
};

//company logedIn

export const LOGIN_EMAIL_OTP_COMPANY_FULFILLED =
  'LOGIN_EMAIL_OTP_COMPANY_FULFILLED';
export const LOGIN_EMAIL_OTP_COMPANY_PENDING =
  'LOGIN_EMAIL_OTP_COMPANY_PENDING';
export const LOGIN_EMAIL_OTP_COMPANY_REJECTED =
  'LOGIN_EMAIL_OTP_COMPANY_REJECTED';

const companyLoginOtpRequest = () => ({
  type: LOGIN_EMAIL_OTP_COMPANY_PENDING,
});

const companyLoginOtpFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_COMPANY_REJECTED,
  payload: { error },
});

const companyLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_EMAIL_OTP_COMPANY_FULFILLED,
  payload: { response: response, companyEmail: data?.email_id },
});

export const sendCompanyEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(companyLoginOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(companyLoginOtpFulfilled(response, data));
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_COMPANY_FULFILLED =
  'LOGIN_MOBILE_OTP_COMPANY_FULFILLED';
export const LOGIN_MOBILE_OTP_COMPANY_PENDING =
  'LOGIN_MOBILE_OTP_COMPANY_PENDING';
export const LOGIN_MOBILE_OTP_COMPANY_REJECTED =
  'LOGIN_MOBILE_OTP_COMPANY_REJECTED';

const companyMobileLoginOtpRequest = () => ({
  type: LOGIN_MOBILE_OTP_COMPANY_PENDING,
});

const companyMobileLoginOtpFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_COMPANY_REJECTED,
  payload: { error },
});

const companyMobileLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_MOBILE_OTP_COMPANY_FULFILLED,
  payload: { response: response, companyMoblie: data?.mobile_number },
});

export const sendCompanyMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(companyMobileLoginOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(companyMobileLoginOtpFulfilled(response, data));
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyMobileLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED =
  'LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED';
export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING =
  'LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING';
export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED =
  'LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED';

export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED =
  'LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED';

const companyLoginVerifyRequest = () => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING,
});

const companyLoginVerifyFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED,
  payload: { error },
});

const companyLoginVerifyFulfilled = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED,
  payload: response,
});

const companyLoginVerifySignupFailed = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED,
  payload: response,
});

export const sendCompanyEmailVerify = (data) => {
  return (dispatch) => {
    dispatch(companyLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log(
          'sendcompanyEmailVerify',
          response?.config,
          response?.headers
        );

        if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers['x-auth']);
          dispatch(companyLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(companyLoginVerifySignupFailed(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: 'error',
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyLoginVerifyFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED =
  'LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED';
export const LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING =
  'LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING';
export const LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED =
  'LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED';

const companyMobileLoginVerifyRequest = () => ({
  type: LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING,
});

const companyMobileLoginVerifyFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED,
  payload: { error },
});

const companyMobileLoginVerifyFulfilled = (response) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED,
  payload: response,
});

export const sendCompanyMobileVerify = (data) => {
  return (dispatch) => {
    dispatch(companyMobileLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log();

        if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers['x-auth']);
          dispatch(companyMobileLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === true &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(companyMobileLoginVerifyFailure(response));

          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: 'error',
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: 'error',
          });
        }

        return response;
      })
      .catch((error) => {
        dispatch(companyMobileLoginVerifyFailure(error));
        return error;
      });
  };
};

export const RESET_COMPANY_LOGIN_DETAILS = 'RESET_COMPANY_LOGIN_DETAILS';
export const resetCompanyLoginDetails = () => {
  return {
    type: RESET_COMPANY_LOGIN_DETAILS,
  };
};
export const STORE_FIRST_REGISTRATION_DATA = 'STORE_FIRST_REGISTRATION_DATA';
export const dataRegistrationFirst = (data) => {
  return {
    type: STORE_FIRST_REGISTRATION_DATA,
    payload: data,
  };
};
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST,
});

const getProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  payload: { error: error },
});

const getProfileSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  payload: data,
});
export const getProfile = (payload) => {
  return (dispatch) => {
    dispatch(getProfileRequest());
    return http
      .get(apiUrl.COMPANY_PROFILE_DETAILS)
      .then((response) => {
        console.log('response', response);

        if (response?.data?.status === true) {
          dispatch(getProfileSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getProfileFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_CANDIDATE_PROFILE_DETAILS_SUCCESS =
  'GET_CANDIDATE_PROFILE_DETAILS_SUCCESS';
export const GET_CANDIDATE_PROFILE_DETAILS_REQUEST =
  'GET_CANDIDATE_PROFILE_DETAILS_REQUEST';
export const GET_CANDIDATE_PROFILE_DETAILS_FAILURE =
  'GET_CANDIDATE_PROFILE_DETAILS_FAILURE';

const getCandidateRequest = () => ({
  type: GET_CANDIDATE_PROFILE_DETAILS_REQUEST,
});

const getCandidateFailure = (error) => ({
  type: GET_CANDIDATE_PROFILE_DETAILS_FAILURE,
  payload: { error: error },
});

const getCandidateSuccess = (data) => ({
  type: GET_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  payload: data,
});
export const getCandidateProfile = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateRequest());
    return http
      .get(apiUrl.CANDIDATE_PROFILE_DETAILS)
      .then((response) => {
        console.log('response', response);

        if (response?.data?.status === true) {
          dispatch(getCandidateSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getCandidateFailure(error));
        console.log('error', error);
      });
  };
};

export const CANDIDATE_UPDATE_PROFILE_FULFILLED =
  'CANDIDATE_UPDATE_PROFILE_FULFILLED';
export const CANDIDATE_UPDATE_PROFILE_PENDING =
  'CANDIDATE_UPDATE_PROFILE_PENDING';
export const CANDIDATE_UPDATE_PROFILE_REJECTED =
  'CANDIDATE_UPDATE_PROFILE_REJECTED';
const candidateUpdateProfileRequest = () => ({
  type: CANDIDATE_UPDATE_PROFILE_PENDING,
});
const candidateUpdateProfileFailure = (error) => ({
  type: CANDIDATE_UPDATE_PROFILE_REJECTED,
  payload: { error },
});
const candidateUpdateProfileFulfilled = (response) => ({
  type: CANDIDATE_UPDATE_PROFILE_FULFILLED,
  payload: response,
});
export const candidateUpdateProfile = (data) => {
  return (dispatch) => {
    dispatch(candidateUpdateProfileRequest());
    return http
      .post(apiUrl.CANDIDATE_UPDATE_PROFILE, data)
      .then((response) => {
        console.log(response?.config, response?.headers);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Successfully update',
            variant: 'success',
          });
          dispatch(candidateUpdateProfileFulfilled(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateUpdateProfileFailure(error));
        return error;
      });
  };
};

export const CANDIDATE_EDUCATION_LIST_FULFILLED =
  'CANDIDATE_EDUCATION_LIST_FULFILLED';
export const CANDIDATE_EDUCATION_LIST_PENDING =
  'CANDIDATE_EDUCATION_LIST_PENDING';
export const CANDIDATE_EDUCATION_LIST_REJECTED =
  'CANDIDATE_EDUCATION_LIST_REJECTED';
const candidateEducationListRequest = () => ({
  type: CANDIDATE_EDUCATION_LIST_PENDING,
});
const candidateEducationListFailure = (error) => ({
  type: CANDIDATE_EDUCATION_LIST_REJECTED,
  payload: { error },
});
const candidateEducationListFulfilled = (response) => ({
  type: CANDIDATE_EDUCATION_LIST_FULFILLED,
  payload: response,
});
export const candidateEducationList = (data) => {
  return (dispatch) => {
    dispatch(candidateEducationListRequest());
    return http_noauth
      .get(apiUrl.CANDIDATE_EDUCATION_LIST, data)
      .then((response) => {
        if (response?.data?.status === true) {
          dispatch(candidateEducationListFulfilled(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateEducationListFailure(error));
        return error;
      });
  };
};
export const COMPANY_UPDATE_PROFILE_FULFILLED =
  'COMPANY_UPDATE_PROFILE_FULFILLED';
export const COMPANY_UPDATE_PROFILE_PENDING = 'COMPANY_UPDATE_PROFILE_PENDING';
export const COMPANY_UPDATE_PROFILE_REJECTED =
  'COMPANY_UPDATE_PROFILE_REJECTED';

const companyUpdateProfileRequest = () => ({
  type: COMPANY_UPDATE_PROFILE_PENDING,
});

const companyUpdateProfileFailure = (error) => ({
  type: COMPANY_UPDATE_PROFILE_REJECTED,
  payload: { error },
});

const companyUpdateProfileFulfilled = (response) => ({
  type: COMPANY_UPDATE_PROFILE_FULFILLED,
  payload: response,
});

export const companyUpdateProfile = (data) => {
  return (dispatch) => {
    dispatch(companyUpdateProfileRequest());
    return http
      .post(apiUrl.COMPANY_UPDATE_PROFILE, data)
      .then((response) => {
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Company update Successfully',
            variant: 'success',
          });
          dispatch(companyUpdateProfileFulfilled(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        console.log('error', error);
        showMessage({
          dispatch,
          message: error?.response?.data?.msg,
          variant: 'error',
        });
        dispatch(companyUpdateProfileFailure(error));
        return error;
      });
  };
};
