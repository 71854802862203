import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import quote from "../../../assets/images/Quote1.png";
import { makeStyles } from "@mui/styles";
import ClientTestimonialBg from "../../../assets/images/ClientTestimonialsBG.png";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  Background: {
    backgroundImage: `url(${ClientTestimonialBg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    padding: "50px 60px",
  },
  title: {
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#5677B9",
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 230px 30px",
  },
  content: {
    color: "#304672",
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: "20px",
  },
  name: {
    color: "#223870",
    fontSize: "24px",
    fontWeight: "500",
  },
  designation: {
    color: "#4C68AE",
    fontSize: "20px",
  },
  quotation: {
    float: "right",
  },
  "@media (max-width: 468px)": {
    Background: {
      backgroundImage: "none",
      width: "100%",
      padding: "10px 20px",
    },
    title: {
      fontSize: "28px",
      lineHeight: "28px",
      margin: "20px 0px",
    },
    subtitle: {
      fontSize: "20px",
      lineHeight: "22px",
      margin: "0px 0px 30px 0px",
    },
    content: {
      color: "#304672",
      fontSize: "18px",
      lineHeight: "24px",
      marginBottom: "20px",
    },
    name: {
      color: "#223870",
      fontSize: "22px",
      fontWeight: "500",
    },
    designation: {
      color: "#4C68AE",
      fontSize: "20px",
    },
    quotation: {
      float: "right",
    },
  },
  "@media (min-width: 1640px)": {
    Background: {
      padding: "30px 0px",
    },
  },
}));

function TestimonialClient(props) {
  // const [clamped, setClamped] = useState(true);
  // const [showButton, setShowButton] = useState(true);
  const [showmore, setShowmore] = useState(false);
  const onClick = () => {
    setShowmore(!showmore);
  };
  const classes = useStyles();
  return (
    <>
      <div>
        {/* {Math.abs(activeStep - index) <= 2 ? ( */}
        <Box
          sx={{
            marginBottom: "50px",
            padding: "30px 80px",
            "@media (max-width: 468px)": {
              padding: "0px ",
            },
            "@media (min-width: 1536px)": {
              padding: "30px 180px",
            },
          }}
        >
          <Box
            component="img"
            src={props?.step.image}
            sx={{
              borderRadius: "10px 1px 10px 1px",
              width: 100,
              height: 100,
              maxWidth: 400,
              overflow: "hidden",
              marginBottom: "20px",
              borderWidth: "7px 0px 0px 7px ",
              borderStyle: "solid",
              borderColor: "  #FDCF57",
            }}
          />
          <Box className={classes.content}>
            {showmore
              ? props?.step.content
              : `${props?.step.content.substring(0, 145)}`}
            {props?.step.content.length >= 140 && (
              <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  color: "#4B4FD9",
                  fontWeight: "500",
                  fontSize: "14px",
                  backgroundColor: "none",
                  "&:hover": {
                    backgroundColor: "none",
                    color: "#4B4FD9",
                  },
                }}
                onClick={onClick}
              >
                {showmore ? "See less..." : " See More..."}
                {/* &nbsp; */}
              </Button>
            )}
          </Box>
          <Box className={classes.name} component="span">
            {props?.step.name}
          </Box>{" "}
          <Box className={classes.quotation} component="span">
            <Box
              component="img"
              style={{
                width: "30px",
                height: "30px",
              }}
              src={quote}
            />
          </Box>
          <Box className={classes.designation}>{props?.step.designation}</Box>
        </Box>
      </div>
    </>
  );
}

export default TestimonialClient;
