import { createTheme } from '@mui/material/styles';
import { checkboxClasses } from '@mui/material/Checkbox';
const theme = createTheme({
  typography: {
   fontFamily: 'Montserrat',
  },
  shape: {
    borderRadius: '5px',
  },
  spacing: 8,

  palette: {
    primary: {
      light: '#4B4FD9',
      main: '#223870',
      contrast: '#5677B9',
      yellow: '#FDCF57',
    },
    secondary: {
      main: '#fff',
      headerbg: '#E9E8F6',
      containerbg: '#F3F2FB',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#5071C4',
          backgroundColor: '#E0DFF4',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          backgroundColor: '#E8EEFB',
          color: '#5677B9',
          border: '1px solid #5677B9',
        },
        arrow: {
          fontSize: 16,
          width: 17,
          '&::before': {
            border: '1px solid #5677B9',
            backgroundColor: '#E8EEFB',
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: '#red',
      },
      checked: {
        color: '#green',
      },
    },

    MuiCssBseline: {
      '@glopbal': {
        img: {
          maxWidth: '100%',
          height: 'auto',
        },
        a: { textDecoration: 'none' },
        '::-webkit-scrollbar ': {
          width: '10px',
        },

        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },

        '::-webkit-scrollbar-thumb': {
          background: '#888',
        },

        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

export default theme;
