import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import jobStatus from "../../../assets/Icons/Status.png";
import Close from "../../../assets/Icons/Close.png";
import Comment from "../../../assets/Icons/Comments.png";
import addMore from "../../../assets/Icons/addMore.png";
import Logo from "../../../assets/images/female.jpeg";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  container: { width: "auto", padding: "0", margin: "0" },

  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 70px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "120px",
  },
  body: {
    padding: "20px 100px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  feedbackbox: {
    textAlign: "left",
    padding: "20px 30px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#EEF3FD",
    //position: "relative",
  },
  addmore: {
    borderRadius: "10px",
    marginTop: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#EEF3FD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "70px",
  },
  textField: {
    marginRight: "20px",
    padding: "4px",
    border: "0px 0px 1px 0px solid blue",
    borderRadius: "5px",
  },
  countrycode: {
    // marginRight: "20px",
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  "@media (min-width: 1600px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (max-width: 468px)": {
    mainHome: {
      padding: "50px 15px",
      backgroundImage: "none",
      backgroundColor: "#c6c2f6",
    },
    search: {
      marginRight: "0px",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0px 0px",
    },
  },
}));

const CandidateFeedback = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const redirect = () => {
    navigate("/createJob");
  };
  const [round, setRound] = React.useState("Round 1");

  const handleRound = (event) => {
    setRound(event.target.value);
  };
  const [result, setResult] = React.useState("clear");

  const handleResult = (event) => {
    setResult(event.target.value);
  };
  const [openstatus, setOpenstatus] = React.useState(false);

  const handleClickOpenstatus = () => {
    setOpenstatus(true);
  };

  const handleClosestatus = () => {
    setOpenstatus(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [jobstatus, setJobstatus] = React.useState("Open");

  const handleChange = (event) => {
    setJobstatus(event.target.value);
  };
  return (
    <>
      <Navbar />
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction="row">
            <Box className={classes.imgFrame}>
              {" "}
              <Box component="img" className={classes.img} src={Logo} />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant="h4"
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}
              >
                Candidate Name
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}
              >
                Designation
              </Typography>
              <Stack
                direction="row"
                spacing={{ xs: 2, sm: 4, md: 10 }}
                sx={{ marginTop: "15px" }}
              >
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Experience
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Salary
                    {/* No. of employee */}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    Resume
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box className={classes.button}>
              <Button
                // onClick={redirect}
                sx={{
                  marginRight: "40px",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "8px 20px",
                  borderRadius: "5px ",
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#F0F1FD",
                  "&:hover": {
                    backgroundColor: "#F0F1FD",
                    color: "#223870 ",
                  },
                }}
              >
                Applied
              </Button>
              <Button
                onClick={handleClickOpenstatus}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "8px 20px",
                  borderRadius: "5px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}
              >
                Change Status
              </Button>
              <Dialog
                open={openstatus}
                onClose={handleClosestatus}
                sx={{
                  // position: "relative",
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                      height: "330px",
                      borderRadius: "8px",
                      backgroundColor: "#E8EEFB",
                    },
                  },
                }}
              >
                <DialogActions>
                  <Box
                    onClick={handleClosestatus}
                    component="img"
                    src={Close}
                    sx={{
                      top: "10px",
                      right: "10px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </DialogActions>
                <DialogTitle
                  sx={{
                    color: "#5677B9 ",
                    textAlign: "center",
                    fontSize: "24px",
                  }}
                >
                  Change Candidate Status
                </DialogTitle>
                <DialogContent>
                  <Stack
                    direction="row"
                    spacing={0}
                    sx={{ marginLeft: "30px" }}
                  >
                    {" "}
                    <Box sm={2}>
                      {" "}
                      <Box
                        component="img"
                        src={jobStatus}
                        sx={{
                          width: "30px",
                          height: "30px",
                          marginTop: "40px",
                        }}
                      />
                    </Box>
                    <Box sm={10}>
                      <Grid container direction="Column">
                        {" "}
                        <Grid Item sx={{ margin: "20px 0px 5px 15px" }}>
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              fontSize: "18px",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Job Status
                          </Typography>{" "}
                        </Grid>
                        <Grid
                          Item
                          sx={{
                            "& > :not(style)": {
                              width: "36ch",
                            },
                          }}
                        >
                          <FormControl
                            variant="standard"
                            sx={{ m: 0, mb: 2, mr: 2, maxWidth: 360 }}
                          >
                            <Select
                              IconComponent={KeyboardArrowDownIcon}
                              className={classes.countrycode}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "500",
                                backgroundColor: "#E8EEFB",
                                marginLeft: "15px",
                              }}
                              value={jobstatus}
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              onChange={handleChange}
                              renderValue={(selected) => {
                                console.log(selected);
                                if (!selected) return "Open";
                                else return selected;
                              }}
                            >
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value="Applied"
                              >
                                Applied
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value="Shortlisted"
                              >
                                Shortlisted
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value="onHold"
                              >
                                On Hold
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value="Rejected"
                              >
                                Rejected
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          Item
                          sx={{
                            textAlign: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            type="submit"
                            sx={{
                              padding: "4px 20px",
                              textTransform: "none",
                              textDecoration: "none",
                              borderRadius: "5px ",
                              color: "#FDCF57",
                              fontWeight: "500",
                              fontSize: "18px",
                              backgroundColor: "#223870",
                              "&:hover": {
                                backgroundColor: "#223870",
                                color: "#FDCF57 ",
                              },
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </DialogContent>
              </Dialog>
            </Box>
          </Stack>
        </Box>
        <Box className={classes.body}>
          <Box sx={{ textAlign: "left" }}>
            <Typography variant="h5" gutterBottom sx={{ color: "#223870" }}>
              Feedback
            </Typography>
          </Box>
          <Box className={classes.feedbackbox}>
            <Box className={classes.heading}>
              {" "}
              <Typography variant="h4" gutterBottom sx={{ color: "#223870" }}>
                Round 1:
              </Typography>
            </Box>
            <Box className={classes.subheading}>
              {" "}
              <Typography variant="h6" gutterBottom sx={{ color: "#223870" }}>
                Panel: Param Singh, Roopa Kumar
              </Typography>
            </Box>
            <Box className={classes.content}>
              {" "}
              <Typography variant="p" gutterBottom sx={{ color: "#223870" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.addmore}>
            <Box onClick={handleClickOpen}>
              {" "}
              <Box
                component="img"
                src={addMore}
                sx={{
                  width: "16px",
                  height: "16px",
                  marginTop: "5px",
                  marginRight: "5px",
                }}
              />
              <Typography
                variant="p"
                gutterBottom
                sx={{ color: "#5071C4", fontWeight: "600", fontSize: "15px" }}
              >
                Add Interview Feedback
              </Typography>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    padding: "0px 20px",
                    width: "100%",
                    maxWidth: "960px",
                    height: "460px",
                    borderRadius: "10px",
                    backgroundColor: "#E8EEFB",
                  },
                },
              }}
            >
              <DialogActions>
                <Box
                  onClick={handleClose}
                  component="img"
                  src={Close}
                  sx={{
                    top: "10px",
                    right: "10px",
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                  }}
                />
              </DialogActions>
              <DialogTitle
                sx={{
                  color: "#5677B9 ",
                  textAlign: "center",
                  fontSize: "22px",
                  marginBottom: "25px",
                }}
              >
                Add Feedback
              </DialogTitle>
              <DialogContent>
                <Stack direction="row">
                  <Box>
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ marginLeft: "30px" }}
                    >
                      {" "}
                      <Box>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            textAlign: "left",
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          Round*
                        </Typography>{" "}
                        <Box
                          sx={{
                            "& > :not(style)": {
                              width: "38ch",
                            },
                          }}
                        >
                          <Select
                            required
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            value={round}
                            onChange={handleRound}
                            className={classes.countrycode}
                          >
                            <MenuItem value="Round 1">Round 1</MenuItem>
                            <MenuItem value="Round 2">Round 2</MenuItem>
                            <MenuItem value="Round 3">Round 3</MenuItem>
                            <MenuItem value="Round 4">Round 4</MenuItem>
                            <MenuItem value="Round 5">Round 5</MenuItem>
                            <MenuItem value="Round 6">Round 6</MenuItem>
                            <MenuItem value="Round 7">Round 7</MenuItem>
                          </Select>
                        </Box>
                      </Box>
                    </Stack>{" "}
                    <Stack direction="row" spacing={0}>
                      {" "}
                      <Box>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            textAlign: "left",
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          Panel*
                        </Typography>{" "}
                        <Box
                          sx={{
                            "& > :not(style)": {
                              width: "38ch",
                            },
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            size="small"
                            variant="outlined"
                            type="text"
                            name="round"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>
                    </Stack>{" "}
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ marginTop: "20px", marginLeft: "30px" }}
                    >
                      {" "}
                      <Box>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            textAlign: "left",
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          Result*
                        </Typography>{" "}
                        <Box
                          sx={{
                            "& > :not(style)": {
                              width: "38ch",
                            },
                          }}
                        >
                          <Select
                            required
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            value={result}
                            onChange={handleResult}
                            className={classes.countrycode}
                          >
                            <MenuItem value="clear">Cleared</MenuItem>
                            <MenuItem value="not-clear">Not Cleared</MenuItem>
                          </Select>
                        </Box>
                      </Box>
                    </Stack>{" "}
                  </Box>
                  <Box>
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ marginLeft: "30px" }}
                    >
                      {" "}
                      <Box sm={2} sx={{ padding: "15px" }}>
                        {" "}
                        <Box
                          component="img"
                          src={Comment}
                          sx={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      </Box>
                      <Box sm={10}>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            textAlign: "left",
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          Comments
                        </Typography>{" "}
                        <Box>
                          <FormControl
                            variant="standard"
                            sx={{ m: 0, mb: 2, mr: 2, maxWidth: 300 }}
                          >
                            <TextareaAutosize
                              placeholder="Start typing here..."
                              sx={{
                                "&::placeholder": {
                                  color: "#5071C4",
                                },
                              }}
                              aria-label="minimum height"
                              minRows={12}
                              style={{
                                padding: "10px",
                                border: "none",
                                borderRadius: "5px",
                                marginTop: "10px",
                                width: 380,
                                backgroundColor: "#E0DFF4",
                                color: "#5071C4",
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Stack>{" "}
                  </Box>
                </Stack>
                <Box
                  sx={{
                    marginLeft: "30px",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <Button
                    type="submit"
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      padding: "4px 35px",
                      borderRadius: "5px ",
                      color: "#FDCF57",
                      fontWeight: "500",
                      fontSize: "20px",
                      backgroundColor: "#223870",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CandidateFeedback;
