import {
  SEND_OTP_CANDIDATE_EMAIL_FULFILLED,
  SEND_OTP_CANDIDATE_EMAIL_PENDING,
  SEND_OTP_CANDIDATE_EMAIL_REJECTED,
  VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED,
  VERIFY_OTP_CANDIDATE_EMAIL_PENDING,
  VERIFY_OTP_CANDIDATE_EMAIL_REJECTED,
  CLOSE_CANDIDATE_EMAIL_DIALOG,
  SEND_OTP_CANDIDATE_MOBILE_FULFILLED,
  SEND_OTP_CANDIDATE_MOBILE_PENDING,
  SEND_OTP_CANDIDATE_MOBILE_REJECTED,
  VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED,
  VERIFY_OTP_CANDIDATE_MOBILE_PENDING,
  VERIFY_OTP_CANDIDATE_MOBILE_REJECTED,
  CLOSE_CANDIDATE_MOBILE_DIALOG,
  SIGNUP_CANDIDATE_FULFILLED,
  SIGNUP_CANDIDATE_PENDING,
  SIGNUP_CANDIDATE_REJECTED,
  RESET_CANDIDATE_DETAILS,
  LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED,
  LOGIN_EMAIL_OTP_CANDIDATE_PENDING,
  LOGIN_EMAIL_OTP_CANDIDATE_REJECTED,
  LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED,
  LOGIN_MOBILE_OTP_CANDIDATE_PENDING,
  LOGIN_MOBILE_OTP_CANDIDATE_REJECTED,
  LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED,
  LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING,
  LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED,
  RESET_CANDIDATE_LOGIN_DETAILS,
  LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED,
  LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING,
  LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED,
  SEND_OTP_COMPANY_EMAIL_FULFILLED,
  SEND_OTP_COMPANY_EMAIL_PENDING,
  SEND_OTP_COMPANY_EMAIL_REJECTED,
  VERIFY_OTP_COMPANY_EMAIL_FULFILLED,
  VERIFY_OTP_COMPANY_EMAIL_PENDING,
  VERIFY_OTP_COMPANY_EMAIL_REJECTED,
  CLOSE_COMPANY_EMAIL_DIALOG,
  SEND_OTP_COMPANY_MOBILE_FULFILLED,
  SEND_OTP_COMPANY_MOBILE_PENDING,
  SEND_OTP_COMPANY_MOBILE_REJECTED,
  VERIFY_OTP_COMPANY_MOBILE_FULFILLED,
  VERIFY_OTP_COMPANY_MOBILE_PENDING,
  VERIFY_OTP_COMPANY_MOBILE_REJECTED,
  VERIFY_COMPANY_GST_FULFILLED,
  VERIFY_COMPANY_GST_PENDING,
  VERIFY_COMPANY_GST_REJECTED,
  CLOSE_COMPANY_MOBILE_DIALOG,
  SIGNUP_COMPANY_FULFILLED,
  SIGNUP_COMPANY_PENDING,
  SIGNUP_COMPANY_REJECTED,
  RESET_COMPANY_DETAILS,
  LOGIN_EMAIL_OTP_COMPANY_FULFILLED,
  LOGIN_EMAIL_OTP_COMPANY_PENDING,
  LOGIN_EMAIL_OTP_COMPANY_REJECTED,
  LOGIN_MOBILE_OTP_COMPANY_FULFILLED,
  LOGIN_MOBILE_OTP_COMPANY_PENDING,
  LOGIN_MOBILE_OTP_COMPANY_REJECTED,
  LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED,
  LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING,
  LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED,
  RESET_COMPANY_LOGIN_DETAILS,
  LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED,
  LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING,
  LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED,
  SEND_OTP_RECRUITER_EMAIL_FULFILLED,
  SEND_OTP_RECRUITER_EMAIL_PENDING,
  SEND_OTP_RECRUITER_EMAIL_REJECTED,
  VERIFY_OTP_RECRUITER_EMAIL_FULFILLED,
  VERIFY_OTP_RECRUITER_EMAIL_PENDING,
  VERIFY_OTP_RECRUITER_EMAIL_REJECTED,
  CLOSE_RECRUITER_EMAIL_DIALOG,
  SEND_OTP_RECRUITER_MOBILE_FULFILLED,
  SEND_OTP_RECRUITER_MOBILE_PENDING,
  SEND_OTP_RECRUITER_MOBILE_REJECTED,
  VERIFY_OTP_RECRUITER_MOBILE_FULFILLED,
  VERIFY_OTP_RECRUITER_MOBILE_PENDING,
  VERIFY_OTP_RECRUITER_MOBILE_REJECTED,
  CLOSE_RECRUITER_MOBILE_DIALOG,
  SIGNUP_RECRUITER_FULFILLED,
  SIGNUP_RECRUITER_PENDING,
  SIGNUP_RECRUITER_REJECTED,
  RESET_RECRUITER_DETAILS,
  LOGIN_EMAIL_OTP_RECRUITER_FULFILLED,
  LOGIN_EMAIL_OTP_RECRUITER_PENDING,
  LOGIN_EMAIL_OTP_RECRUITER_REJECTED,
  LOGIN_MOBILE_OTP_RECRUITER_FULFILLED,
  LOGIN_MOBILE_OTP_RECRUITER_PENDING,
  LOGIN_MOBILE_OTP_RECRUITER_REJECTED,
  LOGIN_EMAIL_OTP_VERIFY_RECRUITER_FULFILLED,
  LOGIN_EMAIL_OTP_VERIFY_RECRUITER_PENDING,
  LOGIN_EMAIL_OTP_VERIFY_RECRUITER_REJECTED,
  RESET_RECRUITER_LOGIN_DETAILS,
  LOGIN_MOBILE_OTP_VERIFY_RECRUITER_FULFILLED,
  LOGIN_MOBILE_OTP_VERIFY_RECRUITER_PENDING,
  LOGIN_MOBILE_OTP_VERIFY_RECRUITER_REJECTED,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_REQUEST,
  GET_PROFILE_FAILURE,
  GET_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  GET_CANDIDATE_PROFILE_DETAILS_REQUEST,
  GET_CANDIDATE_PROFILE_DETAILS_FAILURE,
  LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED,
  CANDIDATE_EDUCATION_LIST_FULFILLED,
  CANDIDATE_EDUCATION_LIST_PENDING,
  CANDIDATE_EDUCATION_LIST_REJECTED,
  VERIFY_OTP_CANDIDATE_EMAIL_EXIST_REJECTED,
  VERIFY_OTP_CANDIDATE_MOBILE_EXIST_REJECTED,
  VERIFY_OTP_COMPANY_EMAIL_EXIST_REJECTED,
  VERIFY_OTP_COMPANY_MOBILE_EXIST_REJECTED,
} from "./actions";
const initialSnackbarState = {
  emailverification: false,
  mobileverification: false,
  gstverification: false,
  loggedIn: false,
  otpsend: false,
  tryAllLogin: false,
  submitsucc: false,
  email: "",
  otptype: "",
  mobile: "",
  atleastoneverify: false,
  userData: [],
  candidateEmailVerification: false,
  candidateMobileVerification: false,
  candidateSignUp: false,
  candidateEmailOtp: false,
  candidateMobileOtp: false,
  usertype: "guest",
  candidateLoginOtpSend: false,
  candidateMoblie: "",
  candidateEmail: "",
  companyMoblie: "",
  companyEmail: "",
  companyEmailVerification: false,
  companyMobileVerification: false,
  companySignUp: false,
  companyEmailOtp: false,
  companyMobileOtp: false,
  companyLoginOtpSend: false,
  recruiterMoblie: "",
  recruiterEmail: "",
  recruiterEmailVerification: false,
  recruiterMobileVerification: false,
  recruiterSignUp: false,
  recruiterEmailOtp: false,
  recruiterMobileOtp: false,
  recruiterLoginOtpSend: false,
  companyRegistrationEmailOtp: false,
  companyRegistrationEmailDialog: false,
  companyRegistrationEmailVerification: false,
  companyRegistrationMobileOtp: false,
  companyRegistrationMobileDialog: false,
  companyRegistrationMobileVerification: false,
  atleastoneverifyReg: false,
  registrationFirstData: {},
  profile: {},
  candidateProfile: {},
  educationlist: [],
};

export const authreducer = (state = initialSnackbarState, action) => {
  console.log("action type....", action.type);
  switch (action.type) {
    case "closedialogemail": {
      return { ...state, otpsend: false };
    }
    case "closedialogmobile": {
      return { ...state, otpsend: false };
    }
    case "logout": {
      return {
        ...state,
        loggedIn: false,
        candidateEmailVerification: false,
        candidateMobileVerification: false,
        companyEmailVerification: false,
        companyMobileVerification: false,
        recruiterEmailVerification: false,
        recruiterMobileVerification: false,
        companySignUp: false,
        candidateSignUp: false,
        recruiterSignUp: false,
        emailverification: false,
        mobileverification: false,
        gstverification: false,
        signup: false,
        atleastoneverify: false,
        userData: [],
        candidateEmailVerification: false,
        candidateMobileVerification: false,
        candidateSignUp: false,
        candidateEmailOtp: false,
        candidateMobileOtp: false,
        usertype: "guest",
        candidateLoginOtpSend: false,
        candidateMoblie: "",
        candidateEmail: "",
        companyMoblie: "",
        companyEmail: "",
        companyEmailVerification: false,
        companyMobileVerification: false,
        companySignUp: false,
        companyEmailOtp: false,
        companyMobileOtp: false,
        companyLoginOtpSend: false,
        recruiterMoblie: "",
        recruiterEmail: "",
        recruiterEmailVerification: false,
        recruiterMobileVerification: false,
        recruiterSignUp: false,
        recruiterEmailOtp: false,
        recruiterMobileOtp: false,
        recruiterLoginOtpSend: false,
        companyRegistrationEmailOtp: false,
        companyRegistrationEmailDialog: false,
        companyRegistrationEmailVerification: false,
        companyRegistrationMobileOtp: false,
        companyRegistrationMobileDialog: false,
        companyRegistrationMobileVerification: false,
        atleastoneverifyReg: false,
        registrationFirstData: {},
        profile: {},
      };
    }

    // case "VERIFY_MOBILE_OTP_FULFILLED": {
    //   return { ...state, ...action.payload };
    // }
    // case VERIFY_MOBILE_OTP_REJECTED:
    //   return { ...state, ...action.payload };

    case "COMPANY_REGISTER_FULFILLED":
      return { ...state, ...action.payload };
    case "COMPANY_REGISTER_PENDING":
      return { ...state, ...action.payload };
    case "COMPANY_REGISTER_REJECTED":
      return { ...state, ...action.payload };
    case VERIFY_COMPANY_GST_FULFILLED:
      return { ...state, ...action.payload };
    case VERIFY_COMPANY_GST_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_COMPANY_GST_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_EMAIL_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_EMAIL_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_EMAIL_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateEmailOtp: true,
        candidateEmailVerification: false,
      };
    case VERIFY_OTP_CANDIDATE_EMAIL_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_OTP_CANDIDATE_EMAIL_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_CANDIDATE_EMAIL_EXIST_REJECTED:
      return {...state,
        candidateEmailOtp: false,
        candidateEmailVerification: false,
      }
    case VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED:
      if (!state?.atleastoneverify) {
        return {
          ...state,
          ...action.payload,
          candidateEmailOtp: false,
          candidateEmailVerification: true,
          atleastoneverify: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          candidateEmailOtp: false,
          candidateEmailVerification: true,
        };
      }

    case CLOSE_CANDIDATE_EMAIL_DIALOG:
      return { ...state, candidateEmailOtp: false };
    case SEND_OTP_CANDIDATE_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_MOBILE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateMobileOtp: true,
        candidateMobileVerification: false,
      };

    case LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        usertype: "candidate",
      };

    case LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        usertype: "candidate",
      };
    case LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED:
      return {
        ...state,
        ...action.payload,
        signup: false,
      };
    case VERIFY_OTP_CANDIDATE_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_OTP_CANDIDATE_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_CANDIDATE_MOBILE_EXIST_REJECTED:
      return {
        ...state, 
        candidateMobileOtp: false,
        candidateMobileVerification: false,
      }  
    case VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED:
      if (!state?.atleastoneverify) {
        return {
          ...state,
          ...action.payload,
          candidateMobileOtp: false,
          candidateMobileVerification: true,
          atleastoneverify: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          candidateMobileOtp: false,
          candidateMobileVerification: true,
        };
      }
    case CLOSE_CANDIDATE_MOBILE_DIALOG:
      return { ...state, candidateMobileOtp: false };

    case SIGNUP_CANDIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateSignUp: true,
        loggedIn: true,
        usertype: "candidate",
      };
    case SIGNUP_CANDIDATE_PENDING:
      return { ...state, ...action.payload };
    case SIGNUP_CANDIDATE_REJECTED:
      return { ...state, ...action.payload };
    case RESET_CANDIDATE_DETAILS:
      return {
        ...state,
        email: "",
        otptype: "",
        mobile: "",
        candidateEmailVerification: false,
        candidateMobileVerification: false,
        candidateSignUp: false,
        candidateEmailOtp: false,
        candidateMobileOtp: false,
        atleastoneverify: false,
      };
    case LOGIN_EMAIL_OTP_CANDIDATE_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_CANDIDATE_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateLoginOtpSend: true,
      };
    case LOGIN_MOBILE_OTP_CANDIDATE_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_CANDIDATE_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateLoginOtpSend: true,
      };
    case RESET_CANDIDATE_LOGIN_DETAILS:
      return {
        ...state,
        candidateLoginOtpSend: false,
        candidateMoblie: "",
        candidateEmail: "",
      };
    case SEND_OTP_COMPANY_EMAIL_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_EMAIL_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_EMAIL_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyEmailOtp: true,
        companyRegistrationEmailDialog: true,
        companyEmailVerification: false,
      };
    case "SEND_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED":
      return {
        ...state,
        ...action.payload,
        companyRegistrationEmailOtp: true,
        companyRegistrationEmailDialog: true,
        companyRegistrationEmailVerification: false,
      };
    case VERIFY_OTP_COMPANY_EMAIL_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_OTP_COMPANY_EMAIL_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_COMPANY_EMAIL_FULFILLED:
      if (!state?.atleastoneverifyReg) {
        return {
          ...state,
          ...action.payload,
          companyEmailOtp: false,
          companyEmailVerification: true,
          companyRegistrationEmailDialog: false,
          atleastoneverify: true,
          atleastoneverifyReg: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          companyEmailOtp: false,
          companyRegistrationEmailDialog: false,
          companyEmailVerification: true,
        };
      }
    case VERIFY_OTP_COMPANY_EMAIL_EXIST_REJECTED:
      return{
        ...state,
        companyEmailOtp: false,
        companyRegistrationEmailDialog: false,
        companyEmailVerification: false,
      }
    case CLOSE_COMPANY_EMAIL_DIALOG:
      return { ...state, companyEmailOtp: false };
    case SEND_OTP_COMPANY_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_MOBILE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyMobileOtp: true,
        companyMobileVerification: false,
      };

    case LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        usertype: "company",
      };

    case LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        usertype: "company",
      };

    case VERIFY_OTP_COMPANY_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_OTP_COMPANY_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_COMPANY_MOBILE_FULFILLED:
      if (!state?.atleastoneverifyReg) {
        return {
          ...state,
          ...action.payload,
          companyMobileOtp: false,
          companyMobileVerification: true,
          companyRegistrationMobileDialog: false,
          atleastoneverify: true,
          atleastoneverifyReg: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          companyMobileOtp: false,
          companyRegistrationMobileDialog: false,
          companyMobileVerification: true,
        };
      }
    case VERIFY_OTP_COMPANY_MOBILE_EXIST_REJECTED:
      return{
        ...state,
        companyMobileOtp: false,
        companyRegistrationMobileDialog: false,
        companyMobileVerification: false,
      }  
    case CLOSE_COMPANY_MOBILE_DIALOG:
      return { ...state, companyMobileOtp: false };
    case VERIFY_COMPANY_GST_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_COMPANY_GST_REJECTED:
      return { ...state, ...action.payload };
    case SIGNUP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companySignUp: true,
        loggedIn: true,
        usertype: "company",
      };
    case SIGNUP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case SIGNUP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case RESET_COMPANY_DETAILS:
      return {
        ...state,
        email: "",
        otptype: "",
        mobile: "",
        companyEmailVerification: false,
        companyMobileVerification: false,
        companySignUp: false,
        companyEmailOtp: false,
        companyMobileOtp: false,
        atleastoneverify: false,
        gstverification: false,
      };
    case LOGIN_EMAIL_OTP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_EMAIL_OTP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyLoginOtpSend: true,
      };
    case LOGIN_MOBILE_OTP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case LOGIN_MOBILE_OTP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyLoginOtpSend: true,
      };
    case RESET_COMPANY_LOGIN_DETAILS:
      return {
        ...state,
        companyLoginOtpSend: false,
        companyMoblie: "",
        companyEmail: "",
      };
    case "SEND_OTP_RECRUITER_EMAIL_PENDING":
      return { ...state, ...action.payload };
    case "SEND_OTP_RECRUITER_EMAIL_REJECTED":
      return { ...state, ...action.payload };
    case "SEND_OTP_RECRUITER_EMAIL_FULFILLED":
      return {
        ...state,
        ...action.payload,
        recruiterEmailOtp: true,
        recruiterEmailVerification: false,
      };
    case "VERIFY_OTP_RECRUITER_EMAIL_PENDING":
      return { ...state, ...action.payload };
    case "VERIFY_OTP_RECRUITER_EMAIL_REJECTED":
      return { ...state, ...action.payload };
    case "VERIFY_OTP_RECRUITER_EMAIL_FULFILLED":
      if (!state?.atleastoneverify) {
        return {
          ...state,
          ...action.payload,
          recruiterEmailOtp: false,
          recruiterEmailVerification: true,
          atleastoneverify: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          recruiterEmailOtp: false,
          recruiterEmailVerification: true,
        };
      }

    case "CLOSE_RECRUITER_EMAIL_DIALOG":
      return { ...state, recruiterEmailOtp: false };
    case "SEND_OTP_RECRUITER_MOBILE_PENDING":
      return { ...state, ...action.payload };
    case "SEND_OTP_RECRUITER_MOBILE_REJECTED":
      return { ...state, ...action.payload };
    case "SEND_OTP_RECRUITER_MOBILE_FULFILLED":
      return {
        ...state,
        ...action.payload,
        recruiterMobileOtp: true,
        recruiterMobileVerification: false,
      };

    case "LOGIN_EMAIL_OTP_VERIFY_RECRUITER_PENDING":
      return { ...state, ...action.payload };
    case "LOGIN_EMAIL_OTP_VERIFY_RECRUITER_REJECTED":
      return { ...state, ...action.payload };
    case "LOGIN_EMAIL_OTP_VERIFY_RECRUITER_FULFILLED":
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        usertype: "recruiter",
      };

    case "LOGIN_MOBILE_OTP_VERIFY_RECRUITER_PENDING":
      return { ...state, ...action.payload };
    case "LOGIN_MOBILE_OTP_VERIFY_RECRUITER_REJECTED":
      return { ...state, ...action.payload };
    case "LOGIN_MOBILE_OTP_VERIFY_RECRUITER_FULFILLED":
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        usertype: "recruiter",
      };

    case "VERIFY_OTP_RECRUITER_MOBILE_PENDING":
      return { ...state, ...action.payload };
    case "VERIFY_OTP_RECRUITER_MOBILE_REJECTED":
      return { ...state, ...action.payload };
    case "VERIFY_OTP_RECRUITER_MOBILE_FULFILLED":
      if (!state?.atleastoneverify) {
        return {
          ...state,
          ...action.payload,
          recruiterMobileOtp: false,
          recruiterMobileVerification: true,
          atleastoneverify: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          recruiterMobileOtp: false,
          recruiterMobileVerification: true,
        };
      }
    case "CLOSE_RECRUITER_MOBILE_DIALOG":
      return { ...state, recruiterMobileOtp: false };

    case "SIGNUP_RECRUITER_FULFILLED":
      return {
        ...state,
        ...action.payload,
        recruiterSignUp: true,
        loggedIn: true,
        usertype: "recruiter",
      };
    case "SIGNUP_RECRUITER_PENDING":
      return { ...state, ...action.payload };
    case "SIGNUP_RECRUITER_REJECTED":
      return { ...state, ...action.payload };
    case "RESET_RECRUITER_DETAILS":
      return {
        ...state,
        email: "",
        otptype: "",
        mobile: "",
        recruiterEmailVerification: false,
        recruiterMobileVerification: false,
        recruiterSignUp: false,
        recruiterEmailOtp: false,
        recruiterMobileOtp: false,
        atleastoneverify: false,
      };
    case "LOGIN_EMAIL_OTP_RECRUITER_PENDING":
      return { ...state, ...action.payload };
    case "LOGIN_EMAIL_OTP_RECRUITER_REJECTED":
      return { ...state, ...action.payload };
    case "LOGIN_EMAIL_OTP_RECRUITER_FULFILLED":
      return {
        ...state,
        companyRegistrationEmailOtp: false,
        companyRegistrationEmailDialog: false,
        companyRegistrationEmailVerification: false,
        companyRegistrationMobileOtp: false,
        companyRegistrationMobileDialog: false,
        companyRegistrationMobileVerification: false,
        atleastoneverifyReg: false,
        registrationFirstData: {},
        ...action.payload,
        recruiterLoginOtpSend: true,
      };
    case "LOGIN_MOBILE_OTP_RECRUITER_PENDING":
      return { ...state, ...action.payload };
    case "LOGIN_MOBILE_OTP_RECRUITER_REJECTED":
      return { ...state, ...action.payload };
    case "LOGIN_MOBILE_OTP_RECRUITER_FULFILLED":
      return {
        ...state,
        ...action.payload,
        recruiterLoginOtpSend: true,
      };
    case "RESET_RECRUITER_LOGIN_DETAILS":
      return {
        ...state,
        recruiterLoginOtpSend: false,
        recruiterMoblie: "",
        recruiterEmail: "",
      };
    case "CLOSE_EMAIL_COMPANY_REGISTRATION_DIALOG":
      return {
        ...state,
        ...action.payload,
        companyRegistrationEmailDialog: false,
      };
    case "CLOSE_MOBILE_COMPANY_REGISTRATION_DIALOG":
      return {
        ...state,
        ...action.payload,
        companyRegistrationMobileDialog: false,
      };
    case "VERIFY_OTP_COMPANY_REGISTRATION_EMAIL_FULFILLED":
      return {
        ...state,
        ...action.payload,
        companyRegistrationEmailOtp: false,
        companyRegistrationEmailDialog: false,
        companyRegistrationEmailVerification: true,
      };
    case "SEND_MOBILE_COMPANY_OTP_FULFILLED":
      if (!state?.atleastoneverifyReg) {
        return {
          ...state,
          ...action.payload,
          companyRegistrationMobileOtp: true,
          companyRegistrationMobileDialog: true,
          companyRegistrationMobileVerification: false,
          atleastoneverifyReg: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          companyRegistrationMobileOtp: true,
          companyRegistrationMobileDialog: true,
          companyRegistrationMobileVerification: false,
        };
      }
    case "VERIFY_MOBILE_COMPANY_OTP_FULFILLED":
      if (!state?.atleastoneverifyReg) {
        return {
          ...state,
          ...action.payload,
          companyRegistrationMobileOtp: false,
          companyRegistrationMobileDialog: false,
          companyRegistrationMobileVerification: true,
          atleastoneverifyReg: true,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          companyRegistrationMobileOtp: false,
          companyRegistrationMobileDialog: false,
          companyRegistrationMobileVerification: true,
        };
      }
    case "STORE_FIRST_REGISTRATION_DATA":
      return {
        ...state,
        registrationFirstData: action.payload,
      };

    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.payload?.data?.data[0],
        loading: false,
        errors: {},
      };
    }
    case GET_PROFILE_REQUEST: {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }
    case GET_PROFILE_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case GET_CANDIDATE_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        candidateProfile: action.payload?.data?.data[0],
        loading: false,
        errors: {},
      };
    }
    case GET_CANDIDATE_PROFILE_DETAILS_REQUEST: {
      return {
        ...state,
        ...action.payload,
        candidateProfile: {},
        loading: false,
        errors: {},
      };
    }
    case GET_CANDIDATE_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        candidateProfile: {},
        loading: false,
        errors: {},
      };
    }
    case CANDIDATE_EDUCATION_LIST_FULFILLED: {
      return {
        ...state,
        ...action.payload,
        educationlist: action?.payload?.data?.data,
        loading: false,
        errors: {},
      };
    }

    case CANDIDATE_EDUCATION_LIST_PENDING: {
      return {
        ...state,
        ...action.payload,
        candidateProfile: {},
        loading: false,
        errors: {},
      };
    }

    case CANDIDATE_EDUCATION_LIST_REJECTED: {
      return {
        ...state,
        ...action.payload,
        candidateProfile: {},
        loading: false,
        errors: {},
      };
    }
    default:
      return state;
  }
};
