import { API, apiUrl } from "../../services";
import { http, http_noauth, http_local } from "../../services/client";
import { showMessage } from "../message";
import {
  setUserData,
  setTokenResponse,
  getUserData as getLocalUserData,
  getLoggedIn,
  clearAll,
  getAccessToken,
} from "../../services/authData";

export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_REQUEST = "GET_LOCATION_REQUEST";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";

const getLocationRequest = () => ({
  type: GET_LOCATION_REQUEST,
});

const getLocationFailure = (error) => ({
  type: GET_LOCATION_FAILURE,
  payload: { error: error },
});

const getLocationSuccess = (data) => ({
  type: GET_LOCATION_SUCCESS,
  payload:  data,
});
export const getLocation = (id) => {
  return (dispatch) => {
    dispatch(getLocationRequest());
    const path = apiUrl.GET_LOCATION
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getLocationSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getLocationFailure(error));
        console.log("error", error);
      });
  };
};


export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
export const GET_SKILLS_REQUEST = "GET_SKILLS_REQUEST";
export const GET_SKILLS_FAILURE = "GET_SKILLS_FAILURE";

const getSkillsRequest = () => ({
  type: GET_SKILLS_REQUEST,
});

const getSkillsFailure = (error) => ({
  type: GET_SKILLS_FAILURE,
  payload: { error: error },
});

const getSkillsSuccess = (data) => ({
  type: GET_SKILLS_SUCCESS,
  payload:  data,
});
export const getSkills = (id) => {
  return (dispatch) => {
    dispatch(getSkillsRequest());
    const path = apiUrl.GET_SKILLS+"?page_no="+0+ "&query_string="+""
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getSkillsSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getSkillsFailure(error));
        console.log("error", error);
      });
  };
};


export const GET_DESIGNATION_SUCCESS = "GET_DESIGNATION_SUCCESS";
export const GET_DESIGNATION_REQUEST = "GET_DESIGNATION_REQUEST";
export const GET_DESIGNATION_FAILURE = "GET_DESIGNATION_FAILURE";

const getDesignationRequest = () => ({
  type: GET_DESIGNATION_REQUEST,
});

const getDesignationFailure = (error) => ({
  type: GET_DESIGNATION_FAILURE,
  payload: { error: error },
});

const getDesignationSuccess = (data) => ({
  type: GET_DESIGNATION_SUCCESS,
  payload:  data,
});
export const getDesignation = (id) => {
  return (dispatch) => {
    dispatch(getDesignationRequest());
    const path = apiUrl.GET_DESIGNATION
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getDesignationSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getDesignationFailure(error));
        console.log("error", error);
      });
  };
};

////


export const GET_INDUSTRY_SUCCESS = "GET_INDUSTRY_SUCCESS";
export const GET_INDUSTRY_REQUEST = "GET_INDUSTRY_REQUEST";
export const GET_INDUSTRY_FAILURE = "GET_INDUSTRY_FAILURE";

const getIndustryRequest = () => ({
  type: GET_INDUSTRY_REQUEST,
});

const getIndustryFailure = (error) => ({
  type: GET_INDUSTRY_FAILURE,
  payload: { error: error },
});

const getIndustrySuccess = (data) => ({
  type: GET_INDUSTRY_SUCCESS,
  payload:  data,
});
export const getIndustry = (id) => {
  return (dispatch) => {
    dispatch(getIndustryRequest());
    const path = apiUrl.GET_INDUSTRY
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getIndustrySuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getIndustryFailure(error));
        console.log("error", error);
      });
  };
};


///


export const GET_FUNCTION_SUCCESS = "GET_FUNCTION_SUCCESS";
export const GET_FUNCTION_REQUEST = "GET_FUNCTION_REQUEST";
export const GET_FUNCTION_FAILURE = "GET_FUNCTION_FAILURE";

const getFunctionRequest = () => ({
  type: GET_FUNCTION_REQUEST,
});

const getFunctionFailure = (error) => ({
  type: GET_FUNCTION_FAILURE,
  payload: { error: error },
});

const getFunctionSuccess = (data) => ({
  type: GET_FUNCTION_SUCCESS,
  payload:  data,
});
export const getFunction = (id) => {
  return (dispatch) => {
    dispatch(getFunctionRequest());
    const path = apiUrl.GET_FUNCTION
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getFunctionSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getFunctionFailure(error));
        console.log("error", error);
      });
  };
};


//


export const GET_COMPANY_SIZE_SUCCESS = "GET_COMPANY_SIZE_SUCCESS";
export const GET_COMPANY_SIZE_REQUEST = "GET_COMPANY_SIZE_REQUEST";
export const GET_COMPANY_SIZE_FAILURE = "GET_COMPANY_SIZE_FAILURE";

const getComapnySizeRequest = () => ({
  type: GET_COMPANY_SIZE_REQUEST,
});

const getComapnySizeFailure = (error) => ({
  type: GET_COMPANY_SIZE_FAILURE,
  payload: { error: error },
});

const getComapnySizeSuccess = (data) => ({
  type: GET_COMPANY_SIZE_SUCCESS,
  payload:  data,
});
export const getComapnySize = (id) => {
  return (dispatch) => {
    dispatch(getComapnySizeRequest());
    const path = apiUrl.GET_COMPANY_SIZE
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getComapnySizeSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getComapnySizeFailure(error));
        console.log("error", error);
      });
  };
};

//

export const GET_JOB_TYPE_SUCCESS = "GET_JOB_TYPE_SUCCESS";
export const GET_JOB_TYPE_REQUEST = "GET_JOB_TYPE_REQUEST";
export const GET_JOB_TYPE_FAILURE = "GET_JOB_TYPE_FAILURE";

const getJobTypeRequest = () => ({
  type: GET_JOB_TYPE_REQUEST,
});

const getJobTypeFailure = (error) => ({
  type: GET_JOB_TYPE_FAILURE,
  payload: { error: error },
});

const getJobTypeSuccess = (data) => ({
  type: GET_JOB_TYPE_SUCCESS,
  payload:  data,
});
export const getJobType = (id) => {
  return (dispatch) => {
    dispatch(getJobTypeRequest());
    const path = apiUrl.GET_JOB_TYPE
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getJobTypeSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getJobTypeFailure(error));
        console.log("error", error);
      });
  };
};




export const GET_COMPANY_TYPE_SUCCESS = "GET_COMPANY_TYPE_SUCCESS";
export const GET_COMPANY_TYPE_REQUEST = "GET_COMPANY_TYPE_REQUEST";
export const GET_COMPANY_TYPE_FAILURE = "GET_COMPANY_TYPE_FAILURE";

const getCompanyTypeRequest = () => ({
  type: GET_COMPANY_TYPE_REQUEST,
});

const getCompanyTypeFailure = (error) => ({
  type: GET_COMPANY_TYPE_FAILURE,
  payload: { error: error },
});

const getCompanyTypeSuccess = (data) => ({
  type: GET_COMPANY_TYPE_SUCCESS,
  payload:  data,
});
export const getCompanyType = (id) => {
  return (dispatch) => {
    dispatch(getCompanyTypeRequest());
    const path = apiUrl.GET_COMPANY_TYPE
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getCompanyTypeSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getCompanyTypeFailure(error));
        console.log("error", error);
      });
  };
};



export const GET_COMPANY_REGISTATION_TYPE_SUCCESS = "GET_COMPANY_REGISTATION_TYPE_SUCCESS";
export const GET_COMPANY_REGISTATION_TYPE_REQUEST = "GET_COMPANY_REGISTATION_TYPE_REQUEST";
export const GET_COMPANY_REGISTATION_TYPE_FAILURE = "GET_COMPANY_REGISTATION_TYPE_FAILURE";

const getCompanyRegistrationTypeRequest = () => ({
  type: GET_COMPANY_REGISTATION_TYPE_REQUEST,
});

const getCompanyRegistrationTypeFailure = (error) => ({
  type: GET_COMPANY_REGISTATION_TYPE_FAILURE,
  payload: { error: error },
});

const getCompanyRegistrationTypeSuccess = (data) => ({
  type: GET_COMPANY_REGISTATION_TYPE_SUCCESS,
  payload:  data,
});
export const getCompanyRegistrationType = (id) => {
  return (dispatch) => {
    dispatch(getCompanyRegistrationTypeRequest());
    const path = apiUrl.GET_COMPANY_REGISTRATION_TYPE
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getCompanyRegistrationTypeSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getJobTypeFailure(error));
        console.log("error", error);
      });
  };
};




export const GET_COMPANY_WORK_MODEL_TYPE_SUCCESS = "GET_COMPANY_WORK_MODEL_TYPE_SUCCESS";
export const GET_COMPANY_WORK_MODEL_TYPE_REQUEST = "GET_COMPANY_WORK_MODEL_TYPE_REQUEST";
export const GET_COMPANY_WORK_MODEL_TYPE_FAILURE = "GET_COMPANY_WORK_MODEL_TYPE_FAILURE";

const getCompanyWorkModelTypeRequest = () => ({
  type:GET_COMPANY_WORK_MODEL_TYPE_REQUEST,
});

const getCompanyWorkModelTypeFailure = (error) => ({
  type:GET_COMPANY_WORK_MODEL_TYPE_FAILURE,
  payload: { error: error },
});

const getCompanyWorkModelTypeSuccess = (data) => ({
  type:GET_COMPANY_WORK_MODEL_TYPE_SUCCESS,
  payload:  data,
});
export const getCompanyWorkModelType = (id) => {
  return (dispatch) => {
    dispatch(getCompanyWorkModelTypeRequest());
    const path = apiUrl.GET_COMPANY_WORK_MODEL
    return http_noauth
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === true) {
          dispatch(getCompanyWorkModelTypeSuccess(response));
      }
        return response;
      })
      .catch((error) => {
        dispatch(getCompanyWorkModelTypeRequest(error));
        console.log("error", error);
      });
  };
};
