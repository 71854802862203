import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "../container/Home/Home";
import CompanyRegister from "../container/RegisterPage/companySignup";
import CandidateDetails from "../container/JobsSection/candidateDetails/candidateDetails";
import CandidateRegister from "../container/RegisterPage/candidateRegister";
import RecruiterRegister from "../container/RegisterPage/recruiterRegister";
import LoginPageCandidate from "../container/LoginPage/loginPageCandidate";
import LoginPageCompany from "../container/LoginPage/loginPageCompany";
import LoginPageRecruiter from "../container/LoginPage/loginPageRecruiter";
import JobsPage from "../container/JobsSection/allJobView/JobsPage";
import JobSearchResult from "../container/JobsSection/jobSearchResult/JobSearchResultPage";
import JobDescriptionBeforeSignup from "../container/JobsSection/jobDescriptionAll/JobDescriptionAll";
import CompanyProfile from "../container/JobsSection/companyProfile/CompanyProfile";
import CompanyJD from "../container/JobsSection/companyJd/CompanyJd";
import CreateJob from "../container/JobsSection/createJob/createJob";
import EditCompanyProfile from "../container/JobsSection/editCompanyProfile/editCompanyProfile";
import EditJob from "../container/JobsSection/createJob/editJob";
import EditCandidateProfile from "../container/JobsSection/candidateProfile/EditCandidateProfile";
import NewCandidateSignup from "../container/JobsSection/candidateProfile/NewCandidateProfile";
import CandidateProfile from "../container/JobsSection/candidateProfile/CandidateProfile";
import CandidateFeedback from "../container/JobsSection/candidateFeedback/CandidateFeedback";
import RecruiterProfile from "../container/JobsSection/recruiterProfile/RecruiterProfile";
const BasicRoutes = () => {
  const userdata = useSelector((state) => state.auth);
  return (
    <Router>
      <Routes>
        <Route exact path="/" name="home" element={<Home />} />
        <Route
          exact
          path="/companySignup"
          name="companySignup"
          element={<CompanyRegister />}
        />
        <Route
          exact
          path="/candidateDetails/:id"
          name="candidateDetails"
          element={<CandidateDetails />}
        />

        <Route
          exact
          path="/candidateRegister"
          name="candidateRegister"
          element={<CandidateRegister />}
        />
        <Route
          exact
          path="/recruiterRegister"
          name="recruiterRegister"
          element={<RecruiterRegister />}
        />
        <Route exact path="/JobsPage" name="jobs" element={<JobsPage />} />
        <Route
          exact
          path="/JobSearchResult"
          name="searchResult"
          element={<JobSearchResult />}
        />
        <Route
          exact
          path="/JobDescription"
          name="jobsBeforeSignup"
          element={<JobDescriptionBeforeSignup />}
        />
        <Route
          exact
          path="/company/profile"
          name="companyProfile"
          element={
            userdata.loggedIn ? (
              <CompanyProfile />
            ) : (
              <Navigate to="/loginPageCompany" />
            )
          }
        />
        <Route
          exact
          path="/companyJd/:id"
          name="companyJd"
          element={<CompanyJD />}
        />
        <Route
          exact
          path="/createJob"
          name="createJob"
          element={<CreateJob />}
        />
        <Route exact path="/editJob" name="editJob" element={<EditJob />} />
        <Route
          exact
          path="/editCompanyProfile"
          name="editCompanyProfile"
          element={<EditCompanyProfile />}
        />

        <Route
          exact
          path="/candidate/profile"
          name="candidateProfile"
          element={<CandidateProfile />}
        />
        <Route
          exact
          path="/candidate/editprofile"
          name="editcandidateProfile"
          element={<EditCandidateProfile />}
        />
        <Route
          exact
          path="/candidate/newprofile"
          name="candidatenewProfile"
          element={<NewCandidateSignup />}
        />
        <Route
          exact
          path="/candidateFeedback"
          name="candidateFeedback"
          element={<CandidateFeedback />}
        />

        <Route
          exact
          path="/loginPageCandidate"
          name="loginPageCandidate"
          element={<LoginPageCandidate />}
        />
        <Route
          exact
          path="/loginPageCompany"
          name="loginPageCompany"
          element={<LoginPageCompany />}
        />
        <Route
          exact
          path="/loginPageRecruiter"
          name="loginPageRecruiter"
          element={<LoginPageRecruiter />}
        />
        <Route
          exact
          path="/recruiterProfile"
          name="recruiterProfile"
          element={<RecruiterProfile />}
        />
      </Routes>
    </Router>
  );
};

export default BasicRoutes;
