import React, { Fragment, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AntSwitch from "../../components/Switch/loginSwitch";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { countryListnew } from "../../assets/Data/data";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import HomeBg from "../../assets/images/loginbg.png";
import formBg from "../../assets/Icons/mobileFormBG.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../Store/auth";
import OtpInput from "react-otp-input";
import OtpTimer from "../../components/OTP_Timer/OtpTimer";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  containerbg: {
    backgroundImage: `url(${HomeBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "auto",
    backgroundPosition: "center",
    position: "relative",
  },
  main: {
    paddingTop: "80px ",
    position: "absolute",
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  input: {
    borderRadius: "5px",
    border: "1px solid #223870",
    width: "50px",
    height: "40px",
    color: "#5071C4",
    backgroundColor: "#E0DFF4",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "@media (max-width: 468px)": {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: "0px",
      textAlign: "center",
      width: "100%",
    },
    form: {
      margin: "0",
      padding: "0 10px",
      width: "100%",
    },

    main: {
      width: "100%",
      padding: "150px 0px 0px 0px ",
      height: "auto",
      textAlign: "center",
    },

    input: {
      borderRadius: "5px",
      border: "1px solid #223870",
      width: "50px",
      height: "40px",
      color: "#5071C4",
      backgroundColor: "#E0DFF4",
    },
  },
}));

const LoginPageCompany = (props) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = React.useState("+91");

  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [loginToggle, setChecked] = React.useState(false);
  const handleChange = (event) => {
    console.log("handleChange", event.target.value);
    setChecked(event.target.checked);
  };
  const handleChangeText = () => {};

  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const companyLoginOtpSend = useSelector(
    (state) => state?.auth?.companyLoginOtpSend
  );
  const companyMoblie = useSelector((state) => state?.auth?.companyMoblie);
  const companyEmail = useSelector((state) => state?.auth?.companyEmail);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const navigate = useNavigate();
  const [msg, setMsg] = React.useState("");
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const verifyOtpCompany = async () => {

    if (loginToggle) {
      const emailotp = {
        user_type: 2,
        email_id: companyEmail,
        email_otp: otp,
        is_secondary: false,
      };
      const companyemailotp = await dispatch(
        authActions.sendCompanyEmailVerify(emailotp)
      );
      console.log("companyemailotp===================", companyemailotp);
      if (companyemailotp.data?.status === true) {
        const profile = await dispatch(authActions.getProfile());
        navigate("/company/profile");
        setMsg(companyemailotp?.data?.msg);
      } else {
        setMsg(companyemailotp?.data?.msg);
      }
    } else {
      const mobileotp = {
        user_type: 2,
        mobile_number: companyMoblie,
        mobile_otp: otp,
        is_secondary: false,
      };
      const companyotpmobile = await dispatch(
        authActions.sendCompanyMobileVerify(mobileotp)
      );
      console.log("otpemobile===================", companyotpmobile);
      if (companyotpmobile.data?.status === true) {
        const profile = await dispatch(authActions.getProfile());
        navigate("/company/profile");
        setMsg(companyotpmobile.data.msg);
      } else {
        setMsg(companyotpmobile?.data?.msg);
      }
    }
  };

  // const [counter, setCounter] = React.useState(59);
  // React.useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  useEffect(() => {
    window.onbeforeunload = async function () {
      const reset = await dispatch(authActions.resetCompanyLoginDetails());
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const onSubmit = async (data) => {
    console.log(data, loginToggle);

    if (loginToggle) {
      const datasend = {
        signup_type: 2,
        user_type: 2,
        email_id: data?.email,
        is_secondary: false,
      };
      const sendotp = await dispatch(authActions.sendCompanyEmailOtp(datasend));
    } else {
      const sendonmobile = {
        signup_type: 2,
        user_type: 2,
        country_code: +91,
        mobile_number: data?.mobile,
        is_secondary: false,
      };
      const sendcompanyotpmobileile = await dispatch(
        authActions.sendCompanyMobileOtp(sendonmobile)
      );
    }
  };

  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.containerbg}>
        <Grid container className={classes.main}>
          <Grid Item xs={12} sm={6} md={6}></Grid>
          <Grid
            Item
            xs={12}
            md={4}
            sm={6}
            className={classes.maingrid}
            sx={{
              maxWidth: "430px",
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
              textAlign: "center",
              "@media (max-width: 468px)": { marginRight: "0px" },
            }}
          >
            <Box sx={{ marginBottom: "30px", textAlign: "center" }}>
              {" "}
              <Typography
                variant="h4"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                }}
              >
                Login as Company
              </Typography>
            </Box>
            <Box py={1}>
              {/* aparajita */}
              <Stack spacing={0} direction="row">
                <Grid
                  container
                  sx={{ padding: "10px 110px", marginLeft: "-15px" }}
                >
                  <Grid
                    xs={4}
                    item
                    sx={{
                      color: "#223870",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeText("checked");
                    }}
                  >
                    {loginToggle ? (
                      <Typography
                        sx={{
                          color: "#5071C4",

                          fontSize: "14px",
                        }}
                        variant="p"
                      >
                        Mobile No.
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#223870",

                          fontSize: "14px",
                        }}
                        variant="p"
                      >
                        Mobile No.
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AntSwitch
                      checked={loginToggle}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      color: "#223870",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeText("unchecked");
                    }}
                  >
                    {loginToggle ? (
                      <Typography
                        sx={{
                          color: "#223870",
                          fontSize: "14px",
                        }}
                        variant="p"
                        component="p"
                      >
                        Email ID
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#5071C4",
                          fontSize: "14px",
                        }}
                        variant="p"
                        component="p"
                      >
                        Email ID
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            <form
              onSubmit={handleSubmit(onSubmit)}
              sx={{ width: "100%", minWidth: "430px" }}
            >
              {loginToggle ? (
                <Box sx={{ padding: "0px 20px" }}>
                  {" "}
                  <Grid container direction="Column">
                    {" "}
                    <Grid
                      Item
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="p"
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}
                      >
                        Email ID*
                      </Typography>{" "}
                    </Grid>
                    <Grid>
                      <Box
                        sm={12}
                        sx={{
                          width: "100%",
                        }}
                      >
                        {" "}
                        <Stack direction="row" spacing={0}>
                          <TextField
                            className={classes.textField}
                            size="small"
                            variant="outlined"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              width: "100%",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                            name="email"
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            {...register("email", {
                              required: "Email id is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Please enter a valid email address",
                              },
                            })}
                          />

                          <Box
                            sx={{
                              backgroundColor: "#E0DFF4",
                              padding: "3px 0px",
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          >
                            {" "}
                            <input
                              variant="standard"
                              type="submit"
                              value="Login via OTP"
                              style={{
                                border: "none",
                                marginRight: "4px",
                                backgroundColor: "#223870",
                                borderRadius: "3px",
                                color: "#FDCF57",
                                marginTop: "2px",
                                padding: "6px 8px 8px",
                                fontSize: "14px",
                                textTransform: "none",
                                "&:hover": {
                                  color: "#FDCF57",
                                  backgroundColor: "#223870",
                                },
                                "@media (max-width: 468px)": {
                                  marginTop: "-29px",
                                  textAlign: "center",
                                  marginRight: "-10px",
                                  padding: "0px 10px",
                                },
                              }}
                            />
                          </Box>
                        </Stack>
                        {/* {errors.email && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.email.message}
                          </Box>
                        )} */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ padding: "0px 20px" }}>
                  {" "}
                  <Grid container direction="Column">
                    {" "}
                    <Grid
                      Item
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="p"
                        gutterBottom
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          color: "#304672",
                          fontWeight: "500",
                        }}
                      >
                        Mobile No.*
                      </Typography>{" "}
                    </Grid>
                    <Grid container>
                      <Grid Item sm={4} sx={{ paddingRight: "10px" }}>
                        <Autocomplete
                          id="country-select-demo"
                          sx={{ width: "100% " }}
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant="standard"
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                              }}
                            />
                          }
                          name="country_code"
                          onChange={(_event, country) => {
                            console.log("country", country);
                            setSelectedCountry(country);
                          }}
                          size="small"
                          getOptionLabel={(option) => {
                            return option.iso3 + " +" + option.phone_code;
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid Item sm={8}>
                        <Stack direction="row" spacing={0}>
                          {" "}
                          <TextField
                            className={classes.textField}
                            size="small"
                            variant="outlined"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                            name="mobile"
                            onChange={(e) => setMobile(e.target.value)}
                            {...register("mobile", {
                              required: "Mobile number is required",
                              pattern: {
                                value: /^\d{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number",
                              },
                            })}
                          />{" "}
                          <Box
                            sx={{
                              backgroundColor: "#E0DFF4",
                              padding: "3px 0px",
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          >
                            {" "}
                            <input
                              variant="standard"
                              type="submit"
                              value="Login via OTP"
                              style={{
                                border: "none",
                                marginRight: "4px",
                                backgroundColor: "#223870",
                                borderRadius: "3px",
                                color: "#FDCF57",
                                marginTop: "2px",
                                padding: "6px 8px 8px",
                                fontSize: "14px",
                                textTransform: "none",
                                "&:hover": {
                                  color: "#FDCF57",
                                  backgroundColor: "#223870",
                                },
                                "@media (max-width: 468px)": {
                                  marginTop: "-29px",
                                  textAlign: "center",
                                  marginRight: "-10px",
                                  padding: "0px 10px",
                                },
                              }}
                            />
                          </Box>
                        </Stack>

                        {errors.mobile && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </form>
            <Box sx={{ color: "#0747f7", fontWeight: "500", fontSize: "12px" }}>
              {msg}
            </Box>
            {companyLoginOtpSend && (
              <>
                <Box sx={{ marginTop: "40px", marginBottom: "10px" }}>
                  {" "}
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "16px",
                      color: "#223870",
                      fontWeight: "600",
                    }}
                  >
                    Enter OTP
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <OtpInput
                    value={otp}
                    onChange={handleChangeOtp}
                    numInputs={6}
                    inputStyle={{
                      height: "35px",
                      width: "45px",
                      color: "#5071C4",
                      backgroundColor: "#E0DFF4",
                      border: "1px solid #223870",
                      margin: "4px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    focusStyle={{
                      border: "1px solid #223870",
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <OtpTimer resendOtp={props.resendOtp} timerzero={timerZero} />
                </Box>
                {/* <Box sx={{ marginTop: "10px" }}>
                <Typography
                  variant="p"
                  sx={{
                    color: "#223870",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  00:{counter}
                </Typography>
              </Box> */}
                <Box sx={{ marginTop: "30px" }}>
                  {" "}
                  <Button
                    onClick={verifyOtpCompany}
                    type="submit"
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      padding: "4px 20px",
                      borderRadius: "3px ",
                      color: "#FDCF57",
                      fontWeight: "500",
                      fontSize: "20px",
                      backgroundColor: "#223870",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </>
            )}
            <Box sx={{ marginTop: "20px" }}>
              <Typography
                variant="p"
                sx={{
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Don't have an account?{" "}
                <a
                  sx={{
                    color: "#223870",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                  href="/companySignup"
                >
                  Signup
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default LoginPageCompany;
