import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import quote from '../../../assets/images/Quote2.png';
import { makeStyles } from '@mui/styles';
import CandidateTestimonialBg from '../../../assets/images/CandidateTestimonials.png';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  Background: {
    backgroundImage: `url(${CandidateTestimonialBg})`,
    backgroundSize: 'cover',
    height: 'auto',
    width: '100%',
    backgroundPosition: 'center',
    padding: '50px 100px',
  },
  title: {
    color: '#223870',
    fontSize: '34px',
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: '10px',
  },
  subtitle: {
    color: '#5677B9',
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: '500',
    margin: '0px 230px 30px',
  },
  content: {
    color: '#304672',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: '20px',
  },
  name: {
    marginTop: '20px',
    color: '#223870',
    fontSize: '24px',
    fontWeight: '500',
    textAlign: 'left',
  },
  designation: {
    color: '#4C68AE',
    fontSize: '20px',
    textAlign: 'left',
  },
  quotation: {
    width: '40px',
    height: '40px',
  },
  '@media (min-width: 1640px)': {
    Background: {
      padding: '30px 0px',
    },
  },
  '@media (max-width: 468px)': {
    Background: {
      backgroundImage: 'none',
      width: '100%',
      padding: '10px 20px',
    },
    title: {
      fontSize: '28px',
      lineHeight: '28px',
      marginTop: '50px',
    },
    subtitle: {
      fontSize: '18px',
      lineHeight: '20px',
      margin: '0px ',
    },
    content: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    name: {
      fontSize: '20px',
    },
    designation: {
      fontSize: '18px',
    },
    quotation: {
      width: '20px',
      height: '20px',
    },
  },
}));

function Testimonial(props) {
  const [showmore, setShowmore] = useState(false);
  const onClick = () => {
    setShowmore(!showmore);
  };
  const classes = useStyles();
  return (
    <>
      <div>
        <Grid
          xs={12}
          sm={12}
          container
          sx={{
            marginRight: '50px',
            marginBottom: '50px',
            padding: '30px 80px 30px 0px',
            '@media (max-width: 468px)': {
              padding: '0px  ',
              width: '100%',
              marginRight: '0px',
            },
            '@media (min-width: 1536px)': {
              padding: '0px 100px',
              width: '100%',
              marginRight: '0px',
            },
          }}
        >
          <Grid Item xs={1} sm={2}>
            {' '}
            <Box component='img' src={quote} className={classes.quotation} />
          </Grid>
          <Grid Item xs={6} sm={8}>
            {' '}
            <Box className={classes.content}>
              {showmore
                ? props?.step.content
                : `${props?.step.content.substring(0, 145)}`}
              {props?.step.content.length >= 140 && (
                <Button
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    color: '#4B4FD9',
                    fontWeight: '500',
                    fontSize: '14px',
                    backgroundColor: 'none',
                    '&:hover': {
                      backgroundColor: 'none',
                      color: '#4B4FD9',
                    },
                  }}
                  onClick={onClick}
                >
                  {showmore ? 'See less...' : ' See More...'}
                  {/* &nbsp; */}
                </Button>
              )}
            </Box>
            <Box className={classes.name}>{props?.step.name}</Box>{' '}
            <Box className={classes.designation}>{props?.step.designation}</Box>
          </Grid>
          <Grid Item xs={5} sm={2}>
            {' '}
            <Box
              sx={{
                '@media (max-width: 468px)': {
                  padding: '0px ',
                },
                '@media (min-width: 1536px)': {
                  padding: '30px 180px',
                },
              }}
            >
              <Box
                component='img'
                src={props?.step.image}
                sx={{
                  marginTop: '30px',
                  marginLeft: '20px',
                  borderRadius: '10px 1px 10px 1px',
                  width: 80,
                  height: 80,
                  maxWidth: 400,
                  overflow: 'hidden',
                  marginBottom: '20px',
                  borderWidth: '7px 0px 0px 7px ',
                  borderStyle: 'solid',
                  borderColor: '  #FDCF57',
                }}
              />
            </Box>
            {/* </Box> */}
          </Grid>
        </Grid>{' '}
      </div>
    </>
  );
}

export default Testimonial;
