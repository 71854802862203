import React, { useState, Fragment } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useMediaQuery,
  useTheme,
  Box,
  useScrollTrigger,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import DrawerComponent from './Drawer';
import Logo from '../../assets/images/LogowithTagline.png';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../../Store/auth';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginLeft: '-1px',
    borderRadius: '3px',
    marginTop: theme.spacing(6),
    minWidth: 114,

    '& .MuiMenu-list': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  appbarnavstyle: {
    padding: '0px 47px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  logo: {
    flexGrow: '1',
    cursor: 'pointer',
  },

  img: {
    marginTop: '12px',
    width: '240px',
    height: 'auto',
  },

  menu: {
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    padding: '0px 15px',
    color: '#223870',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'none',
      color: '#223870',
    },
  },

  '@media (min-width: 900px)': {
    logo1: {
      display: 'none',
    },
    img1: {
      display: 'none',
    },
  },
  '@media (max-width: 900px)': {
    logo1: {
      float: 'right',
      marginLeft: '70%',
      flexGrow: '1',
      cursor: 'pointer',
    },
    logo: {
      display: 'none',
    },
    img1: {
      marginTop: '12px',

      width: '200px',
      height: 'auto',
    },
  },
  '@media (max-width: 680px)': {
    logo1: {
      float: 'right',
      marginLeft: '55%',
      flexGrow: '1',
      cursor: 'pointer',
    },
    logo: {
      display: 'none',
    },
    img1: {
      marginTop: '12px',

      width: '200px',
      height: 'auto',
    },
  },
  '@media (max-width: 468px)': {
    logo1: {
      marginLeft: '45%',
      flexGrow: '1',
      cursor: 'pointer',
    },
    appbarnavstyle: {
      paddingLeft: '0px',
    },

    img1: {
      marginTop: '12px',
      width: '180px',
      height: 'auto',
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const loggedIn = useSelector((state) => state?.auth?.loggedIn);
  const usertype = useSelector((state) => state?.auth?.usertype);
  const handleClickLogout = async () => {
    const logoddt = await dispatch({ type: 'logout' });
    navigate('/');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickCompanyProfile = async () => {
    navigate('/company/profile');
  };

  const handleClickCandidateProfile = async () => {
    navigate('/candidate/profile');
  };

  const headerButton = () => {
    if (usertype === 'candidate') {
      if (location?.pathname === '/candidate/profile') {
        return (
          <Button
            sx={{
              textTransform: 'none',
              textDecoration: 'none',
              padding: '3px 25px',
              borderRadius: '3px ',
              color: '#FDCF57',
              marginLeft: '20px',
              fontWeight: '600',
              fontSize: '18px',
              backgroundColor: '#223870',
              '&:hover': {
                backgroundColor: '#223870',
                color: '#FDCF57 ',
              },

              '@media (max-width: 1100px)': {
                padding: '3px 15px',
              },
            }}
            onClick={handleClickLogout}
          >
            Logout
          </Button>
        );
      } else {
        return (
          <Button
            sx={{
              textTransform: 'none',
              textDecoration: 'none',
              padding: '3px 25px',
              borderRadius: '3px ',
              color: '#FDCF57',
              marginLeft: '20px',
              fontWeight: '600',
              fontSize: '18px',
              backgroundColor: '#223870',
              '&:hover': {
                backgroundColor: '#223870',
                color: '#FDCF57 ',
              },

              '@media (max-width: 1100px)': {
                padding: '3px 15px',
              },
            }}
            onClick={handleClickCandidateProfile}
          >
            Profile
          </Button>
        );
      }
    } else if (usertype === 'company') {
      if (location?.pathname === '/company/profile') {
        return (
          <Button
            sx={{
              textTransform: 'none',
              textDecoration: 'none',
              padding: '3px 25px',
              borderRadius: '3px ',
              color: '#FDCF57',
              marginLeft: '20px',
              fontWeight: '600',
              fontSize: '18px',
              backgroundColor: '#223870',
              '&:hover': {
                backgroundColor: '#223870',
                color: '#FDCF57 ',
              },

              '@media (max-width: 1100px)': {
                padding: '3px 15px',
              },
            }}
            onClick={handleClickLogout}
          >
            Logout
          </Button>
        );
      } else {
        return (
          <Button
            sx={{
              textTransform: 'none',
              textDecoration: 'none',
              padding: '3px 25px',
              borderRadius: '3px ',
              color: '#FDCF57',
              marginLeft: '20px',
              fontWeight: '600',
              fontSize: '18px',
              backgroundColor: '#223870',
              '&:hover': {
                backgroundColor: '#223870',
                color: '#FDCF57 ',
              },

              '@media (max-width: 1100px)': {
                padding: '3px 15px',
              },
            }}
            onClick={handleClickCompanyProfile}
          >
            Profile
          </Button>
        );
      }
    } else if (usertype === 'guest') {
      return (
        <Fragment>
          {' '}
          <Button
            sx={{
              textTransform: 'none',
              textDecoration: 'none',
              padding: '3px 25px',
              borderRadius: '3px ',
              color: '#FDCF57',
              marginLeft: '20px',
              fontWeight: '600',
              fontSize: '18px',
              backgroundColor: '#223870',
              '&:hover': {
                backgroundColor: '#223870',
                color: '#FDCF57 ',
              },

              '@media (max-width: 1100px)': {
                padding: '3px 15px',
              },
            }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClick}
          >
            Join As
          </Button>
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              sx={{
                backgroundColor: '#fff',
                color: '#5677B9 ',
                fontWeight: '500',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#223870',
                  color: '#FDCF57 ',
                },
              }}
              onClick={() => {
                navigate('/loginPageCompany');
              }}
            >
              Company
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: '#fff',
                color: '#5677B9 ',
                fontWeight: '500',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#223870',
                  color: '#FDCF57 ',
                },
              }}
              onClick={() => {
                navigate('/loginPageCandidate');
              }}
            >
              Candidate
            </MenuItem>
          </StyledMenu>
        </Fragment>
      );
    }
  };

  return (
    <AppBar
      className={classes.appbarnavstyle}
      position='fixed'
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#E9E8F6',
      }}
    >
      <CssBaseline />
      <Toolbar>
        <Box className={classes.logo}>
          {' '}
          <Link to='/#home' smooth>
            {' '}
            <Box component='img' className={classes.img} src={Logo} />
          </Link>
        </Box>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Button>
              <Link to='/#aboutus' className={classes.menu}>
                About Us
              </Link>
            </Button>
            <Button>
              <Link to='/#services' className={classes.menu}>
                Services
              </Link>
            </Button>
            <Button>
              <Link to='/#industry' className={classes.menu}>
                Industry Verticals
              </Link>
            </Button>
            <Button>
              <Link to='/#clients' className={classes.menu}>
                Clients
              </Link>
            </Button>
            <Button>
              {usertype !== 'company' && (
                <Link
                  to='/JobsPage?page_no=0'
                  className={classes.menu}
                  onClick={scrollToTop}
                >
                  Jobs
                </Link>
              )}
            </Button>
            {loggedIn ? (
              headerButton()
            ) : (
              <Fragment>
                {' '}
                <Button
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    padding: '4px 15px',
                    borderRadius: '3px ',
                    color: '#FDCF57',
                    marginLeft: '20px',
                    fontWeight: '600',
                    fontSize: '18px',
                    backgroundColor: '#223870',
                    '&:hover': {
                      backgroundColor: '#223870',
                      color: '#FDCF57 ',
                    },

                    '@media (max-width: 1100px)': {
                      padding: '3px 15px',
                    },
                  }}
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={handleClick}
                >
                  Join As
                </Button>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: '#fff',
                      color: '#5677B9 ',
                      fontWeight: '500',
                      fontSize: '16px',
                      '&:hover': {
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                      },
                    }}
                    onClick={() => {
                      navigate('/loginPageCompany');
                    }}
                  >
                    Company
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: '#fff',
                      color: '#5677B9 ',
                      fontWeight: '500',
                      fontSize: '16px',
                      '&:hover': {
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                      },
                    }}
                    onClick={() => {
                      navigate('/loginPageCandidate');
                    }}
                  >
                    Candidate
                  </MenuItem>
                </StyledMenu>
              </Fragment>
            )}
          </div>
        )}
        <Box className={classes.logo1}>
          {' '}
          <Link to='/#home' smooth>
            {' '}
            <Box component='img' className={classes.img1} src={Logo} />
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
