import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import CustomizedSnackbars from "../../../components/Snackbar/CustomizedSnackbars";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { industryList1 } from "../../../assets/Data/data";
import { functionList1 } from "../../../assets/Data/data";
import download from "../../../assets/Icons/Download.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as jobsActions from "../../../Store/Jobs";
import * as authActions from "../../../Store/auth";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  container: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 50px ",
    backgroundSize: "cover",
    backgroundColor: "#DFE5F6",
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
  },

  imgFrame: {
    background: "none",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "@media (min-width: 1640px)": {},
  "@media (max-width: 468px)": {
    container: {
      width: "100%",
      padding: "0px 0px",
    },

    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
  },
}));
function CandidateDetails(props) {
  const classes = useStyles();
  const [status, setStatus] = React.useState("applied");
    const [tempstatus, settempStatus] = React.useState("applied");
  const [showDialog , setShowDialog] = React.useState(false);


  let params = useParams();
  let dispatch = useDispatch();
  const educationlist = useSelector((state) => state?.auth?.educationlist);
  const candidateDetails = useSelector((state) => state?.jobs?.applicant);
  const jobStatusCandidate = useSelector((state) => state?.jobs?.jobStatusCandidate);
  const jobDetails = useSelector((state) => state?.jobs?.jobDetails);
  const candidateJob = useSelector((state) => state?.jobs?.candidateJob);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const handleSelect = (event) => {
    if(event.target.value != 'applied'){
      setShowDialog(true);
      settempStatus(event.target.value);
    // setStatus(event.target.value);
    }
  };
  const [showmore, setShowmore] = useState(false);
  const onClick = () => {
    setShowmore(!showmore);
  };

  const downloadResume = (resume) =>{
    let name = candidateDetails?.first_name?.split(" ").join("-")+"_"+candidateDetails?.last_name?.split(" ").join("-")
    if (resume) {
      saveAs(resume, name);
    }

  }
  const handleOk = async() =>{

    setShowDialog(false);
    setStatus(tempstatus);
    const data = {
      job_id:  candidateJob?.job_id,
      user_id: candidateDetails?._id,
      interview_status: tempstatus
    }

    const candidatestatus= await dispatch(jobsActions.updateJobCandidateStatus(data));
    const payload = {
      job_id: jobDetails?._id,
      user_id: params?.id
    }

    const candidateJobhjh= await dispatch(jobsActions.getCompanyCandidateAppliedStatus(payload));

  }
  const handleCancel = () =>{
    setShowDialog(false);
  }

  useEffect(() => {

    async function jobDetails() {
      const id = params?.id;
      const candidateDetail= await dispatch(jobsActions.getJobCandidateProfile(id));
    }
    jobDetails();
  }, []);

  useEffect(() => {

    async function jobStatus() {
      setStatus(jobStatusCandidate?.interview_status)
      const payload = {
        job_id: jobDetails?._id,
        user_id: params?.id
      }
      const candidateJob= await dispatch(jobsActions.getCompanyCandidateAppliedStatus(payload));
    }
    jobStatus();
  }, []);

  useEffect(() => {
    async function candidateStatusDetails() {
      const id = params?.id;
      const payload = {
        job_id: jobDetails?._id,
        user_id: params?.id
      }
      const candidateJobddd= await dispatch(jobsActions.getCompanyCandidateAppliedStatus(payload));
    }
    candidateStatusDetails();
  }, []);

  useEffect(() => {
    async function geteducationlist() {
      const educationlist = await dispatch(
        authActions.candidateEducationList()
      );
    }
    geteducationlist();
  }, []);

  const getDesignation = () =>{
    if(candidateDetails?.experience_detail){

    // let degree = educationlist.find(item => item._id === candidateProfile?.experience[0]?.degree);
    console.log( 'experience',  candidateDetails?.experience_detail );
      return candidateDetails?.experience_detail[0]?.designation?.designation
    }
  }
  const getExperience = () =>{

  }

  const month = (month) =>{
    if(month === 1){
      return "Jan "
    }
    else if (month === 2) {
        return "Feb "
    }
    else if (month === 3) {
        return "Mar "
    }
    else if (month === 4) {
        return "Apr "
    }
    else if (month === 5) {
        return "May "
    }
    else if (month === 6) {
        return "Jun "
    }
    else if (month === 7) {
        return "Jul "
    }
    else if (month === 8) {
        return "Aug "
    }
    else if (month === 9) {
        return "Sept "
    }
    else if (month === 10) {
        return "Oct "
    }
    else if (month === 11) {
        return "Nov "
    }
    else if (month === 12) {
        return "Dec "
    }
    else if (month === 0) {
        return "Present "
    }


  }

  const getDegree = (id) =>{
    if(id){

    let degree = educationlist.find(item => item._id === id);

      return degree?.degree
    }
  }



  const getIndustry = () =>{
    let industry = industryList1.find(item => item._id === candidateDetails?.job_preference?.industry);
    console.log('industry', industry);
    return  industry?.industry;
  }

  const getFunction = () =>{
    let functionl = functionList1.find(item => item._id === candidateDetails?.job_preference?.function);
    console.log('industry', functionl);
    return  functionl?.function;
  }
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"

      open={showDialog}

    >
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent dividers>
        Are you sure to change the status
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>

      <Box className={classes.container}>
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            right: "90px",

            "@media (max-width: 468px)": {
              top: "10px",
              right: "10px",
            },
          }}
        >
          <Select
            required
            IconComponent={() => (
              <KeyboardArrowDownIcon
                sx={{
                  color: "#FDCF57 ",
                  position: "absolute",
                  right: 8,
                  pointerEvents: "none",
                }}
              />
            )}
            sx={{
              padding: "0px 15px",
              fontSize: "18px",
              width: "100%",
              color: "#FDCF57 ",
              backgroundColor: "#223870",
              fontWeight: "500",
            }}
            value={jobStatusCandidate}
            onChange={handleSelect}
            className={classes.countrycode}
          >
            {jobStatusCandidate === 'applied' && <MenuItem value="applied">Applied</MenuItem>}
      
            <MenuItem value="shortlisted">Shortlisted</MenuItem>
            <MenuItem value="onhold">On Hold</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "80px",
            right: "125px",

            "@media (max-width: 468px)": {
              top: "50px",
              right: "10px",
            },
          }}
        >
          <Typography
            variant="p"
            sx={{
              textAlign: "left",
              color: "#223870",
              fontWeight: "500",
            }}
          >

          </Typography>
        </Box>
        <Grid container sx={12} sm={12}>
          <Grid
            Item
            sx={12}
            sm={2}
            className={classes.imgFrame}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <Avatar
              variant="square"
              src={candidateDetails?.profile_image ?  candidateDetails?.profile_image : PersonIcon}
              sx={{ width: 120, height: 120 }}
            />
          </Grid>
          <Grid Item sx={12} sm={9}>
            <Typography
              variant="h4"
              sx={{
                marginTop: "10px",
                fontSize: "28px",
                textAlign: "left",
                color: "#304672",
                fontWeight: "600",
              }}
            >
             {candidateDetails?.first_name + " " + candidateDetails?.last_name}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "left",
                color: "#304672",
                fontWeight: "500",
              }}
            >
              {getDesignation()}
            </Typography>

            <Stack
              direction="row"
              spacing={{ xs: 1, sm: 1, md: 1 }}
              sx={{ marginTop: "5px" }}
            >
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    color: "#5071C4",
                    fontWeight: "500",
                  }}
                >
                  Relavant Exp. {" "} {candidateJob?.total_relevant_experience } yrs
                </Typography>
              </Box>
              <div
                style={{
                  marginTop: "5px",
                  width: "2px",
                  height: "15px",
                  backgroundColor: "#5071C4",
                }}
              ></div>
              <Box>
                {" "}
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    color: "#5071C4",
                    fontWeight: "500",
                  }}
                >
                  Current CTC {(parseInt(candidateDetails?.current_ctc / 100)) + "."+ ( (candidateDetails?.current_ctc % 100)/10)+ " " + "lpa" }
                </Typography>
              </Box>
              {/*
              <div
                style={{
                  marginTop: "5px",
                  width: "2px",
                  height: "15px",
                  backgroundColor: "#5071C4",
                }}
              ></div>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    color: "#5071C4",
                    fontWeight: "500",
                  }}
                >

                </Typography>
              </Box>
              <div
                style={{
                  marginTop: "5px",
                  width: "2px",
                  height: "15px",
                  backgroundColor: "#5071C4",
                }}
              ></div>

              */}
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "left",
                    color: "#5071C4",
                    fontWeight: "500",
                  }}
                >

                </Typography>
              </Box>{" "}
              <div
                style={{
                  marginTop: "5px",
                  width: "2px",
                  height: "15px",
                  backgroundColor: "#5071C4",
                }}
              ></div>
              <Box>
                <Typography
                  variant="h7"
                  sx={{
                    textAlign: "left",
                    color: "#5071C4",
                    fontWeight: "500",
                  }}
                >
                  Expected CTC { candidateJob?.expected_ctc} lpa
                </Typography>
              </Box>{" "}
              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1, md: 1 }}
                sx={{ marginTop: "15px", cursor: "pointer" }}
              >
                <Box     sx={{cursor: "pointer"}}      onClick={() =>
                                        downloadResume(
                                          candidateJob?.cv_attached
                                        )
                                      }>
                  {" "}
                  <Typography
                    variant="h7"
                    sx={{
                      marginLeft: "20px",
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    Resume
                  </Typography>
                </Box>
                <Box  onClick={() =>
                                        downloadResume(
                                          candidateJob?.cv_attached
                                        )
                                      }>
                  {" "}
                  <Box
                    component="img"
                    src={download}
                    sx={{
                      width: "18px",
                      height: "18px",
                      "@media (max-width: 468px)": {
                        width: "10px",
                        height: "10px",
                      },
                    }}
                  />
                </Box>
              </Stack>{" "}
            </Stack>

            {/*
            <Box>
              {" "}
              <Button
                sx={{
                  padding: "0px",
                  textTransform: "none",
                  textDecoration: "underline",
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "14px",
                  backgroundColor: "none",
                  "&:hover": {
                    backgroundColor: "none",
                    color: "#223870",
                  },
                }}
                onClick={onClick}
              >
                {showmore ? "Show less" : " Show more"}
              </Button>
            </Box>
            */}
            <Box>

              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1, md: 1 }}
                sx={{ marginTop: "15px" }}
              >
                <Box>
                  {" "}
                  <Typography
                    variant="p"
                    sx={{
                      textAlign: "left",
                      color: "#223870",
                      fontWeight: "500",
                    }}
                  >
                    Skills:
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                  {candidateDetails?.skills?.map( ( skill, index) =>{
                    return(

                      <>
                      {index !== 0 ? ", " : ""}
                        {skill?.skill}
                      </>
                    )


                  })  }
                  </Typography>
                </Box>
              </Stack>{" "}


            </Box>
            <Box sx={{ marginTop: "15px" }}>
              <Grid container xs={12} sm={12}>
                <Grid Item sm={7} xs={7}>
                  <Box>
                    {" "}
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#223870",
                        fontWeight: "500",
                      }}
                    >
                      Personal Information:
                    </Typography>
                  </Box>
                  <Box pt={0.5}>
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                      Mobile No:  {"+" +candidateDetails?.country_code+ " " + candidateDetails?.mobile_number}
                    </Typography>
                  </Box>
                  <Box pt={0.5}>
                    {" "}
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                      Email ID: {candidateDetails?.email_id}
                    </Typography>
                  </Box>
                  <Box pt={0.5}>
                    {" "}
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                      LinkedIn Profile: {candidateJob?.linkedin_profile}
                    </Typography>
                  </Box>
                </Grid>
                <Grid Item sm={5} xs={5}>
                  <Box>
                    {" "}
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#223870",
                        fontWeight: "500",
                      }}
                    >
                      Preferrences:
                    </Typography>
                  </Box>

                  <Stack direction="row" >
                  <Box pt={1}>
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                      Industry:
                    </Typography>

                  </Box>
                  <Box pl pt={1}>
                    <Typography
                      variant="p"
                      sx={{

                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                  {candidateDetails?.job_preference?.industry?.map((industry, index) =>{
                    return(
                      <>
                      {index !== 0 ? ", " : ""}
                        {industry?.industry}
                        </>
                  )
                }) }
                </Typography>

              </Box>
                  </Stack>
                  <Stack direction="row"  sx={{width: "100%", paddingTop:"4px" }}>
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                      Function:
                    </Typography>

                  </Box>
                  <Box pl sx={{width:"max-content"}}>
                    <Typography
                      variant="span"
                      sx={{

                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                  {candidateDetails?.job_preference?.function?.map((functio, index) =>{
                    return(
                      <>
                      {index !== 0 ? ", " : ""}
                        {functio?.function}
                        </>
                  )
                }) }
                </Typography>

              </Box>
                  </Stack>
                  <Stack direction="row" sx={{paddingTop:"4px"}} >
                  <Box>
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                      Location:
                    </Typography>

                  </Box>
                  <Box pl>
                    <Typography
                      variant="p"
                      sx={{
                        color: "#5071C4",
                        fontWeight: "500",
                      }}
                    >
                  {candidateDetails?.job_preference?.location?.map((location, index) =>{
                    return(
                      <>
                      {index !== 0 ? ", " : ""}
                        {location?.city}
                        </>
                  )
                }) }
                </Typography>

              </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ marginTop: "15px" }}>
              {" "}
{           /*   <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "3px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "600",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}
              >
                Unlock
              </Button> */}
            </Box>
            <Box sx={{ marginTop: "30px", paddingTop:"4px" }}>
              <Grid container xs={12} sm={12}>
                <Grid Item sm={7} xs={7}>
                  <Box>
                    {" "}
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#223870",
                        fontWeight: "500",
                      }}
                    >
                      Education:
                    </Typography>
                  </Box>
                  {candidateDetails?.education?.map( (education, index) =>{
                    return(
                    <>
                    <Box key={index} pt={1}>
                      <Typography
                        variant="p"
                        sx={{
                          textAlign: "left",
                          color: "#5071C4",
                          fontWeight: "500",
                        }}
                      >
                      {education?.degree?.degree}
                      {getDegree(education?.degree)} - {education?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      {" "}
                      <Typography
                        variant="p"
                        sx={{
                          textAlign: "left",
                          color: "#5071C4",
                          fontWeight: "500",
                        }}
                      >
                        {education?.university}
                      </Typography>
                    </Box>
                    </>
                  )
                  })}

                </Grid>
                <Grid Item sm={5} xs={5}>
                  <Box>
                    {" "}
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "left",
                        color: "#223870",
                        fontWeight: "500",
                      }}
                    >
                      Experience:
                    </Typography>
                  </Box>

                  {candidateDetails?.experience_detail?.map( (experience, index) =>{
                    console.log('experience', experience);
                    return(
                    <>
                    <Box key={index} pt={2}>
                      <Typography
                        variant="p"
                        sx={{
                          textAlign: "left",
                          color: "#5071C4",
                          fontWeight: "500",
                        }}
                      >

                      {experience?.company_name} { " " } {"("}  {month(experience?.start_month)  + experience?.start_year + " - " +month(experience?.end_month)}  {experience?.end_year !==0 ? experience?.end_year : "" } {")"}
                      </Typography>
                    </Box>
                    <Box>
                      {" "}
                      <Typography
                        variant="p"
                        sx={{
                          textAlign: "left",
                          color: "#5071C4",
                          fontWeight: "500",
                        }}
                      >
                      {experience?.designation?.designation}
                      </Typography>
                    </Box>
                    </>
                  )
                  })}

                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid Item sx={0} sm={1}></Grid>
        </Grid>
      </Box>
    </>
  );
}
export default CandidateDetails;
