import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Autocomplete,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import Close from '../../assets/Icons/Close.png';
import formBg from '../../assets/Icons/mobileFormBG.png';
import { useNavigate } from 'react-router-dom';
import { countryListnew } from '../../assets/Data/data';
import { Experience } from '../../assets/Data/data';
import { Months } from '../../assets/Data/data';
import HomeBg from '../../assets/images/loginbg.png';
import { makeStyles } from '@mui/styles';
import Navbar from '../../components/Header/Navbar';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../../Store/auth';
import * as jobsActions from '../../Store/Jobs';
import OtpInput from 'react-otp-input';
import OtpTimer from '../../components/OTP_Timer/OtpTimer';
import { v4 as uuidv4 } from 'uuid';
import MuiAlert from '@mui/material/Alert';
import AWS from 'aws-sdk';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  option: {
    padding: '10px 0px',
    margin: '1px !important',
  },
  html: {},
  containerbg: {
    overflow: 'hidden',
    backgroundImage: `url(${HomeBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: 'auto',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  grid: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingTop: '50px',
    paddingRight: '100px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
  },

  // textField: {
  //   backgroundColor: "#E0DFF4",
  //   textAlign: "center",
  //   padding: "4px",
  //   borderRadius: "5px",
  // },
  input: {
    borderRadius: '5px',
    border: '1px solid #223870',
    width: '50px',
    height: '40px',
    color: '#5071C4',
    backgroundColor: '#E0DFF4',
  },
  countrycode: {
    // marginRight: "20px",
    '& .MuiSelect-outlined': {
      padding: '8.5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  '@media (max-width: 468px)': {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: '80px',
    },
    form: {
      margin: '0',
      padding: '0 10px',
      width: '100%',
    },
  },
}));
const S3_BUCKET = 'exotalent-cv';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIAWCKLV3UAPEYYOZO3',
  secretAccessKey: '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',
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const CandidateRegister = (props) => {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });
  const logedIn = useSelector((state) => state?.auth?.loggedIn);
  const [message, setMessage] = React.useState('');
  console.log('CandidateRegister', logedIn);
  const navigate = useNavigate();
  const redirectCandidateProfile = () => {
    window.scrollTo({
      top: 0,
    });
    navigate('/candidateProfile');
  };
  const [name, setName] = React.useState();

  const handleYears = (event) => {
    setYears(event.target.value);
  };
  const [years, setYears] = React.useState();

  const handleMonths = (event) => {
    setMonths(event.target.value);
  };
  const [months, setMonths] = React.useState();
  const handleChange = (event) => {
    setName(event.target.value);
  };
  const [upload, setUpload] = React.useState(null);
  const handleUploadCv = (event) => {
    setUpload(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
  };

  const [uploadjd, setUploadJd] = React.useState(null);
  const [otp, setOtp] = useState('');
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const [email, setEmail] = React.useState();
  const [mobile, setMobile] = React.useState();
  const [filepath, setFilepath] = React.useState('');
  const [current_location, setCurrentLocation] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    window.onbeforeunload = async function () {
      const reset = await dispatch(authActions.resetCandidateDetails());
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const candidateEmailVerification = useSelector(
    (state) => state?.auth?.candidateEmailVerification
  );

  const candidateEmailOtp = useSelector(
    (state) => state?.auth?.candidateEmailOtp
  );

  const atleastoneverify = useSelector(
    (state) => state?.auth?.atleastoneverify
  );
  const candidateSignUp = useSelector((state) => state?.auth?.candidateSignUp);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const location = useSelector((state) => state?.jobs?.location);
  console.log('candidateEmailOtp', candidateEmailOtp);

  useEffect(() => {
    async function getLocationlist() {
      const educationlist = await dispatch(jobsActions.getLocation());
    }
    getLocationlist();
  }, []);

  const handleClickOpenEmail = async () => {
    setMessage('');
    if (atleastoneverify) {
      const data = {
        mobile_number: mobile,
        signup_type: 1,
        user_type: 1,
        email_id: email,
        is_secondary: false,
      };
      const checkEmail = await dispatch(authActions.checkCandidateEmail(data));

      //setOpenEmail(true);
    } else {
      const data = {
        signup_type: 1,
        user_type: 1,
        email_id: email,
        is_secondary: false,
      };
      const checkEmail = await dispatch(authActions.checkCandidateEmail(data));
    }
  };
  const verifyCandidateEmailOtp = async () => {
    console.log('otp', otp);
    setMessage('');
    const data = {
      email_otp: otp,
      user_type: 1,
      email_id: email,
      is_secondary: false,
    };

    //1111111111111
    const verifyotp = await dispatch(authActions.verifyCandidateEmailOtp(data));
    console.log('verifyotp', verifyotp);

    setOtp('');

    setMessage(verifyotp?.data?.msg);
    console.log('verifyotp', verifyotp);
    handlexAuth(verifyotp?.headers['x-auth']);
  };
  const handleCloseEmail = async () => {
    const closeEmail = await dispatch(authActions.closeCandidateEmailDialog());
    // setOpenEmail(false);
  };
  const candidateMobileVerification = useSelector(
    (state) => state?.auth?.candidateMobileVerification
  );
  const candidateMobileOtp = useSelector(
    (state) => state?.auth?.candidateMobileOtp
  );

  const handleClickOpenMobile = async () => {
    setMessage('');
    console.log('xauth 1....', xauth);
    if (atleastoneverify) {
      const data = {
        email_id: email,
        signup_type: 1,
        user_type: 1,
        country_code: +91,
        mobile_number: mobile,
        is_secondary: false,
      };
      const checkMobile = await dispatch(
        authActions.checkCandidateMobile(data)
      );
      console.log();
    } else {
      const data = {
        signup_type: 1,
        user_type: 1,
        country_code: +91,
        mobile_number: mobile,
        is_secondary: false,
      };
      const checkMobile = await dispatch(
        authActions.checkCandidateMobile(data)
      );
    }
  };
  const verifyCandidateMobileOtp = async () => {
    console.log('otp', otp);
    setMessage('');
    const data = {
      user_type: 1,
      mobile_number: mobile,
      mobile_otp: otp,
      is_secondary: false,
    };
    const verifyotpMobile = await dispatch(
      authActions.verifyCandidateMobileOtp(data)
    );
    setMessage(verifyotpMobile?.data?.msg);
    setOtp('');

    console.log('verifyotpMobile', verifyotpMobile);
    handlexAuth(verifyotpMobile?.headers['x-auth']);
  };
  const handleCloseMobile = async () => {
    const closeMobile = await dispatch(
      authActions.closeCandidateMobileDialog()
    );
  };
  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: '#aaa',
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const [minArrayValue, setMinArrayValue] = useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [maxArrayValue, setMaxArrayValue] = useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [minyear, setMinyear] = useState();
  const [maxyear, setMaxyear] = useState();
  const minYearChange = (event) => {
    setMinyear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const maxYearChange = (event) => {
    setMaxyear(event.target.value);
  };
  const [xauth, setXauth] = useState('');
  const handlexAuth = (data) => {
    setXauth(data);
  };
  const uploadFile = async () => {
    let namekey = uuidv4() + '_lx_' + upload.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: upload,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();
    // .on('httpUploadProgress', (evt) => {
    // setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err,data) => {
    // if (err) console.log('aws err',err)
    // if (data)console.log('aws data',data)
    //
    // })
    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey
        );
        console.log('aws Success', data);
        let path =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const [msg, setMsg] = React.useState('');

  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };
  const uploadJd = async (file) => {
    let namekey = uuidv4() + '_lx_' + file.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();

    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey
        );
        console.log('aws Success', data);
        let path =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };

  const onSubmit = async (details) => {
    console.log('xauth 2....', xauth);
    console.log('detail', details);
    let year = details?.years;
    let month = details?.months;
    let experience = parseInt(year * 12) + parseInt(month);

    console.log('path', filepath);
    var config = {
      headers: {
        'x-auth': xauth,
      },
    };
    let currentctc =
      details?.current_ctc * 100 + details?.current_ctc_thousand * 10;

    const data = {
      first_name: details?.firstName,
      last_name: details?.lastName,
      // experience: 12,
      experience: experience,
      current_ctc: currentctc,
      location: current_location?._id,
      cv: filepath,
      // cv: "https://www.dj/kd.png",
    };
    const signupcandidate = await dispatch(
      authActions.signupCandidate(data, config)
    );
    if (signupcandidate.data?.status === true) {
      setOpen(true);
      const profiless = await dispatch(authActions.getCandidateProfile());
      navigate('/candidate/editprofile');
      setMsg(signupcandidate.msg);
    } else {
      setMsg(signupcandidate.msg);
    }
    console.log('signupcandidate', signupcandidate);
  };

  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}

      <Box className={classes.containerbg}>
        {' '}
        <Grid container className={classes.grid}>
          {' '}
          <Grid Item xs={12} sm={7}></Grid>{' '}
          <Grid Item xs={12} sm={5} className={classes.form}>
            <Typography
              variant='h4'
              sx={{
                fontSize: '30px',
                marginTop: '40px',
                marginBottom: '10px',
                textAlign: 'center',
                color: '#223870',
                fontWeight: '600',
              }}
            >
              Candidate Signup
            </Typography>
            <Box
              sx={{
                paddingBottom: '40px',
              }}
            >
              {' '}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  sm={12}
                  sx={{
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {' '}
                  <Grid
                    Item
                    sm={6}
                    md={6}
                    sx={{
                      width: '50%',
                    }}
                  >
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          First Name*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item sx={{ marginRight: '6px' }}>
                        <TextField
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name='firstName'
                          {...register('firstName', {
                            required: 'First name is required',
                            minLength: {
                              value: 1,
                              message: 'First name must be atleast 1 character',
                            },
                          })}
                        />
                        {errors.firstName && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.firstName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    Item
                    sm={6}
                    md={6}
                    sx={{
                      width: '50%',
                    }}
                  >
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                            marginLeft: '8px',
                          }}
                        >
                          Last Name*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item sx={{ marginLeft: '6px' }}>
                        <TextField
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name='lastName'
                          {...register('lastName', {
                            required: 'Last name is required',
                            minLength: {
                              value: 1,
                              message: 'Last name must be atleast 1 character',
                            },
                          })}
                        />
                        {errors.lastName && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.lastName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {' '}
                  <Grid
                    Item
                    sx={{
                      marginTop: '10px',
                    }}
                  >
                    {' '}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Email ID*
                    </Typography>{' '}
                  </Grid>
                  <Grid>
                    <Box
                      sm={12}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {' '}
                      <Stack direction='row' spacing={0}>
                        <TextField
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            width: '100%',
                            borderRadius: '5px 0px 0px 5px',
                          }}
                          name='email'
                          fullWidth
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#E0DFF4',
                            padding: '3px 0px',
                            borderRadius: '0px 5px 5px 0px',
                          }}
                        >
                          {' '}
                          <Button
                            onClick={handleClickOpenEmail}
                            variant='standard'
                            disabled={candidateEmailVerification}
                            sx={{
                              marginRight: '5px',
                              backgroundColor: '#223870',
                              borderRadius: '3px',
                              color: candidateEmailVerification
                                ? '#FDCF57!important'
                                : '#FDCF57',
                              marginTop: '2px',
                              padding: '2px 20px',
                              fontSize: '16px',
                              textTransform: 'capitalize',
                              '&:hover': {
                                color: '#FDCF57',
                                backgroundColor: '#223870',
                              },
                              '@media (max-width: 468px)': {
                                textAlign: 'center',
                                padding: '0px 10px',
                              },
                            }}
                          >
                            {candidateEmailVerification ? 'Verified' : 'Verify'}
                          </Button>
                          <Dialog
                            open={candidateEmailOtp}
                            onClose={handleCloseEmail}
                            sx={{
                              '& .MuiDialog-container': {
                                '& .MuiPaper-root': {
                                  width: '100%',
                                  maxWidth: '590px',
                                  height: '430px',
                                  borderRadius: '8px',
                                  backgroundColor: '#E8EEFB',
                                },
                              },
                            }}
                          >
                            <DialogActions>
                              <Box
                                onClick={handleCloseEmail}
                                component='img'
                                src={Close}
                                sx={{
                                  top: '10px',
                                  right: '10px',
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: '#223870',
                                textAlign: 'center',
                                fontSize: '22px',
                                marginTop: '-15px',
                              }}
                            >
                              Verify Email ID
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: '20px ',
                                textAlign: 'center',
                              }}
                            >
                              {' '}
                              <Box>
                                {' '}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: '18px',
                                    color: '#5071C4',
                                    fontWeight: '500',
                                  }}
                                >
                                  OTP sent to {email}
                                </Typography>{' '}
                              </Box>
                              <Box
                                sx={{ marginTop: '20px', marginBottom: '10px' }}
                              >
                                {' '}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: '#223870',
                                    fontWeight: '500',
                                  }}
                                >
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <OtpInput
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  inputStyle={{
                                    height: '35px',
                                    width: '45px',
                                    color: '#5071C4',
                                    backgroundColor: '#E0DFF4',
                                    border: '1px solid #223870',
                                    margin: '4px',
                                    fontSize: '18px',
                                    borderRadius: '3px',
                                  }}
                                  focusStyle={{
                                    border: '1px solid #223870',
                                  }}
                                />
                              </Box>
                              <Box>
                                {message === 'Wrong OTP' && (
                                  <Typography
                                    variant='h5'
                                    sx={{
                                      color: 'ff0000',
                                      fontWeight: '500',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {message}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ marginTop: '5px' }}>
                                <OtpTimer
                                  resendOtp={handleClickOpenEmail}
                                  timerzero={timerZero}
                                />
                              </Box>
                              <Box sx={{ marginTop: '40px ' }}>
                                {' '}
                                <Button
                                  onClick={verifyCandidateEmailOtp}
                                  sx={{
                                    padding: '4px 20px',
                                    textTransform: 'none',
                                    textDecoration: 'none',
                                    borderRadius: '3px ',
                                    color: '#FDCF57',
                                    fontWeight: '500',
                                    fontSize: '18px',
                                    backgroundColor: '#223870',
                                    '&:hover': {
                                      backgroundColor: '#223870',
                                      color: '#FDCF57 ',
                                    },
                                  }}
                                >
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.email && (
                        <Box sx={{ color: 'red', fontSize: '12px' }}>
                          {' '}
                          {errors.email.message}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {' '}
                  <Grid
                    Item
                    sx={{
                      marginTop: '10px',
                    }}
                  >
                    {' '}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Mobile No.*
                    </Typography>{' '}
                  </Grid>
                  <Grid container>
                    <Grid Item sm={4} sx={{ paddingRight: '10px' }}>
                      {/* aparajita */}
                      <Autocomplete
                        id='country-select-demo'
                        sx={{ width: '100% ' }}
                        value={selectedCountry}
                        options={countryListnew}
                        autoHighlight
                        disableClearable
                        variant='standard'
                        popupIcon={
                          <KeyboardArrowDownIcon
                            sx={{
                              color: '#5071C4',
                            }}
                          />
                        }
                        name='country_code'
                        onChange={(_event, country) => {
                          console.log('country', country);
                          setSelectedCountry(country);
                        }}
                        size='small'
                        getOptionLabel={(option) => {
                          return option.iso3 + ' +' + option.phone_code;
                        }}
                        renderInput={(params) => (
                          <TextField
                            size='small'
                            {...params}
                            // label="IND +91"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid Item sm={8}>
                      <Stack direction='row' spacing={0}>
                        {' '}
                        <TextField
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={(e) => setMobile(e.target.value)}
                          name='mobile'
                          sx={{
                            width: '100%',
                            borderRadius: '5px 0px 0px 5px',
                          }}
                        />{' '}
                        <Box
                          sx={{
                            marginLeft: '-20px',
                            backgroundColor: '#E0DFF4',
                            padding: '3px 0px',
                            borderRadius: '0px 5px 5px 0px',
                            '@media (max-width: 468px)': {
                              fontSize: '14px',
                            },
                          }}
                        >
                          {' '}
                          <Button
                            onClick={handleClickOpenMobile}
                            variant='standard'
                            disabled={candidateMobileVerification}
                            sx={{
                              marginRight: '4px',
                              backgroundColor: '#223870',
                              borderRadius: '3px',
                              color: candidateMobileVerification
                                ? '#FDCF57!important'
                                : '#FDCF57',
                              marginTop: '1px',
                              padding: '2px 20px',
                              fontSize: '16px',
                              textTransform: 'capitalize',
                              '&:hover': {
                                color: '#FDCF57',
                                backgroundColor: '#223870',
                              },
                              '@media (max-width: 468px)': {
                                textAlign: 'center',
                                padding: '0px 10px',
                              },
                            }}
                          >
                            {candidateMobileVerification
                              ? 'Verified'
                              : 'Verify'}
                          </Button>
                          <Dialog
                            open={candidateMobileOtp}
                            onClose={handleCloseMobile}
                            sx={{
                              '& .MuiDialog-container': {
                                '& .MuiPaper-root': {
                                  width: '100%',
                                  maxWidth: '590px',
                                  height: '430px',
                                  borderRadius: '10px',
                                  backgroundColor: '#E8EEFB',
                                },
                              },
                            }}
                          >
                            <DialogActions>
                              <Box
                                onClick={handleCloseMobile}
                                component='img'
                                src={Close}
                                sx={{
                                  top: '10px',
                                  right: '10px',
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: '#223870',
                                textAlign: 'center',
                                fontSize: '22px',
                                marginTop: '-15px',
                              }}
                            >
                              Verify Mobile No.
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: '20px ',
                                textAlign: 'center',
                              }}
                            >
                              {' '}
                              <Box>
                                {' '}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: '18px',
                                    color: '#5071C4',
                                    fontWeight: '500',
                                  }}
                                >
                                  OTP sent to {mobile}
                                </Typography>{' '}
                              </Box>
                              <Box
                                sx={{ marginTop: '20px', marginBottom: '10px' }}
                              >
                                {' '}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: '#223870',
                                    fontWeight: '500',
                                  }}
                                >
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <OtpInput
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  inputStyle={{
                                    height: '35px',
                                    width: '45px',
                                    color: '#5071C4',
                                    backgroundColor: '#E0DFF4',
                                    border: '1px solid #223870',
                                    margin: '4px',
                                    fontSize: '18px',
                                    borderRadius: '3px',
                                  }}
                                  focusStyle={{
                                    border: '1px solid #223870',
                                  }}
                                />
                              </Box>
                              <Box>
                                {message === 'Wrong OTP' && (
                                  <Typography
                                    variant='h5'
                                    sx={{
                                      color: 'ff0000',
                                      fontWeight: '500',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {message}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ marginTop: '5px' }}>
                                <OtpTimer
                                  resendOtp={handleClickOpenMobile}
                                  timerzero={timerZero}
                                />
                              </Box>
                              <Box sx={{ marginTop: '40px ' }}>
                                {' '}
                                <Button
                                  onClick={verifyCandidateMobileOtp}
                                  sx={{
                                    padding: '4px 20px',
                                    textTransform: 'none',
                                    textDecoration: 'none',
                                    borderRadius: '3px ',
                                    color: '#FDCF57',
                                    fontWeight: '500',
                                    fontSize: '18px',
                                    backgroundColor: '#223870',
                                    '&:hover': {
                                      backgroundColor: '#223870',
                                      color: '#FDCF57 ',
                                    },
                                  }}
                                >
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.mobile && (
                        <Box sx={{ color: 'red', fontSize: '12px' }}>
                          {' '}
                          {errors.mobile.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {' '}
                  <Grid
                    Item
                    sx={{
                      marginTop: '10px',
                    }}
                  >
                    {' '}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Experience*
                    </Typography>{' '}
                  </Grid>
                  <Grid Item>
                    {' '}
                    <Grid
                      container
                      sx={{
                        width: '100%',
                        paddingRight: '10px',
                      }}
                    >
                      <Grid Item sm={6} md={6} sx={{ width: '50%' }}>
                        {/* <Autocomplete
                          id="country-select-demo"
                          sx={{ width: "100% " }}
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant="standard"
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                              }}
                            />
                          }
                          name="country_code"
                          defaultValue="91"
                          onChange={(_event, country) => {
                            console.log("country", country);
                            setSelectedCountry(country);
                          }}
                          size="small"
                          getOptionLabel={(option) => {
                            return option.iso3 + " +" + option.phone_code;
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"

                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        /> */}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          value={minyear}
                          name='years'
                          onChange={minYearChange}
                          className={classes.countrycode}
                          {...register('years', {
                            required: 'Select your experience in years ',
                          })}
                        >
                          {Experience?.map((option) => {
                            return (
                              <MenuItem
                                key={option.label}
                                value={option.label}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option.value ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.years && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.years.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid Item sm={6} md={6} sx={{ width: '50%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                            marginLeft: '10px',
                          }}
                          name='months'
                          value={months}
                          onChange={handleMonths}
                          className={classes.countrycode}
                          {...register('months', {
                            required: 'Select your experience in months',
                          })}
                        >
                          {Months?.map((option) => {
                            return (
                              <MenuItem
                                key={option.label}
                                value={option.label}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option.value ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.months && (
                          <Box
                            sx={{
                              color: 'red',
                              fontSize: '12px',
                              marginLeft: '10px',
                            }}
                          >
                            {' '}
                            {errors.months.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sm={12}
                  sx={{
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  {' '}
                  <Grid Item sm={6} md={6} sx={{ width: '50%' }}>
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Current CTC (in lacs)*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item sx={{ marginRight: '6px' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='current_ctc'
                          className={classes.countrycode}
                          {...register('current_ctc', {
                            required: 'enter your current ctc ',
                          })}
                        >
                          {[...Array(50)]?.map((option, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {index}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.current_ctc && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.current_ctc.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid Item sm={6} md={6} sx={{ width: '50%' }}>
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            fontSize: '12px',
                            color: '#304672',
                            fontWeight: '500',
                            marginLeft: '12px',
                          }}
                        >
                          (in thousands)*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item sx={{ marginLeft: '6px' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='current_ctc_thousand'
                          className={classes.countrycode}
                          {...register('current_ctc_thousand', {
                            required: 'enter your current ctc in thousand',
                          })}
                        >
                          {[...Array(10)]?.map((option, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {index * 10}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.current_ctc_thousand && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.current_ctc_thousand.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    Item
                    sm={12}
                    md={12}
                    sx={{ width: '50%', marginTop: '8px' }}
                  >
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                            marginLeft: '2px',
                          }}
                        >
                          Location*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item sx={{ marginLeft: '0px' }}>
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: '100% ' }}
                          value={current_location}
                          options={location}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                              }}
                            />
                          }
                          name='location'
                          defaultValue=''
                          onChange={(_event, location) => {
                            setCurrentLocation(location);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            if (option) {
                              return option?.city;
                            } else {
                              return '';
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              size='small'
                              className={classes.textField}
                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: '',
                              }}
                            />
                          )}
                        />
                        {/*  <TextField
                          size="small"
                          variant="outlined"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name="location"
                          {...register("location", {
                            required: "Please enter your current Location",
                          })}
                        />{" "}

                        */}
                        {errors.location && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.location.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {' '}
                  <Grid
                    Item
                    sx={{
                      marginTop: '10px',
                    }}
                  >
                    {' '}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Upload CV*
                    </Typography>{' '}
                  </Grid>
                  {/* <Stack spacing={2} direction="row">
                    <Box>
                      <label htmlFor="icon-button-doc">
                        <Box
                          p={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        ></Box>
                      </label>
                      <input
                        id="icon-button-doc"
                        type="file"
                        onChange={handleUploadCv}
                        name="docum"
                      />
                    </Box>
                  </Stack> */}
                  <Grid Item>
                    <Box>
                      <label htmlFor='icon-button-image'></label>
                      <input
                        id='icon-button-image'
                        type='file'
                        style={{
                          width: '100%',
                          padding: '10px',
                          display: 'block',
                          borderRadius: '5px',
                          backgroundColor: '#E0DFF4',
                          color: '#304672',
                          cursor: 'pointer',
                        }}
                        onChange={handleUploadJd}
                        name='image'
                      />
                    </Box>
                    <Box sx={{ color: 'red', fontSize: '12px' }}>
                      {' '}
                      {errors.browse?.type === 'required' && 'Upload your cv'}
                    </Box>
                  </Grid>
                  <Box>
                    {' '}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: '#615d5d',
                        fontSize: '10px',
                      }}
                    >
                      DOC, DOCx, PDF, RTF l Max: 2 MB
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name='acceptTerms'
                        defaultValue='false'
                        inputRef={register()}
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            required
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={
                      <Typography
                        varient='p'
                        color={errors.acceptTerms ? 'error' : 'inherit'}
                        sx={{
                          fontSize: '12px',
                        }}
                      >
                        I agree to the{' '}
                        <a href='/' style={{ color: '#223870' }}>
                          T&C and Privacy Policy
                        </a>{' '}
                        of the ExoTalent*
                      </Typography>
                    }
                  />
                  <br />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.acceptTerms
                      ? '(' + errors.acceptTerms.message + ')'
                      : ''}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction='Row'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  {' '}
                  <Grid
                    item
                    sx={{
                      justifyContent: 'center',
                    }}
                  >
                    {' '}
                    <Button
                      //onClick={redirectCandidateProfile}
                      type='submit'
                      sx={{
                        //position: "relative",
                        textTransform: 'none',
                        textDecoration: 'none',
                        padding: '3px 25px',
                        borderRadius: '3px ',
                        color: '#FDCF57',
                        fontWeight: '500',
                        fontSize: '20px',
                        backgroundColor: '#223870',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#223870',
                          color: '#FDCF57 ',
                        },
                      }}
                    >
                      Signup
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '5px',
                      }}
                    >
                      <a
                        href='/loginPageCandidate'
                        style={{
                          fontSize: '16px',
                          color: '#223870',
                        }}
                      >
                        Login
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CandidateRegister;
