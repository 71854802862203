import React, { useEffect, useState, useRef } from "react";
import { Grid, Box, Button, Select, MenuItem, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { industryList1 } from "../../../assets/Data/data";
import { functionList1 } from "../../../assets/Data/data";
import JobSearchCard from "../../../components/jobCards/jobCardSearchResult";
import JobSearchBar from "../../../components/jobSearchBar/jobSearchBar";
import * as jobsActions from "../../../Store/Jobs";
import * as helperActions from "../../../Store/helper";
import { Type } from "../../../assets/Data/data";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  mainHome: {
    paddingTop: "80px ",
    backgroundSize: "cover",
    backgroundColor: "#F6F8FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },

  countrycode1: {
    marginLeft: "0px",
    "& .MuiSelect-outlined": {
      backgroundColor: "#e9e8f6",
      padding: "8.8px",
    },
    "& .MuiSelect-outlined:hover:after": {
      backgroundColor: "#e9e8f6",
    },
    "& .MuiSelect-outlined:hover:before": {
      backgroundColor: "#e9e8f6",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "9.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  container: {
    padding: "0px 75px",
  },
  maincard: {
    zIndex: "800",
    textAlign: "left",
    color: "#223870",
    height: "auto",
    marginBottom: "20px",
  },

  jobbox: {
    padding: "10px 15px",
    marginRight: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  filterbox: {
    width: "100%",
    textAlign: "left",
    padding: "20px",
    borderRadius: "10px",
    height: "110vh",
    color: "#223870",
    backgroundColor: "#EEF3FD",
  },

  "@media (max-width: 1200px)": {
    jobbox: {
      padding: "20px 15px",
    },
    filterbox: {
      width: "100%",
      paddingLeft: "20px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (max-width: 1080px)": {
    jobbox: {
      padding: "20px 15px",
    },
    filterbox: {
      width: "100%",
      padding: "auto",
    },
    title: {
      paddingRight: "60px ",
    },
  },

  "@media (min-width: 1640px)": {
    jobbox: {
      padding: "20px 15px",
    },
    filterbox: {
      width: "100%",
      padding: "auto",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (max-width: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: "30px 0px",
      padding: "0px 5px",
    },

    container: {
      width: "100%",
      padding: "0px 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "auto",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0px",
      borderRadius: "10px",
    },
    maincard: {
      padding: "20px 5px",
    },
  },
}));

const JobSearchResult = () => {
  const classes = useStyles("");
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = React.useState();
  const handleType = (event) => {
    setType(event.target.value);
  };
  const searchJobs = useSelector((state) => state?.jobs?.searchJobs);
  const location = useSelector((state) => state?.helper?.location);
  const [job_location , setJobLocation] = React.useState();

  useEffect(() => {
    console.log("search", searchParams.get(""));

    async function jobDetails() {
      let data = {};
      if (searchParams.get("page_no")) {
        data["page_no"] = searchParams.get("page_no");
      }
      if (searchParams.get("job_title")) {
        data["job_title"] = searchParams.get("job_title");
      }
      if (searchParams.get("job_location")) {
        data["job_location"] = searchParams.get("job_location");
      }
      if (searchParams.get("min_experience")) {
        data["min_experience"] = searchParams.get("min_experience");
      }
      if (searchParams.get("max_experience")) {
        data["max_experience"] = searchParams.get("max_experience");
      }
      console.log("JobSearchResult", data);
      const searchJobList = await dispatch(jobsActions.getSearchJobList(data));
    }
    jobDetails();
  }, []);

  useEffect(() => {
    async function getIndustrylist() {
      const educationlist = await dispatch(
        helperActions.getIndustry()
      );
    }
    getIndustrylist();
  }, []);

  useEffect(() => {
    async function getSkillslist() {
      const educationlist = await dispatch(helperActions.getSkills());
    }
    getSkillslist();
  }, []);

  useEffect(() => {
    async function getFunctionlist() {
      const educationlist = await dispatch(
        helperActions.getFunction()
      );
    }
    getFunctionlist();
  }, []);

  useEffect(() => {
    async function getLocationlist() {
      const educationlist = await dispatch(
        helperActions.getLocation()
      );
    }
    getLocationlist();
  }, []);

  useEffect(() => {
    async function getCompanySizelist() {
      const educationlist = await dispatch(
        helperActions.getComapnySize()
      );
    }
    getCompanySizelist();
  }, []);
  useEffect(() => {
    async function getCompanyTypelist() {
      const educationlist = await dispatch(
        helperActions.getCompanyType()
      );
    }
    getCompanyTypelist();
  }, []);
useEffect(() => {
  async function getCompanyWorkModelTypelist() {
    const educationlist = await dispatch(
      helperActions.getCompanyWorkModelType()
    );
  }
  getCompanyWorkModelTypelist();
}, []);
useEffect(() => {
  async function getCompanyRegistrationTypelist() {
    const educationlist = await dispatch(
      helperActions.getCompanyRegistrationType()
    );
  }
  getCompanyRegistrationTypelist();
}, []);
  useEffect(() => {
    async function getJobTypelist() {
      const educationlist = await dispatch(
        helperActions.getJobType()
      );
    }
    getJobTypelist();
  }, []);


  console.log("searchJobs", searchJobs);
  const handleJobtype = (event) => {
    setJobtype(event.target.value);
  };
  const [years, setYears] = React.useState("");
  const handleYears = (event) => {
    setYears(event.target.value);
  };
  const [industry, setIndustry] = React.useState("");
  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };
  const [jobtype, setJobtype] = React.useState("");

  const [education, setEducation] = React.useState("");
  const handleEducation = (event) => {
    setEducation(event.target.value);
  };
  const [workmodel, setWorkmodel] = React.useState("");
  const handleWorkmodel = (event) => {
    setWorkmodel(event.target.value);
  };
  const [similarroles, setSimilarroles] = React.useState("");
  const handleSimilarroles = (event) => {
    setSimilarroles(event.target.value);
  };
  const [postedby, setPostedby] = React.useState("");
  const handlePostedby = (event) => {
    setPostedby(event.target.value);
  };
  const [fun, setFun] = React.useState("");
  const handleFun = (event) => {
    setFun(event.target.value);
  };
  const navigate = useNavigate();
  const redirect = () => {
    window.scrollTo({
      top: 0,
    });
    navigate("/JobDescription");
  };
  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [minSalaryValue, setMinSalaryValue] = useState([
    // "open",
    // "not_disclosed",
    0, 3, 5, 7, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
    150, 200,
  ]);
  const [maxSalaryValue, setMaxSalaryValue] = useState([
    // "open",
    // "not_disclosed",
    0, 3, 5, 7, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
    150, 200,
  ]);
  const minSalaryChange = (event) => {
    console.log("minSalaryChange", event.target.value);

    setMinSalary(event.target.value);
    let temp = [];
    if (event.target.value < 100) {
      temp.push("open");
      temp.push("not_disclosed");
      for (let i = event.target.value + 10; i < 110; i = i + 10) {
        temp.push(i);
      }
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 100) {
      temp.push("open");
      temp.push("not_disclosed");
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 150) {
      temp.push("open");
      temp.push("not_disclosed");
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 200) {
      temp.push("open");
      temp.push("not_disclosed");
      setMaxSalaryValue(temp);
    } else {
      setMaxSalaryValue(temp);
    }
  };

  const maxSalaryChange = (event) => {
    setMaxSalary(event.target.value);
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    setIndustry("");
    setEducation("");
    setYears("");
    setJobtype("");
    setWorkmodel("");
    setSimilarroles("");
    setPostedby("");
    setFun("");
    setType("");
    setMaxSalary("");
    setMinSalary("");
  };
  const onSubmit = (data) => {
    console.log("data", data);
  };

  return (
    <>
      <Navbar />
      <Box className={classes.mainHome}>
        <Box className={classes.container}>
          <Box
            sx={{
              zIndex: "1050",
              position: "fixed",
              width: "100%",
              backgroundColor: "#F6F8FD",
            }}
          >
            {" "}
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  marginTop: "20px",
                  color: "#223870",
                  fontWeight: "600",
                  "@media (max-width: 468px)": {
                    fontSize: "18px",
                    marginLeft: "10px",
                  },
                }}
              >
                Looking for a JOB change...
              </Typography>
            </Box>
            <JobSearchBar />
          </Box>
          <Box sx={{ paddingTop: "150px" }}>
            <Box className={classes.maincard}>
              <Grid
                sx={{ width: "100%" }}
                container
                direction="row"
                justify="center"
                columns={{ xs: 2, sm: 7, md: 12, lg: 12 }}
              >
                <Grid Item xs={12} sm={8} md={8}>
                  {searchJobs?.length > 0 &&
                    searchJobs?.map((list, index) => {
                      return (
                        <Box className={classes.jobbox}>
                          <JobSearchCard job={list} />
                        </Box>
                      );
                    })}
                  {searchJobs?.length === 0 && (
                    <Box className={classes.jobbox}>
                      <Typography
                        variant="p"
                        gutterBottom
                        sx={{
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "500",
                        }}
                      >
                        No suitable job match
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid
                  Item
                  xs={12}
                  sm={4}
                  md={4}
                  display="flex"
                  justifyContent="center"
                  // sx={{ position: "relative", height: "100vh" }}
                >
                  <Box className={classes.filterbox}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        color: "#223870",
                        fontWeight: "500",
                      }}
                    >
                      Filters
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Industry
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              fontWeight: "500",
                            }}
                            value={industry}
                            onChange={handleIndustry}
                            renderValue={(selected) => {
                              console.log(selected);
                              if (selected)
                                return selected.substring(0, 20) + "...";
                              else return selected;
                            }}
                          >
                            {industryList1?.map((option) => {
                              return (
                                <MenuItem
                                  key={option._id}
                                  value={option.industry}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}
                                >
                                  {option.industry}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Function
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              fontWeight: "500",
                            }}
                            value={fun}
                            onChange={handleFun}
                            renderValue={(selected) => {
                              console.log(selected);
                              if (selected)
                                return selected.substring(0, 20) + "...";
                              else return selected;
                            }}
                          >
                            {functionList1?.map((option) => {
                              return (
                                <MenuItem
                                  key={option._id}
                                  value={option.function}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}
                                >
                                  {option.function}
                                  {/* {`${option.function.substring(0, 20)}`} */}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Job Type
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              fontWeight: "500",
                            }}
                            value={jobtype}
                            onChange={handleJobtype}
                            renderValue={(selected) => {
                              console.log(selected);
                              if (!selected) return "Full Time";
                              else return selected;
                            }}
                          >
                            <MenuItem value="Full Time">Full Time</MenuItem>
                            <MenuItem value="Part Time">Part Time</MenuItem>
                            <MenuItem value="Consulting">Consulting</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Work Model
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              fontWeight: "500",
                            }}
                            value={workmodel}
                            onChange={handleWorkmodel}
                          >
                            <MenuItem
                              MenuItem
                              value="wfh"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              Work From Home
                            </MenuItem>
                            <MenuItem
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                              MenuItem
                              value="wfo"
                            >
                              Work From Office
                            </MenuItem>
                            <MenuItem
                              value="remote"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              Remote
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Education
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            value={education}
                            onChange={handleEducation}
                          >
                            <MenuItem
                              MenuItem
                              value="ug"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              UG
                            </MenuItem>
                            <MenuItem
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                              MenuItem
                              value="pg"
                            >
                              PG
                            </MenuItem>
                            <MenuItem
                              value="phd"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              phD
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container direction="Row" sx={12} sm={12}>
                        <Grid Item sx={6} sm={6}>
                          <Grid
                            container
                            direction="Column"
                            sx={{
                              paddingRight: "8px",
                            }}
                          >
                            <Grid
                              Item
                              sx={{
                                marginTop: "10px",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="p"
                                gutterBottom
                                sx={{
                                  textAlign: "left",
                                  color: "#304672",
                                  fontWeight: "500",
                                }}
                              >
                                Min. Salary
                              </Typography>{" "}
                            </Grid>
                            <Grid Item>
                              <Select
                                className={classes.countrycode}
                                // <KeyboardArrowDownIcon/>
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: "#5071C4",
                                      position: "absolute",
                                      right: 10,
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                sx={{
                                  width: "100%",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={minSalary}
                                onChange={minSalaryChange}
                              >
                                {minSalaryValue
                                  ? minSalaryValue.map((item, key) => {
                                      if (item < 100) {
                                        return (
                                          <MenuItem key={key} value={item}>
                                            {item} lacs
                                          </MenuItem>
                                        );
                                      } else if (item >= 100) {
                                        return (
                                          <MenuItem key={key} value={item}>
                                            {item / 100} Cr
                                          </MenuItem>
                                        );
                                      }
                                    })
                                  : ""}
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sx={6} sm={6}>
                          <Grid
                            container
                            direction="Column"
                            sx={{
                              paddingLeft: "8px",
                            }}
                          >
                            <Grid
                              Item
                              sx={{
                                marginTop: "10px",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="p"
                                gutterBottom
                                sx={{
                                  textAlign: "left",
                                  color: "#304672",
                                  fontWeight: "500",
                                }}
                              >
                                Max. Salary
                              </Typography>{" "}
                            </Grid>
                            <Grid Item>
                              <Select
                                className={classes.countrycode}
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: "#5071C4",
                                      position: "absolute",
                                      right: 10,
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                sx={{
                                  width: "100%",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={maxSalary}
                                onChange={maxSalaryChange}
                              >
                                {maxSalaryValue
                                  ? maxSalaryValue?.map((item, key) => {
                                      if (item < 100) {
                                        return (
                                          <MenuItem key={key} value={item}>
                                            {item} lacs
                                          </MenuItem>
                                        );
                                      } else if (item >= 100) {
                                        return (
                                          <MenuItem key={key} value={item}>
                                            {item / 100} Cr
                                          </MenuItem>
                                        );
                                      }
                                    })
                                  : ""}
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Company Type*
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            name="type"
                            value={type}
                            onChange={handleType}
                            className={classes.countrycode}
                            {...register("type", {
                              required: "Select type of your company",
                            })}
                          >
                            {Type?.map((option) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}
                                >
                                  {option.value ?? option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>{" "}
                        </Grid>
                      </Grid>
                      {/* <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Similar Roles
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              fontWeight: "500",
                            }}
                            value={similarroles}
                            onChange={handleSimilarroles}
                          >
                            <MenuItem
                              MenuItem
                              value="se"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              Software Engineer
                            </MenuItem>
                            <MenuItem
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                              MenuItem
                              value="ds"
                            >
                              Data Scientist
                            </MenuItem>
                            <MenuItem
                              value="de"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              Data Engineer
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid> */}
                      <Grid container direction="Column">
                        {" "}
                        <Grid
                          Item
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              color: "#304672",
                              fontWeight: "500",
                            }}
                          >
                            Posted By
                          </Typography>{" "}
                        </Grid>
                        <Grid Item>
                          <Select
                            className={classes.countrycode}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              fontWeight: "500",
                            }}
                            value={postedby}
                            onChange={handlePostedby}
                          >
                            <MenuItem
                              MenuItem
                              value="inhouse"
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                            >
                              In - House
                            </MenuItem>
                            <MenuItem
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}
                              MenuItem
                              value="client"
                            >
                              Client
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="Row"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid Item>
                          {" "}
                          <Box sx={{ marginTop: "30px" }}>
                            <Button
                              onClick={() => resetForm()}
                              type="button"
                              sx={{
                                textTransform: "none",
                                textDecoration: "none",
                                padding: "4px 25px",
                                color: "#223870",
                                fontWeight: "500",
                                fontSize: "18px",
                                backgroundColor: "none",
                                "&:hover": {
                                  backgroundColor: "none",
                                  color: "#223870",
                                },
                              }}
                            >
                              Reset
                            </Button>
                          </Box>
                        </Grid>
                        <Grid Item>
                          {" "}
                          <Button
                            type="submit"
                            sx={{
                              marginTop: "30px",
                              textTransform: "none",
                              textDecoration: "none",
                              padding: "4px 25px",
                              borderRadius: "5px ",
                              color: "#FDCF57",
                              fontWeight: "500",
                              fontSize: "20px",
                              backgroundColor: "#223870",
                              "&:hover": {
                                backgroundColor: "#223870",
                                color: "#FDCF57 ",
                              },
                            }}
                            //onClick={redirect}
                          >
                            Apply
                          </Button>
                        </Grid>{" "}
                      </Grid>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default JobSearchResult;
