import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Autocomplete,
  Snackbar,
  Divider,
  ListItem,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Logo from '../../../assets/images/female.jpeg';
import dropDown from '../../../assets/Icons/Dropdown.png';
import UpArrow from '../../../assets/Icons/UpArrow.png';
import DeleteIcon from '../../../assets/Icons/Delete.png';
import companylogo from '../../../assets/images/dummy.png';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Navbar from '../../../components/Header/Navbar';
import Close from '../../../assets/Icons/Close.png';
import { Years } from '../../../assets/Data/data';
import experience from '../../../assets/Icons/Experience.png';
import jobtype from '../../../assets/Icons/JobType.png';
import industry from '../../../assets/Icons/Industry.png';
import location from '../../../assets/Icons/Location.png';
import date from '../../../assets/Icons/Calendar.png';
import designation from '../../../assets/Icons/Function.png';
import salary from '../../../assets/Icons/Salary.png';
import applicant from '../../../assets/Icons/Applicants.png';
import * as jobsActions from '../../../Store/Jobs';
import * as authActions from '../../../Store/auth';
import * as helperActions from '../../../Store/helper';
import { useSelector, useDispatch } from 'react-redux';
import { countryListnew } from '../../../assets/Data/data';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useForm, Controller } from 'react-hook-form';
import { Experience } from '../../../assets/Data/data';
import { minSalary } from '../../../assets/Data/data';
import { Months } from '../../../assets/Data/data';
import { educationlist } from '../../../assets/Data/data';
import { industryList1 } from '../../../assets/Data/data';
import { functionList1 } from '../../../assets/Data/data';
import { locationData } from '../../../assets/Data/data';
import { skillsData } from '../../../assets/Data/data';
import { month } from '../../../assets/Data/data';
import CustomizedSnackbars from '../../../components/Snackbar/CustomizedSnackbars';

import OtpTimer from '../../../components/OTP_Timer/OtpTimer';
import OtpInput from 'react-otp-input';
import { passing_year_list } from '../../../assets/Data/data';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Editicon from '../../../assets/Icons/Edit_White.png';
import EditIcon from '@mui/icons-material/Edit';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import AWS from 'aws-sdk';
const S3_BUCKET = 'exotalent-cv';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIAWCKLV3UAPEYYOZO3',
  secretAccessKey: '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',
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  container: { width: 'auto', padding: '0', margin: '0' },
  countrycode: {
    '& .MuiSelect-outlined': {
      padding: '9px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  topbar: {
    position: 'relative',
    marginTop: '80px ',
    padding: '20px 180px',
    backgroundSize: 'cover',
    backgroundColor: '#F0F1FD',
    height: 'auto',
    width: '100%',
    backgroundPosition: 'center',
  },
  imgFrame: {
    position: 'relative',
    background: '#fff',
    width: '120px',
    height: '120px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  img: {
    objectFit: 'cover',
    margin: '0 auto',
    width: '120px',
    height: '120px',
    borderRadius: '5px',
    backgroundSize: 'cover',
  },
  imgFrame1: {
    bottom: '5px',
    right: '5px',
    position: 'absolute',
    background: '#304672',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  img1: {
    objectFit: 'cover',
    margin: '0 auto',
    width: '15px',
    height: '15px',
    backgroundSize: 'cover',
  },
  text: { marginLeft: '30px' },
  button: {
    position: 'absolute',
    top: '55px',
    right: '100px',
  },
  body: {
    padding: '20px 80px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#F6F8FD',
    textAlign: 'center',
  },
  jobbox: {
    marginLeft: '15px',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#EEF3FD',
    position: 'relative',
  },

  '@media (min-width: 1640px)': {
    mainHome: {
      marginTop: '80px',
      paddingTop: '150px',
    },
    title: {
      paddingRight: '60px ',
    },
  },
  '@media (max-width: 468px)': {
    title: {
      fontSize: '22px',
      textAlign: 'center',
      lineHeight: '26px',
    },
    jobbox: {
      width: '100%',
      margin: '0px',
      padding: '0px 5px',
    },
    container: {
      width: '100%',
      padding: '0px 0px',
    },
    topbar: {
      position: 'relative',
      padding: '20px 10px',
      height: '170px',
      width: '100%',
    },
    imgFrame: {
      width: '90px',
      height: '90px',
    },
    img: {
      width: '80px',
      height: '80px',
    },
    text: { marginLeft: '10px' },
    button: {
      position: 'absolute',
      top: '120px',
      right: '20px',
    },
    body: {
      padding: '0px',
      borderRadius: '10px',
    },
  },
}));
const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: '#aaa',
    '@media (max-width: 468px)': {
      fontSize: '12px',
    },
  },
}));
const NewCandidateProfile = () => {
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });

  const [selectedCountrySecondary, setSelectedCountrySecondary] = useState({
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });

  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };

  const handleCloseMobile = async () => {
    // const closeMobile = await dispatch(
    //   authActions.closeCandidateMobileDialog()
    // );
  };
  const handleCloseEmail = async () => {
    //const closeEmail = await dispatch(authActions.closeCandidateEmailDialog());
    // setOpenEmail(false);
  };
  const [otp, setOtp] = useState('');
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [email, setEmail] = React.useState();
  const [mobile, setMobile] = React.useState();
  const [message, setMessage] = React.useState('');
  // const candidateMobileVerification = useSelector(
  //   (state) => state?.auth?.candidateMobileVerification
  // );
  // const candidateMobileOtp = useSelector(
  //   (state) => state?.auth?.candidateMobileOtp
  // );
  const [options, setOptions] = useState(1);
  const [filepath, setFilepath] = React.useState('');
  const [imagepath, setImagepath] = React.useState('');
  const [upload, setUpload] = React.useState(null);
  const [uploadimage, setUploadImage] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const [selectedFun, setSelectedFun] = React.useState([]);
  const [selectedIndustry, setSelectedIndustry] = React.useState([]);
  const [copyEducation, setCopyEducation] = React.useState([]);
  const [copyExperience, setCopyExperience] = React.useState([]);
  const [noOfEducation, setNoOfeducation] = React.useState(0);
  const [noOfExperience, setNoOfExperience] = React.useState(0);
  const [noOfEducationnew, setNoOfeducationnew] = React.useState(1);
  const [noOfExperiencenew, setNoOfExperiencenew] = React.useState(1);
  const [currentFun, setCurrentFun] = React.useState('');
  const [currentIndustry, setCurrentIndustry] = React.useState('');
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  console.log('noOfExperience', noOfExperience, noOfExperiencenew);
  const updateDesignation = (event, id, designtn, index) => {
    console.log('updateDesignation', editDesignation);
    let updatedval = {};
    if (editDesignation[id]) {
      editDesignation[id] = designtn;
    }
    setEditDesignation((editDesignation) => ({
      ...editDesignation,
      ...updatedval,
    }));
  };

  const updateDesignationnew = (event, designtn, index) => {
    let updatedval = {};
    if (editDesignationnew[index]) {
      editDesignationnew[index] = designtn;
    } else {
      editDesignationnew[index] = designtn;
    }

    setEditDesignationnew((editDesignationnew) => ({
      ...editDesignationnew,
      ...updatedval,
    }));
  };

  const handleCurrentworking = (event, id, index) => {
    let updatedval = {};
    if (checkboxVal[id]) {
      console.log();
      updatedval[id] = false;
    } else {
      setValue('end_month-' + index, '0', { shouldValidate: true });
      setValue('end_year-' + index, '0', { shouldValidate: true });

      updatedval[id] = true;
    }
    setCheckboxVal((checkboxVal) => ({
      ...checkboxVal,
      ...updatedval,
    }));
    console.log('checkboxVal', checkboxVal);
    setChecked(event.target.checked);
  };
  const handleCurrentworkingnew = (event, id, index) => {
    console.log('handleCurrentworking', event, id, index);
    let updatedval = {};
    if (checkboxVal[id]) {
      console.log();
      updatedval[id] = false;
    } else {
      setValue('end_month' + index, '0', { shouldValidate: true });
      setValue('end_year' + index, '0', { shouldValidate: true });

      updatedval[id] = true;
    }
    setCheckboxVal((checkboxVal) => ({
      ...checkboxVal,
      ...updatedval,
    }));
    console.log('checkboxVal', checkboxVal);
    setChecked(event.target.checked);
  };
  const handleUploadCv = (event) => {
    setUpload(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
  };
  const [name, setName] = React.useState();
  const [preferedLocation, setPreferedLocation] = React.useState([]);
  const [skills, setSkills] = React.useState('');
  const [tempSkills, setTempSkills] = React.useState([]);
  const addSkills = () => {
    if (skills) {
      let temp = tempSkills;
      temp.push(skills);
      setTempSkills(temp);
      setSkills('');
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setTempSkills((chips) =>
      tempSkills.filter((chip) => chip._id !== chipToDelete._id)
    );
  };
  const handleYears = (event) => {
    setYears(event.target.value);
  };
  const [years, setYears] = React.useState();
  const [current_location, setCurrentLocation] = React.useState({});

  const handleMonths = (event) => {
    setMonths(event.target.value);
  };
  const [months, setMonths] = React.useState();
  const handleChange = (event) => {
    setName(event.target.value);
  };
  const [ctc, setCtc] = React.useState();
  const ctcChange = (event) => {
    setName(event.target.value);
  };
  const [degree, setDegree] = React.useState();
  const [yearofpassing, setYearOfPassing] = React.useState('2022');
  const yoPassingChange = (event) => {
    setYearOfPassing(event.target.value);
  };
  const degreeChange = (event) => {
    setName(event.target.value);
  };
  const [uploadjd, setUploadJd] = React.useState(null);
  const redirect = () => {
    navigate('/candidateFeedback');
  };
  const redirectCandidateJd = () => {
    navigate('/JobDescription');
  };
  const candidateProfile = useSelector(
    (state) => state?.auth?.candidateProfile
  );
  const educationlist = useSelector((state) => state?.auth?.educationlist);

  const designation = useSelector((state) => state?.jobs?.designation);
  const location = useSelector((state) => state?.jobs?.location);
  const skillsdata = useSelector((state) => state?.jobs?.skills);

  const companyIndustry = useSelector(
    (state) => state?.helper?.companyIndustry
  );
  const companyFunction = useSelector(
    (state) => state?.helper?.companyFunction
  );

  const [numberOfAppliedJobs, setNumberOfAppliedJobs] = React.useState(2);
  const [checkboxVal, setCheckboxVal] = React.useState({});
  const [numberOfShortlistedJobs, setNumberOfShortlistedJobs] =
    React.useState(2);
  const [numberOfHoldJobs, setNumberOfHoldJobs] = React.useState(2);
  const [numberOfRejectedJobs, setNumberOfRejectedJobs] = React.useState(2);
  const [minArrayValue, setMinArrayValue] = useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [maxArrayValue, setMaxArrayValue] = useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [minyear, setMinyear] = useState();
  const [maxyear, setMaxyear] = useState();
  const [education, setEducation] = useState('');
  const [editDesignation, setEditDesignation] = useState({});
  const [editDesignationnew, setEditDesignationnew] = useState({});
  const handleEducation = (event) => {
    setEducation(event.target.value);
  };

  const minYearChange = (event) => {
    setMinyear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const maxYearChange = (event) => {
    setMaxyear(event.target.value);
  };
  const uploadFile = async () => {
    let namekey = uuidv4() + upload.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: upload,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();

    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey
        );
        console.log('aws Success', data);
        let path =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });
  useEffect(() => {
    async function companyProfile() {
      const profiless = await dispatch(authActions.getCandidateProfile());
      console.log('profiless', profiless);
      setCopyEducation(candidateProfile?.education);
      setCopyExperience(candidateProfile?.experience_detail);
      setNoOfeducation(candidateProfile?.education?.length);
      setNoOfExperience(candidateProfile?.experience_detail?.length);
      if (candidateProfile?.education?.length === 0) {
        setNoOfeducationnew(1);
      }
      if (candidateProfile?.experience_detail?.length === 0) {
        setNoOfeducationnew(1);
      }
      let valuechecked = {};
      let checkedCurrent = candidateProfile?.experience_detail?.map(
        (experience) => {
          valuechecked[experience?._id] = false;
          editDesignation[experience?._id] = designation?.find(
            (item, index) => item?._id === experience?._id
          );
        }
      );
      setCheckboxVal(valuechecked);
      setEmail(candidateProfile?.email_id);
      setMobile(candidateProfile?.mobile_number);
      console.log('setCurrentLocation', candidateProfile);
      setCurrentLocation(candidateProfile?.current_location);
      // setSelectedIndustry(candidateProfile?.job_preference?.industry)
      // setSelectedFun(candidateProfile?.job_preference?.function)
      setTempSkills(candidateProfile?.skills);
    }
    companyProfile();
  }, []);

  useEffect(() => {
    async function getdesignationlist() {
      const educationlist = await dispatch(jobsActions.getDesignation());
    }
    getdesignationlist();
  }, []);

  useEffect(() => {
    async function getLocationlist() {
      const educationlist = await dispatch(jobsActions.getLocation());
    }
    getLocationlist();
  }, []);

  useEffect(() => {
    async function getSkillslist() {
      const educationlist = await dispatch(jobsActions.getSkills());
    }
    getSkillslist();
  }, []);

  useEffect(() => {
    async function geteducationlist() {
      const educationlist = await dispatch(
        authActions.candidateEducationList()
      );
    }
    geteducationlist();
  }, []);

  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFun(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const industryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedIndustry(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const addNewEducation = () => {
    setNoOfeducationnew(noOfEducationnew + 1);
  };
  const addNewExperience = () => {
    setNoOfExperiencenew(noOfExperiencenew + 1);
    let updatedval = {};
    updatedval[noOfExperiencenew] = false;
    setCheckboxVal((checkboxVal) => ({
      ...checkboxVal,
      ...updatedval,
    }));
  };
  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };
  const uploadJd = async (file) => {
    let namekey = uuidv4() + '_lx_' + file.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();
    // .on('httpUploadProgress', (evt) => {
    // setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err,data) => {
    // if (err) console.log('aws err',err)
    // if (data)console.log('aws data',data)
    //
    // })
    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey
        );
        console.log('aws Success', data);
        let path =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };

  const saveAsf = () => {
    saveAs(candidateProfile?.cv, candidateProfile?.cv?.split('_lx_')[1]);
  };

  const deleteEducation = (indx, type) => {
    if (type === 'new') {
      setNoOfeducationnew(noOfEducationnew - 1);
    } else if (type === 'old') {
      let temp = copyEducation.filter((item, index) => index !== indx);
      setCopyEducation(temp);
      setNoOfeducation(temp?.length);
    }
  };
  const deleteExperience = (indx, type) => {
    if (type === 'new') {
      setNoOfExperiencenew(noOfExperiencenew - 1);
    } else if (type === 'old') {
      let temp = copyExperience?.filter((item, index) => index !== indx);
      setCopyExperience(temp);
      setNoOfExperience(temp?.length);
    }
  };

  useEffect(() => {
    async function getIndustrylist() {
      const educationlist = await dispatch(helperActions.getIndustry());
    }
    getIndustrylist();
  }, []);

  useEffect(() => {
    async function getFunctionlist() {
      const educationlist = await dispatch(helperActions.getFunction());
    }
    getFunctionlist();
  }, []);

  const handleCurrentIndustry = (event) => {
    setCurrentIndustry(event.target.value);
  };
  const handleCurrentFun = (event) => {
    setCurrentFun(event.target.value);
  };

  const handleUploadImage = async (event) => {
    await setUploadImage(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadImage(event.target.files[0]);
  };
  const uploadImage = async (file) => {
    let namekey = uuidv4() + '_lx_' + file.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };
    let objct = myBucket.putObject(params).promise();
    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey
        );
        console.log('aws Success', data);
        let path2 =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        setImagepath(path2);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };

  const onSubmit = async (data) => {
    let education = [];
    let experiences = [];
    let year = data?.years;
    let month = data?.months;
    let experience = parseInt(data.minyears * 12) + parseInt(data.months);
    let cv = candidateProfile?.cv;
    if (filepath?.length > 0) {
      cv = filepath;
    }
    let image = candidateProfile?.profile_image;
    if (filepath?.length > 0) {
      cv = filepath;
    }
    if (imagepath?.length > 0) {
      image = imagepath;
    }
    for (let i = 0; i < copyEducation?.length; i++) {
      let temp = {};
      temp['degree'] = data['degree-' + i];
      temp['passing_year'] = data['passingyear-' + i];
      temp['university'] = data['university-' + i];
      education.push(temp);
    }

    for (let i = 0; i < noOfEducationnew; i++) {
      let temp = {};
      temp['degree'] = data['degree' + i];
      temp['passing_year'] = data['passingyear' + i];
      temp['university'] = data['university' + i];
      education.push(temp);
    }

    for (let i = 0; i < copyExperience?.length; i++) {
      let temp = {};
      temp['company_name'] = data['company-' + i];
      temp['start_year'] = data['start_year-' + i];
      temp['start_month'] = data['start_month-' + i];
      temp['end_year'] = data['end_year-' + i];
      temp['end_month'] = data['end_month-' + i];
      temp['designation'] = editDesignation[copyExperience[i]._id]?._id;
      experiences.push(temp);
    }

    for (let i = 0; i < noOfExperiencenew; i++) {
      let temp = {};
      temp['company_name'] = data['company' + i];
      temp['start_year'] = data['start_year' + i];
      temp['start_month'] = data['start_month' + i];
      temp['end_year'] = data['end_year' + i];
      temp['end_month'] = data['end_month' + i];
      temp['designation'] = editDesignationnew[i]?._id;
      experiences.push(temp);
    }
    let preferences = {};
    preferences['industry'] = selectedIndustry;
    preferences['function'] = selectedFun;
    preferences['ready_to_relocate'] = data['relocate'];
    preferences['location'] = preferedLocation?.map((location, index) => {
      return location?._id;
    });

    let prefered_skills = tempSkills?.map((skill, index) => {
      return skill?._id;
    });

    // Array(
    //
    //   data["prefered_location"]);
    let currentctc = data?.current_ctc * 100 + data?.current_ctc_thousand * 10;
    console.log('currentIndustry', currentIndustry);
    console.log('currentFun', currentFun);
    const dataupdate = {
      first_name: candidateProfile?.first_name,
      last_name: candidateProfile?.last_name,
      experience: experience,
      current_ctc: currentctc,
      location: current_location?._id,
      cv: cv,
      profile_image: image,
      skills: prefered_skills,
      education: education,
      experience_detail: experiences,
      job_preference: preferences,
      industry: currentIndustry?._id,
      function: currentFun?._id,
    };

    const updatecandidate = await dispatch(
      authActions.candidateUpdateProfile(dataupdate)
    );
    if (updatecandidate?.data?.status === true) {
      navigate('/JobsPage?page_no=0');
    }
  };

  const [img, setImg] = React.useState('');
  const onImageChange = (e) => {
    const [file] = e.target.files;
    setImg(URL.createObjectURL(file));
  };
  const fileRef = useRef();
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        {' '}
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box
              className={classes.imgFrame}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              {imagepath ? (
                <Avatar
                  className={classes.img}
                  variant='square'
                  src={imagepath}
                  sx={{ width: 120, height: 120, position: 'absolute' }}
                />
              ) : (
                <Avatar
                  className={classes.img}
                  variant='square'
                  src={
                    candidateProfile?.profile_image
                      ? candidateProfile?.profile_image
                      : PersonIcon
                  }
                  sx={{ width: 120, height: 120, position: 'absolute' }}
                />
              )}
              <Box sx={{ position: 'relative', top: '48px', right: '-48px' }}>
                <label htmlFor='icon-button-file'>
                  <Box
                    p={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '30px',
                      maxWidth: 'min-content',
                      minWidth: '30px',
                      cursor: 'pointer',
                      backgroundColor: '#304672',
                      borderRadius: '50%',
                    }}
                  >
                    <Box
                      component='img'
                      className={classes.img1}
                      src={Editicon}
                    />
                  </Box>
                </label>
                <input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={handleUploadImage}
                />
              </Box>
            </Box>
            <Box
              className={classes.text}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography
                variant='h4'
                sx={{
                  marginTop: '10px',
                  fontSize: '28px',
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '600',
                }}
              >
                {candidateProfile?.first_name} {candidateProfile?.last_name}
              </Typography>
              <Typography
                variant='p'
                sx={{
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '500',
                }}
              >
                {candidateProfile?.company_name}
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 2, sm: 4, md: 10 }}
                sx={{ marginTop: '15px' }}
              ></Stack>
            </Box>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Box>
                <Box pt={4}>
                  {/* aparajita */}
                  <Typography
                    variant='h5'
                    sx={{
                      fontSize: '22px',
                      textAlign: 'left',
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    Your Information
                  </Typography>
                </Box>
                <Grid container sm={12} xs={12} pt={2}>
                  {' '}
                  <Grid Item sm={6} xs={6} pr={4}>
                    <Grid container sm={12} xs={12}>
                      {' '}
                      <Grid Item sm={6} xs={6}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '400',
                          }}
                        >
                          Experience*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='minyears'
                          defaultValue={parseInt(
                            candidateProfile?.experience / 12
                          )}
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            console.log(selected);
                            if (!selected)
                              return <Placeholder>Min. exp</Placeholder>;
                            else {
                              if (selected === 0 || selected === 1) {
                                return selected + ' ' + 'year';
                              } else {
                                return selected + ' ' + 'years';
                              }
                            }
                          }}
                          {...register('minyears', {
                            required: 'Select your experience in years',
                          })}
                        >
                          {Experience?.map((option) => {
                            return (
                              <MenuItem
                                key={option.label}
                                value={option.label}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option.value ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.minyears && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.minyears.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid Item sm={6} xs={6} pt={3}>
                        {' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                            marginLeft: '10px',
                          }}
                          name='months'
                          defaultValue={parseInt(
                            candidateProfile?.experience % 12
                          )}
                          className={classes.countrycode}
                          {...register('months', {
                            required: 'Select your experience in months',
                          })}
                          renderValue={(selected) => {
                            if (!selected)
                              return <Placeholder>Max. exp</Placeholder>;
                            else {
                              if (selected === 0 || selected === 1) {
                                return selected + ' ' + 'month';
                              } else {
                                return selected + ' ' + 'months';
                              }
                            }
                          }}
                        >
                          {Months?.map((option) => {
                            return (
                              <MenuItem
                                key={option.label}
                                value={option.label}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option.value ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.months && (
                          <Box
                            sx={{
                              color: 'red',
                              fontSize: '12px',
                              marginLeft: '10px',
                            }}
                          >
                            {' '}
                            {errors.months.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>{' '}
                  </Grid>
                  <Grid Item sm={6} xs={6} pl={3}>
                    <Grid container sm={12} xs={12}>
                      {' '}
                      <Grid Item sm={6} xs={6} sx={{ paddingRight: '5px' }}>
                        {/* aparajita */}
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Current CTC &nbsp;
                        </Typography>{' '}
                        <Typography
                          variant='p'
                          sx={{
                            fontSize: '12px',
                            color: '#304672',
                            marginTop: '4px',
                          }}
                        >
                          (in lacs)*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='current_ctc'
                          defaultValue={parseInt(
                            candidateProfile?.current_ctc / 100
                          )}
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            return selected + ' lpa';
                          }}
                          {...register('current_ctc', {
                            required: 'enter your current ctc ',
                          })}
                        >
                          {[...Array(50)]?.map((option, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {index} lpa
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.ctc && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.ctc.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid Item sm={6} xs={6} style={{ paddingLeft: '5px' }}>
                        {/* aparajita */}
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                            fontSize: '12px',
                          }}
                        >
                          (in thousands)*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='current_ctc_thousand'
                          defaultValue={
                            (candidateProfile?.current_ctc % 100) / 10
                          }
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            return selected * 10000;
                          }}
                          {...register('current_ctc_thousand', {
                            required: 'enter your current ctc in thousand',
                          })}
                        >
                          {[...Array(10)]?.map((option, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {index * 10000}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.current_ctc_thousand && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.current_ctc_thousand.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={12} sm={12} pt={1}>
                  <Grid Item sx={6} sm={6} pr={3}>
                    {/* aparajita */}
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Current Industry*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='industry'
                          onChange={handleCurrentIndustry}
                          // defaultValue={candidateProfile?.job_preference?.industry}
                          className={classes.countrycode}
                          value={currentIndustry}
                        >
                          {industryList1?.map((option, index) => {
                            return (
                              <MenuItem
                                key={option._id}
                                value={option._id}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option?.industry}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    {/* aparajita */}
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Current Function*
                    </Typography>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: '#5071C4',
                            position: 'absolute',
                            right: 10,
                            pointerEvents: 'none',
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        color: '#5071C4',
                        backgroundColor: '#E0DFF4',
                        fontWeight: '500',
                      }}
                      name='func'
                      // defaultValue={candidateProfile?.job_preference?.function}
                      className={classes.countrycode}
                      value={currentFun}
                      onChange={handleCurrentFun}
                      // value= {[]}
                    >
                      {functionList1?.map((option) => {
                        return (
                          <MenuItem
                            key={option?._id}
                            value={option?._id}
                            sx={{
                              color: '#5071C4',
                              fontWeight: '700',
                            }}
                          >
                            {`${option?.function}`}
                          </MenuItem>
                        );
                      })}
                    </Select>{' '}
                  </Grid>
                  <Grid
                    Item
                    sx={6}
                    sm={6}
                    style={{ paddingRight: '16px' }}
                  ></Grid>{' '}
                </Grid>
                <Grid container sx={12} sm={12} pt={1}>
                  <Grid Item sx={6} sm={6} pr={3}>
                    <Grid container direction='column'>
                      {' '}
                      <Grid Item>
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Current Location*{' '}
                          {console.log('current_location', current_location)}
                        </Typography>{' '}
                      </Grid>
                      <Grid Item>
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: '100% ' }}
                          value={current_location}
                          options={location}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                              }}
                            />
                          }
                          name='location'
                          onChange={(_event, location) => {
                            setCurrentLocation(location);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            if (option) {
                              return option?.city;
                            } else {
                              return '';
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              size='small'
                              className={classes.textField}
                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: '',
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Resume*
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        borderRadius: '5px',
                        backgroundColor: '#E0DFF4',
                      }}
                    >
                      <label htmlFor='icon-button-image'></label>
                      <input
                        id='icon-button-image'
                        type='file'
                        style={{
                          width: '150px',
                          padding: '10px',
                          display: 'block',
                          borderRadius: '5px',
                          color: 'transparent',
                        }}
                        onChange={handleUploadJd}
                        name='file'
                      />

                      <Box
                        p={1}
                        onClick={saveAsf}
                        sx={{ color: '#304672', cursor: 'pointer' }}
                      >
                        {candidateProfile?.cv?.split('_lx_')[1]}
                      </Box>
                    </Box>
                    <Box sx={{ color: 'red', fontSize: '12px' }}>
                      {' '}
                      {errors.browse?.type === 'required' && 'Upload your cv'}
                    </Box>
                    <Box>
                      {' '}
                      <Typography
                        variant='p'
                        sx={{
                          color: '#615d5d',
                          fontSize: '10px',
                        }}
                      >
                        DOC, DOCx, PDF, RTF l Max: 2 MB
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    Item
                    sx={6}
                    sm={6}
                    style={{ paddingRight: '16px' }}
                  ></Grid>{' '}
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>

            <Box>
              <Box>
                <Box pt={1}>
                  {/* aparajita */}
                  <Typography
                    variant='h5'
                    sx={{
                      fontSize: '22px',
                      textAlign: 'left',
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    Skills
                  </Typography>
                </Box>
                <Grid container sx={12} sm={12} pr={6} pt={2}>
                  <Grid Item sx={6} sm={6}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Add Skills*
                    </Typography>{' '}
                    <Stack direction='row' spacing={0}>
                      <Autocomplete
                        id='country-select-demo'
                        sx={{ width: '100% ' }}
                        value={skills}
                        options={skillsdata}
                        autoHighlight
                        disableClearable
                        variant='standard'
                        popupIcon={
                          <KeyboardArrowDownIcon
                            sx={{
                              display: 'none',
                              color: '#5071C4',
                            }}
                          />
                        }
                        name='skills'
                        defaultValue=''
                        onChange={(_event, skills) => {
                          setSkills(skills);
                        }}
                        size='small'
                        getOptionLabel={(option) => {
                          console.log('getOptionLabel', option);
                          if (option) {
                            return option?.skill;
                          } else {
                            return '';
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            size='small'
                            className={classes.textField}
                            {...params}
                            // label="IND +91"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: '',
                            }}
                          />
                        )}
                      />

                      <Box
                        sx={{
                          width: '100px',
                          marginLeft: '-3px',
                          height: '40px',
                          backgroundColor: '#E0DFF4',
                          padding: '3px 0px',
                          borderRadius: '0px 5px 5px 0px',
                          '@media (max-width: 468px)': {
                            fontSize: '14px',
                          },
                        }}
                      >
                        {' '}
                        <Button
                          onClick={addSkills}
                          style={{
                            width: '80px',
                            marginRight: '3px',
                            backgroundColor: '#C0BEEA',
                            borderRadius: '3px',
                            color: '#223870',
                            marginTop: '1px',
                            textAlign: 'center',
                            padding: '2px 0px',
                            fontSize: '16px',
                            textTransform: 'none',
                            '&:hover': {
                              color: '#223870',
                              backgroundColor: '#C0BEEA',
                            },
                            '@media (max-width: 468px)': {
                              marginTop: '-29px',
                              textAlign: 'center',
                              marginRight: '-10px',
                              fontSize: '14px',
                              // padding: "0px 10px",
                            },
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    Item
                    sx={12}
                    sm={12}
                    style={{
                      paddingRight: '16px',
                      display: 'flex',
                      direction: 'row',
                    }}
                  >
                    <Stack direction='row'>
                      {tempSkills?.map((skill, index) => {
                        return (
                          <Box px={0.5} py={1} key={index}>
                            <Chip
                              label={skill?.skill}
                              onDelete={handleDelete(skill)}
                            />
                          </Box>
                        );
                      })}
                    </Stack>
                  </Grid>{' '}
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>

            {/* <Grid container sx={12} sm={12} pr={3} pt={3}>
              <Grid Item sx={6} sm={6}></Grid>
              <Grid
                Item
                sx={6}
                sm={6}
                style={{ paddingRight: "16px" }}
              ></Grid>{" "}
            </Grid> */}

            <Box pt={1}>
              {/* aparajita */}
              <Typography
                variant='h5'
                sx={{
                  fontSize: '22px',
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '500',
                }}
              >
                Education
              </Typography>
            </Box>

            <Box>
              <Box>
                {copyEducation &&
                  copyEducation?.map((education, index) => {
                    return (
                      <Box>
                        <>
                          <Grid key={index} container sx={12} sm={12} pt={1}>
                            <Grid Item sx={6} sm={6}>
                              <Typography
                                variant='p'
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                Degree*
                              </Typography>{' '}
                              <Select
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: '#5071C4',
                                      position: 'absolute',
                                      right: 10,
                                      pointerEvents: 'none',
                                    }}
                                  />
                                )}
                                sx={{
                                  width: '100%',
                                  marginRight: '10px',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  fontWeight: '500',
                                }}
                                className={classes.countrycode}
                                defaultValue={education?.degree}
                                name={'degree-' + index}
                                {...register('degree-' + index, {
                                  required: 'Please enter your degree name',
                                })}
                              >
                                {educationlist?.map((option, index) => {
                                  return (
                                    <MenuItem
                                      key={option._id}
                                      value={option._id}
                                      sx={{
                                        color: '#5071C4',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {option?.degree}
                                    </MenuItem>
                                  );
                                })}
                              </Select>{' '}
                              {errors?.['degree-' + index] && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors?.['degree-' + index].message}
                                </Box>
                              )}
                            </Grid>
                            <Grid
                              Item
                              sx={6}
                              sm={6}
                              pl={3}
                              style={{ paddingLeft: '16px' }}
                            >
                              <Typography
                                variant='p'
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                Passing year*
                              </Typography>{' '}
                              <Select
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: '#5071C4',
                                      position: 'absolute',
                                      right: 10,
                                      pointerEvents: 'none',
                                    }}
                                  />
                                )}
                                sx={{
                                  width: '100%',
                                  marginRight: '10px',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  fontWeight: '500',
                                }}
                                className={classes.countrycode}
                                defaultValue={education?.passing_year}
                                name={'passingyear-' + index}
                                {...register('passingyear-' + index, {
                                  required: 'Please enter your passing year',
                                })}
                              >
                                {passing_year_list
                                  ?.slice(0)
                                  .reverse()
                                  .map((option, index) => {
                                    return (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        sx={{
                                          color: '#5071C4',
                                          fontWeight: '700',
                                        }}
                                      >
                                        {option?.label}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>{' '}
                              {errors?.['passingyear-' + index] && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors?.['passingyear-' + index]?.message}
                                </Box>
                              )}
                            </Grid>{' '}
                          </Grid>

                          <Grid container sx={12} sm={12} pr={3} pt={1} mb={2}>
                            <Grid Item sx={6} sm={6}>
                              <Typography
                                variant='p'
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                University * {education?.university}
                              </Typography>{' '}
                              <TextField
                                className={classes.textField}
                                size='small'
                                variant='outlined'
                                type='text'
                                defaultValue={
                                  education?.university
                                    ? education?.university
                                    : ''
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                name={'university-' + index}
                                {...register('university-' + index, {
                                  required: 'Please enter your university name',
                                })}
                              />{' '}
                              {errors?.['university-' + index] && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors?.['university-' + index].message}
                                </Box>
                              )}
                            </Grid>
                            <Grid Item sx={6} sm={6}>
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                              >
                                {noOfEducationnew + noOfEducation > 1 && (
                                  <IconButton>
                                    {' '}
                                    <Box
                                      onClick={() => {
                                        deleteEducation(index, 'old');
                                      }}
                                      component='img'
                                      sx={{
                                        marginTop: '-4px',
                                        width: '18px',
                                        height: '18px',
                                        '@media (max-width: 468px)': {
                                          marginTop: '-8px',
                                          width: '14px',
                                          height: '14px',
                                        },
                                      }}
                                      src={DeleteIcon}
                                    />
                                  </IconButton>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      </Box>
                    );
                  })}
                {[...Array(noOfEducationnew)]?.map((elementInArray, index) => (
                  <>
                    <Grid key={index} container sx={12} sm={12} pt={3}>
                      <Grid Item sx={6} sm={6} pr={3}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Degree*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          className={classes.countrycode}
                          name={'degree' + index}
                          {...register('degree' + index, {
                            required: 'Please enter your degree name',
                          })}
                        >
                          {educationlist?.map((option, index) => {
                            console.log('educationlist', option);
                            return (
                              <MenuItem
                                key={option._id}
                                value={option._id}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option?.degree}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors?.['degree' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['degree' + index].message}
                          </Box>
                        )}
                      </Grid>
                      <Grid Item sx={6} sm={6} pl={3}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Passing year*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          className={classes.countrycode}
                          name={'passingyear' + index}
                          {...register('passingyear' + index, {
                            required: 'Please enter your passing year',
                          })}
                        >
                          {passing_year_list
                            ?.slice(0)
                            .reverse()
                            .map((option, index) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  sx={{
                                    color: '#5071C4',
                                    fontWeight: '700',
                                  }}
                                >
                                  {option?.label}
                                </MenuItem>
                              );
                            })}
                        </Select>{' '}
                        {errors?.['passingyear' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['passingyear' + index]?.message}
                          </Box>
                        )}
                      </Grid>{' '}
                    </Grid>

                    <Grid container sx={12} sm={12} pt={1} mb={2}>
                      <Grid Item sx={6} sm={6} pr={3}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          University *
                        </Typography>{' '}
                        <TextField
                          className={classes.textField}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name={'university' + index}
                          {...register('university' + index, {
                            required: 'Please enter your university name',
                          })}
                        />{' '}
                        {errors?.['university' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['university' + index].message}
                          </Box>
                        )}
                      </Grid>

                      <Grid Item sx={6} sm={6}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {noOfEducationnew + noOfEducation > 1 && (
                            <IconButton>
                              {' '}
                              <Box
                                component='img'
                                sx={{
                                  marginTop: '-4px',
                                  width: '18px',
                                  height: '18px',
                                  '@media (max-width: 468px)': {
                                    marginTop: '-8px',
                                    width: '14px',
                                    height: '14px',
                                  },
                                }}
                                onClick={() => {
                                  deleteEducation(index, 'new');
                                }}
                                src={DeleteIcon}
                              />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Box>
              <Box mt={2} onClick={addNewEducation} sx={{ cursor: 'pointer' }}>
                <Typography
                  variant='p'
                  sx={{
                    color: '#304672',
                    fontWeight: '500',
                  }}
                >
                  + Add new education
                </Typography>
              </Box>

              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>

            <Box pt={1}>
              <Typography
                variant='h5'
                sx={{
                  fontSize: '22px',
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '500',
                }}
              >
                Experience
              </Typography>
            </Box>
            <Box>
              <Box>
                {copyExperience &&
                  copyExperience?.map((experience, index) => {
                    return (
                      <>
                        <Grid container sx={12} sm={12} pt={1}>
                          <Grid Item sx={6} sm={6} pr={4}>
                            <Typography
                              variant='p'
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              Company*
                            </Typography>{' '}
                            <TextField
                              className={classes.textField}
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              defaultValue={experience?.company_name}
                              name={'company-' + index}
                              {...register('company-' + index, {
                                required: 'Please enter your Company name',
                              })}
                            />{' '}
                            {errors?.['company-' + index] && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors?.['company-' + index].message}
                              </Box>
                            )}
                          </Grid>
                          <Grid Item sx={1.5} sm={1.5} pl={4}>
                            <Typography
                              variant='p'
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              Start-Month*
                            </Typography>{' '}
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                    position: 'absolute',
                                    right: 10,
                                    pointerEvents: 'none',
                                  }}
                                />
                              )}
                              sx={{
                                width: '100%',
                                marginRight: '10px',
                                color: '#5071C4',
                                backgroundColor: '#E0DFF4',
                                fontWeight: '500',
                              }}
                              name={'start_month-' + index}
                              className={classes.countrycode}
                              defaultValue={experience?.start_month}
                              {...register('start_month-' + index, {
                                required: 'Please enter your start month',
                              })}
                            >
                              {month?.map((option, index) => {
                                return (
                                  <MenuItem
                                    key={option._id}
                                    value={option._id}
                                    sx={{
                                      color: '#5071C4',
                                      fontWeight: '700',
                                    }}
                                  >
                                    {option?.value}
                                  </MenuItem>
                                );
                              })}
                            </Select>{' '}
                            {errors?.['start_month-' + index] && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors?.['start_month-' + index].message}
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            Item
                            sx={1.5}
                            sm={1.5}
                            style={{ paddingLeft: '8px' }}
                          >
                            <Typography
                              variant='p'
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              Start-year*
                            </Typography>{' '}
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                    position: 'absolute',
                                    right: 10,
                                    pointerEvents: 'none',
                                  }}
                                />
                              )}
                              sx={{
                                width: '100%',
                                marginRight: '10px',
                                color: '#5071C4',
                                backgroundColor: '#E0DFF4',
                                fontWeight: '500',
                              }}
                              defaultValue={experience?.start_year}
                              name={'start_year-' + index}
                              {...register('start_year-' + index, {
                                required: 'Please enter your start year',
                              })}
                              className={classes.countrycode}
                            >
                              {passing_year_list
                                ?.slice(0)
                                .reverse()
                                .map((option, index) => {
                                  return (
                                    <MenuItem
                                      key={option?.value}
                                      value={option?.value}
                                      sx={{
                                        color: '#5071C4',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {option?.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>{' '}
                            {errors?.['start_year-' + index] && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors?.['start_year-' + index].message}
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            Item
                            sx={1.5}
                            sm={1.5}
                            style={{ paddingLeft: '8px' }}
                          >
                            <Stack direction='column' spacing={0.1}>
                              <Typography
                                variant='p'
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                  marginBottom: '0px',
                                }}
                              >
                                End-month*
                              </Typography>{' '}
                              <Select
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: '#5071C4',
                                      position: 'absolute',
                                      right: 10,
                                      pointerEvents: 'none',
                                    }}
                                  />
                                )}
                                sx={{
                                  width: '100%',
                                  marginRight: '10px',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  fontWeight: '500',
                                }}
                                disabled={checkboxVal[experience?._id]}
                                name={'end_month-' + index}
                                defaultValue={
                                  checkboxVal[experience?._id]
                                    ? 0
                                    : experience?.end_month
                                }
                                className={classes.countrycode}
                                {...register('end_month-' + index, {
                                  required: 'Please enter your end month',
                                })}
                              >
                                {month?.map((option, index) => {
                                  return (
                                    <MenuItem
                                      key={option?._id}
                                      value={option?._id}
                                      sx={{
                                        color: '#5071C4',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {option?.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>{' '}
                              {errors?.['end_month-' + index] && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors?.['end_month-' + index].message}
                                </Box>
                              )}
                            </Stack>
                          </Grid>

                          <Grid
                            Item
                            sx={1.5}
                            sm={1.5}
                            style={{ paddingLeft: '8px', marginTop: '-2px' }}
                          >
                            <Typography
                              variant='p'
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              End-year*
                            </Typography>{' '}
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                    position: 'absolute',
                                    right: 10,
                                    pointerEvents: 'none',
                                  }}
                                />
                              )}
                              sx={{
                                width: '100%',
                                marginRight: '10px',
                                color: '#5071C4',
                                backgroundColor: '#E0DFF4',
                                fontWeight: '500',
                              }}
                              disabled={checkboxVal[experience?._id]}
                              defaultValue={
                                checkboxVal[experience?._id]
                                  ? 0
                                  : experience?.end_year
                              }
                              name={'end_year-' + index}
                              className={classes.countrycode}
                              {...register('end_year-' + index, {
                                required: 'Please enter your end year',
                              })}
                            >
                              {passing_year_list
                                ?.slice(0)
                                .reverse()
                                .map((option, index) => {
                                  return (
                                    <MenuItem
                                      key={option?.value}
                                      value={option?.value}
                                      sx={{
                                        color: '#5071C4',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {option?.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>{' '}
                            {errors?.['end_year-' + index] && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors?.['end_year-' + index].message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container sx={12} sm={12} pt={1}>
                          <Grid Item sx={6} sm={6} pr={4}>
                            <Typography
                              variant='p'
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              Designation * {console.log('errors', errors)}
                            </Typography>{' '}
                            <Autocomplete
                              id='country-select-demo'
                              sx={{ width: '100% ' }}
                              value={editDesignation[experience?._id]}
                              options={designation}
                              autoHighlight
                              disableClearable
                              variant='standard'
                              popupIcon={
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                  }}
                                />
                              }
                              name='designation'
                              defaultValue={experience?.designation}
                              onChange={(_event, designatin) => {
                                console.log('editDesignation', editDesignation);
                                updateDesignation(
                                  _event,
                                  experience?._id,
                                  designatin,
                                  index
                                );
                                // console.log('designation',designatin);
                                // editDesignation[index] = designatin
                                // setEditDesignation(...editDesignation)
                                // setCurrentLocation(location);
                              }}
                              size='small'
                              getOptionLabel={(option) => {
                                if (option) {
                                  return option?.designation;
                                } else {
                                  return '';
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  className={classes.textField}
                                  {...params}
                                  // label="IND +91"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: '',
                                  }}
                                />
                              )}
                            />
                            {/*    <TextField
                              className={classes.textField}
                              size="small"
                              variant="outlined"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              defaultValue={experience?.designation}
                              name={"designation-" + index}
                              {...register("designation-" + index, {
                                required: "Please enter your designation ",
                              })}
                            />{" "}
                            {errors?.["designation-" + index] && (
                              <Box sx={{ color: "red", fontSize: "12px" }}>
                                {" "}
                                {errors?.["designation-" + index].message}
                              </Box>
                            )}
                            */}
                          </Grid>
                          <Grid Item sx={6} sm={6}>
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <Stack
                                sx={{
                                  width: '50%',
                                  justifyContent: 'space-between',
                                }}
                                direction='row'
                              >
                                <Stack
                                  direction='row'
                                  sx={{ paddingLeft: '8px' }}
                                >
                                  <Checkbox
                                    checked={checkboxVal[experience?._id]}
                                    onChange={(e) =>
                                      handleCurrentworking(
                                        e,
                                        experience?._id,
                                        index
                                      )
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    small
                                  />
                                  <Box py={1}>
                                    <Typography
                                      variant='p'
                                      sx={{
                                        color: '#304672',
                                        fontWeight: '500',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Currently Working{' '}
                                      {console.log(
                                        'noOfExperience',
                                        noOfExperience,
                                        noOfExperiencenew
                                      )}
                                    </Typography>
                                  </Box>
                                </Stack>
                                {parseInt(noOfExperience) +
                                  parseInt(noOfExperiencenew) >
                                  1 && (
                                  <IconButton>
                                    {' '}
                                    <Box
                                      onClick={() => {
                                        deleteExperience(index, 'old');
                                      }}
                                      component='img'
                                      sx={{
                                        marginTop: '-4px',
                                        width: '18px',
                                        height: '18px',
                                        '@media (max-width: 468px)': {
                                          marginTop: '-8px',
                                          width: '14px',
                                          height: '14px',
                                        },
                                      }}
                                      src={DeleteIcon}
                                    />
                                  </IconButton>
                                )}
                              </Stack>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}

                {[...Array(noOfExperiencenew)]?.map((elementInArray, index) => (
                  <>
                    <Grid container sx={12} sm={12} pt={3}>
                      <Grid Item sx={6} sm={6} pr={3}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Company*
                        </Typography>{' '}
                        <TextField
                          className={classes.textField}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name={'company' + index}
                          {...register('company' + index, {
                            required: 'Please enter your Company name',
                          })}
                        />{' '}
                        {errors?.['company' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['company' + index].message}
                          </Box>
                        )}
                      </Grid>
                      <Grid Item sx={1.5} sm={1.5} pl={3}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Start-month*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name={'start_month' + index}
                          className={classes.countrycode}
                          {...register('start_month' + index, {
                            required: 'Please enter your start month',
                          })}
                        >
                          {month?.map((option, index) => {
                            return (
                              <MenuItem
                                key={option?._id}
                                value={option?._id}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option?.value}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors?.['start_month' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['start_month' + index].message}
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        Item
                        sx={1.5}
                        sm={1.5}
                        style={{ paddingLeft: '16px', paddingRight: '8px' }}
                      >
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Start-year*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name={'start_year' + index}
                          {...register('start_year' + index, {
                            required: 'Please enter your start year',
                          })}
                          className={classes.countrycode}
                        >
                          {passing_year_list
                            ?.slice(0)
                            .reverse()
                            .map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?.value}
                                  value={option?.value}
                                  sx={{
                                    color: '#5071C4',
                                    fontWeight: '700',
                                  }}
                                >
                                  {option?.label}
                                </MenuItem>
                              );
                            })}
                        </Select>{' '}
                        {errors?.['start_year' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['start_year' + index].message}
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        Item
                        sx={1.5}
                        sm={1.5}
                        style={{ paddingLeft: '16px', paddingRight: '8px' }}
                      >
                        <Stack direction='column'>
                          <Typography
                            variant='p'
                            sx={{
                              color: '#304672',
                              fontWeight: '500',
                              paddingBottom: '0px!important',
                            }}
                          >
                            End-month*
                          </Typography>{' '}
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: '#5071C4',
                                  position: 'absolute',
                                  right: 10,
                                  pointerEvents: 'none',
                                }}
                              />
                            )}
                            sx={{
                              width: '100%',
                              marginRight: '10px',
                              color: '#5071C4',
                              backgroundColor: '#E0DFF4',
                              fontWeight: '500',
                            }}
                            className={classes.countrycode}
                            name={'end_month' + index}
                            disabled={checkboxVal[index]}
                            defaultValue={checkboxVal[index] ? 0 : 1}
                            {...register('end_month' + index, {
                              required: 'Please enter your end month',
                            })}
                          >
                            {month?.map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?._id}
                                  sx={{
                                    color: '#5071C4',
                                    fontWeight: '700',
                                  }}
                                >
                                  {option?.value}
                                </MenuItem>
                              );
                            })}
                          </Select>{' '}
                          {errors?.['end_month' + index] && (
                            <Box sx={{ color: 'red', fontSize: '12px' }}>
                              {' '}
                              {errors?.['end_month' + index].message}
                            </Box>
                          )}
                        </Stack>
                      </Grid>

                      <Grid
                        Item
                        sx={1.5}
                        sm={1.5}
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                      >
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          End-year*
                        </Typography>{' '}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            width: '100%',
                            marginRight: '10px',
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name={'end_year' + index}
                          disabled={checkboxVal[index]}
                          defaultValue={checkboxVal[index] ? 0 : 2022}
                          className={classes.countrycode}
                          {...register('end_year' + index, {
                            required: 'Please enter your end year',
                          })}
                        >
                          {passing_year_list
                            ?.slice(0)
                            .reverse()
                            .map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?.value}
                                  value={option?.value}
                                  sx={{
                                    color: '#5071C4',
                                    fontWeight: '700',
                                  }}
                                >
                                  {option?.label}
                                </MenuItem>
                              );
                            })}
                        </Select>{' '}
                        {errors?.['end_year' + index] && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors?.['end_year' + index].message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container sx={12} sm={12} pt={1}>
                      <Grid Item sx={6} sm={6} pr={3}>
                        <Typography
                          variant='p'
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Designation * {console.log('errors', errors)}
                        </Typography>{' '}
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: '100% ' }}
                          value={editDesignationnew[index]}
                          options={designation}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                              }}
                            />
                          }
                          name='location'
                          defaultValue=''
                          onChange={(_event, designatn) => {
                            console.log('designation', editDesignationnew);
                            updateDesignationnew(_event, designatn, index);
                            // setCurrentLocation(location);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            if (option) {
                              return option?.designation;
                            } else {
                              return '';
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              size='small'
                              className={classes.textField}
                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: '',
                              }}
                            />
                          )}
                        />
                        {/*  <TextField
                          className={classes.textField}
                          size="small"
                          variant="outlined"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name={"designation" + index}
                          {...register("designation" + index, {
                            required: "Please enter your university name",
                          })}
                        />{" "}
                        {errors?.["designation" + index] && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors?.["designation" + index].message}
                          </Box>
                        )}
                        */}
                      </Grid>
                      <Grid Item sx={6} sm={6}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Stack
                            sx={{
                              width: '50%',
                              justifyContent: 'space-between',
                            }}
                            direction='row'
                          >
                            <Stack direction='row' sx={{ paddingLeft: '8px' }}>
                              <Checkbox
                                checked={checkboxVal[index]}
                                onChange={(e) =>
                                  handleCurrentworkingnew(e, index, index)
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                                small
                              />
                              <Box py={1}>
                                <Typography
                                  variant='p'
                                  sx={{
                                    color: '#304672',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                  }}
                                >
                                  Currently Working
                                </Typography>
                              </Box>
                            </Stack>
                            {parseInt(noOfExperience) +
                              parseInt(noOfExperiencenew) >
                              1 && (
                              <IconButton>
                                {' '}
                                <Box
                                  onClick={() => {
                                    deleteExperience(index, 'new');
                                  }}
                                  component='img'
                                  sx={{
                                    marginTop: '-4px',
                                    width: '18px',
                                    height: '18px',
                                    '@media (max-width: 468px)': {
                                      marginTop: '-8px',
                                      width: '14px',
                                      height: '14px',
                                    },
                                  }}
                                  src={DeleteIcon}
                                />
                              </IconButton>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ))}
                <Box
                  py={2}
                  onClick={addNewExperience}
                  sx={{ width: '50%', cursor: 'pointer' }}
                >
                  <Typography
                    variant='p'
                    sx={{
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    + Add new experience
                  </Typography>
                </Box>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>

            <Box pt={1}>
              {/* aparajita */}
              <Typography
                variant='h5'
                sx={{
                  fontSize: '22px',
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '500',
                }}
              >
                Preferences
              </Typography>
            </Box>
            <Box>
              <Box>
                <Grid container sx={12} sm={12} pt={1}>
                  <Grid Item sx={6} sm={6} pr={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Industry*
                    </Typography>{' '}
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: '#5071C4',
                            position: 'absolute',
                            right: 10,
                            pointerEvents: 'none',
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        marginRight: '10px',
                        color: '#5071C4',
                        backgroundColor: '#E0DFF4',
                        fontWeight: '500',
                      }}
                      multiple
                      name='industry'
                      onChange={industryChange}
                      defaultValue={candidateProfile?.job_preference?.industry}
                      className={classes.countrycode}
                      value={selectedIndustry}
                    >
                      {industryList1?.map((option, index) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: '#5071C4',
                              fontWeight: '700',
                            }}
                          >
                            {option?.industry}
                          </MenuItem>
                        );
                      })}
                    </Select>{' '}
                    {errors.years && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.degree.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Function*
                    </Typography>{' '}
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: '#5071C4',
                            position: 'absolute',
                            right: 10,
                            pointerEvents: 'none',
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        color: '#5071C4',
                        backgroundColor: '#E0DFF4',
                        fontWeight: '500',
                      }}
                      multiple
                      name='func'
                      // defaultValue={candidateProfile?.job_preference?.function}
                      className={classes.countrycode}
                      value={selectedFun}
                      onChange={functionChange}
                      // value= {[]}
                    >
                      {functionList1?.map((option) => {
                        return (
                          <MenuItem
                            key={option?._id}
                            value={option?._id}
                            sx={{
                              color: '#5071C4',
                              fontWeight: '700',
                            }}
                          >
                            {`${option?.function}`}
                          </MenuItem>
                        );
                      })}
                    </Select>{' '}
                    {errors.func && (
                      <Box
                        sx={{
                          color: 'red',
                          fontSize: '12px',
                          marginLeft: '10px',
                        }}
                      >
                        {' '}
                        {errors.func.message}
                      </Box>
                    )}
                  </Grid>{' '}
                </Grid>

                <Grid container sx={12} sm={12} pt={1}>
                  <Grid Item sx={6} sm={6} pr={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Ready to relocate *
                    </Typography>{' '}
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: '#5071C4',
                            position: 'absolute',
                            right: 10,
                            pointerEvents: 'none',
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        marginLeft: '0px',
                        color: '#5071C4',
                        backgroundColor: '#E0DFF4',
                        fontWeight: '500',
                      }}
                      defaultValue={
                        candidateProfile?.job_preference?.ready_to_relocate
                      }
                      name='relocate'
                      className={classes.countrycode}
                      {...register('relocate', {
                        required: 'Select function ',
                      })}
                    >
                      <MenuItem
                        value='true'
                        sx={{
                          color: '#5071C4',
                          fontWeight: '700',
                        }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value='false'
                        sx={{
                          color: '#5071C4',
                          fontWeight: '700',
                        }}
                      >
                        No
                      </MenuItem>
                    </Select>{' '}
                    {errors.relocate && (
                      <Box
                        sx={{
                          color: 'red',
                          fontSize: '12px',
                          marginLeft: '10px',
                        }}
                      >
                        {' '}
                        {errors.relocate.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Location *
                    </Typography>{' '}
                    {/*  // <Autocomplete
                    //   multiple
                    //   id="tags-standard"
                    //   options={top100Films}
                    //   getOptionLabel={(option) => option.title}
                    //   defaultValue={[top100Films[13]]}
                    //   renderInput={(params) => (
                    //     <TextField
                    //       {...params}
                    //       variant="standard"
                    //       label=""
                    //
                    //       />
                    //     )}
                    //     /> */}
                    <Autocomplete
                      id='country-select-demo'
                      sx={{ width: '100% ' }}
                      multiple
                      value={preferedLocation}
                      options={location}
                      autoHighlight
                      disableClearable
                      variant='standard'
                      popupIcon={
                        <KeyboardArrowDownIcon
                          sx={{
                            color: '#5071C4',
                          }}
                        />
                      }
                      name='prefered_location'
                      defaultValue={[]}
                      onChange={(_event, location) => {
                        console.log('preferedLocation', preferedLocation);
                        setPreferedLocation(location);
                      }}
                      size='small'
                      getOptionLabel={(option) => {
                        console.log('getOptionLabel', option);
                        if (option) {
                          return option?.city;
                        } else {
                          return '';
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          size='small'
                          className={classes.textField}
                          {...params}
                          // label="IND +91"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: '',
                          }}
                        />
                      )}
                    />
                    {/*  <TextField
                      className={classes.textField}
                      size="small"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      defaultValue={candidateProfile?.job_preference?.location}
                      name="prefered_location"
                      {...register("prefered_location", {
                        required: "Please enter your prefered location",
                      })}
                    />{" "}
                    {errors.university && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.location.message}
                      </Box>
                    )}
                     */}
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>
            <Box pt={1}>
              {/* aparajita */}
              <Typography
                variant='h5'
                sx={{
                  fontSize: '22px',
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '500',
                }}
              >
                Account Details
              </Typography>
            </Box>
            <Box>
              <Box>
                <Grid container sx={12} sm={12} pt={1}>
                  <Grid Item sx={6} sm={6} pr={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Mobile No.*
                    </Typography>{' '}
                    <Grid container>
                      <Grid Item sm={4} sx={{ paddingRight: '10px' }}>
                        {/* aparajita */}
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: '100% ' }}
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                              }}
                            />
                          }
                          name='country_code'
                          defaultValue='91'
                          onChange={(_event, country) => {
                            console.log('country', country);
                            setSelectedCountry(country);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            return option?.iso3 + ' +' + option?.phone_code;
                          }}
                          renderInput={(params) => (
                            <TextField
                              size='small'
                              className={classes.textField}
                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid Item sm={8}>
                        <Stack direction='row' spacing={0}>
                          {' '}
                          <TextField
                            className={classes.textField}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            onChange={(e) => setMobile(e.target.value)}
                            name='mobile'
                            defaultValue={candidateProfile?.mobile_number}
                            sx={{ width: '100%' }}
                          />{' '}
                          <Box
                            sx={{
                              marginLeft: '-20px',
                              height: '42px',
                              backgroundColor: '#E0DFF4',
                              padding: '3px 0px',
                              borderRadius: '0px 5px 5px 0px',
                            }}
                          >
                            {' '}
                            <Button
                              // onClick={handleClickOpenMobile}
                              variant='standard'
                              // disabled={candidateMobileVerification}
                              sx={{
                                marginRight: '5px',
                                backgroundColor: '#223870',
                                borderRadius: '3px',
                                color: '#FDCF57',
                                // color: candidateMobileVerification
                                //   ? "#FDCF57!important"
                                //   : "#FDCF57",
                                marginTop: '2px',
                                padding: '2px 20px',
                                fontSize: '16px',
                                textTransform: 'capitalize',
                                '&:hover': {
                                  color: '#FDCF57',
                                  backgroundColor: '#223870',
                                },
                                '@media (max-width: 468px)': {
                                  textAlign: 'center',
                                  padding: '0px 10px',
                                },
                              }}
                            >
                              {' '}
                              {candidateProfile?.is_mobile_verified
                                ? 'Verified'
                                : 'Verify'}
                              {/* {candidateMobileVerification
                                ? "Verified"
                                : "Verify"} */}
                            </Button>
                            <Dialog
                              // open={candidateMobileOtp}
                              onClose={handleCloseMobile}
                              sx={{
                                '& .MuiDialog-container': {
                                  '& .MuiPaper-root': {
                                    width: '100%',
                                    maxWidth: '590px',
                                    height: '430px',
                                    borderRadius: '10px',
                                    backgroundColor: '#E8EEFB',
                                  },
                                },
                              }}
                            >
                              <DialogActions>
                                <Box
                                  onClick={handleCloseMobile}
                                  component='img'
                                  src={Close}
                                  sx={{
                                    top: '10px',
                                    right: '10px',
                                    width: '20px',
                                    height: '20px',
                                  }}
                                />
                              </DialogActions>
                              <DialogTitle
                                sx={{
                                  color: '#223870',
                                  textAlign: 'center',
                                  fontSize: '22px',
                                  marginTop: '-15px',
                                }}
                              >
                                Verify Mobile No.
                              </DialogTitle>
                              <DialogContent
                                sx={{
                                  marginTop: '20px ',
                                  textAlign: 'center',
                                }}
                              >
                                {' '}
                                <Box>
                                  {' '}
                                  <Typography
                                    variant='p'
                                    gutterBottom
                                    sx={{
                                      fontSize: '18px',
                                      color: '#5071C4',
                                      fontWeight: '500',
                                    }}
                                  >
                                    OTP sent to {mobile}
                                  </Typography>{' '}
                                </Box>
                                <Box
                                  sx={{
                                    marginTop: '20px',
                                    marginBottom: '10px',
                                  }}
                                >
                                  {' '}
                                  <Typography
                                    variant='h6'
                                    sx={{
                                      color: '#223870',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Enter OTP
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <OtpInput
                                    value={otp}
                                    onChange={handleChangeOtp}
                                    numInputs={6}
                                    inputStyle={{
                                      height: '35px',
                                      width: '45px',
                                      color: '#5071C4',
                                      backgroundColor: '#E0DFF4',
                                      border: '1px solid #223870',
                                      margin: '4px',
                                      fontSize: '18px',
                                      borderRadius: '3px',
                                    }}
                                    focusStyle={{
                                      border: '1px solid #223870',
                                    }}
                                  />
                                </Box>
                                <Box>
                                  {message === 'Wrong OTP' && (
                                    <Typography
                                      variant='h5'
                                      sx={{
                                        color: 'ff0000',
                                        fontWeight: '500',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {message}
                                    </Typography>
                                  )}
                                </Box>
                                <Box sx={{ marginTop: '5px' }}>
                                  <OtpTimer
                                    // resendOtp={handleClickOpenMobile}
                                    timerzero={timerZero}
                                  />
                                </Box>
                                <Box sx={{ marginTop: '40px ' }}>
                                  {' '}
                                  <Button
                                    // onClick={verifyCandidateMobileOtp}
                                    sx={{
                                      padding: '4px 20px',
                                      textTransform: 'none',
                                      textDecoration: 'none',
                                      borderRadius: '3px ',
                                      color: '#FDCF57',
                                      fontWeight: '500',
                                      fontSize: '18px',
                                      backgroundColor: '#223870',
                                      '&:hover': {
                                        backgroundColor: '#223870',
                                        color: '#FDCF57 ',
                                      },
                                    }}
                                  >
                                    Verify
                                  </Button>
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </Box>
                        </Stack>
                        {errors.mobile && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    {errors.years && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.degree.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Email ID*
                    </Typography>{' '}
                    <Stack direction='row' spacing={0}>
                      {/* aparajita */}
                      <TextField
                        className={classes.textField}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          color: '#5071C4',
                          backgroundColor: '#E0DFF4',
                          width: '100%',
                          borderRadius: '5px 0px 0px 5px',
                        }}
                        name='email'
                        defaultValue={candidateProfile?.email_id}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Box
                        sx={{
                          height: '42px',
                          backgroundColor: '#E0DFF4',
                          padding: '3px 0px',
                          borderRadius: '0px 5px 5px 0px',
                        }}
                      >
                        {' '}
                        <Button
                          // onClick={handleClickOpenEmail}
                          variant='standard'
                          // disabled={candidateEmailVerification}
                          sx={{
                            marginRight: '5px',
                            backgroundColor: '#223870',
                            borderRadius: '3px',
                            color: '#FDCF57',
                            // color: candidateEmailVerification
                            //   ? "#FDCF57!important"
                            //   : "#FDCF57",
                            marginTop: '2px',
                            padding: '2px 20px',
                            fontSize: '16px',
                            textTransform: 'capitalize',
                            '&:hover': {
                              color: '#FDCF57',
                              backgroundColor: '#223870',
                            },
                            '@media (max-width: 468px)': {
                              textAlign: 'center',
                              padding: '0px 10px',
                            },
                          }}
                        >
                          {candidateProfile?.is_email_verified
                            ? 'Verified'
                            : 'Verify'}
                        </Button>
                        <Dialog
                          // open={candidateEmailOtp}
                          onClose={handleCloseEmail}
                          sx={{
                            '& .MuiDialog-container': {
                              '& .MuiPaper-root': {
                                width: '100%',
                                maxWidth: '590px',
                                height: '430px',
                                borderRadius: '8px',
                                backgroundColor: '#E8EEFB',
                              },
                            },
                          }}
                        >
                          <DialogActions>
                            <Box
                              onClick={handleCloseEmail}
                              component='img'
                              src={Close}
                              sx={{
                                top: '10px',
                                right: '10px',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: '#223870',
                              textAlign: 'center',
                              fontSize: '22px',
                              marginTop: '-15px',
                            }}
                          >
                            Verify Email ID
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: '20px ',
                              textAlign: 'center',
                            }}
                          >
                            {' '}
                            <Box>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: '18px',
                                  color: '#5071C4',
                                  fontWeight: '500',
                                }}
                              >
                                OTP sent to {email}
                              </Typography>{' '}
                            </Box>
                            <Box
                              sx={{ marginTop: '20px', marginBottom: '10px' }}
                            >
                              {' '}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#223870',
                                  fontWeight: '500',
                                }}
                              >
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={6}
                                inputStyle={{
                                  height: '35px',
                                  width: '45px',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  border: '1px solid #223870',
                                  margin: '4px',
                                  fontSize: '18px',
                                  borderRadius: '3px',
                                }}
                                focusStyle={{
                                  border: '1px solid #223870',
                                }}
                              />
                            </Box>
                            <Box>
                              {message === 'Wrong OTP' && (
                                <Typography
                                  variant='h5'
                                  sx={{
                                    color: 'ff0000',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                  }}
                                >
                                  {message}
                                </Typography>
                              )}
                            </Box>
                            <Box sx={{ marginTop: '5px' }}>
                              <OtpTimer
                                // resendOtp={handleClickOpenEmail}
                                timerzero={timerZero}
                              />
                            </Box>
                            <Box sx={{ marginTop: '40px ' }}>
                              {' '}
                              <Button
                                // onClick={verifyCandidateEmailOtp}
                                sx={{
                                  padding: '4px 20px',
                                  textTransform: 'none',
                                  textDecoration: 'none',
                                  borderRadius: '3px ',
                                  color: '#FDCF57',
                                  fontWeight: '500',
                                  fontSize: '18px',
                                  backgroundColor: '#223870',
                                  '&:hover': {
                                    backgroundColor: '#223870',
                                    color: '#FDCF57 ',
                                  },
                                }}
                              >
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.email && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.email.message}
                      </Box>
                    )}
                  </Grid>{' '}
                </Grid>

                <Grid container sx={12} sm={12} pt={1}>
                  <Grid Item sx={6} sm={6} pr={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Secondary Mobile No. *
                    </Typography>{' '}
                    <Grid container>
                      <Grid Item sm={4} sx={{ paddingRight: '10px' }}>
                        {/* aparajita */}
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: '100% ' }}
                          value={selectedCountrySecondary}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                              }}
                            />
                          }
                          name='country_code'
                          defaultValue='91'
                          onChange={(_event, country) => {
                            console.log('country', country);
                            setSelectedCountrySecondary(country);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            return option?.iso3 + ' +' + option?.phone_code;
                          }}
                          renderInput={(params) => (
                            <TextField
                              size='small'
                              className={classes.textField}
                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid Item sm={8}>
                        <Stack direction='row' spacing={0}>
                          {' '}
                          <TextField
                            className={classes.textField}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            onChange={(e) => setMobile(e.target.value)}
                            name='mobile'
                            sx={{ width: '100%' }}
                          />{' '}
                          <Box
                            sx={{
                              marginLeft: '-20px',
                              height: '42px',
                              backgroundColor: '#E0DFF4',
                              padding: '3px 0px',
                              borderRadius: '0px 5px 5px 0px',
                            }}
                          >
                            {' '}
                            <Button
                              // onClick={handleClickOpenMobile}
                              variant='standard'
                              // disabled={candidateMobileVerification}
                              sx={{
                                marginRight: '5px',
                                backgroundColor: '#223870',
                                borderRadius: '3px',
                                color: '#FDCF57',
                                // color: candidateMobileVerification
                                //   ? "#FDCF57!important"
                                //   : "#FDCF57",
                                marginTop: '2px',
                                padding: '2px 20px',
                                fontSize: '16px',
                                textTransform: 'capitalize',
                                '&:hover': {
                                  color: '#FDCF57',
                                  backgroundColor: '#223870',
                                },
                                '@media (max-width: 468px)': {
                                  textAlign: 'center',
                                  padding: '0px 10px',
                                },
                              }}
                            >
                              {' '}
                              Verify
                              {/* {candidateMobileVerification
                                ? "Verified"
                                : "Verify"} */}
                            </Button>
                            <Dialog
                              // open={candidateMobileOtp}
                              onClose={handleCloseMobile}
                              sx={{
                                '& .MuiDialog-container': {
                                  '& .MuiPaper-root': {
                                    width: '100%',
                                    maxWidth: '590px',
                                    height: '430px',
                                    borderRadius: '10px',
                                    backgroundColor: '#E8EEFB',
                                  },
                                },
                              }}
                            >
                              <DialogActions>
                                <Box
                                  onClick={handleCloseMobile}
                                  component='img'
                                  src={Close}
                                  sx={{
                                    top: '10px',
                                    right: '10px',
                                    width: '20px',
                                    height: '20px',
                                  }}
                                />
                              </DialogActions>
                              <DialogTitle
                                sx={{
                                  color: '#223870',
                                  textAlign: 'center',
                                  fontSize: '22px',
                                  marginTop: '-15px',
                                }}
                              >
                                Verify Mobile No.
                              </DialogTitle>
                              <DialogContent
                                sx={{
                                  marginTop: '20px ',
                                  textAlign: 'center',
                                }}
                              >
                                {' '}
                                <Box>
                                  {' '}
                                  <Typography
                                    variant='p'
                                    gutterBottom
                                    sx={{
                                      fontSize: '18px',
                                      color: '#5071C4',
                                      fontWeight: '500',
                                    }}
                                  >
                                    OTP sent to {mobile}
                                  </Typography>{' '}
                                </Box>
                                <Box
                                  sx={{
                                    marginTop: '20px',
                                    marginBottom: '10px',
                                  }}
                                >
                                  {' '}
                                  <Typography
                                    variant='h6'
                                    sx={{
                                      color: '#223870',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Enter OTP
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <OtpInput
                                    value={otp}
                                    onChange={handleChangeOtp}
                                    numInputs={6}
                                    inputStyle={{
                                      height: '35px',
                                      width: '45px',
                                      color: '#5071C4',
                                      backgroundColor: '#E0DFF4',
                                      border: '1px solid #223870',
                                      margin: '4px',
                                      fontSize: '18px',
                                      borderRadius: '3px',
                                    }}
                                    focusStyle={{
                                      border: '1px solid #223870',
                                    }}
                                  />
                                </Box>
                                <Box>
                                  {message === 'Wrong OTP' && (
                                    <Typography
                                      variant='h5'
                                      sx={{
                                        color: 'ff0000',
                                        fontWeight: '500',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {message}
                                    </Typography>
                                  )}
                                </Box>
                                <Box sx={{ marginTop: '5px' }}>
                                  <OtpTimer
                                    // resendOtp={handleClickOpenMobile}
                                    timerzero={timerZero}
                                  />
                                </Box>
                                <Box sx={{ marginTop: '40px ' }}>
                                  {' '}
                                  <Button
                                    // onClick={verifyCandidateMobileOtp}
                                    sx={{
                                      padding: '4px 20px',
                                      textTransform: 'none',
                                      textDecoration: 'none',
                                      borderRadius: '3px ',
                                      color: '#FDCF57',
                                      fontWeight: '500',
                                      fontSize: '18px',
                                      backgroundColor: '#223870',
                                      '&:hover': {
                                        backgroundColor: '#223870',
                                        color: '#FDCF57 ',
                                      },
                                    }}
                                  >
                                    Verify
                                  </Button>
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </Box>
                        </Stack>
                        {errors.mobile && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    {errors.years && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.degree.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: '#304672',
                        fontWeight: '500',
                      }}
                    >
                      Secondary Email ID*
                    </Typography>{' '}
                    <Stack direction='row' spacing={0}>
                      {/* aparajita */}
                      <TextField
                        className={classes.textField}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          color: '#5071C4',
                          backgroundColor: '#E0DFF4',
                          width: '100%',
                          borderRadius: '5px 0px 0px 5px',
                        }}
                        name='email'
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Box
                        sx={{
                          height: '42px',
                          backgroundColor: '#E0DFF4',
                          padding: '3px 0px',
                          borderRadius: '0px 5px 5px 0px',
                        }}
                      >
                        {' '}
                        <Button
                          // onClick={handleClickOpenEmail}
                          variant='standard'
                          // disabled={candidateEmailVerification}
                          sx={{
                            marginRight: '5px',
                            backgroundColor: '#223870',
                            borderRadius: '3px',
                            color: '#FDCF57',
                            // color: candidateEmailVerification
                            //   ? "#FDCF57!important"
                            //   : "#FDCF57",
                            marginTop: '2px',
                            padding: '2px 20px',
                            fontSize: '16px',
                            textTransform: 'capitalize',
                            '&:hover': {
                              color: '#FDCF57',
                              backgroundColor: '#223870',
                            },
                            '@media (max-width: 468px)': {
                              textAlign: 'center',
                              padding: '0px 10px',
                            },
                          }}
                        >
                          Verify
                          {/* {candidateEmailVerification ? "Verified" : "Verify"} */}
                        </Button>
                        <Dialog
                          // open={candidateEmailOtp}
                          onClose={handleCloseEmail}
                          sx={{
                            '& .MuiDialog-container': {
                              '& .MuiPaper-root': {
                                width: '100%',
                                maxWidth: '590px',
                                height: '430px',
                                borderRadius: '8px',
                                backgroundColor: '#E8EEFB',
                              },
                            },
                          }}
                        >
                          <DialogActions>
                            <Box
                              onClick={handleCloseEmail}
                              component='img'
                              src={Close}
                              sx={{
                                top: '10px',
                                right: '10px',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: '#223870',
                              textAlign: 'center',
                              fontSize: '22px',
                              marginTop: '-15px',
                            }}
                          >
                            Verify Email ID
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: '20px ',
                              textAlign: 'center',
                            }}
                          >
                            {' '}
                            <Box>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: '18px',
                                  color: '#5071C4',
                                  fontWeight: '500',
                                }}
                              >
                                OTP sent to {email}
                              </Typography>{' '}
                            </Box>
                            <Box
                              sx={{ marginTop: '20px', marginBottom: '10px' }}
                            >
                              {' '}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#223870',
                                  fontWeight: '500',
                                }}
                              >
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={6}
                                inputStyle={{
                                  height: '35px',
                                  width: '45px',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  border: '1px solid #223870',
                                  margin: '4px',
                                  fontSize: '18px',
                                  borderRadius: '3px',
                                }}
                                focusStyle={{
                                  border: '1px solid #223870',
                                }}
                              />
                            </Box>
                            <Box>
                              {message === 'Wrong OTP' && (
                                <Typography
                                  variant='h5'
                                  sx={{
                                    color: 'ff0000',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                  }}
                                >
                                  {message}
                                </Typography>
                              )}
                            </Box>
                            <Box sx={{ marginTop: '5px' }}>
                              <OtpTimer
                                // resendOtp={handleClickOpenEmail}
                                timerzero={timerZero}
                              />
                            </Box>
                            <Box sx={{ marginTop: '40px ' }}>
                              {' '}
                              <Button
                                // onClick={verifyCandidateEmailOtp}
                                sx={{
                                  padding: '4px 20px',
                                  textTransform: 'none',
                                  textDecoration: 'none',
                                  borderRadius: '3px ',
                                  color: '#FDCF57',
                                  fontWeight: '500',
                                  fontSize: '18px',
                                  backgroundColor: '#223870',
                                  '&:hover': {
                                    backgroundColor: '#223870',
                                    color: '#FDCF57 ',
                                  },
                                }}
                              >
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.email && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.email.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>
            <Grid
              container
              direction='Row'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              {' '}
              <Grid
                item
                sx={{
                  justifyContent: 'center',
                }}
              >
                {' '}
                <Button
                  //onClick={redirectCandidateProfile}
                  type='submit'
                  sx={{
                    //position: "relative",
                    textTransform: 'none',
                    textDecoration: 'none',
                    padding: '3px 30px',
                    borderRadius: '3px ',
                    color: '#FDCF57',
                    fontWeight: '500',
                    fontSize: '20px',
                    backgroundColor: '#223870',
                    '&:hover': {
                      backgroundColor: '#223870',
                      color: '#FDCF57 ',
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default NewCandidateProfile;

//
// <Box>
//   <Box>
//     <Box pt={4}>
//       {/* aparajita */}
//       <Typography
//         variant="h5"
//         sx={{
//           fontSize: "22px",
//           textAlign: "left",
//           color: "#304672",
//           fontWeight: "500",
//         }}
//       >
//         Your Information
//       </Typography>
//     </Box>
//     <Grid container sm={12} xs={12} pt={2}>
//       {" "}
//       <Grid Item sm={6} xs={6} pr={4}>
//         <Grid container sm={12} xs={12}>
//           {" "}
//           <Grid Item sm={6} xs={6}>
//             <Typography
//               variant="p"
//               sx={{
//                 color: "#304672",
//                 fontWeight: "400",
//               }}
//             >
//               Experience*
//             </Typography>{" "}
//             <Select
//               IconComponent={() => (
//                 <KeyboardArrowDownIcon
//                   sx={{
//                     color: "#5071C4",
//                     position: "absolute",
//                     right: 10,
//                     pointerEvents: "none",
//                   }}
//                 />
//               )}
//               sx={{
//                 width: "100%",
//                 marginRight: "10px",
//                 color: "#5071C4",
//                 backgroundColor: "#E0DFF4",
//                 fontWeight: "500",
//               }}
//               name="minyears"
//               defaultValue={parseInt(
//                 candidateProfile?.experience / 12
//               )}
//               className={classes.countrycode}
//               renderValue={(selected) => {
//                 console.log(selected);
//                 if (!selected)
//                   return <Placeholder>min. exp</Placeholder>;
//                 else {
//                   if (selected === 0 || selected === 1) {
//                     return selected + " " + "year";
//                   } else {
//                     return selected + " " + "years";
//                   }
//                 }
//               }}
//               {...register("minyears", {
//                 required: "Select your experience in years",
//               })}
//             >
//               {Experience?.map((option) => {
//                 return (
//                   <MenuItem
//                     key={option.label}
//                     value={option.label}
//                     sx={{
//                       color: "#5071C4",
//                       fontWeight: "700",
//                     }}
//                   >
//                     {option.value ?? option.label}
//                   </MenuItem>
//                 );
//               })}
//             </Select>{" "}
//             {errors.minyears && (
//               <Box sx={{ color: "red", fontSize: "12px" }}>
//                 {" "}
//                 {errors.minyears.message}
//               </Box>
//             )}
//           </Grid>
//           <Grid Item sm={6} xs={6} pt={3}>
//             {" "}
//             <Select
//               IconComponent={() => (
//                 <KeyboardArrowDownIcon
//                   sx={{
//                     color: "#5071C4",
//                     position: "absolute",
//                     right: 10,
//                     pointerEvents: "none",
//                   }}
//                 />
//               )}
//               sx={{
//                 width: "100%",
//                 color: "#5071C4",
//                 backgroundColor: "#E0DFF4",
//                 fontWeight: "500",
//                 marginLeft: "10px",
//               }}
//               name="months"
//               defaultValue={parseInt(
//                 candidateProfile?.experience % 12
//               )}
//               className={classes.countrycode}
//               {...register("months", {
//                 required: "Select your experience in months",
//               })}
//               renderValue={(selected) => {
//                 if (!selected)
//                   return <Placeholder>Max. exp</Placeholder>;
//                 else {
//                   if (selected === 0 || selected === 1) {
//                     return selected + " " + "month";
//                   } else {
//                     return selected + " " + "months";
//                   }
//                 }
//               }}
//             >
//               {Months?.map((option) => {
//                 return (
//                   <MenuItem
//                     key={option.label}
//                     value={option.label}
//                     sx={{
//                       color: "#5071C4",
//                       fontWeight: "700",
//                     }}
//                   >
//                     {option.value ?? option.label}
//                   </MenuItem>
//                 );
//               })}
//             </Select>{" "}
//             {errors.months && (
//               <Box
//                 sx={{
//                   color: "red",
//                   fontSize: "12px",
//                   marginLeft: "10px",
//                 }}
//               >
//                 {" "}
//                 {errors.months.message}
//               </Box>
//             )}
//           </Grid>
//         </Grid>{" "}
//       </Grid>
//       <Grid Item sm={6} xs={6} pl={3}>
//         <Grid container sm={12} xs={12}>
//           {" "}
//           <Grid Item sm={6} xs={6} style={{ paddingRight: "5px" }}>
//             {" "}
//             <Typography
//               variant="p"
//               sx={{
//                 color: "#304672",
//                 fontWeight: "500",
//               }}
//             >
//               Current CTC (in lacs)*
//             </Typography>{" "}
//             <Select
//               IconComponent={() => (
//                 <KeyboardArrowDownIcon
//                   sx={{
//                     color: "#5071C4",
//                     position: "absolute",
//                     right: 10,
//                     pointerEvents: "none",
//                   }}
//                 />
//               )}
//               sx={{
//                 width: "100%",
//                 marginRight: "10px",
//                 color: "#5071C4",
//                 backgroundColor: "#E0DFF4",
//                 fontWeight: "500",
//               }}
//               name="current_ctc"
//               defaultValue={candidateProfile?.current_ctc}
//               className={classes.countrycode}
//               {...register("current_ctc", {
//                 required: "enter your current ctc ",
//               })}
//             >
//               {[...Array(50)]?.map((option, index) => {
//                 return (
//                   <MenuItem
//                     key={index}
//                     value={index}
//                     sx={{
//                       color: "#5071C4",
//                       fontWeight: "700",
//                     }}
//                   >
//                     {index} lpa
//                   </MenuItem>
//                 );
//               })}
//             </Select>{console?.log('errors',errors)}
//             {errors.ctc && (
//               <Box sx={{ color: "red", fontSize: "12px" }}>
//                 {" "}
//                 {errors.ctc.message}
//               </Box>
//             )}
//           </Grid>
//           <Grid Item sm={6} xs={6} style={{ paddingLeft: "5px" }}>
//             <Typography
//               variant="p"
//               sx={{
//                 color: "#304672",
//                 fontWeight: "500",
//                 marginLeft: "6px",
//               }}
//             >
//               Current Location*
//             </Typography>{" "}
//             <Autocomplete
//               id="country-select-demo"
//               sx={{ width: "100% " }}
//               value={current_location}
//               options={location}
//               autoHighlight
//               disableClearable
//               variant="standard"
//               popupIcon={
//                 <KeyboardArrowDownIcon
//                   sx={{
//                     color: "#5071C4",
//                   }}
//                 />
//               }
//               name="location"
//               defaultValue=""
//               onChange={(_event, location) => {
//                 setCurrentLocation(location);
//               }}
//               size="small"
//               getOptionLabel={(option) => {
//                 if (option) {
//                   return option?.city;
//                 } else {
//                   return "";
//                 }
//               }}
//               renderInput={(params) => (
//                 <TextField
//                   size="small"
//                   className={classes.textField}
//                   {...params}
//                   // label="IND +91"
//                   inputProps={{
//                     ...params.inputProps,
//                     autoComplete: "",
//                   }}
//                 />
//               )}
//             />
//             {/*  <TextField
//               className={classes.textField}
//               size="small"
//               variant="outlined"
//               type="text"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               fullWidth
//               defaultValue={candidateProfile?.current_location}
//               name="location"
//               {...register("location", {
//                 required: "Please enter your current Location",
//               })}
//             /> */}{" "}
//
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//     <Grid container sx={12} sm={12} pr={6} pt={1}>
//       <Grid Item sx={6} sm={6}>
//         <Typography
//           variant="p"
//           sx={{
//             color: "#304672",
//             fontWeight: "500",
//           }}
//         >
//           Resume*
//         </Typography>
//         <Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             borderRadius: "5px",
//             backgroundColor: "#E0DFF4",
//           }}
//         >
//           <label htmlFor="icon-button-image"></label>
//           <input
//             id="icon-button-image"
//             type="file"
//             style={{
//               width: "150px",
//               padding: "10px",
//               display: "block",
//               borderRadius: "5px",
//               color: "transparent",
//             }}
//             onChange={handleUploadJd}
//             name="file"
//           />
//
//           <Box
//             p={1}
//             onClick={saveAsf}
//             sx={{ color: "#304672", cursor: "pointer" }}
//           >
//             {candidateProfile?.cv?.split("_lx_")[1]}
//           </Box>
//         </Box>
//         <Box sx={{ color: "red", fontSize: "12px" }}>
//           {" "}
//           {errors.browse?.type === "required" && "Upload your cv"}
//         </Box>
//         <Box>
//           {" "}
//           <Typography
//             variant="p"
//             sx={{
//               color: "#615d5d",
//               fontSize: "10px",
//             }}
//           >
//             DOC, DOCx, PDF, RTF l Max: 2 MB
//           </Typography>
//         </Box>
//       </Grid>
//       <Grid
//         Item
//         sx={6}
//         sm={6}
//         style={{ paddingRight: "16px" }}
//       ></Grid>{" "}
//     </Grid>
//   </Box>
//   <Box pt={2} pb={2}>
//     <Divider />
//   </Box>
// </Box>
