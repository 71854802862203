import React, { useState } from 'react';
import { Grid, Box, TextField, Button, Stack } from '@mui/material';
import HomeBg from '../../../assets/images/HomeBG.png';
import HomeMobBg from '../../../assets/Icons/mobileViewBG.png';
import AboutusBg from '../../../assets/images/AboutusBG.png';
import AboutusMobBg from '../../../assets/Icons/mobileViewAbout.png';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  mainHome: {
    objectFit: 'cover',
    backgroundImage: `url(${HomeBg})`,
    backgroundSize: 'cover',
    height: 'auto',
    width: '100%',
    backgroundPosition: 'center',
  },
  heading1: {
    marginBottom: '20px',
    fontSize: '40px',
    fontWeight: '500',
    color: '#223870',
    lineHeight: '55px',
  },
  search: {
    backgroundColor: '#e9e8f6',
    padding: '3px 10px',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    '& .MuiTextField-root': { m: 1, width: '30ch', padding: '8px' },
  },

  title: {
    paddingTop: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dontknow: { color: '#223870', fontSize: '18px', marginTop: '20px' },
  aboutus: {
    backgroundImage: `url(${AboutusBg})`,
    backgroundSize: 'cover',
    margin: 'auto',
    height: 'auto',
    width: ' 90%',
    marginTop: '15%',
    padding: '80px 50px',
    borderRadius: '10px',
  },

  heading: {
    fontSize: '32px',
    fontWeight: ' 600',
    color: '#fdcf57',
  },
  text: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: '400',
  },
  container: {
    padding: '60px',
  },
  '@media (min-width: 1536px)': {
    search: {
      '& .MuiTextField-root': { m: 1, width: '40ch', padding: '8px 20px' },
    },
    mainHome: {
      marginTop: '80px',
    },
    title: {
      paddingRight: '100px ',
    },

    heading: {
      paddingTop: '0px',
      fontSize: '34px',
      fontWeight: ' 800',
    },
    text: {
      fontSize: '22px',
    },
    dontknow: {
      fontSize: '18px',
      marginTop: '30px',
    },
    aboutus: {
      marginTop: '27%',
      padding: '30px',
    },
  },
  '@media (max-width: 1200px)': {
    aboutus: {
      height: 'auto',
      padding: '15px',
    },
    heading: {
      fontSize: '24px',
      marginLeft: '20px',
    },
    text: {
      fontSize: '18px',
      marginLeft: '20px',
    },
  },
  '@media (max-width: 900px)': {
    backgroundSize: 'auto 900px',
    objectFit: 'cover',
    backgroundImage: `url(${HomeMobBg})`,
  },
  '@media (max-width: 468px)': {
    mainHome: {
      padding: '500px 5px 5px 5px',
      marginTop: '-640px',
      objectFit: 'cover',
      backgroundImage: `url(${HomeMobBg})`,
      backgroundSize: 'auto 780px',
      backgroundRepeat: 'no-repeat',
      height: 'auto',
      position: 'relative',
      backgroundPosition: 'center',
    },
    heading1: {
      marginTop: '600px',
      fontSize: '24px',
      textAlign: 'center',
      lineHeight: '30px',
      marginBottom: '20px',
    },
    search: {
      '& .MuiTextField-root': { m: 1, width: '15ch', padding: '2px 8px' },
      marginTop: '10px',
      padding: '0px 8px',
    },
    title: {},
    container: {
      padding: '0px 0px',
    },
    dontknow: {
      fontSize: '16px',
      marginTop: '60px',
      marginBottom: '8px',
      textAlign: 'center',
    },
    aboutus: {
      marginTop: '50px',
      objectFit: 'cover',
      backgroundImage: `url(${AboutusMobBg})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '100%',
      height: 'auto',
      padding: '10px',
    },

    heading: {
      marginLeft: '0px',
      textAlign: 'center',
      marginTop: '330px',
      fontSize: '28px',
      marginBottom: '10px',
    },
    text: {
      marginLeft: '0px',
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: '400',
    },
  },
}));

const AboutUs = () => {
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const locationSet = (event) => {
    setLocation(event.target.value);
  };
  const titleSet = (event) => {
    setJobTitle(event.target.value);
  };
  const redirect = () => {
    console.log('redirect');
    let path = '?page_no=0';
    if (jobTitle?.length > 0 || location?.length > 0) {
      if (jobTitle) {
        path = path + '&job_title=' + jobTitle;
      }
      if (location) {
        path = path + '&job_location=' + location;
      }
      window.scrollTo({
        top: 0,
      });

      navigate('/JobSearchResult' + path);
    }
  };

  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainHome} id='home'>
        <Box className={classes.container}>
          <Grid container className={classes.searchbox} justify='center'>
            {' '}
            <Grid Item xs={12} sm={6}>
              <Box className={classes.title}>
                <h3 className={classes.heading1}>
                  AI-Enabled Recruitment Solutions to suit all your Business
                  Needs
                </h3>
              </Box>
              <Stack component='row'>
                <Box className={classes.search} noValidate autoComplete='off'>
                  <TextField
                    placeholder='Job title'
                    variant='standard'
                    sx={{
                      borderRight: '1px solid #a7a9ad',

                      '@media (min-width: 1640px)': { paddingRight: '200px' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={titleSet}
                  />

                  <TextField
                    className={classes.textfield}
                    placeholder='Location'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={locationSet}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: '-50px',
                    marginRight: '10px',
                    '@media (min-width: 1640px)': {
                      marginTop: '-50px',
                      marginRight: '10px',
                    },
                  }}
                >
                  {' '}
                  <Button
                    onClick={redirect}
                    variant='standard'
                    sx={{
                      backgroundColor: '#FDCF57',
                      float: 'right',
                      borderRadius: '3px',
                      color: '#223870',
                      marginTop: '4px',
                      marginRight: '-3px',
                      padding: '4px 16px',
                      fontSize: '18px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#FDCF57',
                        color: '#223870',
                      },
                      '@media (max-width: 468px)': {
                        marginRight: '-5px',
                        marginTop: '20px',
                        fontSize: '14px',
                        textAlign: 'center',
                        padding: '0px 2px',
                      },
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Stack>

              <Box className={classes.dontknow}>
                <p>Don't know where to start ?</p>
              </Box>
              <Box>
                {' '}
                <Box
                  md={12}
                  xl={12}
                  component='span'
                  sx={{
                    '@media (max-width: 468px)': {
                      marginTop: '-20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                  }}
                >
                  {' '}
                  <Button
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#e5e4f8',
                      color: '#223870',
                      padding: '2px 10px',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      marginRight: '20px',
                      border: '1px solid #223870',
                      '&:hover': {
                        border: '1px solid #223870',
                      },
                      '@media (max-width: 468px)': {
                        marginRight: '3px',
                        fontSize: '12px',
                        padding: '2px 8px',
                      },
                      '@media (min-width: 1640px)': {
                        padding: '3px 15px',
                        fontSize: '16px',
                      },
                    }}
                    className='homebtn'
                  >
                    Sales
                  </Button>
                  <Button
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#e5e4f8',
                      color: '#223870',
                      padding: '2px 10px',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      marginRight: '20px',
                      border: '1px solid #223870',
                      '&:hover': {
                        border: '1px solid #223870',
                      },
                      '@media (max-width: 468px)': {
                        marginRight: '3px',
                        fontSize: '12px',
                        padding: '2px 8px',
                      },
                      '@media (min-width: 1640px)': {
                        padding: '3px 15px',
                        fontSize: '16px',
                      },
                    }}
                    className='homebtn'
                  >
                    Accounts
                  </Button>
                  <Button
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#e5e4f8',
                      color: '#223870',
                      padding: '2px 10px',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      border: '1px solid #223870',
                      marginRight: '20px',
                      '&:hover': {
                        border: '1px solid #223870',
                      },
                      '@media (max-width: 468px)': {
                        marginRight: '3px',
                        fontSize: '12px',
                        padding: '2px 8px',
                      },
                      '@media (min-width: 1640px)': {
                        padding: '3px 15px',
                        fontSize: '16px',
                      },
                    }}
                    className='homebtn'
                  >
                    Management
                  </Button>
                  <Button
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#e5e4f8',
                      color: '#223870',
                      padding: '2px 10px',
                      fontSize: '14px',
                      textTransform: 'none',
                      border: '1px solid #223870',
                      marginRight: '20px',
                      '&:hover': {
                        border: '1px solid #223870',
                      },
                      '@media (max-width: 468px)': {
                        marginRight: '3px',
                        fontSize: '12px',
                        padding: '2px 8px',
                      },
                      '@media (min-width: 1640px)': {
                        padding: '3px 15px',
                        fontSize: '16px',
                      },
                    }}
                    className='homebtn'
                  >
                    Technology
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid Item xs={12} sm={6}></Grid>
          </Grid>
        </Box>

        <Box className={classes.aboutus} id='aboutus'>
          {' '}
          <Grid container>
            {' '}
            <Grid Item xs={12} sm={5}></Grid>
            <Grid Item xs={12} sm={7}>
              <Box className={classes.heading}>About Us</Box>
              <Box className={classes.text} style={{ color: '#fff' }}>
                One of the top recruitment and RPO firms in India that provides
                remarkable and unmatched recruitment process outsourcing and
                Talent Acquisition services across various business verticals.
                Backed by decades of experience, our team is comprised of agile
                experts and consultants with a strong focus on Automotive,
                Manufacturing, and Technology. We believe in developing a full
                suite of technology-driven Talent Acquisition solutions, powered
                by a diversity-rich network of top talents who deliver
                tailor-made recruitment solutions as per the client's needs.
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default AboutUs;
