import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, IconButton, Tooltip } from '@mui/material';
import companylogo from '../../assets/images/dummy.png';
import experience from '../../assets/Icons/Experience.png';
import jobtype from '../../assets/Icons/JobType.png';
import industry from '../../assets/Icons/Industry.png';
import locationIcon from '../../assets/Icons/Location.png';
import date from '../../assets/Icons/Calendar.png';
import designation from '../../assets/Icons/Function.png';
import salary from '../../assets/Icons/Salary.png';
import education from '../../assets/Icons/Education.png';
import { useNavigate } from 'react-router-dom';
import * as jobsActions from '../../Store/Jobs';
import * as authActions from '../../Store/auth';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
function JobCardMain(props) {
  console.log('JobCardMain', props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [jobdetails, setJobDetails] = useState('');
  const educationlist = useSelector((state) => state?.auth?.educationlist);

  const companyIndustry = useSelector(
    (state) => state?.helper?.companyIndustry
  );
  const companyFunction = useSelector(
    (state) => state?.helper?.companyFunction
  );
  // const companySize = useSelector((state) => state?.helper?.companySize);
  // const companytype = useSelector((state) => state?.helper?.companyType);
  const location = useSelector((state) => state?.helper?.location);
  const jobcompanytype = useSelector((state) => state?.helper?.jobType);
  // const skillsdata = useSelector((state) => state?.helper?.skills);
  // const workmodelcompany = useSelector((state) => state?.helper?.workmodel);

  const jobDetails = (e) => {
    e.stopPropagation();
    console.log('id', props?.data?._id);
    window.scrollTo({ top: 0 });
    navigate('/companyJd/' + props?.data?._id);
  };

  useEffect(() => {
    async function geteducationlist() {
      await dispatch(authActions.candidateEducationList());
    }
    geteducationlist();
  }, []);

  useEffect(() => {
    async function jobDetails() {
      if (props?.data?.job_id) {
        const id = props?.data?.job_id;
        const jobList = await dispatch(jobsActions.getJobDescription(id));
        if (jobList?.data?.status === true) {
          setJobDetails(jobList?.data?.data[0]);
        }
      }
    }
    jobDetails();
  }, []);

  // const getIndustry = () => {
  //   let industry = companyIndustry?.find(
  //     (item) => item._id === props?.data?.industry
  //   );
  //   console.log('industry', industry);
  //   return industry?.industry;
  // };

  // const getFunction = () => {
  //   let functionl = companyFunction?.find(
  //     (item) => item._id === props?.data?.function
  //   );
  //   console.log('industry', functionl);
  //   return functionl?.function;
  // };

  // const getType = () => {
  //   let type = jobcompanytype?.find(
  //     (item) => item?._id === props?.data?.job_type
  //   );
  //   console.log('type', type, props?.data?.job_type);
  //   return type?.job_type;
  // };

  // const getLocation = () => {
  //   let location_city = location?.find(
  //     (item) => item?._id === props?.data?.location
  //   );
  //   console.log('location_city', location_city);
  //   return location_city?.city;
  // };

  // const getEducation = () => {
  //   console.log('educationlist', educationlist);
  //   let education = educationlist?.find(
  //     (item) => item._id === props?.data?.education
  //   );
  //   console.log('type', education, props?.data?.education);
  //   return education?.degree;
  // };

  return (
    <>
      <div onClick={jobDetails} style={{ cursor: 'pointer' }}>
        {' '}
        <Grid container>
          <Grid Item>
            <Typography
              variant='h5'
              sx={{
                position: 'absolute',
                top: '20px',
                left: '25px',
                color: '#304672',
                fontWeight: '600',
                '@media (max-width: 468px)': {
                  top: '5px',
                  left: '5px',
                  marginTop: '10px',
                  fontSize: '16px',
                },

                '@media (min-width: 1536px)': { top: '20px', left: '38px' },
              }}
            >
              {props?.data?.job_title
                ? props?.data?.job_title
                : jobdetails?.job_title}
            </Typography>
            {props?.data?.is_company_name_hide ? (
              <Typography
                variant='h6'
                sx={{
                  position: 'absolute',
                  top: '45px',
                  left: '25px',
                  color: '#304672',
                  fontWeight: '600',
                  '@media (max-width: 468px)': {
                    top: '25px',
                    left: '10px',
                    marginTop: '10px',
                    fontSize: '16px',
                  },
                  '@media (min-width: 1536px)': { left: '38px' },
                }}
              >
                Confidential
              </Typography>
            ) : (
              <Typography
                variant='h6'
                sx={{
                  position: 'absolute',
                  top: '45px',
                  left: '25px',
                  color: '#304672',
                  fontWeight: '600',
                  '@media (max-width: 468px)': {
                    top: '25px',
                    left: '10px',
                    marginTop: '10px',
                    fontSize: '16px',
                  },
                  '@media (min-width: 1536px)': { left: '38px' },
                }}
              >
                {props?.data?.company_name
                  ? props?.data?.company_name
                  : jobdetails?.company_name}
              </Typography>
            )}
          </Grid>
          <Grid Item>
            {props?.data?.is_company_name_hide ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  width: '80px',
                  height: '80px',
                  backgroundColor: '#ffffff',
                  '@media (max-width: 468px)': {
                    top: '10px',
                    right: '10px',
                    width: '60px',
                    height: '60px',
                  },
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    padding: '30px 0px',
                    fontSize: '12px',
                    textAlign: 'center',
                    marginLeft: '-2px',
                    color: '#F00',
                    '@media (max-width: 468px)': {
                      fontSize: '8px',
                      lineHeight: '18px',
                      textAlign: 'left',
                    },
                  }}
                >
                  Confidential
                </Typography>
              </Box>
            ) : (
              <Box
                component='img'
                src={
                  props?.data?.company_logo
                    ? props?.data?.company_logo
                    : jobdetails?.company_logo
                }
                sx={{
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  width: '60px',
                  height: '60px',
                  '@media (max-width: 468px)': {
                    top: '10px',
                    right: '10px',
                    width: '60px',
                    height: '60px',
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ marginLeft: '5px' }}>
          <Grid Item xs={12} sm={12}>
            <Grid
              container
              sx={{
                marginTop: '60px',
              }}
            >
              <Grid Item sm={6} xs={6}>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Location'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={locationIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.location?.city}
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Job Type'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={jobtype}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.job_type?.job_type}
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Industry'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={industry}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.industry?.industry}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Function'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={designation}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.function?.function}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid Item sm={6} xs={6}>
                {' '}
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Total Experience'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={experience}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.experience?.min
                        ? props?.data?.experience?.min
                        : jobdetails?.experience?.min}{' '}
                      -{' '}
                      {props?.data?.experience?.max
                        ? props?.data?.experience?.max
                        : jobdetails?.experience?.max}{' '}
                      yrs exp.
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title=' Salary'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={salary}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    {props?.data?.is_salary_hide ? (
                      <Typography
                        variant='h6'
                        sx={{
                          fontSize: '16px',
                          textAlign: 'left',
                          marginLeft: '-2px',
                          color: '#5677B9',
                          '@media (max-width: 468px)': {
                            fontSize: '14px',
                            lineHeight: '18px',
                            textAlign: 'left',
                          },
                        }}
                      >
                        Confidential
                      </Typography>
                    ) : (
                      <Typography
                        variant='h6'
                        sx={{
                          fontSize: '16px',
                          textAlign: 'left',
                          marginLeft: '-2px',
                          color: '#5677B9',
                          '@media (max-width: 468px)': {
                            fontSize: '14px',
                            lineHeight: '18px',
                            textAlign: 'left',
                          },
                        }}
                      >
                        {props?.data?.salary?.min} -{' '}
                        {props?.data?.salary?.max
                          ? props?.data?.salary?.max
                          : jobdetails?.salary?.max}{' '}
                        lpa
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      disableFocusListener
                      disableTouchListener
                      title='Date'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '16px',
                            height: '16px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={date}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      Posted on{' '}
                      {moment(
                        props?.data?.created_at
                          ? props?.data?.created_at
                          : jobdetails?.created_at
                      ).format('D') +
                        ' ' +
                        moment(
                          props?.data?.created_at
                            ? props?.data?.created_at
                            : jobdetails?.created_at
                        ).format('MMM') +
                        ', ' +
                        moment(
                          props?.data?.created_at
                            ? props?.data?.created_at
                            : jobdetails?.created_at
                        ).format('YYYY')}
                    </Typography>{' '}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid Item xs={1} sm={1}>
              <Box
                component='img'
                sx={{
                  marginLeft: '10px',
                  marginTop: '3px',
                  width: '18px',
                  height: '18px',
                  '@media (max-width: 468px)': {
                    marginTop: '-5px',
                    width: '14px',
                    height: '14px',
                  },
                }}
                src={education}
              />
            </Grid>
            <Grid Item xs={11} sm={11}>
              <Typography
                variant='h6'
                sx={{
                  marginLeft: '3px',
                  fontSize: '16px',
                  textAlign: 'left',
                  color: '#5677B9',
                  '@media (max-width: 468px)': {
                    fontSize: '14px',
                  },
                  '@media (min-width: 1536px)': {
                    marginLeft: '1px',
                  },
                }}
              >
                {props?.data?.education?.degree}
              </Typography>{' '}
            </Grid>
          </Grid>
        </Grid>{' '}
      </div>
    </>
  );
}
export default JobCardMain;
