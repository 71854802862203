import {

GET_LOCATION_SUCCESS,
GET_LOCATION_REQUEST,
GET_LOCATION_FAILURE,

GET_SKILLS_SUCCESS,
GET_SKILLS_REQUEST,
GET_SKILLS_FAILURE,

GET_DESIGNATION_SUCCESS,
GET_DESIGNATION_REQUEST,
GET_DESIGNATION_FAILURE,

GET_FUNCTION_SUCCESS,
GET_FUNCTION_REQUEST,
GET_FUNCTION_FAILURE,


GET_INDUSTRY_SUCCESS,
GET_INDUSTRY_REQUEST,
GET_INDUSTRY_FAILURE,


GET_COMPANY_SIZE_SUCCESS,
GET_COMPANY_SIZE_REQUEST,
GET_COMPANY_SIZE_FAILURE,

GET_JOB_TYPE_SUCCESS,
GET_JOB_TYPE_REQUEST,
GET_JOB_TYPE_FAILURE,

GET_COMPANY_TYPE_SUCCESS,
GET_COMPANY_TYPE_REQUEST,
GET_COMPANY_TYPE_FAILURE,

GET_COMPANY_REGISTATION_TYPE_SUCCESS,
GET_COMPANY_REGISTATION_TYPE_REQUEST,
GET_COMPANY_REGISTATION_TYPE_FAILURE,


GET_COMPANY_WORK_MODEL_TYPE_SUCCESS,
GET_COMPANY_WORK_MODEL_TYPE_REQUEST,
GET_COMPANY_WORK_MODEL_TYPE_FAILURE,

} from "./action";

const initialState = {
  designation:[],
  location:[],
  skills:[],
  companyIndustry: [],
  companyFunction: [],
  companyType: [],
  companySize: [],
  companyRegType: [],
  workmodel: []
};
export const helperreducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION_SUCCESS:{
      return{
        ...state,
        location:action.payload?.data?.data
      }
    }
 case GET_LOCATION_REQUEST:{
   return{
     ...state,
     location:[]
   }
 }
 case GET_LOCATION_FAILURE:{
   return{
     ...state,
     location:[]
   }
 }

 case GET_SKILLS_SUCCESS:{
   return{
     ...state,
     skills: action.payload?.data?.data
   }
 }
 case GET_SKILLS_REQUEST:{
   return{
     ...state,
     skills:[]
   }
 }
 case GET_SKILLS_FAILURE:{
   return{
     ...state,
     skills:[]
   }
 }

 case GET_DESIGNATION_SUCCESS:{
   return{
     ...state,
     designation:action.payload?.data?.data
   }
 }
 case GET_DESIGNATION_REQUEST:{
   return{
     ...state,
     designation:[]
   }
 }
 case GET_DESIGNATION_FAILURE:{
   return{
     ...state,
     designation:[]
   }
 }

 case GET_FUNCTION_SUCCESS:{
   return{
     ...state,
     companyFunction:action.payload?.data?.data
   }
 }
case GET_FUNCTION_REQUEST:{
return{
  ...state,
  companyFunction:[]
}
}
case GET_FUNCTION_FAILURE:{
return{
  ...state,
  companyFunction:[]
}
}

case GET_INDUSTRY_SUCCESS:{
  return{
    ...state,
    companyIndustry: action.payload?.data?.data
  }
}
case GET_INDUSTRY_REQUEST:{
  return{
    ...state,
    companyIndustry:[]
  }
}
case GET_INDUSTRY_FAILURE:{
  return{
    ...state,
    companyIndustry:[]
  }
}

case GET_COMPANY_SIZE_SUCCESS:{
  return{
    ...state,
    companySize: action.payload?.data?.data
  }
}
case GET_COMPANY_SIZE_REQUEST:{
  return{
    ...state,
    companySize: []
  }
}
case GET_COMPANY_SIZE_FAILURE:{
  return{
    ...state,
    companySize: []
  }
}


case GET_JOB_TYPE_SUCCESS:{
  return{
    ...state,
    jobType: action.payload?.data?.data

  }
}
case GET_JOB_TYPE_REQUEST:{
  return{
    ...state,
    jobType: []
  }
}
case GET_JOB_TYPE_FAILURE:{
  return{
    ...state,
    jobType: []

  }
}

case GET_COMPANY_TYPE_SUCCESS:{
  return{
    ...state,
    companyType: action.payload?.data?.data
  }
}
case GET_COMPANY_TYPE_REQUEST:{
  return{
    ...state,
    companyType: []
  }
}
case GET_COMPANY_TYPE_FAILURE:{
  return{
    ...state,
    companyType: []
  }
}


case GET_COMPANY_REGISTATION_TYPE_SUCCESS:{
  return{
    ...state,
    companyRegType: action.payload?.data?.data
  }
}
case GET_COMPANY_REGISTATION_TYPE_REQUEST:{
  return{
    ...state,
    companyRegType: []
  }
}
case GET_COMPANY_REGISTATION_TYPE_FAILURE:{
  return{
    ...state,
    companyRegType: []
  }
}

case GET_COMPANY_WORK_MODEL_TYPE_SUCCESS: {
  return{
    ...state,
    workmodel: action.payload?.data?.data
  }

}
case GET_COMPANY_WORK_MODEL_TYPE_REQUEST: {
  return{
    ...state,
    workmodel: []
  }

}
case GET_COMPANY_WORK_MODEL_TYPE_FAILURE: {
  return{
    ...state,
    workmodel: []
  }

}
    default:
      return state;
  }
};
