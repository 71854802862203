import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomizedSnackbars from '../../components/Snackbar/CustomizedSnackbars';
import formBg from '../../assets/Icons/mobileFormBG.png';
import Close from '../../assets/Icons/Close.png';
import { useNavigate } from 'react-router-dom';
import { countryListnew } from '../../assets/Data/data';
import HomeBg from '../../assets/images/loginbg.png';
import { makeStyles } from '@mui/styles';
import Navbar from '../../components/Header/Navbar';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../../Store/auth';
import * as jobsActions from '../../Store/Jobs';
import * as helperActions from '../../Store/helper';
import OtpInput from 'react-otp-input';
import OtpTimer from '../../components/OTP_Timer/OtpTimer';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import itLocale from 'i18n-iso-countries/langs/it.json';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  containerbg: {
    overflow: 'hidden',
    backgroundImage: `url(${HomeBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: 'auto',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingRight: '100px',
    paddingTop: '80px',
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
    position: 'relative',
  },
  textField: {
    backgroundColor: '#E0DFF4',
    textAlign: 'center',
    padding: '5.5px',
    borderRadius: '5px',
  },

  countrycode: {
    '& .MuiSelect-outlined': {
      padding: '8.5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '5px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  input: {
    borderRadius: '5px',
    border: '1px solid #223870',
    width: '50px',
    height: '40px',
    color: '#5071C4',
    backgroundColor: '#E0DFF4',
  },
  '@media (max-width: 468px)': {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: '80px',
    },
    form: {
      margin: '0',
      padding: '0 10px',
      width: '100%',
    },
  },
}));
const S3_BUCKET = 'exotalent-cv';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIAWCKLV3UAPEYYOZO3',
  secretAccessKey: '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',
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const CompanyRegister = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setName] = React.useState();
  const [filepath, setFilepath] = React.useState('');
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  });
  const handleChange = (event) => {
    setName(event.target.value);
  };
  // const openDialog = useSelector((state) => state?.auth.emailverification);
  // const showDialogMsg = useSelector((state) => state?.auth.gstverification);
  // const dialogOpen = useSelector((state) => state?.auth.mobileverification);
  const designation = useSelector((state) => state?.jobs?.designation);
  // const companyRegistrationEmailOtp = useSelector(
  //   (state) => state?.auth?.companyRegistrationEmailOtp
  // );
  const companyRegistrationEmailDialog = useSelector(
    (state) => state?.auth?.companyRegistrationEmailDialog
  );
  const companyRegistrationEmailVerification = useSelector(
    (state) => state?.auth?.companyEmailVerification
  );

  // const companyRegistrationMobileOtp = useSelector(
  //   (state) => state?.auth?.companyRegistrationMobileOtp
  // );
  const companyRegistrationMobileDialog = useSelector(
    (state) => state?.auth?.companyRegistrationMobileDialog
  );
  const companyRegistrationMobileVerification = useSelector(
    (state) => state?.auth?.companyMobileVerification
  );
  const registrationFirstData = useSelector(
    (state) => state?.auth?.registrationFirstData
  );
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const gstverification = useSelector((state) => state?.auth?.gstverification);
  const otpemail = useSelector((state) => state?.auth?.email);
  const otpmobile = useSelector((state) => state?.auth?.mobile);
  const sendotp = useSelector((state) => state?.auth.otpsend);
  // const loggedIn = useSelector((state) => state?.auth.loggedIn);
  const atleastoneverifyReg = useSelector(
    (state) => state?.auth?.atleastoneverifyReg
  );

  const companyIndustry = useSelector(
    (state) => state?.helper?.companyIndustry
  );
  const companyFunction = useSelector(
    (state) => state?.helper?.companyFunction
  );
  const companySize = useSelector((state) => state?.helper?.companySize);
  const companyType = useSelector((state) => state?.helper?.companyType);
  const companyRegType = useSelector((state) => state?.helper?.companyRegType);

  const [userDesignation, setUserDesignation] = React.useState();
  const [, setOpenEmail] = React.useState(false);
  const [, setOpenMobile] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [, setType] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const handleCloseEmail = async () => {
    await dispatch({
      type: 'CLOSE_EMAIL_COMPANY_REGISTRATION_DIALOG',
    });
  };
  const handleCloseMobile = async () => {
    await dispatch({
      type: 'CLOSE_MOBILE_COMPANY_REGISTRATION_DIALOG',
    });
    setOpenMobile(false);
  };
  // const handleIndustry = (event) => {
  //   setIndustry(event.target.value);
  // };
  // const handleFunct = (event) => {
  //   setFunct(event.target.value);
  // };
  const handleType = (event) => {
    setType(event.target.value);
  };
  // const handleSize = (event) => {
  //   setSize(event.target.value);
  // };

  const handlexAuth = (data) => {
    setXauth(data);
  };

  const [xauth, setXauth] = useState('');
  const handleClickSendEmailotp = async () => {
    console.log(email);
    if (atleastoneverifyReg) {
      const datasend = {
        signup_type: 1,
        user_type: 2,
        email_id: email,
        mobile_number: mobile,
        is_secondary: false,
      };

      await dispatch(authActions.sendEmailOtp(datasend));
      console.log();
    } else {
      const datasend = {
        signup_type: 1,
        user_type: 2,
        email_id: email,
        is_secondary: false,
      };

      await dispatch(authActions.sendEmailOtp(datasend));
    }

    console.log('sendotp', sendotp);
    if (sendotp.status === 200) {
      setOpenEmail(true);
      setMsg(sendotp.msg);
    } else {
      setOpenEmail(false);
      setMsg(sendotp.msg);
    }
  };
  const handleClickSendMobileotp = async () => {
    if (atleastoneverifyReg) {
      const sendonmobile = {
        signup_type: 1,
        user_type: 2,
        country_code: +91,
        mobile_number: mobile,
        email_id: email,
        is_secondary: false,
      };

      await dispatch(authActions.sendMobileOtp(sendonmobile));
      console.log();
    } else {
      const sendonmobile = {
        signup_type: 1,
        user_type: 2,
        country_code: +91,
        mobile_number: mobile,
        is_secondary: false,
      };
      await dispatch(authActions.sendMobileOtp(sendonmobile));
    }
  };

  const handleClickVerifyGst = async () => {
    const data = {
      gst_number: 'gst8776w7w6qwt673geq',
      user_type: 2,
      email_id: email,
    };
    await dispatch(authActions.verifyGst(data));
  };
  useEffect(() => {
    window.onbeforeunload = async function () {
      await dispatch(authActions.resetcompanyDetails());
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  const handleClickVerifyEmail = async () => {
    console.log('.....data....', email);
    setOtp('');

    const emailotp = {
      user_type: 2,
      email_id: email,
      email_otp: otp,
      is_secondary: false,
    };
    const otpmail = await dispatch(authActions.verifyCompanyEmailOtp(emailotp));
    console.log('otpemail===================', otpmail);
    handlexAuth(otpmail?.headers['x-auth']);
    if (otpmail.data.status === true) {
      // navigate("/editCompanyProfile");
      setOpenEmail(false);
      setMsg(otpmail.msg);
    } else {
      setOpenEmail(true);
      setMsg(otpmail.msg);
    }
    setData(otp);
  };
  const handleClickVerifyMobile = async () => {
    setOtp('');
    const mobileotp = {
      user_type: 2,
      mobile_number: mobile,
      mobile_otp: otp,
      is_secondary: false,
    };
    const otpmob = await dispatch(
      authActions.verifycompanyMobileOtp(mobileotp)
    );
    handlexAuth(otpmob?.headers['x-auth']);
    if (otpmob.data.status === true) {
      setOpenMobile(false);
      setMsg(otpmob.msg);
    } else {
      setOpenMobile(true);
      setMsg(otpmob.msg);
    }
    setData(otp);
  };

  const [otp, setOtp] = useState('');
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [reg, setReg] = React.useState(true);
  const [kyc, setKyc] = React.useState(false);

  const handleClickBack = () => {
    setKyc(false);
    setReg(true);
  };
  const [, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const [, setData] = useState(0);

  const selectCountryHandler = (value) => {
    console.log('selectCountryHandler', value);
    setSelectedCountry(value);
  };
  // Have to register the languages you want to use
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  // Returns an object not a list
  const countryObj = countries.getNames('en', { select: 'official' });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  const [openGst, setOpenGst] = React.useState(false);
  // const [gst, setGst] = React.useState('');
  // const handleClickOpenGst = async () => {
  //   console.log(email);
  //   const gstverify = {
  //     gst_number: gst,
  //     user_type: 2,
  //     email_id: email,
  //   };

  //   const getgst = await dispatch(authActions.verifyGst(gstverify));

  //   console.log('getgst', getgst);
  //   if (getgst.status === 200) {
  //     setOpenGst(true);
  //     setMsg(getgst.msg);
  //   } else {
  //     setOpenGst(false);
  //     setMsg(getgst.msg);
  //   }
  // };
  const handleCloseGst = () => {
    setOpenGst(false);
  };
  // const handleClickOpenGst = () => {
  //   setOpenGst(true);
  // };
  // const handlecheckOtp = () => {
  //    navigate("/companyProfile");
  //   setData(otp);
  //    props.onCheckOtp(props.user, otp);
  // };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const [, setUploadJd] = React.useState(null);
  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };

  useEffect(() => {
    async function getdesignationlist() {
      await dispatch(jobsActions.getDesignation());
    }
    getdesignationlist();
  }, []);

  const uploadJd = async (file) => {
    let namekey = uuidv4() + file.name.replace(/ /g, '_');
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();
    // .on('httpUploadProgress', (evt) => {
    // setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err,data) => {
    // if (err) console.log('aws err',err)
    // if (data)console.log('aws data',data)
    //
    // })
    console.log('aws ', objct);
    objct
      .then(function (data) {
        console.log(
          'aws Successfully uploaded data to ' + S3_BUCKET + '/' + namekey
        );
        console.log('aws Success', data);
        let path =
          'https://' +
          S3_BUCKET +
          '.s3.' +
          REGION +
          '.amazonaws.com/' +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log('aws err', err);
      });
  };

  const onSubmit = async (data) => {
    console.log(data);
    // const signup = {
    //   firstName: 'first_name',
    //   lastName: 'last_name',
    //   companyName: 'company_name',

    //   tagline: [''],
    //   domain: 'domain_name',
    //   industry: 'industry',
    //   func: 'funct',
    //   type: 'type',
    //   size: 'size',
    //   logo: '',
    //   email: '',
    //   mobile: '',
    //   address: 'address',
    //   country: '',
    //   state: '',
    //   city: '',
    //   pin_code: '',
    //   pan_no: '',
    //   tan_no: '',
    // };
    const firstData = {
      ...data,
      email: otpemail,
      designation: userDesignation?._id,
      mobile: otpmobile,
    };
    const registrationfirst = await dispatch(
      authActions.dataRegistrationFirst(firstData)
    );
    setKyc(true);
    setReg(false);
    console.log('sendotp', registrationfirst);
  };

  const onSubmitSignup = async (data) => {
    let taglin = [];
    taglin.push(registrationFirstData?.tagline);
    console.log('taglin', taglin);
    var config = {
      headers: {
        'x-auth': xauth,
      },
    };
    const signupdata = {
      first_name: registrationFirstData?.firstName,
      last_name: registrationFirstData?.lastName,
      company_name: registrationFirstData?.companyName,
      tagline: taglin,
      domain_name: registrationFirstData?.domain,
      about_company: registrationFirstData?.about_company,
      website: registrationFirstData?.domain,
      industry: registrationFirstData?.industry,
      function: registrationFirstData?.func,
      company_type: registrationFirstData?.companytype,
      size: registrationFirstData?.size,
      designation: registrationFirstData?.designation,
      registration_type: registrationFirstData?.regtype,
      address: data?.address,
      logo: filepath,
      country: selectedCountry,
      state: data?.state,
      city: data?.city,
      pin_code: data?.pincode,
      pan_no: data?.pan,
      gst_no: data?.gst,
      tan_no: data?.tan,
    };
    const signup = await dispatch(
      authActions.signupcompany(signupdata, config)
    );
    if (signup.data.status === true) {
      await dispatch(authActions.getProfile());
      navigate('/editCompanyProfile');
      setOpenMobile(false);
      setMsg(signup.msg);
    } else {
      setOpenMobile(true);
      setMsg(signup.msg);
    }
  };
  useEffect(() => {
    async function getIndustrylist() {
      await dispatch(helperActions.getIndustry());
    }
    getIndustrylist();
  }, []);

  useEffect(() => {
    async function getFunctionlist() {
      await dispatch(helperActions.getFunction());
    }
    getFunctionlist();
  }, []);

  useEffect(() => {
    async function getCompanySizelist() {
      await dispatch(helperActions.getComapnySize());
    }
    getCompanySizelist();
  }, []);

  useEffect(() => {
    async function getCompanyTypelist() {
      await dispatch(helperActions.getCompanyType());
    }
    getCompanyTypelist();
  }, []);

  useEffect(() => {
    async function getCompanyRegistrationTypelist() {
      await dispatch(helperActions.getCompanyRegistrationType());
    }
    getCompanyRegistrationTypelist();
  }, []);
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.containerbg}>
        <Grid container className={classes.grid}>
          {' '}
          <Grid Item xs={12} sm={7}></Grid>{' '}
          <Grid Item xs={12} sm={5} className={classes.form}>
            <Typography
              variant='h4'
              sx={{
                fontSize: '30px',
                color: '#223870',
                fontWeight: '600',
                textAlign: 'center',
                marginTop: '15px',
                marginBottom: '10px',
              }}
            >
              Company Signup
            </Typography>{' '}
            <Box>
              {' '}
              {reg && (
                <Box sx={{ paddingBottom: '40px' }}>
                  {' '}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                      container
                      sm={12}
                      sx={{
                        marginTop: '25px',
                        width: '100%',
                      }}
                    >
                      {' '}
                      <Grid
                        Item
                        sm={6}
                        md={6}
                        sx={{ width: '50%', paddingRight: '4px' }}
                      >
                        <Grid container direction='column'>
                          {' '}
                          <Grid Item>
                            {' '}
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              First Name*
                            </Typography>{' '}
                          </Grid>
                          <Grid Item>
                            <TextField
                              className={classes.textField}
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              onChange={handleChange}
                              name='firstName'
                              {...register('firstName', {
                                required: 'First name is required',
                                minLength: {
                                  value: 1,
                                  message:
                                    'First name must be atleast 1 character',
                                },
                              })}
                            />
                            {errors.firstName && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors.firstName.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        Item
                        sm={6}
                        md={6}
                        sx={{ width: '50%', paddingLeft: '4px' }}
                      >
                        <Grid container direction='column'>
                          {' '}
                          <Grid Item>
                            {' '}
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              Last Name*
                            </Typography>{' '}
                          </Grid>
                          <Grid Item>
                            <TextField
                              className={classes.textField}
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              name='lastName'
                              {...register('lastName', {
                                required: 'Last name is required',
                                minLength: {
                                  value: 1,
                                  message:
                                    'Last name must be atleast 1 character',
                                },
                              })}
                            />
                            {errors.lastName && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors.lastName.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container direction='column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Designation*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        {' '}
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: '100% ' }}
                          value={userDesignation}
                          options={designation}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                              }}
                            />
                          }
                          name='designation'
                          defaultValue=''
                          onChange={(_event, designatin) => {
                            setUserDesignation(designatin);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            if (option) {
                              return option?.designation;
                            } else {
                              return '';
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              size='small'
                              className={classes.textField}
                              {...params}
                              // label="IND +91"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: '',
                              }}
                            />
                          )}
                        />
                        {/*      <TextField
                          className={classes.textField}
                          size="small"
                          variant="outlined"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name="designation"
                          {...register("designation", {
                            required: "Designation is required",
                            minLength: {
                              value: 3,
                              message:
                                "Designation must be atleast 3 characters",
                            },
                          })}
                        />
                        {errors.designation && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.designation.message}
                          </Box>
                        )}

                        */}
                      </Grid>
                    </Grid>
                    <Grid container direction='column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Company Name*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        {' '}
                        <TextField
                          className={classes.textField}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='companyName'
                          {...register('companyName', {
                            required: 'Company Name is required',
                            minLength: {
                              value: 3,
                              message:
                                'Company Name must be atleast 3 characters',
                            },
                            maxLength: {
                              value: 60,
                              message: 'Maximum 60 characters allowed',
                            },
                          })}
                        />
                        {errors.companyName && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.companyName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction='column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Tagline*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        {' '}
                        <TextField
                          className={classes.textField}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='tagline'
                          {...register('tagline', {
                            required: 'Tagline is required',
                            minLength: {
                              value: 3,
                              message: 'Tagline must be atleast 3 characters',
                            },
                            maxLength: {
                              value: 60,
                              message: 'Maximum 60 characters allowed',
                            },
                          })}
                        />
                        {errors.tagline && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.tagline.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction='column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Domain Name*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        {' '}
                        <TextField
                          className={classes.textField}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='domain'
                          {...register('domain', {
                            required: 'Domain name is required',
                          })}
                        />
                        {errors.domain && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.domain.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sm={12}
                      sx={{
                        marginTop: '10px',
                        width: '100%',
                        paddingRight: '8px',
                        '@media (max-width: 468px)': {
                          paddingRight: 'none',
                        },
                      }}
                    >
                      {' '}
                      <Grid Item sm={6} md={6} sx={{ width: '50%' }}>
                        <Grid container direction='column'>
                          {' '}
                          <Grid Item>
                            {' '}
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                              }}
                            >
                              Industry*
                            </Typography>{' '}
                          </Grid>
                          <Grid Item>
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                    position: 'absolute',
                                    right: 10,
                                    pointerEvents: 'none',
                                  }}
                                />
                              )}
                              sx={{
                                width: '100%',

                                color: '#5071C4',
                                backgroundColor: '#E0DFF4',
                                fontWeight: '500',
                                '@media (max-width: 468px)': {
                                  paddingRight: 'none',
                                },
                              }}
                              name='industry'
                              className={classes.countrycode}
                              {...register('industry', {
                                required: 'Select industry ',
                              })}
                            >
                              {companyIndustry?.map((option) => {
                                return (
                                  <MenuItem
                                    key={option._id}
                                    value={option._id}
                                    sx={{
                                      color: '#5071C4',
                                      fontWeight: '700',
                                    }}
                                  >
                                    {option.industry}
                                    {/* {option.industry ?? option.label} */}
                                  </MenuItem>
                                );
                              })}
                            </Select>{' '}
                            {errors.industry && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors.industry.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid Item sm={6} md={6} sx={{ width: '50%' }}>
                        <Grid container direction='column'>
                          {' '}
                          <Grid Item>
                            {' '}
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: '#304672',
                                fontWeight: '500',
                                marginLeft: '10px',
                              }}
                            >
                              Function*
                            </Typography>{' '}
                          </Grid>
                          <Grid Item>
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                    position: 'absolute',
                                    right: 10,
                                    pointerEvents: 'none',
                                  }}
                                />
                              )}
                              sx={{
                                width: '100%',
                                marginLeft: '8px',
                                color: '#5071C4',
                                backgroundColor: '#E0DFF4',
                                fontWeight: '500',
                              }}
                              name='func'
                              className={classes.countrycode}
                              {...register('func', {
                                required: 'Select function ',
                              })}
                            >
                              {companyFunction?.map((option) => {
                                return (
                                  <MenuItem
                                    key={option._id}
                                    value={option._id}
                                    sx={{
                                      color: '#5071C4',
                                      fontWeight: '700',
                                      widt: '280px',
                                    }}
                                  >
                                    {/* {`${option.function.substring(0, 20)}`} */}
                                    <p>
                                      {option.function?.length > 26
                                        ? option.function.substring(0, 26)
                                        : option.function}
                                    </p>
                                  </MenuItem>
                                );
                              })}
                            </Select>{' '}
                            {errors.func && (
                              <Box
                                sx={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginLeft: '10px',
                                }}
                              >
                                {' '}
                                {errors.func.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container direction='Column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Company Registration Type*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='regtype'
                          className={classes.countrycode}
                          {...register('regtype', {
                            required: 'Select type of your company',
                          })}
                        >
                          {companyRegType?.map((option) => {
                            return (
                              <MenuItem
                                key={option._id}
                                value={option._id}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option.company_registration_type ??
                                  option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.regtype && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.regtype.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction='Column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Company Type*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          onChange={handleType}
                          className={classes.countrycode}
                          {...register('companytype', {
                            required: 'Select type of your company',
                          })}
                        >
                          {companyType?.map((option) => {
                            return (
                              <MenuItem
                                key={option._id}
                                value={option._id}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option.company_type ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.companytype && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.companytype.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction='Column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Size*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: '#5071C4',
                                position: 'absolute',
                                right: 10,
                                pointerEvents: 'none',
                              }}
                            />
                          )}
                          sx={{
                            color: '#5071C4',
                            backgroundColor: '#E0DFF4',
                            fontWeight: '500',
                          }}
                          name='size'
                          className={classes.countrycode}
                          {...register('size', {
                            required: 'Select size of your company ',
                          })}
                        >
                          {companySize?.map((option) => {
                            return (
                              <MenuItem
                                key={option._id}
                                value={option._id}
                                sx={{
                                  color: '#5071C4',
                                  fontWeight: '700',
                                }}
                              >
                                {option?.max != null
                                  ? option.min +
                                    ' - ' +
                                    option?.max +
                                    ' Employees'
                                  : '>' + option.min + '+  Employees'}
                              </MenuItem>
                            );
                          })}
                        </Select>{' '}
                        {errors.size && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.size.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction='Column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Logo*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        {' '}
                        <Box>
                          {/* <label htmlFor="icon-button-image">
                            <Box
                              p={1}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            ></Box> */}
                          {/* </label> */}
                          <input
                            id='icon-button-image'
                            type='file'
                            style={{
                              width: '100%',
                              padding: '10px',
                              display: 'block',
                              borderRadius: '5px',
                              backgroundColor: '#E0DFF4',
                              color: '#304672',
                            }}
                            onChange={handleUploadJd}
                            name='image'
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container direction='column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          About Company*
                        </Typography>{' '}
                      </Grid>
                      <Grid
                        Item
                        sx={{
                          '& > :not(style)': {
                            width: '100%',
                          },
                        }}
                      >
                        {' '}
                        <TextField
                          className={classes.textField}
                          size='small'
                          variant='outlined'
                          id='outlined-multiline-static'
                          multiline
                          maxRows={4}
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='about_company'
                          {...register('about_company', {
                            required: 'About Company is required',
                            minLength: {
                              value: 3,
                              message:
                                'About Company must be atleast 3 characters',
                            },
                          })}
                        />
                        {errors.about_company && (
                          <Box sx={{ color: 'red', fontSize: '12px' }}>
                            {' '}
                            {errors.about_company.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction='Column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Official Email ID*
                        </Typography>{' '}
                      </Grid>
                      <Grid>
                        <Box
                          sm={12}
                          sx={{
                            width: '100%',
                          }}
                        >
                          {' '}
                          <Stack direction='row' spacing={0}>
                            <TextField
                              className={classes.textField}
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                color: '#5071C4',
                                backgroundColor: '#E0DFF4',
                                width: '100%',
                                borderRadius: '5px 0px 0px 5px',
                              }}
                              name='email'
                              fullWidth
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              // {...register("email", {
                              //   required: "Email id is required",
                              //   pattern: {
                              //     value:
                              //       /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              //     message: "Please enter a valid email address",
                              //   },
                              // })}
                            />

                            <Box
                              sx={{
                                backgroundColor: '#E0DFF4',
                                padding: '3px 0px',
                                borderRadius: '0px 5px 5px 0px',
                              }}
                            >
                              {' '}
                              <Button
                                variant='standard'
                                onClick={handleClickSendEmailotp}
                                disabled={companyRegistrationEmailVerification}
                                style={{
                                  marginRight: '5px',
                                  backgroundColor: '#223870',
                                  borderRadius: '3px',
                                  color: companyRegistrationEmailVerification
                                    ? '#FDCF57!important'
                                    : '#FDCF57',
                                  padding: '2px 20px',
                                  fontSize: '16px',
                                  textTransform: 'capitalize',
                                  '&:hover': {
                                    color: '#FDCF57',
                                    backgroundColor: '#223870',
                                  },
                                  '@media (max-width: 468px)': {
                                    textAlign: 'center',
                                    padding: '0px 10px',
                                  },
                                }}
                              >
                                {companyRegistrationEmailVerification
                                  ? 'Verified'
                                  : 'Verify'}
                              </Button>
                              <Dialog
                                open={companyRegistrationEmailDialog}
                                onClose={handleCloseEmail}
                                sx={{
                                  textAlign: 'center',
                                  '& .MuiDialog-container': {
                                    '& .MuiPaper-root': {
                                      width: '100%',
                                      maxWidth: '590px',
                                      height: '430px',
                                      borderRadius: '8px',
                                      backgroundColor: '#E8EEFB',
                                    },
                                  },
                                }}
                              >
                                <DialogActions>
                                  <Box
                                    onClick={handleCloseEmail}
                                    component='img'
                                    src={Close}
                                    sx={{
                                      top: '10px',
                                      right: '10px',
                                      width: '20px',
                                      height: '20px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </DialogActions>
                                <DialogTitle
                                  sx={{
                                    color: '#223870',
                                    textAlign: 'center',
                                    fontSize: '22px',
                                    marginTop: '-15px',
                                  }}
                                >
                                  Verify Email ID
                                </DialogTitle>
                                <DialogContent
                                  sx={{
                                    marginTop: '20px ',
                                    textAlign: 'center',
                                  }}
                                >
                                  {' '}
                                  <Box>
                                    {' '}
                                    <Typography
                                      variant='p'
                                      gutterBottom
                                      sx={{
                                        fontSize: '18px',
                                        color: '#5071C4',
                                        fontWeight: '500',
                                      }}
                                    >
                                      OTP sent to your email {otpemail}
                                    </Typography>{' '}
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: '40px',
                                      marginBottom: '10px',
                                    }}
                                  >
                                    {' '}
                                    <Typography
                                      variant='h6'
                                      sx={{
                                        color: '#223870',
                                        fontWeight: '500',
                                      }}
                                    >
                                      Enter OTP
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <OtpInput
                                      value={otp}
                                      onChange={handleChangeOtp}
                                      numInputs={6}
                                      inputStyle={{
                                        height: '35px',
                                        width: '45px',
                                        color: '#5071C4',
                                        backgroundColor: '#E0DFF4',
                                        border: '1px solid #223870',
                                        margin: '4px',
                                        fontSize: '18px',
                                        borderRadius: '3px',
                                      }}
                                      focusStyle={{
                                        border: '1px solid #223870',
                                      }}
                                    />
                                  </Box>
                                  <Box sx={{ marginTop: '5px' }}>
                                    <OtpTimer
                                      resendOtp={handleClickSendEmailotp}
                                      timerzero={timerZero}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      marginLeft: '215px',
                                      marginTop: '30px',
                                      width: '120px',
                                    }}
                                  >
                                    {' '}
                                    <Button
                                      onClick={handleClickVerifyEmail}
                                      disabled={
                                        companyRegistrationMobileVerification
                                      }
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '120px',
                                        backgroundColor: '#223870',
                                        borderRadius: '3px',
                                        color:
                                          companyRegistrationMobileVerification
                                            ? '#FDCF57!important'
                                            : '#FDCF57',
                                        marginTop: '3px',
                                        padding: '6px 25px 7px 35px',
                                        fontSize: '18px',
                                        textTransform: 'none',
                                        '&:hover': {
                                          color: '#FDCF57',
                                          backgroundColor: '#223870',
                                        },
                                        '@media (max-width: 468px)': {
                                          marginTop: '-29px',
                                          textAlign: 'center',
                                          marginRight: '-10px',
                                          padding: '0px 10px',
                                        },
                                      }}
                                    >
                                      {companyRegistrationMobileVerification
                                        ? 'Verified'
                                        : 'Verify'}
                                    </Button>
                                  </Box>
                                  <Box
                                    sx={{
                                      color: '#0747f7',
                                      fontWeight: '500',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {msg}
                                  </Box>
                                </DialogContent>
                              </Dialog>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction='Column'>
                      {' '}
                      <Grid
                        Item
                        sx={{
                          marginTop: '10px',
                        }}
                      >
                        {' '}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: '#304672',
                            fontWeight: '500',
                          }}
                        >
                          Mobile No.*
                        </Typography>{' '}
                      </Grid>
                      <Grid Item>
                        {' '}
                        <Grid container>
                          <Grid Item sm={4} sx={{ paddingRight: '10px' }}>
                            {/* aparajita */}
                            <Autocomplete
                              id='country-select-demo'
                              sx={{ width: '100% ' }}
                              value={selectedCountry}
                              options={countryListnew}
                              autoHighlight
                              disableClearable
                              variant='standard'
                              popupIcon={
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: '#5071C4',
                                  }}
                                />
                              }
                              name='country_code'
                              onChange={(_event, country) => {
                                setSelectedCountry(country);
                              }}
                              size='small'
                              getOptionLabel={(option) => {
                                return option.iso3 + ' +' + option.phone_code;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  {...params}
                                  // label="IND +91"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid Item sm={8}>
                            <Stack direction='row' spacing={0}>
                              <TextField
                                size='small'
                                variant='outlined'
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{
                                  width: '100%',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  borderRadius: '5px 0px 0px 5px',
                                }}
                                name='mobile'
                                fullWidth
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                // {...register("mobile", {
                                //   required: "Mobile number is required",
                                //   pattern: {
                                //     value: /^\d{10}$/,
                                //     message:
                                //       "Please enter a valid 10-digit mobile number",
                                //   },
                                // })}
                              />

                              <Box
                                sx={{
                                  marginLeft: '-20px',
                                  backgroundColor: '#E0DFF4',
                                  padding: '3px 0px',
                                  borderRadius: '0px 5px 5px 0px',
                                  '@media (max-width: 468px)': {
                                    fontSize: '14px',
                                  },
                                }}
                              >
                                {' '}
                                <Button
                                  onClick={handleClickSendMobileotp}
                                  disabled={
                                    companyRegistrationMobileVerification
                                  }
                                  variant='standard'
                                  sx={{
                                    marginRight: '4px',
                                    backgroundColor: '#223870',
                                    borderRadius: '3px',
                                    color: companyRegistrationMobileVerification
                                      ? '#FDCF57!important'
                                      : '#FDCF57',
                                    marginTop: '1px',
                                    padding: '2px 20px',
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                      color: '#FDCF57',
                                      backgroundColor: '#223870',
                                    },
                                    '@media (max-width: 468px)': {
                                      textAlign: 'center',
                                      padding: '0px 10px',
                                    },
                                  }}
                                >
                                  {companyRegistrationMobileVerification
                                    ? 'Verified'
                                    : 'Verify'}
                                </Button>
                                <Dialog
                                  open={companyRegistrationMobileDialog}
                                  onClose={handleCloseMobile}
                                  sx={{
                                    '& .MuiDialog-container': {
                                      '& .MuiPaper-root': {
                                        width: '100%',
                                        maxWidth: '590px',
                                        height: '430px',
                                        borderRadius: '8px',
                                        backgroundColor: '#E8EEFB',
                                      },
                                    },
                                  }}
                                >
                                  <DialogActions>
                                    <Box
                                      onClick={handleCloseMobile}
                                      component='img'
                                      src={Close}
                                      sx={{
                                        top: '10px',
                                        right: '10px',
                                        width: '20px',
                                        height: '20px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </DialogActions>
                                  <DialogTitle
                                    sx={{
                                      color: '#223870',
                                      textAlign: 'center',
                                      fontSize: '22px',
                                      marginTop: '-15px',
                                    }}
                                  >
                                    Verify Mobile
                                  </DialogTitle>
                                  <DialogContent
                                    sx={{
                                      marginTop: '20px ',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {' '}
                                    <Box>
                                      {' '}
                                      <Typography
                                        variant='p'
                                        gutterBottom
                                        sx={{
                                          fontSize: '18px',
                                          color: '#5071C4',
                                          fontWeight: '500',
                                        }}
                                      >
                                        OTP sent to your Mobile No +91{' '}
                                        {otpmobile}
                                      </Typography>{' '}
                                    </Box>
                                    <Box
                                      sx={{
                                        marginTop: '40px',
                                        marginBottom: '10px',
                                      }}
                                    >
                                      {' '}
                                      <Typography
                                        variant='h6'
                                        sx={{
                                          color: '#223870',
                                          fontWeight: '500',
                                        }}
                                      >
                                        Enter OTP
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <OtpInput
                                        value={otp}
                                        onChange={handleChangeOtp}
                                        numInputs={6}
                                        inputStyle={{
                                          height: '35px',
                                          width: '45px',
                                          color: '#5071C4',
                                          backgroundColor: '#E0DFF4',
                                          border: '1px solid #223870',
                                          margin: '4px',
                                          fontSize: '18px',
                                          borderRadius: '3px',
                                        }}
                                        focusStyle={{
                                          border: '1px solid #223870',
                                        }}
                                      />
                                    </Box>
                                    <Box sx={{ marginTop: '5px' }}>
                                      <OtpTimer
                                        resendOtp={handleClickSendMobileotp}
                                        timerzero={timerZero}
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        marginLeft: '215px',
                                        marginTop: '30px',
                                        width: '120px',
                                      }}
                                    >
                                      {' '}
                                      <Button
                                        onClick={handleClickVerifyMobile}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          width: '120px',
                                          backgroundColor: '#223870',
                                          borderRadius: '3px',
                                          color: '#FDCF57',
                                          marginTop: '3px',
                                          padding: '6px 25px 7px 35px',
                                          fontSize: '18px',
                                          textTransform: 'none',
                                          '&:hover': {
                                            color: '#FDCF57',
                                            backgroundColor: '#223870',
                                          },
                                          '@media (max-width: 468px)': {
                                            marginTop: '-29px',
                                            textAlign: 'center',
                                            marginRight: '-10px',
                                            padding: '0px 10px',
                                          },
                                        }}
                                      >
                                        Verify
                                      </Button>
                                    </Box>
                                  </DialogContent>
                                </Dialog>
                              </Box>
                            </Stack>
                            {errors.mobile && (
                              <Box sx={{ color: 'red', fontSize: '12px' }}>
                                {' '}
                                {errors.mobile.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction='Row'
                      sx={{
                        paddingRight: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid Item>
                        {' '}
                        <Box sx={{ marginTop: '30px' }}>
                          <a
                            href='/loginPageCompany'
                            style={{
                              fontSize: '18px',
                              color: '#223870',
                            }}
                          >
                            Login
                          </a>
                        </Box>
                      </Grid>
                      <Grid Item>
                        {' '}
                        <input
                          type='submit'
                          value='Next'
                          style={{
                            marginTop: '30px',
                            border: 'none',
                            textTransform: 'none',
                            textDecoration: 'none',
                            padding: '7px 20px',
                            borderRadius: '3px ',
                            color: '#FDCF57',
                            fontWeight: '500',
                            fontSize: '20px',
                            cursor: 'pointer',
                            backgroundColor: '#223870',
                            '&:hover': {
                              backgroundColor: '#223870',
                              color: '#FDCF57 ',
                            },
                          }}
                        />
                      </Grid>{' '}
                    </Grid>
                    <Box
                      sx={{
                        color: '#0747f7',
                        fontWeight: '500',
                        fontSize: '12px',
                      }}
                    >
                      {msg}
                    </Box>
                  </form>
                </Box>
              )}
              {kyc && (
                <Box>
                  <Box>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        textAlign: 'left',
                        color: '#223870',
                        fontWeight: '500',
                        fontSize: '16px',
                      }}
                    >
                      KYC Compliance
                    </Typography>
                  </Box>
                  <form onSubmit={handleSubmit(onSubmitSignup)}>
                    <Box
                      sx={{
                        height: '100vh',
                        overflowY: 'scroll',
                      }}
                    >
                      <Grid container direction='Column'>
                        {' '}
                        <Grid
                          Item
                          sx={{
                            marginTop: '10px',
                          }}
                        >
                          {' '}
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              color: '#304672',
                              fontWeight: '500',
                            }}
                          >
                            Address*
                          </Typography>{' '}
                        </Grid>
                        <Grid
                          Item
                          sx={{
                            '& > :not(style)': {
                              width: '100%',
                            },
                          }}
                        >
                          {' '}
                          <TextField
                            className={classes.textField}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name='address'
                            {...register('address', {
                              required: 'Address is required',
                            })}
                          />
                          {errors.address && (
                            <Box sx={{ color: 'red', fontSize: '12px' }}>
                              {' '}
                              {errors.address.message}
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sm={12}
                        sx={{
                          marginTop: '10px',
                          width: '100%',
                          '@media (max-width: 468px)': {
                            paddingRight: 'none',
                          },
                        }}
                      >
                        {' '}
                        <Grid
                          Item
                          sm={6}
                          md={6}
                          sx={{ width: '50%', paddingRight: '4px' }}
                        >
                          <Grid container direction='column'>
                            {' '}
                            <Grid Item>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                Country*
                              </Typography>{' '}
                            </Grid>
                            <Grid Item sx={{}}>
                              <Select
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: '#5071C4',
                                      position: 'absolute',
                                      right: 10,
                                      pointerEvents: 'none',
                                    }}
                                  />
                                )}
                                sx={{
                                  width: '100%',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  fontWeight: '500',
                                  '@media (max-width: 468px)': {
                                    paddingRight: 'none',
                                  },
                                }}
                                name='country'
                                value={selectedCountry}
                                onChange={(e) =>
                                  selectCountryHandler(e.target.value)
                                }
                                className={classes.countrycode}
                              >
                                {!!countryArr?.length &&
                                  countryArr.map(({ label, value }) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                        sx={{
                                          color: '#5071C4',
                                          fontWeight: '700',
                                        }}
                                      >
                                        {label}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>{' '}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          Item
                          sm={6}
                          md={6}
                          sx={{ width: '50%', paddingLeft: '4px' }}
                        >
                          {' '}
                          <Grid container direction='column'>
                            {' '}
                            <Grid Item>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                State*
                              </Typography>{' '}
                            </Grid>
                            <Grid Item>
                              {' '}
                              <TextField
                                className={classes.textField}
                                size='small'
                                variant='outlined'
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name='state'
                                {...register('state', {
                                  required: 'State is required',
                                })}
                              />
                              {errors.state && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors.state.message}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sm={12}
                        sx={{
                          marginTop: '10px',
                          width: '100%',
                          paddingRight: 'none',
                          '@media (max-width: 468px)': {
                            paddingRight: 'none',
                          },
                        }}
                      >
                        {' '}
                        <Grid
                          Item
                          sm={6}
                          md={6}
                          sx={{ width: '50%', paddingRight: '4px' }}
                        >
                          <Grid container direction='column'>
                            {' '}
                            <Grid Item>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                {' '}
                                City*
                              </Typography>{' '}
                            </Grid>
                            <Grid Item>
                              {' '}
                              <TextField
                                className={classes.textField}
                                size='small'
                                variant='outlined'
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name='city'
                                {...register('city', {
                                  required: 'City is required',
                                })}
                              />
                              {errors.city && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors.city.message}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          Item
                          sm={6}
                          md={6}
                          sx={{ width: '50%', paddingLeft: '4px' }}
                        >
                          <Grid container direction='column'>
                            {' '}
                            <Grid Item>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                {' '}
                                Pincode*
                              </Typography>{' '}
                            </Grid>
                            <Grid Item>
                              {' '}
                              <TextField
                                className={classes.textField}
                                size='small'
                                variant='outlined'
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name='pincode'
                                {...register('pincode', {
                                  required: 'Pincode is required',
                                  minLength: {
                                    value: 6,
                                    message: 'Pincode must be 6 digit',
                                  },

                                  maxLength: {
                                    value: 6,
                                    message: 'Pincode must be 6 digit',
                                  },
                                })}
                              />
                              {errors.pincode && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors.pincode.message}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container direction='Column'>
                        {' '}
                        <Grid
                          Item
                          sx={{
                            marginTop: '10px',
                          }}
                        >
                          {' '}
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              color: '#304672',
                              fontWeight: '500',
                            }}
                          >
                            GST No.*
                          </Typography>{' '}
                        </Grid>
                        <Grid Item>
                          <Box>
                            {console.log('errors', errors)}
                            <Stack direction='row' spacing={0}>
                              <TextField
                                className={classes.textField}
                                size='small'
                                variant='outlined'
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{
                                  width: '100%',
                                  color: '#5071C4',
                                  backgroundColor: '#E0DFF4',
                                  borderRadius: '5px 0px 0px 5px',
                                }}
                                name='gst'
                                fullWidth
                                // value={gst}
                                // onChange={(e) => setGst(e.target.value)}
                                {...register('gst', {
                                  required: 'Gst is required',
                                  minLength: {
                                    value: 15,
                                    message: 'Gst must be 15 digit',
                                  },

                                  maxLength: {
                                    value: 15,
                                    message: 'Gst must be 15 digit',
                                  },
                                })}
                              />
                              <Box
                                sx={{
                                  width: '100px',
                                  height: '42px',
                                  backgroundColor: '#E0DFF4',
                                  padding: '3px 0px',
                                  borderRadius: '0px 5px 5px 0px',
                                }}
                              >
                                {' '}
                                <Button
                                  onClick={handleClickVerifyGst}
                                  // variant="standard"
                                  style={{
                                    width: '95px',
                                    marginRight: '30px',
                                    backgroundColor: '#223870',
                                    borderRadius: '3px',
                                    color: '#FDCF57',
                                    marginTop: '2px',
                                    textAlign: 'center',
                                    padding: '3px 0',
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    '&:hover': {
                                      color: '#FDCF57',
                                      backgroundColor: '#223870',
                                    },
                                    '@media (max-width: 468px)': {
                                      marginTop: '-29px',
                                      textAlign: 'center',
                                      marginRight: '-10px',
                                      padding: '0px 10px',
                                    },
                                  }}
                                >
                                  {' '}
                                  {gstverification ? 'Verified' : 'Verify'}
                                </Button>
                                <Dialog
                                  open={openGst}
                                  onClose={handleCloseGst}
                                  sx={{
                                    '& .MuiDialog-container': {
                                      '& .MuiPaper-root': {
                                        width: '100%',
                                        maxWidth: '590px',
                                        height: '300px',
                                        borderRadius: '5px',
                                        backgroundColor: '#F7FAFF',
                                      },
                                    },
                                  }}
                                >
                                  <DialogActions>
                                    <Box
                                      onClick={handleCloseGst}
                                      component='img'
                                      src={Close}
                                      sx={{
                                        top: '10px',
                                        right: '10px',
                                        width: '20px',
                                        height: '20px',
                                      }}
                                    />
                                  </DialogActions>
                                  <DialogTitle
                                    sx={{
                                      color: '#5071C4',
                                      textAlign: 'center',
                                      fontSize: '26px',
                                    }}
                                  >
                                    Agreement & NDA
                                  </DialogTitle>
                                  <DialogContent
                                    sx={{
                                      marginTop: '10px ',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {' '}
                                    <Box>
                                      {' '}
                                      <Typography
                                        variant='p'
                                        gutterBottom
                                        sx={{
                                          fontSize: '18px',
                                          color: '#223870',
                                          fontWeight: '500',
                                        }}
                                      >
                                        Agreement & NDA has been sent to your
                                        email. Please add the digital signature
                                        and send it for further validation.
                                      </Typography>{' '}
                                    </Box>
                                    <Box sx={{ marginTop: '40px ' }}>
                                      {' '}
                                      <Button
                                        onClick={handleCloseGst}
                                        type='submit'
                                        sx={{
                                          padding: '4px 20px',
                                          textTransform: 'none',
                                          textDecoration: 'none',
                                          borderRadius: '3px ',
                                          color: '#FDCF57',
                                          fontWeight: '500',
                                          fontSize: '18px',
                                          backgroundColor: '#223870',
                                          '&:hover': {
                                            backgroundColor: '#223870',
                                            color: '#FDCF57 ',
                                          },
                                        }}
                                      >
                                        OK
                                      </Button>
                                    </Box>
                                  </DialogContent>
                                </Dialog>
                              </Box>
                            </Stack>
                            {errors.gst && (
                              <Box
                                sx={{
                                  color: '#fff',
                                  fontSize: '12px',
                                  backgroundColor: 'red',
                                  marginTop: '5px',
                                }}
                              >
                                {' '}
                                {errors.gst.message}
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sm={12}
                        sx={{
                          marginTop: '10px',
                          width: '100%',

                          '@media (max-width: 468px)': {
                            paddingRight: 'none',
                          },
                        }}
                      >
                        {' '}
                        <Grid
                          Item
                          sm={6}
                          md={6}
                          sx={{ width: '50%', paddingRight: '4px' }}
                        >
                          <Grid container direction='column'>
                            {' '}
                            <Grid Item>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                PAN No.*
                              </Typography>{' '}
                            </Grid>
                            <Grid Item>
                              {' '}
                              <TextField
                                className={classes.textField}
                                size='small'
                                variant='outlined'
                                type='text'
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name='pan'
                                {...register('pan', {
                                  required: 'pan is required',
                                  minLength: {
                                    value: 10,
                                    message: 'pan must be 10 characters',
                                  },

                                  maxLength: {
                                    value: 10,
                                    message: 'pan must be 10 characters',
                                  },
                                })}
                              />
                              {errors.pan && (
                                <Box sx={{ color: 'red', fontSize: '12px' }}>
                                  {' '}
                                  {errors.pan.message}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          Item
                          sm={6}
                          md={6}
                          sx={{ width: '50%', paddingLeft: '4px' }}
                        >
                          <Grid container direction='column'>
                            {' '}
                            <Grid Item>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  color: '#304672',
                                  fontWeight: '500',
                                }}
                              >
                                TAN No.*
                              </Typography>{' '}
                            </Grid>
                            <Grid Item>
                              {' '}
                              <Box>
                                <TextField
                                  className={classes.textField}
                                  size='small'
                                  variant='outlined'
                                  type='text'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                  name='tan'
                                  {...register('tan', {
                                    required: 'Tan is required',
                                    minLength: {
                                      value: 10,
                                      message: 'Tan must be 10 characters',
                                    },

                                    maxLength: {
                                      value: 10,
                                      message: 'Tan must be 10 characters',
                                    },
                                  })}
                                />
                                {errors.tan && (
                                  <Box sx={{ color: 'red', fontSize: '12px' }}>
                                    {' '}
                                    {errors.tan.message}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12} sm={12}>
                        <FormControlLabel
                          control={
                            <Controller
                              control={control}
                              name='acceptTerms'
                              defaultValue='false'
                              inputRef={register()}
                              render={({ field: { onChange } }) => (
                                <Checkbox
                                  sx={{
                                    color: '#223870',
                                    '&.Mui-checked': {
                                      color: '#223870',
                                    },
                                    '&$checked': {
                                      color: 'yellow',
                                    },
                                  }}
                                  onChange={(e) => onChange(e.target.checked)}
                                />
                              )}
                            />
                          }
                          label={
                            <Typography
                              varient='p'
                              color={errors.acceptTerms ? 'error' : 'inherit'}
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              I agree to the{' '}
                              <a href='/' style={{ color: '#223870' }}>
                                T&C and Privacy Policy
                              </a>{' '}
                              of the ExoTalent
                            </Typography>
                          }
                        />
                        <br />
                        <Typography variant='inherit' color='textSecondary'>
                          {errors.acceptTerms
                            ? '(' + errors.acceptTerms.message + ')'
                            : ''}
                        </Typography>
                      </Grid>
                      <Stack
                        direction='row'
                        sx={{
                          diaplay: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Button
                            sx={{
                              textTransform: 'none',
                              textDecoration: 'none',
                              padding: '4px 20px',
                              borderRadius: '3px ',
                              color: '#FDCF57',
                              fontWeight: '500',
                              fontSize: '20px',
                              backgroundColor: '#223870',
                              '&:hover': {
                                backgroundColor: '#223870',
                                color: '#FDCF57 ',
                              },
                            }}
                            onClick={handleClickBack}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <input
                            type='submit'
                            value='Signup'
                            style={{
                              border: 'none',

                              textDecoration: 'none',
                              padding: '8px 18px',
                              borderRadius: '5px ',
                              color: '#FDCF57',
                              fontWeight: '500',
                              fontSize: '20px',
                              backgroundColor: '#223870',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: '#223870',
                                color: '#FDCF57 ',
                              },
                            }}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CompanyRegister;
