import React, { useState, Fragment } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowRight';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import Burger from '../Burger/Burger';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../../Store/auth';

const StyledMenuCss = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginLeft: '210px',
    borderRadius: '3px',
    marginTop: theme.spacing(64),
    minWidth: 80,

    '& .MuiMenu-list': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  MuiDrawer: {
    backgroundColor: '#F3F2FB',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawerRoot: {
    position: 'relative !important',
    marginTop: '100px',
    '& .MuiBackdrop-root': {
      marginTop: '100px',
      position: 'relative !important',
      height: '100vh',
    },
  },
  drawerPaper: {
    position: 'absolute !important',
  },
  root: {
    justifyContent: 'flex-end',
   fontFamily: 'Montserrat',
  },
  link: {
    fontWeight: '600',
    textDecoration: 'none',
    color: '#223870',
    fontSize: '20px',
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state?.auth.loggedIn);
  const handleClickLogout = async () => {
    const logoddt = await dispatch({ type: 'logout' });
  };
  return (
    <>
      <IconButton edge='start' onClick={() => setOpenDrawer(!openDrawer)}>
        <Burger />
      </IconButton>

      <Drawer
        PaperProps={{
          textAlign: 'center',
          sx: {
            backgroundColor: '#F3F2FB',
            marginTop: '71px',
            width: '100%',
            textAlign: 'center',
          },
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='/#aboutus'
          >
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#223870',
              }}
            >
              About Us
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='#services'
          >
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#223870',
              }}
            >
              Services
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='#industry'
          >
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#223870',
              }}
            >
              Industry Verticals
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='#clients'
          >
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#223870',
              }}
            >
              Clients
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='/JobsPage'
          >
            <ListItemText
              style={{
                textAlign: 'center',
                padding: '20px 0px',
                color: '#223870',
              }}
            >
              Jobs
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText style={{ textAlign: 'center', padding: '20px 0px' }}>
              {loggedIn ? (
                <Button
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    padding: '4px 20px',
                    borderRadius: '3px ',
                    color: '#FDCF57',
                    marginLeft: '20px',
                    fontWeight: '600',
                    fontSize: '18px',
                    backgroundColor: '#223870',
                    '&:hover': {
                      backgroundColor: '#223870',
                      color: '#FDCF57 ',
                    },

                    '@media (max-width: 1100px)': {
                      padding: '3px 15px',
                    },
                  }}
                  onClick={handleClickLogout}
                >
                  Logout
                </Button>
              ) : (
                <Fragment>
                  {' '}
                  <Button
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'none',
                      padding: '3px 20px',
                      borderRadius: '3px ',
                      color: '#FDCF57',
                      fontWeight: '600',
                      fontSize: '18px',
                      backgroundColor: '#223870',
                      '&:hover': {
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                      },
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                  >
                    Join
                  </Button>
                  <StyledMenuCss
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                        fontWeight: '500',
                        fontSize: '16px',
                        '&:hover': {
                          backgroundColor: '#223870',
                          color: '#FDCF57 ',
                        },
                      }}
                      onClick={handleClose}
                    >
                      Company
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                        fontWeight: '500',
                        fontSize: '16px',
                        '&:hover': {
                          backgroundColor: '#223870',
                          color: '#FDCF57 ',
                        },
                      }}
                      onClick={handleClose}
                    >
                      Candidate
                    </MenuItem>
                  </StyledMenuCss>
                </Fragment>
              )}
            </ListItemText>
          </ListItem>

          <Divider />
        </List>
      </Drawer>
    </>
  );
}
export default DrawerComponent;
