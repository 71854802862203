export const countryListnew = [
  {
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  },
  {
    id: 1,
    iso2: 'AF',
    country_name: 'Afghanistan',
    iso3: 'AFG',
    phone_code: 93,
  },
  {
    id: 2,
    iso2: 'AL',
    country_name: 'Albania',
    iso3: 'ALB',
    phone_code: 355,
  },
  {
    id: 3,
    iso2: 'DZ',
    country_name: 'Algeria',
    iso3: 'DZA',
    phone_code: 213,
  },
  {
    id: 4,
    iso2: 'AS',
    country_name: 'American Samoa',
    iso3: 'ASM',
    phone_code: 1684,
  },
  {
    id: 5,
    iso2: 'AD',
    country_name: 'Andorra',
    iso3: 'AND',
    phone_code: 376,
  },
  {
    id: 6,
    iso2: 'AO',
    country_name: 'Angola',
    iso3: 'AGO',
    phone_code: 244,
  },
  {
    id: 7,
    iso2: 'AI',
    country_name: 'Anguilla',
    iso3: 'AIA',
    phone_code: 1264,
  },
  {
    id: 8,
    iso2: 'AQ',
    country_name: 'Antarctica',
    iso3: 'ATA',
    phone_code: 672,
  },
  {
    id: 9,
    iso2: 'AG',
    country_name: 'Antigua and Barbuda',
    iso3: 'ATG',
    phone_code: 1200,
  },
  {
    id: 10,
    iso2: 'AR',
    country_name: 'Argentina',
    iso3: 'ARG',
    phone_code: 54,
  },
  {
    id: 11,
    iso2: 'AM',
    country_name: 'Armenia',
    iso3: 'ARM',
    phone_code: 374,
  },
  {
    id: 12,
    iso2: 'AW',
    country_name: 'Aruba',
    iso3: 'ABW',
    phone_code: 297,
  },
  {
    id: 13,
    iso2: 'AU',
    country_name: 'Australia',
    iso3: 'AUS',
    phone_code: 61,
  },
  {
    id: 14,
    iso2: 'AT',
    country_name: 'Austria',
    iso3: 'AUT',
    phone_code: 43,
  },
  {
    id: 15,
    iso2: 'AZ',
    country_name: 'Azerbaijan',
    iso3: 'AZE',
    phone_code: 994,
  },
  {
    id: 16,
    iso2: 'BS',
    country_name: 'Bahamas',
    iso3: 'BHS',
    phone_code: 1242,
  },
  {
    id: 17,
    iso2: 'BH',
    country_name: 'Bahrain',
    iso3: 'BHR',
    phone_code: 973,
  },
  {
    id: 18,
    iso2: 'BD',
    country_name: 'Bangladesh',
    iso3: 'BGD',
    phone_code: 880,
  },
  {
    id: 19,
    iso2: 'BB',
    country_name: 'Barbados',
    iso3: 'BRB',
    phone_code: 1246,
  },
  {
    id: 20,
    iso2: 'BY',
    country_name: 'Belarus',
    iso3: 'BLR',
    phone_code: 375,
  },
  {
    id: 21,
    iso2: 'BE',
    country_name: 'Belgium',
    iso3: 'BEL',
    phone_code: 32,
  },
  {
    id: 22,
    iso2: 'BZ',
    country_name: 'Belize',
    iso3: 'BLZ',
    phone_code: 501,
  },
  {
    id: 23,
    iso2: 'BJ',
    country_name: 'Benin',
    iso3: 'BEN',
    phone_code: 229,
  },
  {
    id: 24,
    iso2: 'BM',
    country_name: 'Bermuda',
    iso3: 'BMU',
    phone_code: 1441,
  },
  {
    id: 25,
    iso2: 'BT',
    country_name: 'Bhutan',
    iso3: 'BTN',
    phone_code: 975,
  },
  {
    id: 26,
    iso2: 'BO',
    country_name: 'Bolivia',
    iso3: 'BOL',
    phone_code: 591,
  },
  {
    id: 27,
    iso2: 'BA',
    country_name: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    phone_code: 387,
  },
  {
    id: 28,
    iso2: 'BW',
    country_name: 'Botswana',
    iso3: 'BWA',
    phone_code: 267,
  },
  {
    id: 29,
    iso2: 'BV',
    country_name: 'Bouvet Island',
    iso3: null,
    phone_code: 0,
  },
  {
    id: 30,
    iso2: 'BR',
    country_name: 'Brazil',
    iso3: 'BRA',
    phone_code: 55,
  },
  {
    id: 31,
    iso2: 'IO',
    country_name: 'British Indian Ocean Territory',
    iso3: null,
    phone_code: 246,
  },
  {
    id: 32,
    iso2: 'BN',
    country_name: 'Brunei Darussalam',
    iso3: 'BRN',
    phone_code: 673,
  },
  {
    id: 33,
    iso2: 'BG',
    country_name: 'Bulgaria',
    iso3: 'BGR',
    phone_code: 359,
  },
  {
    id: 34,
    iso2: 'BF',
    country_name: 'Burkina Faso',
    iso3: 'BFA',
    phone_code: 226,
  },
  {
    id: 35,
    iso2: 'BI',
    country_name: 'Burundi',
    iso3: 'BDI',
    phone_code: 257,
  },
  {
    id: 36,
    iso2: 'KH',
    country_name: 'Cambodia',
    iso3: 'KHM',
    phone_code: 855,
  },
  {
    id: 37,
    iso2: 'CM',
    country_name: 'Cameroon',
    iso3: 'CMR',
    phone_code: 237,
  },
  {
    id: 38,
    iso2: 'CA',
    country_name: 'Canada',
    iso3: 'CAN',
    phone_code: 1,
  },
  {
    id: 39,
    iso2: 'CV',
    country_name: 'Cape Verde',
    iso3: 'CPV',
    phone_code: 238,
  },
  {
    id: 40,
    iso2: 'KY',
    country_name: 'Cayman Islands',
    iso3: 'CYM',
    phone_code: 1345,
  },
  {
    id: 41,
    iso2: 'CF',
    country_name: 'Central African Republic',
    iso3: 'CAF',
    phone_code: 236,
  },
  {
    id: 42,
    iso2: 'TD',
    country_name: 'Chad',
    iso3: 'TCD',
    phone_code: 235,
  },
  {
    id: 43,
    iso2: 'CL',
    country_name: 'Chile',
    iso3: 'CHL',
    phone_code: 56,
  },
  {
    id: 44,
    iso2: 'CN',
    country_name: 'China',
    iso3: 'CHN',
    phone_code: 86,
  },
  {
    id: 45,
    iso2: 'CX',
    country_name: 'Christmas Island',
    iso3: null,
    phone_code: 61,
  },
  {
    id: 46,
    iso2: 'CC',
    country_name: 'Cocos (Keeling) Islands',
    iso3: null,
    phone_code: 672,
  },
  {
    id: 47,
    iso2: 'CO',
    country_name: 'Colombia',
    iso3: 'COL',
    phone_code: 57,
  },
  {
    id: 48,
    iso2: 'KM',
    country_name: 'Comoros',
    iso3: 'COM',
    phone_code: 269,
  },
  {
    id: 49,
    iso2: 'CG',
    country_name: 'Congo',
    iso3: 'COG',
    phone_code: 242,
  },
  {
    id: 50,
    iso2: 'CD',
    country_name: 'Congo, the Democratic Republic of the',
    iso3: 'COD',
    phone_code: 242,
  },
  {
    id: 51,
    iso2: 'CK',
    country_name: 'Cook Islands',
    iso3: 'COK',
    phone_code: 682,
  },
  {
    id: 52,
    iso2: 'CR',
    country_name: 'Costa Rica',
    iso3: 'CRI',
    phone_code: 506,
  },
  {
    id: 53,
    iso2: 'CI',
    country_name: "Cote D'Ivoire",
    iso3: 'CIV',
    phone_code: 225,
  },
  {
    id: 54,
    iso2: 'HR',
    country_name: 'Croatia',
    iso3: 'HRV',
    phone_code: 385,
  },
  {
    id: 55,
    iso2: 'CU',
    country_name: 'Cuba',
    iso3: 'CUB',
    phone_code: 53,
  },
  {
    id: 56,
    iso2: 'CY',
    country_name: 'Cyprus',
    iso3: 'CYP',
    phone_code: 357,
  },
  {
    id: 57,
    iso2: 'CZ',
    country_name: 'Czech Republic',
    iso3: 'CZE',
    phone_code: 420,
  },
  {
    id: 58,
    iso2: 'DK',
    country_name: 'Denmark',
    iso3: 'DNK',
    phone_code: 45,
  },
  {
    id: 59,
    iso2: 'DJ',
    country_name: 'Djibouti',
    iso3: 'DJI',
    phone_code: 253,
  },
  {
    id: 60,
    iso2: 'DM',
    country_name: 'Dominica',
    iso3: 'DMA',
    phone_code: 1767,
  },
  {
    id: 61,
    iso2: 'DO',
    country_name: 'Dominican Republic',
    iso3: 'DOM',
    phone_code: 1809,
  },
  {
    id: 62,
    iso2: 'EC',
    country_name: 'Ecuador',
    iso3: 'ECU',
    phone_code: 593,
  },
  {
    id: 63,
    iso2: 'EG',
    country_name: 'Egypt',
    iso3: 'EGY',
    phone_code: 20,
  },
  {
    id: 64,
    iso2: 'SV',
    country_name: 'El Salvador',
    iso3: 'SLV',
    phone_code: 503,
  },
  {
    id: 65,
    iso2: 'GQ',
    country_name: 'Equatorial Guinea',
    iso3: 'GNQ',
    phone_code: 240,
  },
  {
    id: 66,
    iso2: 'ER',
    country_name: 'Eritrea',
    iso3: 'ERI',
    phone_code: 291,
  },
  {
    id: 67,
    iso2: 'EE',
    country_name: 'Estonia',
    iso3: 'EST',
    phone_code: 372,
  },
  {
    id: 68,
    iso2: 'ET',
    country_name: 'Ethiopia',
    iso3: 'ETH',
    phone_code: 251,
  },
  {
    id: 69,
    iso2: 'FK',
    country_name: 'Falkland Islands (Malvinas)',
    iso3: 'FLK',
    phone_code: 500,
  },
  {
    id: 70,
    iso2: 'FO',
    country_name: 'Faroe Islands',
    iso3: 'FRO',
    phone_code: 298,
  },
  {
    id: 71,
    iso2: 'FJ',
    country_name: 'Fiji',
    iso3: 'FJI',
    phone_code: 679,
  },
  {
    id: 72,
    iso2: 'FI',
    country_name: 'Finland',
    iso3: 'FIN',
    phone_code: 358,
  },
  {
    id: 73,
    iso2: 'FR',
    country_name: 'France',
    iso3: 'FRA',
    phone_code: 33,
  },
  {
    id: 74,
    iso2: 'GF',
    country_name: 'French Guiana',
    iso3: 'GUF',
    phone_code: 594,
  },
  {
    id: 75,
    iso2: 'PF',
    country_name: 'French Polynesia',
    iso3: 'PYF',
    phone_code: 689,
  },
  {
    id: 76,
    iso2: 'TF',
    country_name: 'French Southern Territories',
    iso3: null,
    phone_code: 0,
  },
  {
    id: 77,
    iso2: 'GA',
    country_name: 'Gabon',
    iso3: 'GAB',
    phone_code: 241,
  },
  {
    id: 78,
    iso2: 'GM',
    country_name: 'Gambia',
    iso3: 'GMB',
    phone_code: 220,
  },
  {
    id: 79,
    iso2: 'GE',
    country_name: 'Georgia',
    iso3: 'GEO',
    phone_code: 995,
  },
  {
    id: 80,
    iso2: 'DE',
    country_name: 'Germany',
    iso3: 'DEU',
    phone_code: 49,
  },
  {
    id: 81,
    iso2: 'GH',
    country_name: 'Ghana',
    iso3: 'GHA',
    phone_code: 233,
  },
  {
    id: 82,
    iso2: 'GI',
    country_name: 'Gibraltar',
    iso3: 'GIB',
    phone_code: 350,
  },
  {
    id: 83,
    iso2: 'GR',
    country_name: 'Greece',
    iso3: 'GRC',
    phone_code: 30,
  },
  {
    id: 84,
    iso2: 'GL',
    country_name: 'Greenland',
    iso3: 'GRL',
    phone_code: 299,
  },
  {
    id: 85,
    iso2: 'GD',
    country_name: 'Grenada',
    iso3: 'GRD',
    phone_code: 1473,
  },
  {
    id: 86,
    iso2: 'GP',
    country_name: 'Guadeloupe',
    iso3: 'GLP',
    phone_code: 590,
  },
  {
    id: 87,
    iso2: 'GU',
    country_name: 'Guam',
    iso3: 'GUM',
    phone_code: 1671,
  },
  {
    id: 88,
    iso2: 'GT',
    country_name: 'Guatemala',
    iso3: 'GTM',
    phone_code: 502,
  },
  {
    id: 89,
    iso2: 'GN',
    country_name: 'Guinea',
    iso3: 'GIN',
    phone_code: 224,
  },
  {
    id: 90,
    iso2: 'GW',
    country_name: 'Guinea-Bissau',
    iso3: 'GNB',
    phone_code: 245,
  },
  {
    id: 91,
    iso2: 'GY',
    country_name: 'Guyana',
    iso3: 'GUY',
    phone_code: 592,
  },
  {
    id: 92,
    iso2: 'HT',
    country_name: 'Haiti',
    iso3: 'HTI',
    phone_code: 509,
  },
  {
    id: 93,
    iso2: 'HM',
    country_name: 'Heard Island and Mcdonald Islands',
    iso3: null,
    phone_code: 672,
  },
  {
    id: 94,
    iso2: 'VA',
    country_name: 'Holy See (Vatican City State)',
    iso3: 'VAT',
    phone_code: 39,
  },
  {
    id: 95,
    iso2: 'HN',
    country_name: 'Honduras',
    iso3: 'HND',
    phone_code: 504,
  },
  {
    id: 96,
    iso2: 'HK',
    country_name: 'Hong Kong',
    iso3: 'HKG',
    phone_code: 852,
  },
  {
    id: 97,
    iso2: 'HU',
    country_name: 'Hungary',
    iso3: 'HUN',
    phone_code: 36,
  },
  {
    id: 98,
    iso2: 'IS',
    country_name: 'Iceland',
    iso3: 'ISL',
    phone_code: 354,
  },
  {
    id: 100,
    iso2: 'ID',
    country_name: 'Indonesia',
    iso3: 'IDN',
    phone_code: 62,
  },
  {
    id: 101,
    iso2: 'IR',
    country_name: 'Iran, Islamic Republic of',
    iso3: 'IRN',
    phone_code: 98,
  },
  {
    id: 102,
    iso2: 'IQ',
    country_name: 'Iraq',
    iso3: 'IRQ',
    phone_code: 964,
  },
  {
    id: 103,
    iso2: 'IE',
    country_name: 'Ireland',
    iso3: 'IRL',
    phone_code: 353,
  },
  {
    id: 104,
    iso2: 'IL',
    country_name: 'Israel',
    iso3: 'ISR',
    phone_code: 972,
  },
  {
    id: 105,
    iso2: 'IT',
    country_name: 'Italy',
    iso3: 'ITA',
    phone_code: 39,
  },
  {
    id: 106,
    iso2: 'JM',
    country_name: 'Jamaica',
    iso3: 'JAM',
    phone_code: 1876,
  },
  {
    id: 107,
    iso2: 'JP',
    country_name: 'Japan',
    iso3: 'JPN',
    phone_code: 81,
  },
  {
    id: 108,
    iso2: 'JO',
    country_name: 'Jordan',
    iso3: 'JOR',
    phone_code: 962,
  },
  {
    id: 109,
    iso2: 'KZ',
    country_name: 'Kazakhstan',
    iso3: 'KAZ',
    phone_code: 7,
  },
  {
    id: 110,
    iso2: 'KE',
    country_name: 'Kenya',
    iso3: 'KEN',
    phone_code: 254,
  },
  {
    id: 111,
    iso2: 'KI',
    country_name: 'Kiribati',
    iso3: 'KIR',
    phone_code: 686,
  },
  {
    id: 112,
    iso2: 'KP',
    country_name: "Korea, Democratic People's Republic of",
    iso3: 'PRK',
    phone_code: 850,
  },
  {
    id: 113,
    iso2: 'KR',
    country_name: 'Korea, Republic of',
    iso3: 'KOR',
    phone_code: 82,
  },
  {
    id: 114,
    iso2: 'KW',
    country_name: 'Kuwait',
    iso3: 'KWT',
    phone_code: 965,
  },
  {
    id: 115,
    iso2: 'KG',
    country_name: 'Kyrgyzstan',
    iso3: 'KGZ',
    phone_code: 996,
  },
  {
    id: 116,
    iso2: 'LA',
    country_name: "Lao People's Democratic Republic",
    iso3: 'LAO',
    phone_code: 856,
  },
  {
    id: 117,
    iso2: 'LV',
    country_name: 'Latvia',
    iso3: 'LVA',
    phone_code: 371,
  },
  {
    id: 118,
    iso2: 'LB',
    country_name: 'Lebanon',
    iso3: 'LBN',
    phone_code: 961,
  },
  {
    id: 119,
    iso2: 'LS',
    country_name: 'Lesotho',
    iso3: 'LSO',
    phone_code: 266,
  },
  {
    id: 120,
    iso2: 'LR',
    country_name: 'Liberia',
    iso3: 'LBR',
    phone_code: 231,
  },
  {
    id: 121,
    iso2: 'LY',
    country_name: 'Libyan Arab Jamahiriya',
    iso3: 'LBY',
    phone_code: 218,
  },
  {
    id: 122,
    iso2: 'LI',
    country_name: 'Liechtenstein',
    iso3: 'LIE',
    phone_code: 423,
  },
  {
    id: 123,
    iso2: 'LT',
    country_name: 'Lithuania',
    iso3: 'LTU',
    phone_code: 370,
  },
  {
    id: 124,
    iso2: 'LU',
    country_name: 'Luxembourg',
    iso3: 'LUX',
    phone_code: 352,
  },
  {
    id: 125,
    iso2: 'MO',
    country_name: 'Macao',
    iso3: 'MAC',
    phone_code: 853,
  },
  {
    id: 126,
    iso2: 'MK',
    country_name: 'Macedonia, the Former Yugoslav Republic of',
    iso3: 'MKD',
    phone_code: 389,
  },
  {
    id: 127,
    iso2: 'MG',
    country_name: 'Madagascar',
    iso3: 'MDG',
    phone_code: 261,
  },
  {
    id: 128,
    iso2: 'MW',
    country_name: 'Malawi',
    iso3: 'MWI',
    phone_code: 265,
  },
  {
    id: 129,
    iso2: 'MY',
    country_name: 'Malaysia',
    iso3: 'MYS',
    phone_code: 60,
  },
  {
    id: 130,
    iso2: 'MV',
    country_name: 'Maldives',
    iso3: 'MDV',
    phone_code: 960,
  },
  {
    id: 131,
    iso2: 'ML',
    country_name: 'Mali',
    iso3: 'MLI',
    phone_code: 223,
  },
  {
    id: 132,
    iso2: 'MT',
    country_name: 'Malta',
    iso3: 'MLT',
    phone_code: 356,
  },
  {
    id: 133,
    iso2: 'MH',
    country_name: 'Marshall Islands',
    iso3: 'MHL',
    phone_code: 692,
  },
  {
    id: 134,
    iso2: 'MQ',
    country_name: 'Martinique',
    iso3: 'MTQ',
    phone_code: 596,
  },
  {
    id: 135,
    iso2: 'MR',
    country_name: 'Mauritania',
    iso3: 'MRT',
    phone_code: 222,
  },
  {
    id: 136,
    iso2: 'MU',
    country_name: 'Mauritius',
    iso3: 'MUS',
    phone_code: 230,
  },
  {
    id: 137,
    iso2: 'YT',
    country_name: 'Mayotte',
    iso3: null,
    phone_code: 269,
  },
  {
    id: 138,
    iso2: 'MX',
    country_name: 'Mexico',
    iso3: 'MEX',
    phone_code: 52,
  },
  {
    id: 139,
    iso2: 'FM',
    country_name: 'Micronesia, Federated States of',
    iso3: 'FSM',
    phone_code: 691,
  },
  {
    id: 140,
    iso2: 'MD',
    country_name: 'Moldova, Republic of',
    iso3: 'MDA',
    phone_code: 373,
  },
  {
    id: 141,
    iso2: 'MC',
    country_name: 'Monaco',
    iso3: 'MCO',
    phone_code: 377,
  },
  {
    id: 142,
    iso2: 'MN',
    country_name: 'Mongolia',
    iso3: 'MNG',
    phone_code: 976,
  },
  {
    id: 143,
    iso2: 'MS',
    country_name: 'Montserrat',
    iso3: 'MSR',
    phone_code: 1664,
  },
  {
    id: 144,
    iso2: 'MA',
    country_name: 'Morocco',
    iso3: 'MAR',
    phone_code: 212,
  },
  {
    id: 145,
    iso2: 'MZ',
    country_name: 'Mozambique',
    iso3: 'MOZ',
    phone_code: 258,
  },
  {
    id: 146,
    iso2: 'MM',
    country_name: 'Myanmar',
    iso3: 'MMR',
    phone_code: 95,
  },
  {
    id: 147,
    iso2: 'NA',
    country_name: 'Namibia',
    iso3: 'NAM',
    phone_code: 264,
  },
  {
    id: 148,
    iso2: 'NR',
    country_name: 'Nauru',
    iso3: 'NRU',
    phone_code: 674,
  },
  {
    id: 149,
    iso2: 'NP',
    country_name: 'Nepal',
    iso3: 'NPL',
    phone_code: 977,
  },
  {
    id: 150,
    iso2: 'NL',
    country_name: 'Netherlands',
    iso3: 'NLD',
    phone_code: 31,
  },
  {
    id: 151,
    iso2: 'AN',
    country_name: 'Netherlands Antilles',
    iso3: 'ANT',
    phone_code: 599,
  },
  {
    id: 152,
    iso2: 'NC',
    country_name: 'New Caledonia',
    iso3: 'NCL',
    phone_code: 687,
  },
  {
    id: 153,
    iso2: 'NZ',
    country_name: 'New Zealand',
    iso3: 'NZL',
    phone_code: 64,
  },
  {
    id: 154,
    iso2: 'NI',
    country_name: 'Nicaragua',
    iso3: 'NIC',
    phone_code: 505,
  },
  {
    id: 155,
    iso2: 'NE',
    country_name: 'Niger',
    iso3: 'NER',
    phone_code: 227,
  },
  {
    id: 156,
    iso2: 'NG',
    country_name: 'Nigeria',
    iso3: 'NGA',
    phone_code: 234,
  },
  {
    id: 157,
    iso2: 'NU',
    country_name: 'Niue',
    iso3: 'NIU',
    phone_code: 683,
  },
  {
    id: 158,
    iso2: 'NF',
    country_name: 'Norfolk Island',
    iso3: 'NFK',
    phone_code: 672,
  },
  {
    id: 159,
    iso2: 'MP',
    country_name: 'Northern Mariana Islands',
    iso3: 'MNP',
    phone_code: 1670,
  },
  {
    id: 160,
    iso2: 'NO',
    country_name: 'Norway',
    iso3: 'NOR',
    phone_code: 47,
  },
  {
    id: 161,
    iso2: 'OM',
    country_name: 'Oman',
    iso3: 'OMN',
    phone_code: 968,
  },
  {
    id: 162,
    iso2: 'PK',
    country_name: 'Pakistan',
    iso3: 'PAK',
    phone_code: 92,
  },
  {
    id: 163,
    iso2: 'PW',
    country_name: 'Palau',
    iso3: 'PLW',
    phone_code: 680,
  },
  {
    id: 164,
    iso2: 'PS',
    country_name: 'Palestinian Territory, Occupied',
    iso3: null,
    phone_code: 970,
  },
  {
    id: 165,
    iso2: 'PA',
    country_name: 'Panama',
    iso3: 'PAN',
    phone_code: 507,
  },
  {
    id: 166,
    iso2: 'PG',
    country_name: 'Papua New Guinea',
    iso3: 'PNG',
    phone_code: 675,
  },
  {
    id: 167,
    iso2: 'PY',
    country_name: 'Paraguay',
    iso3: 'PRY',
    phone_code: 595,
  },
  {
    id: 168,
    iso2: 'PE',
    country_name: 'Peru',
    iso3: 'PER',
    phone_code: 51,
  },
  {
    id: 169,
    iso2: 'PH',
    country_name: 'Philippines',
    iso3: 'PHL',
    phone_code: 63,
  },
  {
    id: 170,
    iso2: 'PN',
    country_name: 'Pitcairn',
    iso3: 'PCN',
    phone_code: 64,
  },
  {
    id: 171,
    iso2: 'PL',
    country_name: 'Poland',
    iso3: 'POL',
    phone_code: 48,
  },
  {
    id: 172,
    iso2: 'PT',
    country_name: 'Portugal',
    iso3: 'PRT',
    phone_code: 351,
  },
  {
    id: 173,
    iso2: 'PR',
    country_name: 'Puerto Rico',
    iso3: 'PRI',
    phone_code: 1787,
  },
  {
    id: 174,
    iso2: 'QA',
    country_name: 'Qatar',
    iso3: 'QAT',
    phone_code: 974,
  },
  {
    id: 175,
    iso2: 'RE',
    country_name: 'Reunion',
    iso3: 'REU',
    phone_code: 262,
  },
  {
    id: 176,
    iso2: 'RO',
    country_name: 'Romania',
    iso3: 'ROM',
    phone_code: 40,
  },
  {
    id: 177,
    iso2: 'RU',
    country_name: 'Russian Federation',
    iso3: 'RUS',
    phone_code: 70,
  },
  {
    id: 178,
    iso2: 'RW',
    country_name: 'Rwanda',
    iso3: 'RWA',
    phone_code: 250,
  },
  {
    id: 179,
    iso2: 'SH',
    country_name: 'Saint Helena',
    iso3: 'SHN',
    phone_code: 290,
  },
  {
    id: 180,
    iso2: 'KN',
    country_name: 'Saint Kitts and Nevis',
    iso3: 'KNA',
    phone_code: 1869,
  },
  {
    id: 181,
    iso2: 'LC',
    country_name: 'Saint Lucia',
    iso3: 'LCA',
    phone_code: 1758,
  },
  {
    id: 182,
    iso2: 'PM',
    country_name: 'Saint Pierre and Miquelon',
    iso3: 'SPM',
    phone_code: 508,
  },
  {
    id: 183,
    iso2: 'VC',
    country_name: 'Saint Vincent and the Grenadines',
    iso3: 'VCT',
    phone_code: 1784,
  },
  {
    id: 184,
    iso2: 'WS',
    country_name: 'Samoa',
    iso3: 'WSM',
    phone_code: 684,
  },
  {
    id: 185,
    iso2: 'SM',
    country_name: 'San Marino',
    iso3: 'SMR',
    phone_code: 378,
  },
  {
    id: 186,
    iso2: 'ST',
    country_name: 'Sao Tome and Principe',
    iso3: 'STP',
    phone_code: 239,
  },
  {
    id: 187,
    iso2: 'SA',
    country_name: 'Saudi Arabia',
    iso3: 'SAU',
    phone_code: 966,
  },
  {
    id: 188,
    iso2: 'SN',
    country_name: 'Senegal',
    iso3: 'SEN',
    phone_code: 221,
  },
  {
    id: 189,
    iso2: 'CS',
    country_name: 'Serbia and Montenegro',
    iso3: null,
    phone_code: 381,
  },
  {
    id: 190,
    iso2: 'SC',
    country_name: 'Seychelles',
    iso3: 'SYC',
    phone_code: 248,
  },
  {
    id: 191,
    iso2: 'SL',
    country_name: 'Sierra Leone',
    iso3: 'SLE',
    phone_code: 232,
  },
  {
    id: 192,
    iso2: 'SG',
    country_name: 'Singapore',
    iso3: 'SGP',
    phone_code: 65,
  },
  {
    id: 193,
    iso2: 'SK',
    country_name: 'Slovakia',
    iso3: 'SVK',
    phone_code: 421,
  },
  {
    id: 194,
    iso2: 'SI',
    country_name: 'Slovenia',
    iso3: 'SVN',
    phone_code: 386,
  },
  {
    id: 195,
    iso2: 'SB',
    country_name: 'Solomon Islands',
    iso3: 'SLB',
    phone_code: 677,
  },
  {
    id: 196,
    iso2: 'SO',
    country_name: 'Somalia',
    iso3: 'SOM',
    phone_code: 252,
  },
  {
    id: 197,
    iso2: 'ZA',
    country_name: 'South Africa',
    iso3: 'ZAF',
    phone_code: 27,
  },
  {
    id: 198,
    iso2: 'GS',
    country_name: 'South Georgia and the South Sandwich Islands',
    iso3: null,
    phone_code: 500,
  },
  {
    id: 199,
    iso2: 'ES',
    country_name: 'Spain',
    iso3: 'ESP',
    phone_code: 34,
  },
  {
    id: 200,
    iso2: 'LK',
    country_name: 'Sri Lanka',
    iso3: 'LKA',
    phone_code: 94,
  },
  {
    id: 201,
    iso2: 'SD',
    country_name: 'Sudan',
    iso3: 'SDN',
    phone_code: 249,
  },
  {
    id: 202,
    iso2: 'SR',
    country_name: 'Suriname',
    iso3: 'SUR',
    phone_code: 597,
  },
  {
    id: 203,
    iso2: 'SJ',
    country_name: 'Svalbard and Jan Mayen',
    iso3: 'SJM',
    phone_code: 47,
  },
  {
    id: 204,
    iso2: 'SZ',
    country_name: 'Swaziland',
    iso3: 'SWZ',
    phone_code: 268,
  },
  {
    id: 205,
    iso2: 'SE',
    country_name: 'Sweden',
    iso3: 'SWE',
    phone_code: 46,
  },
  {
    id: 206,
    iso2: 'CH',
    country_name: 'Switzerland',
    iso3: 'CHE',
    phone_code: 41,
  },
  {
    id: 207,
    iso2: 'SY',
    country_name: 'Syrian Arab Republic',
    iso3: 'SYR',
    phone_code: 963,
  },
  {
    id: 208,
    iso2: 'TW',
    country_name: 'Taiwan, Province of China',
    iso3: 'TWN',
    phone_code: 886,
  },
  {
    id: 209,
    iso2: 'TJ',
    country_name: 'Tajikistan',
    iso3: 'TJK',
    phone_code: 992,
  },
  {
    id: 210,
    iso2: 'TZ',
    country_name: 'Tanzania, United Republic of',
    iso3: 'TZA',
    phone_code: 255,
  },
  {
    id: 211,
    iso2: 'TH',
    country_name: 'Thailand',
    iso3: 'THA',
    phone_code: 66,
  },
  {
    id: 212,
    iso2: 'TL',
    country_name: 'Timor-Leste',
    iso3: null,
    phone_code: 670,
  },
  {
    id: 213,
    iso2: 'TG',
    country_name: 'Togo',
    iso3: 'TGO',
    phone_code: 228,
  },
  {
    id: 214,
    iso2: 'TK',
    country_name: 'Tokelau',
    iso3: 'TKL',
    phone_code: 690,
  },
  {
    id: 215,
    iso2: 'TO',
    country_name: 'Tonga',
    iso3: 'TON',
    phone_code: 676,
  },
  {
    id: 216,
    iso2: 'TT',
    country_name: 'Trinidad and Tobago',
    iso3: 'TTO',
    phone_code: 1868,
  },
  {
    id: 217,
    iso2: 'TN',
    country_name: 'Tunisia',
    iso3: 'TUN',
    phone_code: 216,
  },
  {
    id: 218,
    iso2: 'TR',
    country_name: 'Turkey',
    iso3: 'TUR',
    phone_code: 90,
  },
  {
    id: 219,
    iso2: 'TM',
    country_name: 'Turkmenistan',
    iso3: 'TKM',
    phone_code: 7370,
  },
  {
    id: 220,
    iso2: 'TC',
    country_name: 'Turks and Caicos Islands',
    iso3: 'TCA',
    phone_code: 1649,
  },
  {
    id: 221,
    iso2: 'TV',
    country_name: 'Tuvalu',
    iso3: 'TUV',
    phone_code: 688,
  },
  {
    id: 222,
    iso2: 'UG',
    country_name: 'Uganda',
    iso3: 'UGA',
    phone_code: 256,
  },
  {
    id: 223,
    iso2: 'UA',
    country_name: 'Ukraine',
    iso3: 'UKR',
    phone_code: 380,
  },
  {
    id: 224,
    iso2: 'AE',
    country_name: 'United Arab Emirates',
    iso3: 'ARE',
    phone_code: 971,
  },
  {
    id: 225,
    iso2: 'GB',
    country_name: 'United Kingdom',
    iso3: 'GBR',
    phone_code: 44,
  },
  {
    id: 226,
    iso2: 'US',
    country_name: 'United States',
    iso3: 'USA',
    phone_code: 1,
  },
  {
    id: 227,
    iso2: 'UM',
    country_name: 'United States Minor Outlying Islands',
    iso3: null,
    phone_code: 1,
  },
  {
    id: 228,
    iso2: 'UY',
    country_name: 'Uruguay',
    iso3: 'URY',
    phone_code: 598,
  },
  {
    id: 229,
    iso2: 'UZ',
    country_name: 'Uzbekistan',
    iso3: 'UZB',
    phone_code: 998,
  },
  {
    id: 230,
    iso2: 'VU',
    country_name: 'Vanuatu',
    iso3: 'VUT',
    phone_code: 678,
  },
  {
    id: 231,
    iso2: 'VE',
    country_name: 'Venezuela',
    iso3: 'VEN',
    phone_code: 58,
  },
  {
    id: 232,
    iso2: 'VN',
    country_name: 'Viet Nam',
    iso3: 'VNM',
    phone_code: 84,
  },
  {
    id: 233,
    iso2: 'VG',
    country_name: 'Virgin Islands, British',
    iso3: 'VGB',
    phone_code: 1284,
  },
  {
    id: 234,
    iso2: 'VI',
    country_name: 'Virgin Islands, U.s.',
    iso3: 'VIR',
    phone_code: 1340,
  },
  {
    id: 235,
    iso2: 'WF',
    country_name: 'Wallis and Futuna',
    iso3: 'WLF',
    phone_code: 681,
  },
  {
    id: 236,
    iso2: 'EH',
    country_name: 'Western Sahara',
    iso3: 'ESH',
    phone_code: 212,
  },
  {
    id: 237,
    iso2: 'YE',
    country_name: 'Yemen',
    iso3: 'YEM',
    phone_code: 967,
  },
  {
    id: 238,
    iso2: 'ZM',
    country_name: 'Zambia',
    iso3: 'ZMB',
    phone_code: 260,
  },
  {
    id: 239,
    iso2: 'ZW',
    country_name: 'Zimbabwe',
    iso3: 'ZWE',
    phone_code: 263,
  },
  {
    id: 240,
    iso2: 'IN',
    country_name: 'India',
    iso3: 'IND',
    phone_code: 91,
  },
];
export const industryList = [
  { label: '1', value: 'Startup / VCs/ PEs' },
  { label: '2', value: 'Real Estate / Construction' },
  { label: '3', value: 'Professional Services' },
  { label: '4', value: 'Not for profit / NGO' },
  { label: '5', value: 'Media / Entertainment ' },
  { label: '6', value: 'Logistics / Transportation' },
  { label: '7', value: 'IT & ITES' },
  { label: '8', value: 'Industrial / Manufacturing' },
  { label: '9', value: 'Healthcare / Pharma' },
  { labe: '10', value: 'FMCG / Retail' },
  { label: '11', value: 'Energy / Oil & Gas' },
  { label: '12', value: 'Not for profit / NGO' },
  { label: '13', value: 'BFSI' },
  { label: '14', value: 'Agriculture and allied services' },
  { label: '15', value: 'Tourism & Hospitality' },
];
export const functionList = [
  { label: '1', value: 'HR and Administration' },
  { label: '2', value: 'Accounting and Finance' },
  { label: '3', value: 'General Management and Operations' },
  { label: '4', value: 'Production' },
  { label: '5', value: 'Maintenance' },
  { label: '6', value: 'Quality' },
  { label: '7', value: 'Supply Chain and Purchase' },
  { label: '8', value: 'Sales, Marketing & Service' },
  { label: '9', value: 'R&D and Engineering' },
  { label: '10', value: 'Legal and Secretarial' },
  { label: '11', value: 'Project Engineering and Construction' },
  { label: '12', value: 'Information Technology' },
];
export const skillsData = [
  { _id: '628b0f5b9e0fa1308f447521', skills: 'java scripts' },
  { _id: '628b0f5b9e0fa1309f447521', skills: 'java' },
  { _id: '628b0f5b9e0fa1310f447521', skills: 'React' },
];
export const locationData = [
  { _id: '628b0f5b9e0fa1308f447521', location: 'Kolkatta' },
  { _id: '628b0f5b9e0fa1309f447521', location: 'Delhi' },
  { _id: '628b0f5b9e0fa1310f447521', location: 'Bengaluru' },
  { _id: '628b0f5b9e0fa1310f447521', location: 'Mumbai' },
];
export const industryList1 = [
  { _id: '628b0f5b9e0fa1306f447521', industry: 'Startup / VCs/ PEs' },
  { _id: '628b0f5b9e0fa1306f447522', industry: 'Real Estate / Construction' },
  { _id: '628b0f5b9e0fa1306f447523', industry: 'Professional Slabelvices' },
  { _id: '628b0f5b9e0fa1306f447524', industry: 'Not for profit / NGO' },
  { _id: '628b0f5b9e0fa1306f447525', industry: 'Media / Entertainment' },
  { _id: '628b0f5b9e0fa1306f447526', industry: 'Logistics / Transportation' },
  { _id: '628b0f5b9e0fa1306f447527', industry: 'IT/ITES' },
  { _id: '628b0f5b9e0fa1306f447528', industry: 'Industrial / Manufacturing' },
  { _id: '628b0f5b9e0fa1306f447529', industry: 'Healthcare / Pharma' },
  { _id: '628b0f5b9e0fa1306f447510', industry: 'FMCG / Retail' },
  { _id: '628b0f5b9e0fa1306f447511', industry: 'Enlabelgy / Oil & Gas' },
  { _id: '628b0f5b9e0fa1306f447512', industry: ' Education / Training ' },
  { _id: '628b0f5b9e0fa1306f447513', industry: 'E commce' },
  { _id: '628b0f5b9e0fa1306f447514', industry: 'Confidential' },
  { _id: '628b0f5b9e0fa1306f447515', industry: 'BFSI' },
  {
    _id: '628b0f5b9e0fa1306f447516',
    industry: 'Agriculture and allied services',
  },
  { _id: '628b0f5b9e0fa1306f447517', industry: 'Tourism & Hospitality' },
];
export const functionList1 = [
  {
    _id: '728b0f5b9e0fa1306f447521',
    function: 'HR and Administration',
  },
  { _id: '728b0f5b9e0fa1306f447522', function: 'Accounting and Finance' },
  {
    _id: '728b0f5b9e0fa1306f447523',
    function: 'General Management and Operations',
  },
  { _id: '728b0f5b9e0fa1306f447524', function: 'Production' },
  { _id: '728b0f5b9e0fa1306f447525', function: 'Maintenance' },
  { _id: '728b0f5b9e0fa1306f447526', function: 'Quality' },
  { _id: '728b0f5b9e0fa1306f447527', function: 'Supply Chain and Purchase' },
  { _id: '728b0f5b9e0fa1306f447528', function: 'Sales, Marketing & Service' },
  { _id: '728b0f5b9e0fa1306f447529', function: 'R&D and Engineering' },
  { _id: '728b0f5b9e0fa1306f447510', function: 'Legal and Secretarial' },
  {
    _id: '728b0f5b9e0fa1306f447511',
    function: 'Project Engineering and Construction',
  },
  { _id: '728b0f5b9e0fa1306f447512', function: 'Information Technology' },
];

export const Size = [
  { label: '828b0f5b9e0fa1306f447521', value: '0 - 10 Employees' },
  { label: '828b0f5b9e0fa1306f447522', value: '11 - 25 Employees' },
  { label: '828b0f5b9e0fa1306f447523', value: '26 - 51 Employees' },
  { label: '828b0f5b9e0fa1306f447524', value: '51 - 100 Employees' },
  { label: '828b0f5b9e0fa1306f447525', value: '101 - 200 Employees' },
  { label: '828b0f5b9e0fa1306f447526', value: '201 - 500 Employees' },
  { label: '828b0f5b9e0fa1306f447527', value: '2501 - 1000 Employees' },
  { label: '828b0f5b9e0fa1306f447528', value: '> 1000+ Employees' },
];

export const educationlist = [
  { _id: '838b0f5b9e0fa1306f447521', value: 'B. Tech' },
  { _id: '838b0f5b9e0fa1306f447522', value: 'M. Tech' },
  { _id: '838b0f5b9e0fa1306f447523', value: 'Phd' },
  { _id: '838b0f5b9e0fa1306f447524', value: 'MBA' },
  { _id: '838b0f5b9e0fa1306f447525', value: 'Bsc' },
  { _id: '838b0f5b9e0fa1306f447526', value: 'CA' },
  { _id: '838b0f5b9e0fa1306f447527', value: 'Graduate' },
  { _id: '838b0f5b9e0fa1306f447528', value: 'Post Graduate' },
];

export const month3 = [
  { _id: '838b0f5b9e0fa1406f447521', value: 'January' },
  { _id: '838b0f5b9e0fa1406f447522', value: 'February' },
  { _id: '838b0f5b9e0fa1406f447523', value: 'March' },
  { _id: '838b0f5b9e0fa1406f447524', value: 'April' },
  { _id: '838b0f5b9e0fa1406f447525', value: 'May' },
  { _id: '838b0f5b9e0fa1406f447526', value: 'June' },
  { _id: '838b0f5b9e0fa1406f447527', value: 'July' },
  { _id: '838b0f5b9e0fa1406f447528', value: 'August' },
  { _id: '838b0f5b9e0fa1406f447529', value: 'September' },
  { _id: '838b0f5b9e0fa1406f447530', value: 'October' },
  { _id: '838b0f5b9e0fa1406f447531', value: 'November' },
  { _id: '838b0f5b9e0fa1406f447532', value: 'December' },
];

export const month = [
  { _id: 1, value: 'January' },
  { _id: 2, value: 'February' },
  { _id: 3, value: 'March' },
  { _id: 4, value: 'April' },
  { _id: 5, value: 'May' },
  { _id: 6, value: 'June' },
  { _id: 7, value: 'July' },
  { _id: 8, value: 'August' },
  { _id: 9, value: 'September' },
  { _id: 10, value: 'October' },
  { _id: 11, value: 'November' },
  { _id: 12, value: 'December' },
  { _id: 0, value: ' ' },
];
export const Type = [
  { label: '928b0f5b9e0fa1306f447521', value: 'Private Limited' },
  { label: '928b0f5b9e0fa1306f447522', value: 'Public Limited Company' },
  { label: '928b0f5b9e0fa1306f447523', value: 'Partnerships' },
  { label: '928b0f5b9e0fa1306f447524', value: 'Limited Liability Partnership' },
  { label: '928b0f5b9e0fa1306f447525', value: 'One Person Company' },
  { label: '928b0f5b9e0fa1306f447526', value: 'Sole Proprietorship' },
  { label: '928b0f5b9e0fa1306f447527', value: 'Section 8 Company' },
];
export const typet = [
  { label: '925b0f5b9e0fa1306f447521', value: 'Full Time' },
  { label: '925b0f5b9e0fa1306f447522', value: 'Part Time' },
  { label: '925b0f5b9e0fa1306f447523', value: 'Consulting' },
];
export const minSalary = [
  { label: '0', value: '0 lpa' },
  { label: '1', value: '2 lpa' },
  { label: '2', value: '4 lpa' },
  { label: '3', value: '6 lpa' },
  { label: '4', value: '8 lpa' },
  { label: '5', value: '10 lpa' },
  { label: '6', value: '12 lpa' },
  { label: '7', value: '14 lpa' },
  { label: '8', value: '16 lpa' },
  { label: '9', value: '18 lpa' },
  { label: '10', value: '20 lpa' },
  { label: '11', value: '22 lpa' },
  { label: '12', value: '24 lpa' },
  { label: '13', value: '26 lpa' },
  { label: '14', value: '28 lpa' },
  { label: '15', value: '30 lpa' },
  { label: '16', value: '32 lpa' },
  { label: '17', value: '34 lpa' },
  { label: '18', value: '36 lpa' },
  { label: '19', value: '38 lpa' },
  { label: '20', value: '40 lpa' },
  { label: '21', value: '42 lpa' },
  { label: '22', value: '44 lpa' },
  { label: '23', value: '46 lpa' },
  { label: '24', value: '48 lpa' },
  { label: '25', value: '50 lpa' },
  { label: '26', value: '50+ lpa' },
];
export const maxSalary = [
  { label: '0', value: '0 lpa' },
  { label: '1', value: '2 lpa' },
  { label: '2', value: '4 lpa' },
  { label: '3', value: '6 lpa' },
  { label: '4', value: '8 lpa' },
  { label: '5', value: '10 lpa' },
  { label: '6', value: '12 lpa' },
  { label: '7', value: '14 lpa' },
  { label: '8', value: '16 lpa' },
  { label: '9', value: '18 lpa' },
  { label: '10', value: '20 lpa' },
  { label: '11', value: '22 lpa' },
  { label: '12', value: '24 lpa' },
  { label: '13', value: '26 lpa' },
  { label: '14', value: '28 lpa' },
  { label: '15', value: '30 lpa' },
  { label: '16', value: '32 lpa' },
  { label: '17', value: '34 lpa' },
  { label: '18', value: '36 lpa' },
  { label: '19', value: '38 lpa' },
  { label: '20', value: '40 lpa' },
  { label: '21', value: '42 lpa' },
  { label: '22', value: '44 lpa' },
  { label: '23', value: '46 lpa' },
  { label: '24', value: '48 lpa' },
  { label: '25', value: '50 lpa' },
  { label: '26', value: '50+ lpa' },
];
export const Experience = [
  { label: '0', value: '0 Year' },
  { label: '1', value: '1 Year' },
  { label: '2', value: '2 Years' },
  { label: '3', value: '3 Years' },
  { label: '4', value: '4 Years' },
  { label: '5', value: '5 Years' },
  { label: '6', value: '6 Years' },
  { label: '7', value: '7 Years' },
  { label: '8', value: '8 Years' },
  { label: '9', value: '9 Years' },
  { label: '10', value: '10 Years' },
  { label: '11', value: '11 Years' },
  { label: '12', value: '12 Years' },
  { label: '13', value: '13 Years' },
  { label: '14', value: '14 Years' },
  { label: '15', value: '15+ Years' },
];
export const Years = [
  { label: '0', value: '0-1 Year' },
  { label: '1', value: '1-2 Years' },
  { label: '2', value: '2-3 Years' },
  { label: '3', value: '3-4 Years' },
  { label: '4', value: '4-5 Years' },
  { label: '5', value: '5-6 Years' },
  { label: '6', value: '6-7 Years' },
  { label: '7', value: '7-8 Years' },
  { label: '8', value: '8-9 Years' },
  { label: '9', value: '9-10 Years' },
  { label: '10', value: '10-12 Years' },
  { label: '11', value: '12-14 Years' },
  { label: '12', value: '14-15 Years' },
  { label: '13', value: '15-20 Years' },
  { label: '14', value: '20-25 Years' },
  { label: '15', value: '25+ Years' },
];

export const Months = [
  { label: '0', value: '0 Month' },
  { label: '1', value: '1 Month' },
  { label: '2', value: '2 Months' },
  { label: '3', value: '3 Months' },
  { label: '4', value: '4 Months' },
  { label: '5', value: '5 Months' },
  { label: '6', value: '6 Months' },
  { label: '7', value: '7 Months' },
  { label: '8', value: '8 Months' },
  { label: '9', value: '9 Months' },
  { label: '10', value: '10 Months' },
  { label: '11', value: '11 Months' },
];
export const NoticePeriod = [
  {
    value: 0,
    label: 'Currently serving notice period',
  },
  { value: 1, label: '1 Month' },
  { value: 2, label: '2 Months' },
  { value: 3, label: '3 Months' },
  { value: 4, label: 'More than 3 Months' },
];
export const passing_year_list_iit = [
  { label: '1960', value: '1960' },
  { label: '1961', value: '1961' },
  { label: '1962', value: '1962' },
  { label: '1963', value: '1963' },
  { label: '1964', value: '1964' },
  { label: '1965', value: '1965' },
  { label: '1966', value: '1966' },
  { label: '1967', value: '1967' },
  { label: '1968', value: '1968' },
  { label: '1969', value: '1969' },
  { label: '1970', value: '1970' },
  { label: '1971', value: '1971' },
  { label: '1972', value: '1972' },
  { label: '1973', value: '1973' },
  { label: '1974', value: '1974' },
  { label: '1975', value: '1975' },
  { label: '1976', value: '1976' },
  { label: '1977', value: '1977' },
  { label: '1978', value: '1978' },
  { label: '1979', value: '1979' },
  { label: '1980', value: '1980' },
  { label: '1981', value: '1981' },
  { label: '1982', value: '1982' },
  { label: '1983', value: '1983' },
  { label: '1984', value: '1984' },
  { label: '1985', value: '1985' },
  { label: '1986', value: '1986' },
  { label: '1987', value: '1987' },
  { label: '1988', value: '1988' },
  { label: '1989', value: '1989' },
  { label: '1990', value: '1990' },
  { label: '1991', value: '1991' },
  { label: '1992', value: '1992' },
  { label: '1993', value: '1993' },
  { label: '1994', value: '1994' },
  { label: '1995', value: '1995' },
  { label: '1996', value: '1996' },
  { label: '1997', value: '1997' },
  { label: '1998', value: '1998' },
  { label: '1999', value: '1999' },
  { label: '2000', value: '2000' },
  { label: '2001', value: '2001' },
  { label: '2002', value: '2002' },
  { label: '2003', value: '2003' },
  { label: '2004', value: '2004' },
  { label: '2005', value: '2005' },
  { label: '2006', value: '2006' },
  { label: '2007', value: '2007' },
  { label: '2008', value: '2008' },
  { label: '2009', value: '2009' },
  { label: '2010', value: '2010' },
  { label: '2011', value: '2011' },
  { label: '2012', value: '2012' },
  { label: '2013', value: '2013' },
  { label: '2014', value: '2014' },
  { label: '2015', value: '2015' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: 'Present', value: 'Present' },
];

export const passing_year_list = [
  { label: '0', value: '0' },
  { label: '1960', value: '1960' },
  { label: '1961', value: '1961' },
  { label: '1962', value: '1962' },
  { label: '1963', value: '1963' },
  { label: '1964', value: '1964' },
  { label: '1965', value: '1965' },
  { label: '1966', value: '1966' },
  { label: '1967', value: '1967' },
  { label: '1968', value: '1968' },
  { label: '1969', value: '1969' },
  { label: '1970', value: '1970' },
  { label: '1971', value: '1971' },
  { label: '1972', value: '1972' },
  { label: '1973', value: '1973' },
  { label: '1974', value: '1974' },
  { label: '1975', value: '1975' },
  { label: '1976', value: '1976' },
  { label: '1977', value: '1977' },
  { label: '1978', value: '1978' },
  { label: '1979', value: '1979' },
  { label: '1980', value: '1980' },
  { label: '1981', value: '1981' },
  { label: '1982', value: '1982' },
  { label: '1983', value: '1983' },
  { label: '1984', value: '1984' },
  { label: '1985', value: '1985' },
  { label: '1986', value: '1986' },
  { label: '1987', value: '1987' },
  { label: '1988', value: '1988' },
  { label: '1989', value: '1989' },
  { label: '1990', value: '1990' },
  { label: '1991', value: '1991' },
  { label: '1992', value: '1992' },
  { label: '1993', value: '1993' },
  { label: '1994', value: '1994' },
  { label: '1995', value: '1995' },
  { label: '1996', value: '1996' },
  { label: '1997', value: '1997' },
  { label: '1998', value: '1998' },
  { label: '1999', value: '1999' },
  { label: '2000', value: '2000' },
  { label: '2001', value: '2001' },
  { label: '2002', value: '2002' },
  { label: '2003', value: '2003' },
  { label: '2004', value: '2004' },
  { label: '2005', value: '2005' },
  { label: '2006', value: '2006' },
  { label: '2007', value: '2007' },
  { label: '2008', value: '2008' },
  { label: '2009', value: '2009' },
  { label: '2010', value: '2010' },
  { label: '2011', value: '2011' },
  { label: '2012', value: '2012' },
  { label: '2013', value: '2013' },
  { label: '2014', value: '2014' },
  { label: '2015', value: '2015' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: 'Currently Persuing', value: '9999' },
];

export const countryCodeList = [
  { value: '+91', label: 'India' },
  { value: '+93', label: 'Afghanistan' },
  { value: '+355', label: 'Albania' },
  { value: '+213', label: 'Algeria' },
  { value: '+1684', label: 'American Samoa' },
  { value: '+376', label: 'Andorra' },
  { value: '+1264', label: 'Anguilla' },
  { value: '+672', label: 'Antarctica' },
  { value: '+1200', label: 'Antigua and Barbuda' },
  { value: '+54', label: 'Argentina' },
  { value: '+374', label: 'Armenia' },
  { value: '+297', label: 'Aruba' },
  { value: '+61', label: 'Australia' },
  { value: '+43', label: 'Austria' },
  { value: '+994', label: 'Azerbaijan' },
  { value: '+1242', label: 'Bahamas' },
  { value: '+973', label: 'Bahrain' },
  { value: '+880', label: 'Bangladesh' },
  { value: '+1246', label: 'Barbados' },
  { value: '+375', label: 'Belarus' },
  { value: '+32', label: 'Belgium' },
  { value: '+501', label: 'Belize' },
  { value: '+229', label: 'Benin' },
  { value: '+1441', label: 'Bermuda' },
  { value: '+975', label: 'Bhutan' },
  { value: '+591', label: 'Bolivia' },
  { value: '+387', label: 'Bosnia and Herzegovina' },
  { value: '+267', label: 'Botswana' },
  { value: '+55', label: 'Brazil' },
  { value: '+246', label: 'British Indian Ocean Territory' },
  { value: '+1284', label: 'British Virgin Islands' },
  { value: '+673', label: 'Brunei' },
  { value: '+359', label: 'Bulgaria' },
  { value: '+226', label: 'Burkina Faso' },
  { value: '+257', label: 'Burundi' },
  { value: '+855', label: 'Cambodia' },
  { value: '+237', label: 'Cameroon' },
  { value: '+1', label: 'Canada' },
  { value: '+238', label: 'Cape Verde' },
  { value: '+1345', label: 'Cayman Islands' },
  { value: '+236', label: 'Central African Republic' },
  { value: '+235', label: 'Chad' },
  { value: '+56', label: 'Chile' },
  { value: '+86', label: 'China' },
  { value: '+61', label: 'Christmas Island' },
  { value: '+61', label: 'Cocos Islands' },
  { value: '+57', label: 'Colombia' },
  { value: '+269', label: 'Comoros' },
  { value: '+682', label: 'Cook Islands' },
  { value: '+506', label: 'Costa Rica' },
  { value: '+385', label: 'Croatia' },
  { value: '+53', label: 'Cuba' },
  { value: '+599', label: 'Curacao' },
  { value: '+357', label: 'Cyprus' },
  { value: '+420', label: 'Czech Republic' },
  { value: '+243', label: 'Democratic Republic of the Congo' },
  { value: '+45', label: 'Denmark' },
  { value: '+253', label: 'Djibouti' },
  { value: '+1767', label: 'Dominica' },
  { value: '+1809', label: 'Dominican Republic' },
  { value: '+1829', label: 'Dominican Republic' },
  { value: '+1849', label: 'Dominican Republic' },
  { value: '+670', label: 'East Timor' },
  { value: '+593', label: 'Ecuador' },
  { value: '+20', label: 'Egypt' },
  { value: '+503', label: 'El Salvador' },
  { value: '+240', label: 'Equatorial Guinea' },
  { value: '+291', label: 'Eritrea' },
  { value: '+372', label: 'Estonia' },
  { value: '+251', label: 'Ethiopia' },
  { value: '+500', label: 'Falkland Islands' },
  { value: '+298', label: 'Faroe Islands' },
  { value: '+679', label: 'Fiji' },
  { value: '+358', label: 'Finland' },
  { value: '+33', label: 'France' },
  { value: '+689', label: 'French Polynesia' },
  { value: '+241', label: 'Gabon' },
  { value: '+220', label: 'Gambia' },
  { value: '+995', label: 'Georgia' },
  { value: '+49', label: 'Germany' },
  { value: '+233', label: 'Ghana' },
  { value: '+350', label: 'Gibraltar' },
  { value: '+30', label: 'Greece' },
  { value: '+299', label: 'Greenland' },
  { value: '+1473', label: 'Grenada' },
  { value: '+1-671', label: 'Guam' },
  { value: '+502', label: 'Guatemala' },
  { value: '+441481', label: 'Guernsey' },
  { value: '+224', label: 'Guinea' },
  { value: '+245', label: 'Guinea-Bissau' },
  { value: '+592', label: 'Guyana' },
  { value: '+509', label: 'Haiti' },
  { value: '+504', label: 'Honduras' },
  { value: '+852', label: 'Hong Kong' },
  { value: '+36', label: 'Hungary' },
  { value: '+354', label: 'Iceland' },
  { value: '+62', label: 'Indonesia' },
  { value: '+98', label: 'Iran' },
  { value: '+964', label: 'Iraq' },
  { value: '+353', label: 'Ireland' },
  { value: '+441624', label: 'Isle of Man' },
  { value: '+972', label: 'Israel' },
  { value: '+39', label: 'Italy' },
  { value: '+225', label: 'Ivory Coast' },
  { value: '+1876', label: 'Jamaica' },
  { value: '+81', label: 'Japan' },
  { value: '+441534', label: 'Jersey' },
  { value: '+962', label: 'Jordan' },
  { value: '+7', label: 'Kazakhstan' },
  { value: '+254', label: 'Kenya' },
  { value: '+686', label: 'Kiribati' },
  { value: '+383', label: 'Kosovo' },
  { value: '+965', label: 'Kuwait' },
  { value: '+996', label: 'Kyrgyzstan' },
  { value: '+856', label: 'Laos' },
  { value: '+371', label: 'Latvia' },
  { value: '+961', label: 'Lebanon' },
  { value: '+266', label: 'Lesotho' },
  { value: '+231', label: 'Liberia' },
  { value: '+218', label: 'Libya' },
  { value: '+423', label: 'Liechtenstein' },
  { value: '+370', label: 'Lithuania' },
  { value: '+352', label: 'Luxembourg' },
  { value: '+853', label: 'Macau' },
  { value: '+389', label: 'Macedonia' },
  { value: '+261', label: 'Madagascar' },
  { value: '+265', label: 'Malawi' },
  { value: '+60', label: 'Malaysia' },
  { value: '+960', label: 'Maldives' },
  { value: '+223', label: 'Mali' },
  { value: '+356', label: 'Malta' },
  { value: '+692', label: 'Marshall Islands' },
  { value: '+222', label: 'Mauritania' },
  { value: '+230', label: 'Mauritius' },
  { value: '+262', label: 'Mayotte' },
  { value: '+52', label: 'Mexico' },
  { value: '+691', label: 'Micronesia' },
  { value: '+373', label: 'Moldova' },
  { value: '+377', label: 'Monaco' },
  { value: '+976', label: 'Mongolia' },
  { value: '+382', label: 'Montenegro' },
  { value: '+1-664', label: 'Montserrat' },
  { value: '+212', label: 'Morocco' },
  { value: '+258', label: 'Mozambique' },
  { value: '+95', label: 'Myanmar' },
  { value: '+264', label: 'Namibia' },
  { value: '+674', label: 'Nauru' },
  { value: '+977', label: 'Nepal' },
  { value: '+31', label: 'Netherlands' },
  { value: '+599', label: 'Netherlands Antilles' },
  { value: '+687', label: 'New Caledonia' },
  { value: '+64', label: 'New Zealand' },
  { value: '+505', label: 'Nicaragua' },
  { value: '+227', label: 'Niger' },
  { value: '+234', label: 'Nigeria' },
  { value: '+683', label: 'Niue' },
  { value: '+850', label: 'North Korea' },
  { value: '+1-670', label: 'Northern Mariana Islands' },
  { value: '+47', label: 'Norway' },
  { value: '+968', label: 'Oman' },
  { value: '+92', label: 'Pakistan' },
  { value: '+680', label: 'Palau' },
  { value: '+970', label: 'Palestine' },
  { value: '+507', label: 'Panama' },
  { value: '+675', label: 'Papua New Guinea' },
  { value: '+595', label: 'Paraguay' },
  { value: '+51', label: 'Peru' },
  { value: '+63', label: 'Philippines' },
  { value: '+64', label: 'Pitcairn' },
  { value: '+48', label: 'Poland' },
  { value: '+351', label: 'Portugal' },
  { value: '+1787, 1939', label: 'Puerto Rico' },
  { value: '+974', label: 'Qatar' },
  { value: '+242', label: 'Republic of the Congo' },
  { value: '+262', label: 'Reunion' },
  { value: '+40', label: 'Romania' },
  { value: '+7', label: 'Russia' },
  { value: '+250', label: 'Rwanda' },
  { value: '+590', label: 'Saint Barthelemy' },
  { value: '+290', label: 'Saint Helena' },
  { value: '+1869', label: 'Saint Kitts and Nevis' },
  { value: '+1758', label: 'Saint Lucia' },
  { value: '+590', label: 'Saint Martin' },
  { value: '+508', label: 'Saint Pierre and Miquelon' },
  { value: '+1784', label: 'Saint Vincent and the Grenadines' },
  { value: '+685', label: 'Samoa' },
  { value: '+378', label: 'San Marino' },
  { value: '+239', label: 'Sao Tome and Principe' },
  { value: '+966', label: 'Saudi Arabia' },
  { value: '+221', label: 'Senegal' },
  { value: '+381', label: 'Serbia' },
  { value: '+248', label: 'Seychelles' },
  { value: '+232', label: 'Sierra Leone' },
  { value: '+65', label: 'Singapore' },
  { value: '+1721', label: 'Sint Maarten' },
  { value: '+421', label: 'Slovakia' },
  { value: '+386', label: 'Slovenia' },
  { value: '+677', label: 'Solomon Islands' },
  { value: '+252', label: 'Somalia' },
  { value: '+27', label: 'South Africa' },
  { value: '+82', label: 'South Korea' },
  { value: '+211', label: 'South Sudan' },
  { value: '+34', label: 'Spain' },
  { value: '+94', label: 'Sri Lanka' },
  { value: '+249', label: 'Sudan' },
  { value: '+597', label: 'Suriname' },
  { value: '+47', label: 'Svalbard and Jan Mayen' },
  { value: '+268', label: 'Swaziland' },
  { value: '+46', label: 'Sweden' },
  { value: '+41', label: 'Switzerland' },
  { value: '+963', label: 'Syria' },
  { value: '+886', label: 'Taiwan' },
  { value: '+992', label: 'Tajikistan' },
  { value: '+255', label: 'Tanzania' },
  { value: '+66', label: 'Thailand' },
  { value: '+228', label: 'Togo' },
  { value: '+690', label: 'Tokelau' },
  { value: '+676', label: 'Tonga' },
  { value: '+1868', label: 'Trinidad and Tobago' },
  { value: '+216', label: 'Tunisia' },
  { value: '+90', label: 'Turkey' },
  { value: '+993', label: 'Turkmenistan' },
  { value: '+1649', label: 'Turks and Caicos Islands' },
  { value: '+688', label: 'Tuvalu' },
  { value: '+1340', label: 'U.S. Virgin Islands' },
  { value: '+256', label: 'Uganda' },
  { value: '+380', label: 'Ukraine' },
  { value: '+971', label: 'United Arab Emirates' },
  { value: '+44', label: 'United Kingdom' },
  { value: '+1', label: 'United States' },
  { value: '+598', label: 'Uruguay' },
  { value: '+998', label: 'Uzbekistan' },
  { value: '+678', label: 'Vanuatu' },
  { value: '+379', label: 'Vatican' },
  { value: '+58', label: 'Venezuela' },
  { value: '+84', label: 'Vietnam' },
  { value: '+681', label: 'Wallis and Futuna' },
  { value: '+212', label: 'Western Sahara' },
  { value: '+967', label: 'Yemen' },
  { value: '+260', label: 'Zambia' },
  { value: '+263', label: 'Zimbabwe' },
];
