import React from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  TextField,
  InputBase,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import Close from '../../assets/Icons/Close.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { countryCodeList } from '../../assets/Data/data';
import HomeBg from '../../assets/images/loginbg.png';
import { makeStyles } from '@mui/styles';
import Navbar from '../../components/Header/Navbar';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../../Store/auth';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  containerbg: {
    marginTop: '50px',
    overflow: 'hidden',
    backgroundImage: `url(${HomeBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: 'auto',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  grid: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingRight: '100px',
  },

  textField: {
    backgroundColor: '#E0DFF4',
    textAlign: 'center',
    padding: '6px',
    borderRadius: '5px',
  },
  input: {
    borderRadius: '5px',
    border: '1px solid #223870',
    width: '50px',
    height: '40px',
    color: '#5071C4',
    backgroundColor: '#E0DFF4',
  },
  countrycode: {
    '& .MuiSelect-outlined': {
      padding: '9.5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  '@media (max-width: 468px)': {},
}));
const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-underline:after': {
    border: '1px solid #223870',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #223870',
    },
    '&:hover fieldset': {
      border: '1px solid #223870',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #223870',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #223870',
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #223870',
    },
  },
}));
const RecruiterRegister = () => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = React.useState('+91');
  const handleSelect = (event) => {
    setCountryCode(event.target.value);
  };
  const navigate = useNavigate();
  // const redirectProfile = () => {
  //   navigate('/recruiterProfile');
  // };
  const [setName] = React.useState();

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(false);

  const [email, setEmail] = React.useState();
  const dispatch = useDispatch();
  const handleClickOpenEmail = async () => {
    console.log(email);
    const data = {
      signup_type: 1,
      user_type: 3,
      email_id: email,
      is_secondary: false,
    };
    // const sendotp = await dispatch(authActions.sendEmailOtp(data));
    // setOpenEmail(true);
  };
  const handleClickOpenMobile = () => {
    setOpenMobile(true);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };
  const handleCloseMobile = () => {
    setOpenMobile(false);
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const onSubmit = (data) => console.log(data);
  // console.log(errors);
  return (
    <>
      <Navbar />
      <Box className={classes.containerbg}>
        {' '}
        <Grid container className={classes.grid}>
          {' '}
          <Grid Item xs={12} sm={7}></Grid>{' '}
          <Grid Item xs={12} sm={5} className={classes.form}>
            <Typography
              variant='h4'
              sx={{
                textAlign: 'center',
                color: '#223870',
                fontWeight: '600',
              }}
            >
              Recruiter Signup
            </Typography>
            <Box component='form' onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                sm={12}
                sx={{
                  marginTop: '25px',
                  width: '100%',
                }}
              >
                {' '}
                <Grid Item sm={6} md={6}>
                  <Grid container direction='column'>
                    {' '}
                    <Grid Item>
                      {' '}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: '#304672',
                          fontWeight: '500',
                        }}
                      >
                        First Name*
                      </Typography>{' '}
                    </Grid>
                    <Grid Item sx={{ marginRight: '6px' }}>
                      <TextField
                        className={classes.textField}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                        name='firstName'
                        {...register('firstName', {
                          required: 'First name is required',
                          minLength: {
                            value: 3,
                            message: 'First name must be atleast 3 characters',
                          },
                        })}
                      />
                      {errors.firstName && (
                        <Box sx={{ color: 'red', fontSize: '12px' }}>
                          {' '}
                          {errors.firstName.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid Item sm={6} md={6}>
                  <Grid container direction='column'>
                    {' '}
                    <Grid Item>
                      {' '}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: '#304672',
                          fontWeight: '500',
                          marginLeft: '8px',
                        }}
                      >
                        Last Name*
                      </Typography>{' '}
                    </Grid>
                    <Grid Item sx={{ marginLeft: '6px' }}>
                      <TextField
                        className={classes.textField}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                        name='lastName'
                        {...register('lastName', {
                          required: 'Last name is required',
                          minLength: {
                            value: 3,
                            message: 'Last name must be atleast 3 characters',
                          },
                        })}
                      />
                      {errors.lastName && (
                        <Box sx={{ color: 'red', fontSize: '12px' }}>
                          {' '}
                          {errors.lastName.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction='Column'>
                {' '}
                <Grid
                  Item
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {' '}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    Email ID*
                  </Typography>{' '}
                </Grid>
                <Grid>
                  <Box
                    sm={12}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {' '}
                    <Stack direction='row' spacing={0}>
                      <TextField
                        className={classes.textField}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          color: '#5071C4',
                          backgroundColor: '#E0DFF4',
                          width: '100%',
                          borderRadius: '5px 0px 0px 5px',
                        }}
                        name='email'
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        {...register('email', {
                          required: 'Email id is required',
                          pattern: {
                            value:
                              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: 'Please enter a valid email address',
                          },
                        })}
                      />
                      <Box
                        sx={{
                          height: '42px',
                          backgroundColor: '#E0DFF4',
                          padding: '3px 0px',
                          borderRadius: '0px 5px 5px 0px',
                        }}
                      >
                        {' '}
                        <Button
                          onClick={handleClickOpenEmail}
                          variant='standard'
                          sx={{
                            marginRight: '5px',
                            backgroundColor: '#223870',
                            borderRadius: '3px',
                            color: '#FDCF57',
                            marginTop: '2px',
                            padding: '2px 20px',
                            fontSize: '16px',
                            textTransform: 'capitalize',
                            '&:hover': {
                              color: '#FDCF57',
                              backgroundColor: '#223870',
                            },
                            '@media (max-width: 468px)': {
                              marginTop: '-29px',
                              textAlign: 'center',
                              marginRight: '-10px',
                              padding: '0px 10px',
                            },
                          }}
                        >
                          Verify
                        </Button>
                        <Dialog
                          open={openEmail}
                          onClose={handleCloseEmail}
                          sx={{
                            '& .MuiDialog-container': {
                              '& .MuiPaper-root': {
                                width: '100%',
                                maxWidth: '590px',
                                height: '430px',
                                borderRadius: '8px',
                                backgroundColor: '#E8EEFB',
                              },
                            },
                          }}
                        >
                          <DialogActions>
                            <Box
                              onClick={handleCloseEmail}
                              component='img'
                              src={Close}
                              sx={{
                                top: '10px',
                                right: '10px',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: '#223870',
                              textAlign: 'center',
                              fontSize: '22px',
                              marginTop: '-15px',
                            }}
                          >
                            Verify Email ID
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: '20px ',
                              textAlign: 'center',
                            }}
                          >
                            {' '}
                            <Box>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: '18px',
                                  color: '#5071C4',
                                  fontWeight: '500',
                                }}
                              >
                                OTP sent to aparajita@gmail.com
                              </Typography>{' '}
                            </Box>
                            <Box
                              sx={{ marginTop: '40px', marginBottom: '10px' }}
                            >
                              {' '}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#223870',
                                  fontWeight: '500',
                                }}
                              >
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box>
                              <Grid
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  padding: '0px 100px',
                                }}
                                container
                                direction='row'
                                columns={{ xs: 3, sm: 8, md: 18, lg: 18 }}
                              >
                                <Grid item xs={2} md={2}>
                                  {' '}
                                  <CssTextField
                                    //className={classes.textField}
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input1'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    //className={classes.textField}
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input2'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input3'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    //className={classes.textField}
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input4'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input5'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input6'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#223870',
                                  fontWeight: '500',
                                }}
                              >
                                00.59
                              </Typography>
                            </Box>
                            <Box sx={{ marginTop: '40px ' }}>
                              {' '}
                              <Button
                                type='submit'
                                sx={{
                                  padding: '5px 30px',
                                  textTransform: 'none',
                                  textDecoration: 'none',
                                  borderRadius: '3px ',
                                  color: '#FDCF57',
                                  fontWeight: '500',
                                  fontSize: '18px',
                                  backgroundColor: '#223870',
                                  '&:hover': {
                                    backgroundColor: '#223870',
                                    color: '#FDCF57 ',
                                  },
                                }}
                              >
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.email && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.email.message}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction='Column'>
                {' '}
                <Grid
                  Item
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {' '}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    Mobile No.*
                  </Typography>{' '}
                </Grid>
                <Grid container>
                  <Grid Item sm={3} sx={{ paddingRight: '10px' }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: '#5071C4',
                            position: 'absolute',
                            right: 10,
                            pointerEvents: 'none',
                          }}
                        />
                      )}
                      sx={{
                        color: '#5071C4',
                        backgroundColor: '#E0DFF4',
                        fontWeight: '500',
                        width: '100%',
                      }}
                      value={countryCode}
                      onChange={handleSelect}
                      className={classes.countrycode}
                    >
                      {countryCodeList?.map((option) => {
                        return (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            sx={{
                              color: '#5071C4',
                              fontWeight: '700',
                            }}
                          >
                            {option.value ?? option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>{' '}
                  </Grid>
                  <Grid Item sm={9}>
                    <Stack direction='row' spacing={0}>
                      {' '}
                      <TextField
                        className={classes.textField}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                        name='mobile'
                        {...register('mobile', {
                          required: 'Mobile number is required',
                          pattern: {
                            value: /^\d{10}$/,
                            message:
                              'Please enter a valid 10-digit mobile number',
                          },
                        })}
                      />{' '}
                      <Box
                        sx={{
                          marginLeft: '-20px',
                          height: '42px',
                          backgroundColor: '#E0DFF4',
                          padding: '3px 0px',
                          borderRadius: '0px 5px 5px 0px',
                        }}
                      >
                        {' '}
                        <Button
                          onClick={handleClickOpenMobile}
                          variant='standard'
                          sx={{
                            marginRight: '3px',
                            backgroundColor: '#223870',
                            borderRadius: '3px',
                            color: '#FDCF57',
                            marginTop: '2px',
                            padding: '2px 20px',
                            fontSize: '16px',
                            textTransform: 'capitalize',
                            '&:hover': {
                              color: '#FDCF57',
                              backgroundColor: '#223870',
                            },
                            '@media (max-width: 468px)': {
                              marginTop: '-29px',
                              textAlign: 'center',
                              marginRight: '-10px',
                              padding: '0px 10px',
                            },
                          }}
                        >
                          Verify
                        </Button>
                        <Dialog
                          open={openMobile}
                          onClose={handleCloseMobile}
                          sx={{
                            '& .MuiDialog-container': {
                              '& .MuiPaper-root': {
                                width: '100%',
                                maxWidth: '590px',
                                height: '430px',
                                borderRadius: '10px',
                                backgroundColor: '#E8EEFB',
                              },
                            },
                          }}
                        >
                          <DialogActions>
                            <Box
                              onClick={handleCloseMobile}
                              component='img'
                              src={Close}
                              sx={{
                                top: '10px',
                                right: '10px',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: '#223870',
                              textAlign: 'center',
                              fontSize: '22px',
                              marginTop: '-15px',
                            }}
                          >
                            Verify Mobile No.
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: '20px ',
                              textAlign: 'center',
                            }}
                          >
                            {' '}
                            <Box>
                              {' '}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: '18px',
                                  color: '#5071C4',
                                  fontWeight: '500',
                                }}
                              >
                                OTP sent to +91 9630325138
                              </Typography>{' '}
                            </Box>
                            <Box
                              sx={{ marginTop: '40px', marginBottom: '10px' }}
                            >
                              {' '}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#223870',
                                  fontWeight: '500',
                                }}
                              >
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box>
                              <Grid
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  padding: '0px 100px',
                                }}
                                container
                                direction='row'
                                columns={{ xs: 3, sm: 8, md: 16, lg: 16 }}
                              >
                                <Grid item xs={2} md={2}>
                                  {' '}
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp1'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp2'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp3'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp4'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp5'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <CssTextField
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp6'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#223870',
                                  fontWeight: '500',
                                }}
                              >
                                00.59
                              </Typography>
                            </Box>
                            <Box sx={{ marginTop: '40px ' }}>
                              {' '}
                              <Button
                                type='submit'
                                sx={{
                                  padding: '4px 20px',
                                  textTransform: 'none',
                                  textDecoration: 'none',
                                  borderRadius: '3px ',
                                  color: '#FDCF57',
                                  fontWeight: '500',
                                  fontSize: '18px',
                                  backgroundColor: '#223870',
                                  '&:hover': {
                                    backgroundColor: '#223870',
                                    color: '#FDCF57 ',
                                  },
                                }}
                              >
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.mobile && (
                      <Box sx={{ color: 'red', fontSize: '12px' }}>
                        {' '}
                        {errors.mobile.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction='Column'>
                {' '}
                <Grid
                  Item
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {' '}
                  <Typography
                    variant='p'
                    gutterBottom
                    // component="div"
                    sx={{
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    Company*
                  </Typography>{' '}
                </Grid>
                <Grid Item>
                  <InputBase
                    size='small'
                    sx={{
                      color: '#5071C4',
                      backgroundColor: '#E0DFF4',
                      marginRight: '20px',
                      width: '100%',
                    }}
                    onChange={handleChange}
                    className={classes.textField}
                    name='companyName'
                    {...register('companyName', { required: true })}
                  />{' '}
                  <Box sx={{ color: 'red', fontSize: '12px' }}>
                    {errors.companyName?.type === 'required' &&
                      'Company name is required'}
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction='Column'>
                {' '}
                <Grid
                  Item
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {' '}
                  <Typography
                    variant='p'
                    gutterBottom
                    // component="div"
                    sx={{
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    PAN No.*
                  </Typography>{' '}
                </Grid>
                <Grid Item>
                  <InputBase
                    size='small'
                    sx={{
                      color: '#5071C4',
                      backgroundColor: '#E0DFF4',
                      marginRight: '20px',
                      width: '100%',
                    }}
                    onChange={handleChange}
                    className={classes.textField}
                    name='pan'
                    {...register('pan', {
                      required: 'PAN number is required',
                      pattern: {
                        value: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
                        message: 'Please enter a valid PAN number',
                      },
                    })}
                  />
                  {errors.pan && (
                    <Box sx={{ color: 'red', fontSize: '12px' }}>
                      {' '}
                      {errors.pan.message}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container direction='Column'>
                {' '}
                <Grid
                  Item
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  {' '}
                  <Typography
                    variant='p'
                    gutterBottom
                    // component="div"
                    sx={{
                      color: '#304672',
                      fontWeight: '500',
                    }}
                  >
                    Aadhar No.*
                  </Typography>{' '}
                </Grid>
                <Grid Item>
                  <InputBase
                    size='small'
                    sx={{
                      width: '100%',
                      color: '#5071C4',
                      backgroundColor: '#E0DFF4',
                      marginRight: '20px',
                    }}
                    onChange={handleChange}
                    className={classes.textField}
                    name='aadhar'
                    {...register('aadhar', {
                      required: 'Aadhar number is required',
                      pattern: {
                        value: /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
                        message: 'Please enter a valid Aadhar number',
                      },
                    })}
                  />
                  {errors.aadhar && (
                    <Box sx={{ color: 'red', fontSize: '12px' }}>
                      {' '}
                      {errors.aadhar.message}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name='acceptTerms'
                      defaultValue='false'
                      inputRef={register()}
                      render={({ field: { onChange } }) => (
                        <Checkbox
                          // checked={checked}
                          sx={{
                            color: '#223870',
                            '&.Mui-checked': {
                              color: '#223870',
                            },
                            '&$checked': {
                              color: 'yellow',
                            },
                          }}
                          required
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={
                    <Typography
                      varient='p'
                      color={errors.acceptTerms ? 'error' : 'inherit'}
                      sx={{
                        fontSize: '12px',
                      }}
                    >
                      I agree to the{' '}
                      <a href='/' style={{ color: '#223870' }}>
                        T&C and Privacy Policy
                      </a>{' '}
                      of the ExoTalent*
                    </Typography>
                  }
                />
                <br />
                <Typography variant='inherit' color='textSecondary'>
                  {errors.acceptTerms
                    ? '(' + errors.acceptTerms.message + ')'
                    : ''}
                </Typography>
              </Grid>

              <Grid
                container
                direction='Row'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {' '}
                <Grid
                  item
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    //onClick={redirectProfile}
                    type='submit'
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'none',
                      padding: '4px 20px',
                      borderRadius: '3px ',
                      color: '#FDCF57',
                      fontWeight: '500',
                      fontSize: '20px',
                      backgroundColor: '#223870',
                      '&:hover': {
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                      },
                    }}
                  >
                    Signup
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '5px',
                    }}
                  >
                    <a
                      href='/loginPageCandidate'
                      style={{
                        fontSize: '16px',
                        color: '#223870',
                      }}
                    >
                      Login
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default RecruiterRegister;
