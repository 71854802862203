import { API, apiUrl } from '../../services';
import { http, http_noauth } from '../../services/client';

import {
  setUserData,
  setTokenResponse,
  getUserData as getLocalUserData,
  getLoggedIn,
  clearAll,
  getAccessToken,
} from '../../services/authData';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const CLOSE_UNDER_VERIFICATION = 'CLOSE_UNDER_VERIFICATION';
export const OPEN_UNDER_VERIFICATION = 'OPEN_UNDER_VERIFICATION';

export const enqueueSnackbar = (message, variant) => {
  return {
    type: ENQUEUE_SNACKBAR,
    payload: {
      ...message,
      ...variant,
    },
  };
};

export const enqueueSnackbarComponent = (message, variant) => {
  return {
    type: ENQUEUE_SNACKBAR,
    payload: {
      message: message,
      variant: variant,
    },
  };
};
export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

export const removeSnackbar = () => ({
  type: REMOVE_SNACKBAR,
});

export const showMessage = ({ dispatch, message, variant }) => {
  dispatch(
    enqueueSnackbar({
      message,
      variant,
    })
  );
};

export const messagefromComponent = (payload, type) => {
  console.log(payload, type);
  return (dispatch) => {
    return dispatch(enqueueSnackbarComponent(payload, type));
  };
};

export const closeUnderVerification = () => ({
  type: CLOSE_UNDER_VERIFICATION,
});

export const openUnderVerification = () => ({
  type: OPEN_UNDER_VERIFICATION,
});

export const NOTIFICATION_LIST_FULFILLED = 'NOTIFICATION_LIST_FULFILLED';
export const NOTIFICATION_LIST_PENDING = 'NOTIFICATION_LIST_PENDING';
export const NOTIFICATION_LIST_REJECTED = 'NOTIFICATION_LIST_REJECTED';

const notificationListPending = () => ({
  type: NOTIFICATION_LIST_PENDING,
});

const notificationListFailure = (error) => ({
  type: NOTIFICATION_LIST_REJECTED,
  payload: { error },
});

const notificationListSuccess = (data) => ({
  type: NOTIFICATION_LIST_FULFILLED,
  payload: data,
});

export const notificationList = (payload) => {
  return (dispatch) => {
    dispatch(notificationListPending());
    return http
      .post(apiUrl.NOTIFICATION_LIST, payload)
      .then((response) => {
        if (response?.status == 1) {
          dispatch(notificationListSuccess(response));

          return response;
        } else {
          dispatch(notificationListFailure(response));

          return response;
        }
      })
      .catch((error) => {
        dispatch(notificationListFailure(error));

        return error;
      });
  };
};
