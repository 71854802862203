export const api = {
  EMAIL_OTP: `/user/email/send_otp`,
  MOBILE_OTP: `/user/mobile/send_otp`,
  VERIFY_MOBILE_OTP: `/user/mobile/otp_verification`,
  VERIFY_EMAIL_OTP: `/user/email/otp_verification`,
  COMPANY_SIGNUP: `/user/company/signup`,
  VERIFY_GST: `/user/gst/verification`,
  CANDIDATE_SIGNUP: `/user/candidate/signup`,
  RECRUITER_SIGNUP: `/user/recruiter/signup`,
  COMPANY_CREATE_JOB: `/user/company/create_job`,
  CREATE_JOB: `/user/company/create_job`,
  UPDATE_JOB: `/user/company/update_job`,
  CANDTDATE_APPLY_JOB: `/user/candidate/apply_job`,
  CANDTDATE_WITHDRAW_JOB: `/user/candidate/withdraw_job`,
  COMPANY_GET_ALL_JOB: `/user/company/get_all_created_job`,
  COMPANY_GET_JOB_DESCRIPTION: `/user/company/get_job_description/`,
  CANDIDATE_UPDATE_PROFILE:`/user/candidate/update_profile`,
  JOB_CANDIDATE_PROFILE: `/user/candidate/get_profile`,
  COMPANY_GET_JOB_BY_FILTER: `/user/company/get_job_by_filter`,
  COMPANY_PROFILE_DETAILS: `/user/company/get_profile`,
  CANDIDATE_PROFILE_DETAILS: `/user/candidate/get_profile`,
  GET_ALL_APPLIED_JOB: `/user/candidate/get_all_applied_job`,
  APPLY_JOB_CANDIDATE: `user/candidate/apply_job`,
  GET_ALL_APPLIED_CANDIDATE: `/user/company/get_all_applied_candidate`,
  CANDIDATE_EDUCATION_LIST: `/helper/api/get_education_degree`,
  UPDATE_JOB_STATUS: `/user/company/update_job_status`,
  CANDIDATE_WITHDRAW_JOB : `/user/candidate/withdraw_job`,
  COMPANY_UPDATE_PROFILE: `/user/company/update_profile`,
  COMPANY_CANDIDATE_JOB_UPDATE_STATUS:`/user/company/change_interview_status`,

  COMPANY_CANDIDATE_APPLIED_STATUS: `user/company/applied_candidate_status`,
  CANDIDATE_JOB_APPLIED_STATUS: `user/candidate/applied_job_status`,

  GET_DESIGNATION: `/helper/api/get_designation`,
  GET_SKILLS:`/helper/api/get_skill`,
  GET_LOCATION: `/helper/api/get_city`,
  GET_INDUSTRY: `/helper/api/get_industry`,
  GET_FUNCTION: `/helper/api/get_function`,
  GET_COMPANY_SIZE: `/helper/api/get_company_size`,
  GET_COMPANY_TYPE: `/helper/api/get_company_type`,
  GET_JOB_TYPE: `/helper/api/get_job_type`,
  GET_COMPANY_REGISTRATION_TYPE: `/helper/api/get_company_registration_type`,
  GET_COMPANY_WORK_MODEL: `/helper/api/get_work_model`,


};
// export const api = {
//
// };
// GET_PEOPLE_RECOMMENDATION_LIST: "network/recommendation",
