import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Card,
  Stack,
} from '@mui/material';
import companylogo from '../../assets/images/dummy.png';
import experience from '../../assets/Icons/Experience.png';
import jobtype from '../../assets/Icons/JobType.png';
import industry from '../../assets/Icons/Industry.png';
import location from '../../assets/Icons/Location.png';
import date from '../../assets/Icons/Calendar.png';
import designation from '../../assets/Icons/Function.png';
import salary from '../../assets/Icons/Salary.png';
import applicant from '../../assets/Icons/Applicants.png';
import education from '../../assets/Icons/Education.png';
import { useNavigate } from 'react-router-dom';
import * as jobsActions from '../../Store/Jobs';
import { useSelector, useDispatch } from 'react-redux';
import { industryList1 } from '../../assets/Data/data';
import { functionList1 } from '../../assets/Data/data';
import { typet } from '../../assets/Data/data';
import { Size } from '../../assets/Data/data';
import { Type } from '../../assets/Data/data';
import { educationlist } from '../../assets/Data/data';
import moment from 'moment';

function JobCandidateCard(props) {
  console.log('JobCandidateCard', props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [jobdetails, setJobDetails] = useState('');
  const jobDetails = async (e) => {
    e.stopPropagation();
    console.log('id', props?.data?._id);
    window.scrollTo({ top: 0 });
    const applyjob = await dispatch(jobsActions.candidateJob(props?.data));
    navigate('/companyJd/' + props?.data?._id);
  };

  useEffect(() => {
    async function jobDetails() {
      if (props?.data?.job_id) {
        const id = props?.data?.job_id;
        const jobList = await dispatch(jobsActions.getJobDescription(id));
        console.log('jobList', jobList);
        if (jobList?.data?.status === true) {
          setJobDetails(jobList?.data?.data[0]);
        }
      }
    }
    jobDetails();
  }, []);

  const getIndustry = () => {
    let industry = industryList1.find(
      (item) => item._id === props?.data?.industry
    );
    console.log('industry', industry);
    return industry?.industry;
  };

  const getFunction = () => {
    let functionl = functionList1.find(
      (item) => item._id === props?.data?.function
    );
    console.log('industry', functionl);
    return functionl?.function;
  };

  const getType = () => {
    let type = typet.find((item) => item._id === props?.data?.type);
    console.log('type', type);
    return type?.value;
  };
  const getEducation = () => {
    let education = educationlist.find(
      (item) => item._id === props?.data?.education
    );
    console.log('type', education);
    return education?.value;
  };

  return (
    <>
      <Card
        onClick={jobDetails}
        style={{
          backgroundColor: '#DFE5F6',
          cursor: 'pointer',
          margin: '0px 4px 0px 4px',
        }}
      >
        {' '}
        <Grid
          container
          sx={{
            display: 'flex',
            padding: '16px',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid Item sx={{}}>
            <Stack
              direction='column'
              sx={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <Typography
                variant='h5'
                sx={{
                  top: '10px',
                  left: '20px',
                  color: '#304672',
                  fontWeight: '600',
                  '@media (max-width: 468px)': {
                    top: '5px',
                    left: '5px',
                    marginTop: '10px',
                    fontSize: '16px',
                  },
                }}
              >
                {props?.data?.job_title
                  ? props?.data?.job_title
                  : jobdetails?.job_title}
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  top: '35px',
                  left: '16px',
                  color: '#304672',
                  '@media (max-width: 468px)': {
                    top: '25px',
                    left: '8px',
                    marginTop: '10px',
                    fontSize: '16px',
                  },
                }}
              >
                {props?.data?.company_name
                  ? props?.data?.company_name
                  : jobdetails?.company_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid Item>
            <Box
              component='img'
              src={
                props?.data?.company_logo
                  ? props?.data?.company_logo
                  : jobdetails?.company_logo
              }
              sx={{
                top: '20px',
                right: '20px',
                width: '60px',
                height: '60px',
                '@media (max-width: 468px)': {
                  top: '10px',
                  right: '10px',
                  width: '60px',
                  height: '60px',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{}}>
          <Grid Item xs={12} sm={12}>
            <Grid container sx={{}}>
              <Grid Item sm={6} xs={6}>
                <Grid container sx={{ marginLeft: '0px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Location'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={location}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.location?.city
                        ? props?.data?.location?.city
                        : jobdetails?.location?.city}
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '0px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Job Type'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={jobtype}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.job_type?.job_type}
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '0px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Industry'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={industry}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.industry?.industry}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '0px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Function'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={designation}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.function?.function}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid Item sm={6} xs={6}>
                {' '}
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Total Experience'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={experience}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.experience?.min
                        ? props?.data?.experience?.min
                        : jobdetails?.experience?.min}{' '}
                      {' - '}
                      {props?.data?.experience?.max
                        ? props?.data?.experience?.max
                        : jobdetails?.experience?.max}{' '}
                      yrs exp.
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title=' Salary'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={salary}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.salary?.min} {' - '}
                      {props?.data?.salary?.max} lpa
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      disableFocusListener
                      disableTouchListener
                      title='Date'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '16px',
                            height: '16px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={date}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      Posted on{' '}
                      {moment(
                        props?.data?.created_at
                          ? props?.data?.created_at
                          : jobdetails?.created_at
                      ).format('D') +
                        ' ' +
                        moment(
                          props?.data?.created_at
                            ? props?.data?.created_at
                            : jobdetails?.created_at
                        ).format('MMM') +
                        ', ' +
                        moment(
                          props?.data?.created_at
                            ? props?.data?.created_at
                            : jobdetails?.created_at
                        ).format('YYYY')}
                    </Typography>{' '}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid Item xs={1} sm={1}>
              <Box
                component='img'
                sx={{
                  marginLeft: '0px',
                  marginTop: '3px',
                  width: '18px',
                  height: '18px',
                  '@media (max-width: 468px)': {
                    marginTop: '-5px',
                    width: '14px',
                    height: '14px',
                  },
                }}
                src={education}
              />
            </Grid>
            <Grid Item xs={11} sm={11}>
              <Typography
                variant='h6'
                sx={{
                  marginLeft: '0px',
                  paddingBottom: '16px',
                  fontSize: '16px',
                  textAlign: 'left',
                  color: '#5677B9',
                  '@media (max-width: 468px)': {
                    marginLeft: '3px',
                    fontSize: '14px',
                  },
                }}
              >
                {props?.data?.education?.degree}
              </Typography>{' '}
            </Grid>
          </Grid>
        </Grid>{' '}
      </Card>
    </>
  );
}
export default JobCandidateCard;
