import {
  CREATE_JOB_SUCCESS,
  CREATE_JOB_REQUEST,
  CREATE_JOB_FAILURE,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_FAILURE,
  GET_ALL_OPEN_JOBS_SUCCESS,
  GET_ALL_OPEN_JOBS_REQUEST,
  GET_ALL_OPEN_JOBS_FAILURE,
  GET_ALL_INACTIVE_JOBS_SUCCESS,
  GET_ALL_INACTIVE_JOBS_REQUEST,
  GET_ALL_INACTIVE_JOBS_FAILURE,
  GET_ALL_CLOSED_JOBS_SUCCESS,
  GET_ALL_CLOSED_JOBS_REQUEST,
  GET_ALL_CLOSED_JOBS_FAILURE,
  GET_JOB_DESCRIPTION_SUCCESS,
  GET_JOB_DESCRIPTION_REQUEST,
  GET_JOB_DESCRIPTION_FAILURE,
  GET_JOB_BY_FILTER_SUCCESS,
  GET_JOB_BY_FILTER_REQUEST,
  GET_JOB_BY_FILTER_FAILURE,
  CANDIDATE_APPLY_JOB_SUCCESS,
  CANDIDATE_APPLY_JOB_REQUEST,
  CANDIDATE_APPLY_JOB_FAILURE,
  CANDIDATE_WITHDRAW_JOB_SUCCESS,
  CANDIDATE_WITHDRAW_JOB_REQUEST,
  CANDIDATE_WITHDRAW_JOB_FAILURE,

  GET_ALL_CANDIDATE_OPEN_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_OPEN_JOBS_REQUEST,
  GET_ALL_CANDIDATE_OPEN_JOBS_FAILURE,

  GET_ALL_SEARCH_JOBS_SUCCESS,
  GET_ALL_SEARCH_JOBS_REQUEST,
  GET_ALL_SEARCH_JOBS_FAILURE,
  GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST,
  GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE,


  GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST,
  GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE,


  GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_REQUEST,
  GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_FAILURE,


  GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_REQUEST,
  GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_FAILURE,


  GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS,
  GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST,
  GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE,

  GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_SUCCESS,
  GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_REQUEST,
  GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_FAILURE,

  GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS,
  GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST,
  GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE,

  GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS,
  GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_REQUEST,
  GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_FAILURE,

  GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_SUCCESS,
  GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_REQUEST,
  GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_FAILURE,

  UPDATE_JOB_STATUS_SUCCESS,
  UPDATE_JOB_STATUS_REQUEST,
  UPDATE_JOB_STATUS_FAILURE,

  GET_JOB_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  GET_JOB_CANDIDATE_PROFILE_DETAILS_REQUEST,
  GET_JOB_CANDIDATE_PROFILE_DETAILS_FAILURE,


  COMPANY_CANDIDATE_JOB_UPDATE_STATUS_SUCCESS,
COMPANY_CANDIDATE_JOB_UPDATE_STATUS_REQUEST,
COMPANY_CANDIDATE_JOB_UPDATE_STATUS_FAILURE,

GET_CANDIDATE_JOB_APPLIED_STATUS_SUCCESS,
GET_CANDIDATE_JOB_APPLIED_STATUS_REQUEST,
GET_CANDIDATE_JOB_APPLIED_STATUS_FAILURE,

GET_COMPANY_CANDIDATE_APPLIED_STATUS_SUCCESS,
GET_COMPANY_CANDIDATE_APPLIED_STATUS_REQUEST,
GET_COMPANY_CANDIDATE_APPLIED_STATUS_FAILURE,

GET_LOCATION_SUCCESS,
GET_LOCATION_REQUEST,
GET_LOCATION_FAILURE,

GET_SKILLS_SUCCESS,
GET_SKILLS_REQUEST,
GET_SKILLS_FAILURE,

GET_DESIGNATION_SUCCESS,
GET_DESIGNATION_REQUEST,
GET_DESIGNATION_FAILURE,

SAVE_JOB_CANDIDATE,

} from "./action";

const initialState = {
  createdOpenJobList: [],
  createdInactiveJobList: [],
  createdClosedJobList: [],
  similarJobList: [],
  jobDescription: [],
  jobCreate: false,
  jobApply: false,
  jobEdit: false,
  loading: false,
  jobDetails: [],
  candidateOpenJob: [],
  searchJobs: [],
  cadidateAppliedJobs:[],
  cadidateShortlistJobs:[],
  cadidateHoldJobs:[],
  cadidateRejectedJobs:[],
  jobCandidateApply: [],
  jobCandidateShortlist: [],
  jobCandidateHold: [],
  jobCandidateReject: [],
  applicant: [],
  candidateJob:{},
  candidateJobshow:{},
  candidateJobStatus: {},
  jobStatusCandidate: {},
  designation:[],
  location:[],
  skills:[],
  withdrawJobs:[],
  job_creator: {},
};
export const jobsreducer = (state = initialState, action) => {
  switch (action.type) {
    //===================================job-list======================
    case CREATE_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case CREATE_JOB_REQUEST: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case CREATE_JOB_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case UPDATE_JOB_REQUEST: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case UPDATE_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case UPDATE_JOB_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_OPEN_JOBS_SUCCESS: {
      return {
        ...state,
        createdOpenJobList: action.payload.jobs.data.data,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_OPEN_JOBS_REQUEST: {
      return {
        ...state,
        loading: false,
        createdOpenJobList:[],
        errors: {},
      };
    }
    case GET_ALL_OPEN_JOBS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        createdOpenJobList:[],
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_INACTIVE_JOBS_SUCCESS: {
      return {
        ...state,
        createdInactiveJobList: action.payload.jobs.data.data,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_INACTIVE_JOBS_REQUEST: {
      return {
        ...state,
        loading: false,
        createdInactiveJobList:[],
        errors: {},
      };
    }
    case GET_ALL_INACTIVE_JOBS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        createdInactiveJobList:[],
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_CLOSED_JOBS_SUCCESS: {
      return {
        ...state,
        createdClosedJobList: action.payload.jobs.data.data,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_CLOSED_JOBS_REQUEST: {
      return {
        ...state,
        loading: false,
        createdClosedJobList:[],
        errors: {},
      };
    }
    case GET_ALL_CLOSED_JOBS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        createdClosedJobList:[],
        errors: {},
      };
    }
    case GET_JOB_DESCRIPTION_SUCCESS: {
      return {
        ...state,

        jobDetails: action?.payload?.data?.data?.data?.[0],
        job_creator: action?.payload?.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_JOB_DESCRIPTION_REQUEST: {
      return {
        ...state,
        ...action.payload,
        jobDetails: [],
        job_creator: {},
        loading: false,
        errors: {},
      };
    }
    case GET_JOB_DESCRIPTION_FAILURE: {
      return {
        ...state,
        ...action.payload,
        job_creator: {},
        loading: false,
        errors: {},
      };
    }
    case GET_JOB_BY_FILTER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        searchJobs: action.payload?.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_JOB_BY_FILTER_REQUEST: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }

    case GET_JOB_BY_FILTER_FAILURE: {
      return {
        ...state,
        ...action.payload,
        filteredJob: [],
        loading: false,
        errors: {},
      };
    }

    case CANDIDATE_APPLY_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case CANDIDATE_APPLY_JOB_REQUEST: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case CANDIDATE_APPLY_JOB_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_CANDIDATE_OPEN_JOBS_SUCCESS: {
      return {
        ...state,
        candidateOpenJob: action.payload.jobs.data.data,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_CANDIDATE_OPEN_JOBS_REQUEST: {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_CANDIDATE_OPEN_JOBS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_SEARCH_JOBS_SUCCESS: {
      return {
        ...state,
        searchJobs: action.payload?.data?.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_SEARCH_JOBS_REQUEST: {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }
    case GET_ALL_SEARCH_JOBS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        searchJobs: [],
        loading: false,
        errors: {},
      };
    }

  case   GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS: {
    return {
      ...state,
      ...action.payload,
      cadidateAppliedJobs: action.payload?.data?.data,
      loading: false,
      errors: {},
    };

  }
  case  GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST: {
    return {
      ...state,
      loading: false,
      cadidateAppliedJobs: [],
      errors: {},
    };
  }
  case  GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE:{
    return {
      ...state,
      loading: false,
      cadidateAppliedJobs: [],
      errors: {},
    };
  }



  case   GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS: {
    return {
      ...state,
      ...action.payload,
      cadidateShortlistJobs: action.payload?.data?.data,
      loading: false,
      errors: {},
    };

  }
  case  GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST: {
    return {
      ...state,
      loading: false,
      cadidateShortlistJobs: [],
      errors: {},
    };
  }
  case  GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE:{
    return {
      ...state,
      loading: false,
      cadidateShortlistJobs: [],
      errors: {},
    };
  }


  case   GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS: {
    return {
      ...state,
      ...action.payload,
      cadidateHoldJobs: action.payload?.data?.data,
      loading: false,
      errors: {},
    };

  }
  case  GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_REQUEST: {
    return {
      ...state,
      loading: false,
      cadidateHoldJobs: [],
      errors: {},
    };
  }
  case  GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_FAILURE:{
    return {
      ...state,
      loading: false,
      cadidateHoldJobs: [],
      errors: {},
    };
  }


  case   GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_SUCCESS: {
    return {
      ...state,
      ...action.payload,
      cadidateRejectJobs: action.payload?.data?.data,
      loading: false,
      errors: {},
    };

  }
  case  GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_REQUEST: {
    return {
      ...state,
      loading: false,
      cadidateRejectJobs: [],
      errors: {},
    };
  }
  case  GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_FAILURE:{
    return {
      ...state,
      loading: false,
      cadidateRejectJobs: [],
      errors: {},
    };
  }

  case GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS:{
    return{
      ...state,
      loading: false,
      jobCandidateApply: action?.payload?.data?.data,
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST:{
    return{
      ...state,
      loading: false,
      jobCandidateApply: []
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE:{
    return{
      ...state,
      loading: false,
      jobCandidateApply: []
    }
  }

  case GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS:{
    return{
      ...state,
      loading: false,
      jobCandidateShortlist: action.payload?.data?.data
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST:{
    return{
      ...state,
      loading: false,
      jobCandidateShortlist: []
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE:{
    return{
      ...state,
      loading: false,
      jobCandidateShortlist: []
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS:{
    return{
      ...state,
      loading: false,
      jobCandidateHold: action.payload?.data?.data
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_REQUEST:{
    return{
      ...state,
      loading: false,
      jobCandidateHold: []
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_FAILURE:{
    return{
      ...state,
      loading: false,
      jobCandidateHold: []
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_SUCCESS:{
    return{
      ...state,
      loading: false,
      jobCandidateReject: action.payload?.data?.data
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_REQUEST:{
    return{
      ...state,
      loading: false,
      jobCandidateReject: []
    }
  }
  case GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_FAILURE:{
    return{
      ...state,
      loading: false,
      jobCandidateReject: []
    }
  }
  case UPDATE_JOB_STATUS_SUCCESS:{
    return{
      ...state,
      loading: false,

    }
  }
  case UPDATE_JOB_STATUS_REQUEST:{
    return{
      ...state,
      loading: false,

    }
  }
  case UPDATE_JOB_STATUS_FAILURE:{
    return{
      ...state,
      loading: false,

    }
  }

  case CANDIDATE_WITHDRAW_JOB_SUCCESS:{
    return{
      ...state,
      loading: false,
    }
  }
  case CANDIDATE_WITHDRAW_JOB_REQUEST:{
    return{
      ...state,
      loading: false,
    }
  }
  case CANDIDATE_WITHDRAW_JOB_FAILURE:{
    return{
      ...state,
      loading: false,
    }
  }
  case GET_JOB_CANDIDATE_PROFILE_DETAILS_SUCCESS:{
    return{
      ...state,
      loading:false,
      applicant: action.payload?.data?.data[0]
    }
  }
  case GET_JOB_CANDIDATE_PROFILE_DETAILS_REQUEST:{
    return{
      ...state,
      loading:false,
      applicant: []
    }
  }
  case GET_JOB_CANDIDATE_PROFILE_DETAILS_FAILURE:{
    return{
      ...state,
      loading:false,
      applicant: []
    }
  }
  case SAVE_JOB_CANDIDATE:{
    console.log('SAVE_JOB_CANDIDATE', action.payload);
    return{
      ...state,
      candidateJob: action.payload
    }

  }
  case "CANDIDATE_JOB": {
    return{
      ...state,
      candidateJobshow: action.payload
    }
  }


 case COMPANY_CANDIDATE_JOB_UPDATE_STATUS_SUCCESS:{
  return{
    ...state,
    loading: false,
  }
 }
 case COMPANY_CANDIDATE_JOB_UPDATE_STATUS_REQUEST:{
  return{
    ...state,
    loading: false,
  }
 }
 case COMPANY_CANDIDATE_JOB_UPDATE_STATUS_FAILURE:{
  return{
    ...state,
    loading: false,
  }
 }

 case GET_CANDIDATE_JOB_APPLIED_STATUS_SUCCESS: {
   return {
     ...state,
     loading: false,
     candidateJobStatus: action.payload?.data?.data

   }
 }
 case GET_CANDIDATE_JOB_APPLIED_STATUS_REQUEST: {
   return {
     ...state,
     loading: false,
     candidateJobStatus: {}

   }
 }
 case GET_CANDIDATE_JOB_APPLIED_STATUS_FAILURE: {
   return {
     ...state,
     loading: false,
     candidateJobStatus:{}

   }
 }

 case GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_SUCCESS: {
   return{
     ...state,
     withdrawJobs:action?.payload?.data?.data
   }

 }
case GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_REQUEST: {
  return{
    ...state,
    withdrawJobs:[]
  }

}
case GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_FAILURE: {
  return{
    ...state,
    withdrawJobs:[]
  }

}

 case GET_COMPANY_CANDIDATE_APPLIED_STATUS_SUCCESS: {

   return{
     ...state,
     loading:false,
     jobStatusCandidate:  action.payload?.data?.data?.interview_status
   }
 }
 case GET_COMPANY_CANDIDATE_APPLIED_STATUS_REQUEST: {
   return{
     ...state,
     loading:false,
     jobStatusCandidate: {}
   }
 }
 case GET_COMPANY_CANDIDATE_APPLIED_STATUS_FAILURE: {
   return{
     ...state,
     loading:false,
     jobStatusCandidate: {}
   }
 }

 case GET_LOCATION_SUCCESS:{
   return{
     ...state,
     location:action.payload?.data?.data
   }
 }
 case GET_LOCATION_REQUEST:{
   return{
     ...state,
     location:[]
   }
 }
 case GET_LOCATION_FAILURE:{
   return{
     ...state,
     location:[]
   }
 }

 case GET_SKILLS_SUCCESS:{
   return{
     ...state,
     skills: action.payload?.data?.data
   }
 }
 case GET_SKILLS_REQUEST:{
   return{
     ...state,
     skills:[]
   }
 }
 case GET_SKILLS_FAILURE:{
   return{
     ...state,
     skills:[]
   }
 }

 case GET_DESIGNATION_SUCCESS:{
   return{
     ...state,
     designation:action.payload?.data?.data
   }
 }
 case GET_DESIGNATION_REQUEST:{
   return{
     ...state,
     designation:[]
   }
 }
 case GET_DESIGNATION_FAILURE:{
   return{
     ...state,
     designation:[]
   }
 }

    default:
      return state;
  }
};
