import React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import Logo from "../../../assets/images/female.jpeg";
import dropDown from "../../../assets/Icons/Dropdown.png";
import companylogo from "../../../assets/images/dummy.png";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import { Years } from "../../../assets/Data/data";
import experience from "../../../assets/Icons/Experience.png";
import jobtype from "../../../assets/Icons/JobType.png";
import industry from "../../../assets/Icons/Industry.png";
import location from "../../../assets/Icons/Location.png";
import date from "../../../assets/Icons/Calendar.png";
import designation from "../../../assets/Icons/Function.png";
import salary from "../../../assets/Icons/Salary.png";
import applicant from "../../../assets/Icons/Applicants.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  container: { width: "auto", padding: "0", margin: "0" },

  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 70px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    marginLeft: "15px",
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#EEF3FD",
    position: "relative",
  },

  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (max-width: 468px)": {
    mainHome: {
      padding: "50px 15px",
      backgroundImage: "none",
      backgroundColor: "#c6c2f6",
    },
    search: {
      marginRight: "0px",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0px 0px",
    },
  },
}));

const RecruiterProfile = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const redirect = () => {
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction="row">
            <Box className={classes.imgFrame}>
              {" "}
              <Box component="img" className={classes.img} src={Logo} />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant="h4"
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}
              >
                Candidate Name
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}
              >
                Designation
              </Typography>
              <Stack
                direction="row"
                spacing={{ xs: 2, sm: 4, md: 10 }}
                sx={{ marginTop: "15px" }}
              >
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Experience
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Salary
                    {/* No. of employee */}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    Resume
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box className={classes.button}>
              {" "}
              <Button
                onClick={redirect}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "8px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}
              >
                Edit Profile
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box className={classes.body}>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Applied (12)
              </Typography>
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  width: "20px",
                  height: "20px",
                }}
                src={dropDown}
              />
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {" "}
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Function
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Shortlisted (6)
              </Typography>
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  width: "20px",
                  height: "20px",
                }}
                src={dropDown}
              />
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {" "}
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                On Hold (12)
              </Typography>
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  width: "20px",
                  height: "20px",
                }}
                src={dropDown}
              />
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {" "}
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Rejected (3)
              </Typography>
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  width: "20px",
                  height: "20px",
                }}
                src={dropDown}
              />
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {" "}
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox} sx={{ opacity: "0.5" }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid Item xs={12} sm={6}>
                <Box className={classes.jobbox} sx={{ opacity: "0.5" }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
                  >
                    <Grid Item>
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h7"
                        sx={{
                          marginLeft: "20px",
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "600",
                        }}
                      >
                        Company name
                      </Typography>
                    </Grid>
                    <Grid Item>
                      <Box
                        component="img"
                        src={companylogo}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid Item xs={12} sm={12}>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={location}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Location
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={jobtype}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Job Type
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={industry}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Industry
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={designation}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Designation
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={5}>
                          {" "}
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={experience}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Experience in Years
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={salary}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Salary
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={applicant}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Total applicants
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid Item xs={12} sm={2}>
                              <Box
                                component="img"
                                sx={{
                                  marginTop: "5px",
                                  width: "18px",
                                  height: "18px",
                                }}
                                src={date}
                              />
                            </Grid>
                            <Grid Item xs={12} sm={10}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  textAlign: "left",
                                  color: "#5677B9",
                                }}
                              >
                                Date(2 Jul 2022)
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid Item sm={2}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default RecruiterProfile;
