import * as React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  LargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  back: {
    backgroundColor: '#223870',
    margin: '0px',
    height: 'auto',
    padding: '25px 0px 80px',
    textAlign: 'center',
  },
  title: {
    paddingTop: '50px',
    color: '#fdcf57',
    fontSize: '34px',
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: '10px',
  },
  imgFrame: {
    background: '#223870',
    width: '120px',
    height: '135px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  logo_img: {
    objectFit: 'contain',
    margin: '0 auto',
    width: '110px',
    height: '125px',
    backgroundSize: 'cover',
  },
  imgFrame1: {
    background: '#223870',
    width: '75px',
    height: '75px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  logo_img1: {
    objectFit: 'contain',
    margin: '0 auto',
    width: '65px',
    height: '65px',
    backgroundSize: 'cover',
  },
  '@media (max-width: 468px)': {
    imgFrame: {
      width: '100px',
      height: '100px',
    },
    logo_img: {
      objectFit: 'contain',
      margin: '0 auto',
      width: '90px',
      height: '90px',
      backgroundSize: 'cover',
    },
    back: {
      padding: '40px 10px 20px',
      margin: '0 auto',
      textAlign: 'center',
    },
    title: {
      paddingBottom: '10px',
      fontSize: '26px',
      lineHeight: '30px',
    },
  },
}));

const items = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Autoliv.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bosch.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Calsoft.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Company_Logo/RectLogo/Jaguar Land Rover India.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Continuum.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Daimler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/DMG MORI.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hindustan Platinum.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Honda power pack.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hyundai.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Inteva Products.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ITL-logo-01.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/John Deere.png",
  },
];
const items2 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bajaj Auto.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Aditya%20birla.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Cummins.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Eaton.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/GMR.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Honda.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ISS.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/MBRDI.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/PVR.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schindler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Wipro.png",
  },
];
const items3 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Kone%20Crane.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Mahindra & Mahindra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Onward Technologies.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Rieter.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Satven.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schlumberger.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Sun Mobility.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tata Elxsi.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tech Mahindra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Weichai.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Yokogawa.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Zetwerk.png",
  },
];
function Partner() {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.back} id='clients'>
        <Box className={classes.title}>
          Some of our highly reputed clients who swear by our services
        </Box>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          autoPlay={true}
          ssr={true}
          infinite={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          direction='left'
          customTransition='all .5'
          transitionDuration={20}
          containerClass='carousel-container'
          dotListClass='custom-dot-list-style'
          itemClass='carousel-item-padding-40-px'
          removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
          sx={{ paddingBottom: '-50px' }}
        >
          {items.map((step, index) => (
            <div key={step.label} className={classes.imgFrame}>
              <img src={step.image} alt='' className={classes.logo_img} />
            </div>
          ))}
        </Carousel>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          autoPlay={true}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          direction='left'
          customTransition='all .5'
          transitionDuration={20}
          containerClass='carousel-container'
          dotListClass='custom-dot-list-style'
          itemClass='carousel-item-padding-40-px'
          removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
          sx={{ paddingBottom: '-50px' }}
        >
          {items2.map((step, index) => (
            <div key={step.label} className={classes.imgFrame1}>
              <img src={step.image} alt='' className={classes.logo_img1} />
            </div>
          ))}
        </Carousel>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          autoPlay={true}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          direction='left'
          customTransition='all .5'
          transitionDuration={20}
          containerClass='carousel-container'
          dotListClass='custom-dot-list-style'
          itemClass='carousel-item-padding-40-px'
          removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
          sx={{ paddingBottom: '-50px' }}
        >
          {items3.map((step, index) => (
            <div key={step.label} className={classes.imgFrame}>
              <img src={step.image} alt='' className={classes.logo_img} />
            </div>
          ))}
        </Carousel>
      </Box>
    </>
  );
}

export default Partner;
