import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate , useSearchParams} from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../../components/Header/Navbar";
import JobCardMain from "../../../components/jobCards/jobCardMain";
import JobSearchBar from "../../../components/jobSearchBar/jobSearchBar";
import * as jobsActions from "../../../Store/Jobs";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  mainHome: {
    paddingTop: "80px",
    backgroundSize: "cover",
    backgroundColor: "#F6F8FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  jobbox: {
    padding: "20px 0px",
    margin:"4px 4px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },

  countrycode: {
    marginLeft: "0px",
    "& .MuiSelect-outlined": {
      backgroundColor: "#e9e8f6",
      padding: "8.8px",
    },
    "& .MuiSelect-outlined:hover:after": {
      backgroundColor: "#e9e8f6",
    },
    "& .MuiSelect-outlined:hover:before": {
      backgroundColor: "#e9e8f6",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  container: {
    padding: "0px 75px",
  },
  maincard: {
    // margin: "0px 75px",
    width: "100%",
    textAlign: "center",
    color: "#223870",
    backgroundColor: "#EEF3FD",
    borderRadius: "10px",
    padding: "20px 20px",
    height: "auto",
    marginBottom: "20px",
    position: "relative",
  },
  gridcard: {
    borderRadius: "10px",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    margin: "0px 75px",
    // padding: "10px",
    height: "auto",
  },
  "@media (min-width: 1640px)": {
    mainHome: {},
    title: {
      paddingRight: "60px ",
    },
    gridcard: {
      margin: "0",
      padding: "10px",
      height: "auto",
    },
    maincard: {
      padding: "20px 70px",
    },
  },
  "@media (max-width: 1200px)": {
    title: {
      paddingRight: "60px ",
    },
    gridcard: {
      margin: "0",
      padding: "10px",
      height: "auto",
    },
    maincard: {
      padding: "20px 25px",
    },
  },
  "@media (max-width: 1080px)": {
    gridcard: {
      margin: "0",
      padding: "10px",
      height: "auto",
    },
    maincard: {
      padding: "20px 70px",
    },
  },
  "@media (max-width: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: "0px",
      padding: "0px 5px",
    },

    container: {
      width: "100%",
      padding: "0px 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0px",
      borderRadius: "10px",
    },
    maincard: {
      padding: "20px 5px",
    },
  },
}));

function JobsPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const openJobs = useSelector((state) => state?.jobs?.createdOpenJobList);
  const searchJobs = useSelector((state) => state?.jobs?.searchJobs);
  const candidateOpenJob = useSelector(
    (state) => state?.jobs?.candidateOpenJob
  );
  useEffect(() => {
    async function listofOpenJobs() {
      const data = {
        job_status: "open",
        page_no: 0,
      };
      const jobList = await dispatch(jobsActions.getAllCandidateOpenJobs(data));
      console.log("jobList==========", jobList);
    }

    listofOpenJobs();
  }, []);


  useEffect(() =>{


    async function jobDetails() {
      let data = {
        page_no: searchParams.get("page_no"),
      };
      const searchJobList = await dispatch(jobsActions.getSearchJobList(data));
    }
    jobDetails();

  }, []);


  const redirect = () => {
    window.scrollTo({
      top: 0,
    });
    navigate("/JobSearchResult");
  };

  return (
    <>
      <Navbar />
      <Box className={classes.mainHome}>
        <Box className={classes.container}>
          <Box
            sx={{
              zIndex: "1050",
              position: "fixed",
              width: "100%",
              backgroundColor: "#F6F8FD",
            }}
          >
            {" "}
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  marginTop: "20px",
                  color: "#223870",
                  fontWeight: "600",
                  "@media (max-width: 468px)": {
                    fontSize: "18px",
                    marginLeft: "10px",
                  },
                }}
              >
                Looking for a JOB change...
              </Typography>
            </Box>
            <JobSearchBar />
          </Box>
          <Box sx={{ paddingTop: "140px" }}>
            <Box className={classes.maincard}>
              <Box sx={{ zIndex: "600", textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#223870",
                    fontWeight: "600",
                    marginBottom: "20px",
                    "@media (max-width: 468px)": {
                      fontSize: "18px",
                      textAlign: "left",
                    },
                  }}
                >
                  Trending Jobs
                </Typography>
              </Box>
              <Box sx={{ zIndex: "600" }}>
                <Typography
                  onClick={redirect}
                  variant="p"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#223870",
                    fontWeight: "600",
                    top: "20px",
                    right: "50px",
                    position: "absolute",
                    cursor: "pointer",

                    "@media (max-width: 468px)": {
                      top: "20px",
                      right: "10px",
                    },
                  }}
                >
                  See more...
                </Typography>
              </Box>

              <Grid
                container
                direction="row"
                spacing={0}
                justify="center"
                alignItems="center"
                columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
              >
                {" "}

                  {searchJobs?.length > 0 &&
                    searchJobs?.map((list, index) => {
                      return (
                        <Grid Item xs={12} sm={6} key={index} sx={{ paddingRight: index % 2 === 0 ? "6px" : " 0px" , paddingLeft:  index % 2 != 0 ? "6px" : " 0px"}}>
                          <Box className={classes.jobbox} >
                            <JobCardMain data={list} />
                          </Box>
                        </Grid>
                      );
                    })}



              </Grid>
            </Box>
            {/* <Box className={classes.maincard}>
              <Box sx={{ zIndex: "600", textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#223870",
                    fontWeight: "600",
                    marginBottom: "20px",
                    "@media (max-width: 468px)": {
                      fontSize: "18px",
                      textAlign: "left",
                    },
                  }}
                >
                  Jobs in Automotive Industry
                </Typography>
              </Box>
              <Box sx={{ zIndex: "600" }}>
                <Typography
                  onClick={redirect}
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    color: "#223870",
                    fontWeight: "600",
                    top: "20px",
                    right: "50px",
                    position: "absolute",
                    cursor: "pointer",

                    "@media (max-width: 468px)": {
                      top: "20px",
                      right: "10px",
                    },
                  }}
                >
                  see more...
                </Typography>
              </Box>

              <Grid
                container
                direction="row"
                spacing={0}
                justify="center"
                alignItems="center"
                columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
              >
                {" "}
                <Grid Item xs={12} sm={6}>
                  <Box
                    className={classes.jobbox}
                    sx={{ margin: "10px 10px 10px 0px" }}
                  >
                    <JobCardMain />
                  </Box>
                </Grid>
                <Grid Item xs={12} sm={6}>
                  <Box
                    className={classes.jobbox}
                    sx={{
                      margin: "10px 0px 10px 10px",
                      "@media (max-width: 468px)": {
                        margin: "20px 10px 10px 0px",
                      },
                    }}
                  >
                    <JobCardMain />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.maincard}>
              <Box sx={{ zIndex: "600", textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#223870",
                    fontWeight: "600",
                    marginBottom: "20px",

                    "@media (max-width: 468px)": {
                      fontSize: "18px",
                      textAlign: "left",
                    },
                  }}
                >
                  Jobs in Bangalore
                </Typography>
              </Box>
              <Box sx={{ zIndex: "600" }}>
                <Typography
                  onClick={redirect}
                  variant="p"
                  sx={{
                    fontSize: "14px",
                    color: "#223870",
                    fontWeight: "600",
                    top: "20px",
                    right: "50px",
                    position: "absolute",
                    cursor: "pointer",

                    "@media (max-width: 468px)": {
                      top: "20px",
                      right: "10px",
                    },
                  }}
                >
                  see more...
                </Typography>
              </Box>

              <Grid
                container
                direction="row"
                spacing={0}
                justify="center"
                alignItems="center"
                columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
              >
                {" "}
                <Grid Item xs={12} sm={6}>
                  <Box
                    className={classes.jobbox}
                    sx={{ margin: "10px 10px 10px 0px" }}
                  >
                    <JobCardMain />
                  </Box>
                </Grid>
                <Grid Item xs={12} sm={6}>
                  <Box
                    className={classes.jobbox}
                    sx={{
                      margin: "10px 0px 10px 10px",
                      "@media (max-width: 468px)": {
                        margin: "20px 10px 10px 0px",
                      },
                    }}
                  >
                    <JobCardMain />
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default JobsPage;
