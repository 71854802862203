import React, { useEffect } from "react";
import { Grid, Box, Button, Typography, Stack } from "@mui/material";
import dropDown from "../../../assets/Icons/Dropdown.png";
import UpArrow from "../../../assets/Icons/UpArrow.png";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import * as jobsActions from "../../../Store/Jobs";
import * as authActions from "../../../Store/auth";
import { useSelector, useDispatch } from "react-redux";
import JobCandidateCard from "../../../components/jobCards/jobCandidateCard";
import JobCandidateCardnew from "../../../components/jobCards/JobCandidateCardnew";
import CustomizedSnackbars from "../../../components/Snackbar/CustomizedSnackbars";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Montserrat',
  },
  container: { width: "auto", padding: "0", margin: "0" },

  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 70px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    padding: "20px",
    margin: "20px 4px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#EEF3FD",
    position: "relative",
  },
  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (max-width: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: "0px",
      padding: "0px 5px",
    },
    container: {
      width: "100%",
      padding: "0px 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0px",
      borderRadius: "10px",
    },
  },
}));

const CandidateProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/candidateFeedback");
  };
  const redirectCandidateJd = () => {
    navigate("/JobDescription");
  };
  const candidateProfile = useSelector(
    (state) => state?.auth?.candidateProfile
  );
  const cadidateAppliedJobs = useSelector(
    (state) => state?.jobs?.cadidateAppliedJobs
  );
  const cadidateShortlistJobs = useSelector(
    (state) => state?.jobs?.cadidateShortlistJobs
  );
  const cadidateHoldJobs = useSelector(
    (state) => state?.jobs?.cadidateHoldJobs
  );
  const cadidateRejectedJobs = useSelector(
    (state) => state?.jobs?.cadidateRejectJobs
  );

  const withdrawJobs = useSelector((state)=> state?.jobs?.withdrawJobs);

  const educationlist = useSelector((state) => state?.auth?.educationlist);
  const [numberOfAppliedJobs, setNumberOfAppliedJobs] = React.useState(2);
  const [numberOfShortlistedJobs, setNumberOfShortlistedJobs] =
    React.useState(2);
  const [numberOfHoldJobs, setNumberOfHoldJobs] = React.useState(2);
  const [numberOfRejectedJobs, setNumberOfRejectedJobs] = React.useState(2);

  const [numberOfWithdrawJobs, setNumberOfWithdrawJobs] = React.useState(2);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  useEffect(() => {
    async function geteducationlist() {
      const educationlist = await dispatch(
        authActions.candidateEducationList()
      );
    }
    geteducationlist();
  }, []);
  useEffect(() => {
    async function companyProfile() {
      const profiless = await dispatch(authActions.getCandidateProfile());
      console.log("closedJobList ==========", profiless);
    }
    companyProfile();
  }, []);

  useEffect(() => {
    async function profileJobApplied() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "applied",
      };

      const profilejobs = await dispatch(
        jobsActions.getAllCandidateAppliedOpenJobs(data)
      );
    }
    profileJobApplied();
  }, []);

  useEffect(() => {
    async function profileJobShortlist() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "shortlisted",
      };

      const profilejobs = await dispatch(
        jobsActions.getAllCandidateShortlistOpenJobs(data)
      );
    }
    profileJobShortlist();
  }, []);

  useEffect(() => {
    async function profileJobRejected() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "rejected",
      };

      const profilejobs = await dispatch(
        jobsActions.getAllCandidateRejectedOpenJobs(data)
      );
    }
    profileJobRejected();
  }, []);

  useEffect(() => {
    async function profileJobHold() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "onhold",
      };

      const profiljobse = await dispatch(
        jobsActions.getAllCandidateHoldOpenJobs(data)
      );
    }
    profileJobHold();
  }, []);



  useEffect(() => {
    async function profileJobWithdraw() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "applied",
        withdraw_status: true
      };

      const profiljobse = await dispatch(
        jobsActions.getAllCandidateWithdrawOpenJobs(data)
      );
    }
    profileJobWithdraw();
  }, []);


  const seeMoreApplied = () => {
    if (cadidateAppliedJobs?.length > numberOfAppliedJobs) {
      setNumberOfAppliedJobs(cadidateAppliedJobs?.length);
    }
  };
  const seeMoreShortlisted = () => {
    if (cadidateShortlistJobs?.length > numberOfShortlistedJobs) {
      setNumberOfShortlistedJobs(cadidateShortlistJobs?.length);
    }
  };
  const seeMoreOnhold = () => {
    if (cadidateHoldJobs?.length > numberOfHoldJobs) {
      setNumberOfHoldJobs(cadidateHoldJobs?.length);
    }
  };
  const seeMoreRejected = () => {
    if (cadidateRejectedJobs?.length > numberOfRejectedJobs) {
      setNumberOfRejectedJobs(cadidateRejectedJobs?.length);
    }
  };

  const seeMoreWithdraw = () =>{
    if (withdrawJobs?.length > numberOfWithdrawJobs) {
      setNumberOfWithdrawJobs(withdrawJobs?.length);
    }
  }
  const seeLessApplied = () => {
    // if (openJobs?.length === numberOfOpenJobs) {
    setNumberOfAppliedJobs(2);
    // }
  };

  const seeLessWithdraw = () =>{
    setNumberOfWithdrawJobs(2)
  }

  const seeLessRejected = () => {
    setNumberOfRejectedJobs(2);
  };
  const seeLessHold = () => {
    setNumberOfHoldJobs(2);
  };
  const seeLessShortlisted = () => {
    setNumberOfShortlistedJobs(2);
  };

  const editProfile = () => {
    navigate("/candidate/editprofile");
  };

  const getDegree = () => {
    if (candidateProfile?.education) {
      let degree = educationlist.find(
        (item) => item._id === candidateProfile?.education[0]?.degree?.degree
      );
      return candidateProfile?.education[0]?.degree?.degree;
    }
  };
  const getDesignation = () => {
    if (candidateProfile?.experience_detail) {
      // let degree = educationlist.find(item => item._id === candidateProfile?.experience[0]?.degree);
      let designatin = candidateProfile?.experience_detail?.find(item => item.end_year === 0 );
      console.log('getDesignation', designatin);
      if (designatin){
      return  designatin?.designation?.designation;
    }
    else{
      return candidateProfile?.experience[0]?.designation?.designation;
    }
    }
  };
  return (
    <>
      <Navbar />
        {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction="row">
            <Box
              className={classes.imgFrame}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <Avatar
                variant="square"
                src={
                  candidateProfile?.profile_image
                    ? candidateProfile?.profile_image
                    : PersonIcon
                }
                sx={{ width: 120, height: 120 }}
              />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant="h4"
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}
              >
                {candidateProfile?.first_name} {candidateProfile?.last_name}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}
              >
                {getDesignation()}

              </Typography>
              <Stack
                direction="row"
                spacing={{ xs: 2, sm: 2, md: 2 }}
                sx={{ marginTop: "15px" }}
              >
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {parseInt(candidateProfile?.experience / 12) +
                      "." +
                      parseInt(candidateProfile?.experience % 12) +
                      " years"}
                  </Typography>
                </Box>
                <Box>|</Box>
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {getDegree()}
                  </Typography>
                </Box>
                <Box>|</Box>
                <Box>
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  >
                    {candidateProfile?.current_location?.city} {/* No. of employee */}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography
                    variant="h7"
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}
                  ></Typography>
                </Box>
              </Stack>
            </Box>
            <Box className={classes.button}>
              {" "}
              <Button
                onClick={editProfile}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "4px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}
              >
                Edit Profile
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box className={classes.body}>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Applied{" "}
                {cadidateAppliedJobs?.length > 0
                  ? "(" + cadidateAppliedJobs?.length + ")"
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (max-width: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}
                onClick={seeMoreApplied}
              >
                {cadidateAppliedJobs?.length > 1 &&
                cadidateAppliedJobs?.length !== numberOfAppliedJobs ? (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {cadidateAppliedJobs?.length > 0 &&
                cadidateAppliedJobs?.length === numberOfAppliedJobs && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                        top: "10px",
                      },
                    }}
                    onClick={seeLessApplied}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (max-width: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {cadidateAppliedJobs?.length > 0 &&
                cadidateAppliedJobs
                  ?.slice(0, numberOfAppliedJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index} sx={{ paddingRight: index % 2 === 0 ? "12px" : " 0px" , paddingLeft:  index % 2 != 0 ? "12px" : " 0px", paddingTop:"8px"}}>

                          <JobCandidateCard data={list?.job_id} />

                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Shortlisted{" "}
                {cadidateShortlistJobs?.length > 0
                  ? "(" + cadidateShortlistJobs?.length + ")"
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (max-width: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}
                onClick={seeMoreShortlisted}
              >
                {cadidateShortlistJobs?.length > 1 &&
                cadidateShortlistJobs?.length !== numberOfShortlistedJobs ? (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {cadidateShortlistJobs?.length > 0 &&
                cadidateShortlistJobs?.length === numberOfShortlistedJobs && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                        top: "10px",
                      },
                    }}
                    onClick={seeLessShortlisted}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (max-width: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {cadidateShortlistJobs?.length > 0 &&
                cadidateShortlistJobs
                  ?.slice(0, numberOfShortlistedJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index} sx={{ paddingRight: index % 2 === 0 ? "12px" : " 0px" , paddingLeft:  index % 2 != 0 ? "12px" : " 0px", paddingTop:"16px"}}>
                          <JobCandidateCard data={list?.job_id}/>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                OnHold{" "}
                {cadidateHoldJobs?.length > 0
                  ? "(" + cadidateHoldJobs?.length + ")"
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (max-width: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}
                onClick={seeMoreOnhold}
              >
                {cadidateHoldJobs?.length > 1 &&
                cadidateHoldJobs?.length !== numberOfHoldJobs ? (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {cadidateHoldJobs?.length > 0 &&
                cadidateHoldJobs?.length === numberOfHoldJobs && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                        top: "10px",
                      },
                    }}
                    onClick={seeLessHold}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (max-width: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {cadidateHoldJobs?.length > 0 &&
                cadidateHoldJobs
                  ?.slice(0, numberOfHoldJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index} sx={{ paddingRight: index % 2 === 0 ? "12px" : " 0px" , paddingLeft:  index % 2 != 0 ? "12px" : " 0px", paddingTop:"16px"}}>

                          <JobCandidateCard data={list?.job_id} />

                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Rejected{" "}
                {cadidateRejectedJobs?.length > 0
                  ? "(" + cadidateRejectedJobs?.length + ")"
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (max-width: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}
                onClick={seeMoreRejected}
              >
                {cadidateRejectedJobs?.length > 1 &&
                cadidateRejectedJobs?.length !== numberOfRejectedJobs ? (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {cadidateRejectedJobs?.length > 0 &&
                cadidateRejectedJobs?.length === numberOfRejectedJobs && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                        top: "10px",
                      },
                    }}
                    onClick={seeLessRejected}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (max-width: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {cadidateRejectedJobs?.length > 0 &&
                cadidateRejectedJobs
                  ?.slice(0, numberOfRejectedJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index} sx={{ paddingRight: index % 2 === 0 ? "12px" : " 0px" , paddingLeft:  index % 2 != 0 ? "12px" : " 0px", paddingTop:"16px"}}>

                          <JobCandidateCard data={list?.job_id} />

                      </Grid>
                    );
                  })}
            </Grid>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Withdraw{" "}
                {withdrawJobs?.length > 0
                  ? "(" + withdrawJobs?.length + ")"
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (max-width: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}
                onClick={seeMoreWithdraw}
              >
                {withdrawJobs?.length > 1 &&
                withdrawJobs?.length !== numberOfWithdrawJobs ? (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {withdrawJobs?.length > 0 &&
                withdrawJobs?.length === numberOfWithdrawJobs && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      "@media (max-width: 468px)": {
                        width: "16px",
                        height: "16px",
                        top: "10px",
                      },
                    }}
                    onClick={seeLessWithdraw}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (max-width: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>

            <Grid
              container
              direction="row"
              spacing={0}
              justify="center"
              alignItems="center"
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {withdrawJobs?.length > 0 &&
                withdrawJobs
                  ?.slice(0, numberOfWithdrawJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index} sx={{ paddingRight: index % 2 === 0 ? "12px" : " 0px" , paddingLeft:  index % 2 != 0 ? "12px" : " 0px",paddingTop:"16px"}}>
                          <JobCandidateCard data={list?.job_id} />
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        </Box>
      </Box>



    </>
  );
};
export default CandidateProfile;

// <Box component="img" className={classes.img} src={PersonIcon} />
