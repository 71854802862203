import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Tooltip,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import companylogo from '../../assets/images/dummy.png';
import experience from '../../assets/Icons/Experience.png';
import job from '../../assets/Icons/JobType.png';
import ind from '../../assets/Icons/Industry.png';
import location from '../../assets/Icons/Location.png';
import date from '../../assets/Icons/Calendar.png';
import designation from '../../assets/Icons/Function.png';
import salary from '../../assets/Icons/Salary.png';
import applicant from '../../assets/Icons/Applicants.png';
import jobdescription from '../../assets/Icons/JobDescription.png';
import Edu from '../../assets/Icons/Education.png';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
}));

const JobCardCompanyJdMobile = (props) => {
  const classes = useStyles();
  const [status, setStatus] = React.useState('Open');
  const handleSelect = (event) => {
    setStatus(event.target.value);
  };
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/JobDescription');
  };
  const redirectApply = () => {
    navigate('/candidateRegister');
  };
  return (
    <>
      {' '}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
      >
        <Grid Item>
          <Typography
            variant='h5'
            sx={{
              marginTop: '15px',
              marginLeft: '40px',
              textAlign: 'left',
              color: '#304672',
              fontWeight: '600',
              '@media (max-width: 468px)': {
                marginTop: '10px',
                fontSize: '16px',
                marginLeft: '30px',
              },
            }}
          >
            Designation
          </Typography>
          <Typography
            variant='h7'
            sx={{
              marginLeft: '40px',
              textAlign: 'left',
              color: '#304672',
              fontWeight: '600',
              '@media (max-width: 468px)': {
                marginTop: '10px',
                fontSize: '16px',
                marginLeft: '30px',
              },
            }}
          >
            Company name
          </Typography>
        </Grid>
        <Grid Item>
          <Box
            component='img'
            src={companylogo}
            sx={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              width: '80px',
              height: '80px',
              '@media (max-width: 468px)': {
                top: '10px',
                right: '10px',
                width: '60px',
                height: '60px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid Item xs={12} sm={12}>
          <Grid container sx={{ marginTop: '10px' }}>
            <Grid Item sm={5}>
              {' '}
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Location'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={location}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Location
                  </Typography>{' '}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Job Type'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={job}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Job Type
                  </Typography>{' '}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Industry'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={ind}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Industry
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Function'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={designation}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Function
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid Item sm={5}>
              {' '}
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Total Experience'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={experience}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Experience in Years
                  </Typography>{' '}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title=' Salary'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={salary}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Salary
                  </Typography>{' '}
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title=' Total applicants'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={applicant}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Total applicants
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid Item xs={2} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    disableFocusListener
                    disableTouchListener
                    title='Date'
                    placement='left'
                    arrow
                  >
                    <IconButton>
                      {' '}
                      <Box
                        component='img'
                        sx={{
                          width: '18px',
                          height: '18px',
                          '@media (max-width: 468px)': {
                            width: '14px',
                            height: '14px',
                          },
                        }}
                        src={date}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid Item xs={10} sm={10}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginTop: '3px',
                      marginLeft: '10px',
                      fontSize: '16px',
                      textAlign: 'left',
                      color: '#5677B9',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Date(2 Jul 2022)
                  </Typography>{' '}
                </Grid>
              </Grid>
            </Grid>
            <Grid Item sm={1}>
              {' '}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid Item xs={2} sm={1}>
          <Tooltip
            enterNextDelay={1500}
            enterDelay={1500}
            leaveDelay={200}
            title='Job Description'
            placement='left'
            arrow
          >
            <IconButton>
              {' '}
              <Box
                component='img'
                sx={{
                  width: '18px',
                  height: '18px',
                  '@media (max-width: 468px)': {
                    width: '14px',
                    height: '14px',
                  },
                }}
                src={Edu}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid Item xs={10} sm={10}>
          <Box
            sx={{
              marginTop: '3px',
              marginLeft: '-23px',
              textAlign: 'left',
              color: '#5677B9',
            }}
          >
            <Typography
              variant='p'
              sx={{
                textAlign: 'left',
                color: '#5677B9',
                fontWeight: '500',
                marginBottom: '20px',
                '@media (max-width: 468px)': {
                  fontSize: '14px',
                },
              }}
            >
              Education
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid Item xs={2} sm={1}>
          <Tooltip
            enterNextDelay={1500}
            enterDelay={1500}
            leaveDelay={200}
            title='Job Description'
            placement='left'
            arrow
          >
            <IconButton>
              {' '}
              <Box
                component='img'
                sx={{
                  marginLeft: '2px',
                  width: '18px',
                  height: '18px',
                  '@media (max-width: 468px)': {
                    width: '14px',
                    height: '14px',
                  },
                }}
                src={jobdescription}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid Item xs={10} sm={10}>
          <Box
            sx={{
              marginTop: '3px',
              marginLeft: '-23px',
              textAlign: 'left',
            }}
          >
            <Typography
              variant='p'
              sx={{
                textAlign: 'left',
                color: '#5677B9',
                fontWeight: '500',
                marginBottom: '20px',
                '@media (max-width: 468px)': { fontSize: '14px' },
              }}
            >
              Nunc vel risus commodo viverra maecenas accumsan. At lectus urna
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
        sx={{ '@media (max-width: 468px)': { display: 'none' } }}
      >
        <Grid Item xs={2} sm={1}>
          <Tooltip
            enterNextDelay={1500}
            enterDelay={1500}
            leaveDelay={200}
            title='Job Description'
            placement='left'
            arrow
          >
            <IconButton>
              {' '}
              <Box
                component='img'
                sx={{
                  marginLeft: '2px',
                  width: '18px',
                  height: '18px',
                }}
                src={jobdescription}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid Item xs={10} sm={10}>
          <Box
            sx={{
              marginTop: '3px',
              marginLeft: '-23px',
              textAlign: 'left',
            }}
          >
            <Typography
              variant='p'
              sx={{
                textAlign: 'left',
                color: '#5677B9',
                fontWeight: '500',
                marginBottom: '20px',
              }}
            >
              Nunc vel risus commodo viverra maecenas accumsan. At lectus urna
              duis convallis. Pellentesque sit amet Volutpat consequat mauris
              nunc congue nisi vitae..
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justify='center'
        alignItems='center'
        sx={{
          marginTop: '60px',
          '@media (max-width: 468px)': { marginTop: '10px' },
        }}
      >
        <Grid Item>
          {' '}
          <Box
            sx={{
              '& > :not(style)': {
                width: '12ch',
              },
            }}
          >
            <Select
              required
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  sx={{
                    color: '#FDCF57 ',
                    position: 'absolute',
                    right: 8,
                    pointerEvents: 'none',
                  }}
                />
              )}
              sx={{
                fontSize: '18px',
                width: '100%',
                color: '#FDCF57 ',
                backgroundColor: '#223870',
                fontWeight: '500',
              }}
              value={status}
              onChange={handleSelect}
              className={classes.countrycode}
            >
              <MenuItem value='Open'>Open</MenuItem>
              <MenuItem value='Closed'>Closed</MenuItem>
              <MenuItem value='Inactive'>Inactive</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid Item>
          {' '}
          <Button
            type='submit'
            sx={{
              marginLeft: '20px',
              textTransform: 'none',
              textDecoration: 'none',
              padding: '4px 25px',
              borderRadius: '3px ',
              color: '#FDCF57',
              fontWeight: '500',
              fontSize: '18px',
              backgroundColor: '#223870',
              '&:hover': {
                backgroundColor: '#223870',
                color: '#FDCF57 ',
              },
            }}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default JobCardCompanyJdMobile;
