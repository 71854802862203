import React, { useEffect } from 'react';
import { Grid, Box, Button, Typography, Stack } from '@mui/material';
import Logo from '../../../assets/images/LogowithTagline.png';
import dropDown from '../../../assets/Icons/Dropdown.png';
import UpArrow from '../../../assets/Icons/UpArrow.png';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Navbar from '../../../components/Header/Navbar';
import JobCardMain from '../../../components/jobCards/jobCardMain';
import CustomizedSnackbars from '../../../components/Snackbar/CustomizedSnackbars';
import { industryList1 } from '../../../assets/Data/data';
import { Size } from '../../../assets/Data/data';
import { useSelector, useDispatch } from 'react-redux';
import * as jobsActions from '../../../Store/Jobs';
import * as authActions from '../../../Store/auth';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  container: { width: 'auto', padding: '0', margin: '0' },

  topbar: {
    position: 'relative',
    marginTop: '80px',
    padding: '20px 75px',
    backgroundSize: 'cover',
    backgroundColor: '#DFE5F6',
    height: 'auto',
    width: '100%',
    backgroundPosition: 'center',
  },
  imgFrame: {
    background: '#fff',
    width: '120px',
    height: '120px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  img: {
    objectFit: 'contain',
    margin: '0 auto',
    width: '110px',
    height: '110px',
    borderRadius: '5px',
    backgroundSize: 'cover',
  },
  text: { marginLeft: '30px' },
  button: {
    position: 'absolute',
    top: '55px',
    right: '100px',
  },
  body: {
    padding: '20px 80px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#F6F8FD',
    textAlign: 'center',
  },
  jobbox: {
    marginLeft: '15px',
    padding: '20px 0px',
    marginBottom: '20px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#EEF3FD',
    position: 'relative',
  },

  '@media (min-width: 1640px)': {
    jobbox: {
      marginLeft: '15px',
      padding: ' 30px 0px',
    },
    mainHome: {
      marginTop: '80px',
      paddingTop: '150px',
    },
    title: {
      paddingRight: '60px ',
    },
  },
  '@media (max-width: 468px)': {
    title: {
      fontSize: '22px',
      textAlign: 'center',
      lineHeight: '26px',
    },
    jobbox: {
      width: '100%',
      margin: ' 20px 0px 0px 0px',
      padding: '0px 5px',
    },
    container: {
      width: '100%',
      padding: '0px 0px',
    },
    topbar: {
      position: 'relative',
      padding: '20px 10px',
      height: '170px',
      width: '100%',
    },
    imgFrame: {
      width: '90px',
      height: '90px',
    },
    img: {
      width: '80px',
      height: '80px',
    },
    text: { marginLeft: '10px' },
    button: {
      position: 'absolute',
      top: '120px',
      right: '20px',
    },
    body: {
      padding: '0px',
      borderRadius: '10px',
    },
  },
}));

const CompanyProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/createJob');
  };
  const redirectedit = () => {
    navigate('/editCompanyProfile');
  };
  const redirectCompanyJd = () => {
    navigate('/companyJd');
  };
  const [pageNoOpen, setPageNoOpen] = React.useState(0);
  const [pageNoClosed, setPageNoClosed] = React.useState(0);
  const [pageNoInactive, setPageNoInactive] = React.useState(0);
  const profile = useSelector((state) => state?.auth?.profile);

  useEffect(() => {
    async function listofOpenJobs() {
      const data = {
        job_status: 'open',
        page_no: pageNoOpen,
      };
      const jobList = await dispatch(jobsActions.getAllOpenJobs(data));
      console.log('jobList==========', jobList);
    }
    listofOpenJobs();
  }, []);
  useEffect(() => {
    async function listofInactiveJobs() {
      const data = {
        job_status: 'inactive',
        page_no: pageNoInactive,
      };
      const inactiveJobList = await dispatch(
        jobsActions.getAllInactiveJobs(data)
      );
      console.log('inactiveJobList ==========', inactiveJobList);
    }
    listofInactiveJobs();
  }, []);
  useEffect(() => {
    async function listofClosedJobs() {
      const data = {
        job_status: 'closed',
        page_no: pageNoClosed,
      };
      const closedJobList = await dispatch(jobsActions.getAllClosedJobs(data));
      console.log('closedJobList ==========', closedJobList);
    }
    listofClosedJobs();
  }, []);

  useEffect(() => {
    async function companyProfile() {
      const profile = await dispatch(authActions.getProfile());
      console.log('closedJobList ==========', profile);
    }
    companyProfile();
  }, []);

  const [numberOfOpenJobs, setNumberOfOpenJobs] = React.useState(2);
  const [numberOfInactiveJobs, setNumberOfInactiveJobs] = React.useState(2);
  const [numberOfClosedJobs, setNumberOfClosedJobs] = React.useState(2);
  const openJobs = useSelector((state) => state?.jobs?.createdOpenJobList);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  console.log('openJobs==================', openJobs);
  const inactiveJobs = useSelector(
    (state) => state?.jobs?.createdInactiveJobList
  );
  console.log('inactiveJobs==================', inactiveJobs);
  const closedJobs = useSelector((state) => state?.jobs?.createdClosedJobList);
  console.log('closedJobs==================', closedJobs);
  const seeMoreOpen = () => {
    if (openJobs?.length > numberOfOpenJobs) {
      setNumberOfOpenJobs(openJobs?.length);
    }
  };
  const seeMoreInactive = () => {
    if (inactiveJobs?.length > numberOfInactiveJobs) {
      setNumberOfInactiveJobs(inactiveJobs?.length);
    }
  };
  const seeMoreClosed = () => {
    if (closedJobs?.length > numberOfClosedJobs) {
      setNumberOfClosedJobs(closedJobs?.length);
    }
  };
  const seeLessOpen = () => {
    setNumberOfOpenJobs(2);
  };
  const seeLessInactive = () => {
    setNumberOfInactiveJobs(2);
  };

  const seeLessClosed = () => {
    setNumberOfClosedJobs(2);
  };

  const getIndustry = () => {
    let industry = industryList1.find((item) => item._id === profile?.industry);
    console.log('industry', industry);
    return industry?.industry;
  };

  const getSize = () => {
    let industry = Size.find((item) => item.label === profile?.size);
    console.log('industry', industry);
    return industry?.value;
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        {' '}
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box className={classes.imgFrame}>
              {' '}
              <Box
                component='img'
                className={classes.img}
                src={profile?.logo ? profile?.logo : Logo}
              />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: '10px',
                  fontSize: '28px',
                  textAlign: 'left',
                  color: '#304672',
                  fontWeight: '600',
                  '@media (max-width: 468px)': {
                    marginTop: '5px',
                    fontSize: '18px',
                  },
                }}
              >
                {profile?.company_name ? profile?.company_name : ''}
              </Typography>
              <Typography
                variant='p'
                sx={{
                  fontSize: '18px',
                  textAlign: 'left',
                  color: '#223870',
                  fontWeight: 'normal',
                  '@media (max-width: 468px)': {
                    fontSize: '16px',
                  },
                }}
              >
                {profile?.tagline ? profile?.tagline?.join(' | ') : ''}
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 2, sm: 4, md: 4 }}
                sx={{
                  marginTop: '15px',
                  '@media (max-width: 468px)': {
                    marginTop: '5px',
                  },
                }}
              >
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: 'left',
                      color: '#5071C4',
                      fontWeight: '500',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    {' '}
                    {profile?.industry?.industry}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: 'left',
                      color: '#5071C4',
                      fontWeight: '500',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    {profile?.size?.min} - {profile?.size?.max} Employees
                  </Typography>
                </Box>
                <Box>
                  {' '}
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: 'left',
                      color: '#5071C4',
                      fontWeight: '500',
                      '@media (max-width: 468px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    {profile?.website ? profile?.website : ''}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box className={classes.button}>
              {' '}
              <Button
                onClick={redirectedit}
                sx={{
                  marginRight: '20px',
                  textTransform: 'none',
                  textDecoration: 'none',
                  padding: '4px 16px',
                  width: '152px',
                  borderRadius: '3px ',
                  color: '#FDCF57',
                  fontWeight: '500',
                  fontSize: '18px',
                  height: '48px',
                  backgroundColor: '#223870',
                  '&:hover': {
                    backgroundColor: '#223870',
                    color: '#FDCF57 ',
                  },
                  '@media (max-width: 468px)': {
                    padding: '4px 10px',
                    fontSize: '14px',
                  },
                }}
              >
                Edit
              </Button>
              <Button
                onClick={redirect}
                sx={{
                  textTransform: 'none',
                  textDecoration: 'none',
                  padding: '4px 16px',
                  borderRadius: '3px ',
                  color: '#FDCF57',
                  width: '152px',
                  height: '48px',
                  fontWeight: '500',
                  fontSize: '18px',
                  backgroundColor: '#223870',
                  '&:hover': {
                    backgroundColor: '#223870',
                    color: '#FDCF57 ',
                  },
                  '@media (max-width: 468px)': {
                    padding: '4px 10px',
                    fontSize: '14px',
                  },
                }}
              >
                + Create Job
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box className={classes.body}>
          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Open ({openJobs?.length > 0 ? openJobs?.length : '0'})
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
                onClick={seeMoreOpen}
              >
                {openJobs?.length > 1 &&
                openJobs?.length !== numberOfOpenJobs ? (
                  <Box
                    component='img'
                    sx={{
                      width: '20px',
                      height: '20px',
                      '@media (max-width: 468px)': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component='img'
                    sx={{
                      width: '20px',
                      height: '20px',
                      '@media (max-width: 468px)': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>

              {openJobs?.length > 0 &&
                openJobs?.length === numberOfOpenJobs && (
                  <Button
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      width: '20px',
                      height: '20px',
                      '@media (max-width: 468px)': {
                        width: '16px',
                        height: '16px',
                        top: '10px',
                      },
                    }}
                    onClick={seeLessOpen}
                  >
                    <Box
                      component='img'
                      sx={{
                        width: '20px',
                        height: '20px',
                        '@media (max-width: 468px)': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              // columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {openJobs?.length > 0 &&
                openJobs?.slice(0, numberOfOpenJobs)?.map((list, index) => {
                  return (
                    <Grid Item xs={12} sm={6} key={index}>
                      <Box
                        className={classes.jobbox}
                        onClick={redirectCompanyJd}
                      >
                        <JobCardMain data={list} />
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Inactive (
                {inactiveJobs?.length > 0 ? inactiveJobs?.length : '0'})
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
              >
                {' '}
                <Box
                  component='img'
                  sx={{
                    width: '20px',
                    height: '20px',
                    '@media (max-width: 468px)': {
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreInactive}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {inactiveJobs?.length > 0 &&
                inactiveJobs
                  ?.slice(0, numberOfInactiveJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid Item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCardMain data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              '@media (max-width: 468px)': { marginTop: '30px' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant='h6'
                sx={{
                  color: '#223870',
                  fontWeight: '600',
                  marginBottom: '20px',
                }}
              >
                Closed ({closedJobs?.length > 0 ? closedJobs?.length : '0'})
              </Typography>
              <Button
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  width: '20px',
                  height: '20px',
                  '@media (max-width: 468px)': {
                    width: '16px',
                    height: '16px',
                    top: '10px',
                  },
                }}
              >
                {' '}
                <Box
                  component='img'
                  sx={{
                    width: '20px',
                    height: '20px',
                    '@media (max-width: 468px)': {
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreClosed}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
            >
              {closedJobs?.length > 0 &&
                closedJobs?.slice(0, numberOfClosedJobs)?.map((list, index) => {
                  return (
                    <Grid Item xs={12} sm={6} key={index}>
                      <Box className={classes.jobbox}>
                        <JobCardMain data={list} />
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CompanyProfile;
