import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, IconButton, Tooltip } from '@mui/material';
import companylogo from '../../assets/images/dummy.png';
import experience from '../../assets/Icons/Experience.png';
import jobtype from '../../assets/Icons/JobType.png';
import industry from '../../assets/Icons/Industry.png';
import location from '../../assets/Icons/Location.png';
import date from '../../assets/Icons/Calendar.png';
import designation from '../../assets/Icons/Function.png';
import salary from '../../assets/Icons/Salary.png';
import applicant from '../../assets/Icons/Applicants.png';
import education from '../../assets/Icons/Education.png';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import * as jobsActions from '../../Store/Jobs';
import { useSelector, useDispatch } from 'react-redux';
import { industryList1 } from '../../assets/Data/data';
import { functionList1 } from '../../assets/Data/data';
import { typet } from '../../assets/Data/data';
import { Size } from '../../assets/Data/data';
import { Type } from '../../assets/Data/data';

import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
   fontFamily: 'Montserrat',
  },
  container: { width: 'auto', padding: '0', margin: '0' },

  topbar: {
    position: 'relative',
    marginTop: '80px',
    padding: '20px 75px',
    backgroundSize: 'cover',
    backgroundColor: '#DFE5F6',
    height: 'auto',
    width: '100%',
    backgroundPosition: 'center',
  },
  imgFrame: {
    background: '#fff',
    width: '120px',
    height: '120px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  img: {
    objectFit: 'contain',
    margin: '0 auto',
    width: '110px',
    height: '110px',
    borderRadius: '5px',
    backgroundSize: 'cover',
  },
  text: { marginLeft: '30px' },
  button: {
    position: 'absolute',
    top: '55px',
    right: '100px',
  },
  body: {
    padding: '20px 80px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#F6F8FD',
    textAlign: 'center',
  },
  jobbox: {
    marginLeft: '15px',
    padding: '20px 0px',
    marginBottom: '20px',
    borderRadius: '10px',
    height: 'auto',
    color: '#223870',
    backgroundColor: '#EEF3FD',
    position: 'relative',
  },

  '@media (min-width: 1640px)': {
    mainHome: {
      marginTop: '80px',
      paddingTop: '150px',
    },
    title: {
      paddingRight: '60px ',
    },
  },
  '@media (max-width: 468px)': {
    title: {
      fontSize: '22px',
      textAlign: 'center',
      lineHeight: '26px',
    },
    jobbox: {
      width: '100%',
      margin: ' 20px 0px 0px 0px',
      padding: '0px 5px',
    },
    container: {
      width: '100%',
      padding: '0px 0px',
    },
    topbar: {
      position: 'relative',
      padding: '20px 10px',
      height: '170px',
      width: '100%',
    },
    imgFrame: {
      width: '90px',
      height: '90px',
    },
    img: {
      width: '80px',
      height: '80px',
    },
    text: { marginLeft: '10px' },
    button: {
      position: 'absolute',
      top: '120px',
      right: '20px',
    },
    body: {
      padding: '0px',
      borderRadius: '10px',
    },
  },
}));

function JobCandidateCardnew(props) {
  console.log('JobCandidateCard', props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [jobdetails, setJobDetails] = useState('');
  const educationlist = useSelector((state) => state?.auth?.educationlist);
  const candidateDetails = async (e) => {
    e.stopPropagation();
    console.log('id', props?.data);
    const viewjob = await dispatch(jobsActions.saveJobCandidate(props?.data));
    window.scrollTo({ top: 0 });
    navigate('/candidateDetails/' + props?.data?.user_id?._id);
  };

  useEffect(() => {
    async function jobDetails() {
      if (props?.data?.job_id) {
        const id = props?.data?.job_id;
        const jobList = await dispatch(jobsActions.getJobDescription(id));
        console.log('jobList', jobList);
        if (jobList?.data?.status === true) {
          setJobDetails(jobList?.data?.data[0]);
        }
      }
    }
    jobDetails();
  }, []);

  const getIndustry = () => {
    let industry = industryList1.find(
      (item) => item._id === jobdetails?.industry
    );
    console.log('industry', industry);
    return industry?.industry;
  };

  const getFunction = () => {
    let functionl = functionList1.find(
      (item) => item._id === jobdetails?.function
    );
    console.log('industry', functionl);
    return functionl?.function;
  };

  const getType = () => {
    let type = typet.find((item) => item._id === jobdetails?.type);
    console.log('type', type);
    return type?.value;
  };
  const getEducation = () => {
    if (props?.data?.user_id?.education?.length > 0) {
      console.log('getEducation', props?.data?.user_id?.education[0]?.degree);
      let degree = educationlist.find(
        (item) => item._id === props?.data?.user_id?.education[0]?.degree
      );

      return degree?.degree;
    }
  };

  return (
    <>
      <div
        onClick={candidateDetails}
        style={{ cursor: 'pointer', margin: '0px 4px 0px 4px' }}
      >
        {' '}
        <Grid container>
          <Grid Item>
            <Typography
              variant='h5'
              sx={{
                position: 'absolute',
                top: '10px',
                left: '20px',
                color: '#304672',
                fontWeight: '600',
                '@media (max-width: 468px)': {
                  top: '5px',
                  left: '5px',
                  marginTop: '10px',
                  fontSize: '16px',
                },
              }}
            >
              {props?.data?.user_id?.first_name
                ? props?.data?.user_id?.first_name
                : ''}{' '}
              {'  '}
              {props?.data?.user_id?.last_name
                ? props?.data?.user_id?.last_name
                : ''}
            </Typography>
            <Typography
              variant='h6'
              sx={{
                position: 'absolute',
                top: '35px',
                left: '20px',
                color: '#304672',
                fontWeight: '600',
                '@media (max-width: 468px)': {
                  top: '25px',
                  left: '10px',
                  marginTop: '10px',
                  fontSize: '16px',
                },
              }}
            ></Typography>
          </Grid>
          <Grid Item>
            <Box
              component='img'
              src={
                props?.data?.user_id?.profile_image
                  ? props?.data?.user_id?.profile_image
                  : ''
              }
              sx={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                width: '60px',
                height: '60px',
                '@media (max-width: 468px)': {
                  top: '10px',
                  right: '10px',
                  width: '60px',
                  height: '60px',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ marginLeft: '5px' }}>
          <Grid Item xs={12} sm={12}>
            <Grid
              container
              sx={{
                marginTop: '50px',
              }}
            >
              <Grid Item sm={6} xs={6}>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Location'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={location}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.location
                        ? props?.data?.location
                        : jobdetails?.location?.city}
                      {props?.data?.location}
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Job Type'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={jobtype}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {getType()}
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Industry'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={industry}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {jobdetails?.industry?.industry}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Function'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={designation}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {jobdetails?.function?.function}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid Item sm={6} xs={6}>
                {' '}
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Total Experience'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={experience}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.total_relevant_experience} yrs exp.
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title=' Salary'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '18px',
                            height: '18px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={salary}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      {props?.data?.expected_ctc} lpa
                    </Typography>{' '}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: '5px' }}>
                  <Grid Item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      disableFocusListener
                      disableTouchListener
                      title='Date'
                      placement='left'
                      arrow
                    >
                      <IconButton>
                        {' '}
                        <Box
                          component='img'
                          sx={{
                            marginTop: '-4px',
                            width: '16px',
                            height: '16px',
                            '@media (max-width: 468px)': {
                              marginTop: '-8px',
                              width: '14px',
                              height: '14px',
                            },
                          }}
                          src={date}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid Item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: '16px',
                        textAlign: 'left',
                        marginLeft: '-2px',
                        color: '#5677B9',
                        '@media (max-width: 468px)': {
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'left',
                        },
                      }}
                    >
                      Applied on{' '}
                      {moment(
                        props?.data?.created_at
                          ? props?.data?.created_at
                          : jobdetails?.created_at
                      ).format('ll')}
                    </Typography>{' '}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid Item xs={1} sm={1}>
              <Box
                component='img'
                sx={{
                  marginLeft: '10px',
                  marginTop: '3px',
                  width: '18px',
                  height: '18px',
                  '@media (max-width: 468px)': {
                    marginTop: '-5px',
                    width: '14px',
                    height: '14px',
                  },
                }}
                src={education}
              />
            </Grid>
            <Grid Item xs={11} sm={11}>
              <Typography
                variant='h6'
                sx={{
                  marginLeft: '3px',
                  fontSize: '16px',
                  textAlign: 'left',
                  color: '#5677B9',
                  '@media (max-width: 468px)': {
                    marginLeft: '3px',
                    fontSize: '14px',
                  },
                }}
              >
                {getEducation()}
              </Typography>{' '}
            </Grid>
          </Grid>
        </Grid>{' '}
      </div>
    </>
  );
}

export default JobCandidateCardnew;
